import axios from "axios";
import { BASE_URL } from '../url/baseurl'

export const report_category_table_data_api = async (data) => {
    let resposne = axios.post(`${BASE_URL}/api/ReportingCategoryList`, data);
    return resposne;
}

export const report_category_delete = async (data) => {
    let resposne = axios.post(`${BASE_URL}/api/delete_cuzcategory`, data);
    return resposne;
}

// ------------------- Completed Topics Report API -----------------------

export const user_session_data = async (data) => {
    let response = axios.get(`${BASE_URL}/api/user_session_data_completerpt?userid=${data}`);
    return response;
}
export const report_data = async ({userid,customerid}) => {
    let response = axios.get(`${BASE_URL}/api/report_data_program?userid=${userid}&customerid=${customerid}`);
    return response;
}

export const report_group_location_api = async ({customerid}) => {
    let response = axios.get(`${BASE_URL}/api/report_group_desc?customerid=${customerid}`);
    return response;
}

export const custgroup_location_api = async ({customerid,Custgroupid,Groupid}) => {
    let response = axios.get(`${BASE_URL}/api/custgroup_location?customerid=${customerid}&Custgroupid=${Custgroupid}&Groupid=${Groupid}`);
    return response;
}

// ------------------ Topic History Comparison API -------------------------

export const compare_user_session_data = async (data) => {
    let response = axios.get(`${BASE_URL}/api/user_session_data?userid=${data}`);
    return response;
}
export const runreporta = async ({userid,customerid}) => {
    let response = axios.get(`${BASE_URL}/api/runreporta?userid=${userid}&customerid=${customerid}`);
    return response;
}

export const comparerpt_run_report_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/comparerpt_run_report`, data);
    return response;
};

export const runreporta_api = async (data) => {
    console.log(data);
    let response = axios.post(`${BASE_URL}/api/runreporta`, data);
    return response;
};
export const comparerpt_drilldown_api = async (data) => {
    let response = axios.get(`${BASE_URL}/api/comparerpt_drilldown?
    topicid=${data.topicid}&
    customerid=${data.customerid}&
    locationid=${data.locationid}&
    latestperiod=${data.latestperiod}&
    periodcode=${data.periodcode}
    
    
    
    `);

    return response;
}

// ------------------------------------------------------------------------------