import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import ReactModal from "react-modal";
import MainHeader from "../../components/MainHeader";
import { cuz_drive_start } from "../../services/CuzDriveApi";
import { check_status_massage } from "../../services/CuzDriveApi";
import { delete_test } from "../../services/CuzDriveApi";
import { initial_step } from "../../services/CuzDriveApi";
import {
  response_solution,
  reverse_response,
} from "../../services/CuzDriveApi";
import { Radio, Input, Space, message } from "antd";
import * as Dataservice from "../../services/Database";
const theme = localStorage.getItem('brandColor');
// import * as Dataservice from "../services/Database"

const StyledLink = styled(Link)`
  color: darkgrey;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  left: 3.68%;
  top: 28.71%;
  width: 92.6%;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledButton = styled.button`
  background: ${theme};
  width: 100px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  color: white;
  padding: 0px 20px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;
const StyledTableRow = styled.tr`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding-left: 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledStatus = styled.td`
  color: green;
`;

const ModalStyle = {
  content: {
    width: "70%",
    // height: "70%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
  },
};

const CuzDriveTest = (props) => {
  //   console.log(props.match.params.topicid);

  const params = useParams();

  const topicid = params.topicid;
  const selection = params.selection;

  const [data, setData] = useState([]);
  const [result, set_result] = useState({});
  const [modal, set_modal] = useState(false);
  const [cust, set_cust] = useState([]);
  const [cuz, set_cuz] = useState([]);
  const [status_Masaage, set_Status_Message] = useState([]);
  const [getTopicid, setGetTopicid] = useState([]);
  const [question, setQuestion] = useState([]);
  const [question1, setQuestion1] = useState([]);
  const [count, setCount] = useState(0);
  // const [count1, setCount1] = useState("");
  const [solution, setSolution] = useState("");
  const [data2, setData2] = useState([]);
  const [ques2, setQues2] = useState("");
  const [currques, setCurrques] = useState("");
  const [index1, setIndex1] = useState("");
  const [quesformat, setQuesformat] = useState([]);
  const [count1, setCount1] = useState(0);
  const [cuur_index, setCuur_index] = useState(0);

  const [radio, setRadio] = useState("");
  const [c_id, setC_id] = useState(100001);
  const [c_id2, setC_id2] = useState(100002);

  const [category_display, set_category_display] = useState([]);
  const [guidance_display, set_guidance_display] = useState([]);
  const [userResponseData, setUserResponseData] = useState([]);

  const [previous, setPrevious] = useState(false);
  const [previous1, setPrevious1] = useState(false);
  // const [previous1, setPrevious1] = useState(false);
  const [quizEnd, setquizEnd] = useState(false);
  // list

  const history = useHistory();

  const getTableData = async () => {
    await cuz_drive_start()
      .then((res) => {
        console.log("cuz drive data===>", res);

        setData(res.data.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    document.body.style.backgroundColor = '#eee';
    getTableData();
    return () => {
      document.body.style.backgroundColor = '#fff';
    }
  }, []);

  const deleteTest = () => {
    delete_test({ topicid: topicid })
      .then((res) => {
        console.log("response delete test==>", res);
        console.log("response delete test==>", res.data.new_questions);
        setUserResponseData(res.data.question);
        setQuestion(res.data.new_questions);
        get_category_list(topicid);
        console.table(question+ " Ques");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initialStep = () => {
    initial_step({ topicid: topicid })
      .then((res) => {
        console.log("check intial step data===>",res.data.question);
          setUserResponseData(res.data.question);
        setQuestion(res.data.question);
        setquizEnd(res.data.quizEnd);
        setQuestion1(res.data.question);
        setCount1(res.data.lastqcode - 1);
      
        console.log(res.data.question);
        setData2(res.data);

        res.data.question?.map((attr, index) => {
          console.log("res.data.question[index].qcode ==>",res.data.question[index].qcode);
           console.log("res.data.lastqcode ==>",res.data.lastqcode);
          if (res.data.question[index].qcode == res.data.lastqcode) {
            console.log(
              res.data.question[index].qdescr,
              index,
              res.data.question.qdescr
            );
            setCurrques(res.data.question[index].qdescr);
            setIndex1(index);
          }
        });

        get_category_list(topicid);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const [priotity_display, set_priotity_display] = useState([]);
  const get_category_list = async (id) => {
    Dataservice.ReportingCategoryList({ topicid: id }).then((res) => {
      set_category_display(res.data["Reporting Category List"]);
      console.log("category", res.data["Reporting Category List"]);
    });
    Dataservice.guidance_list({ topicid: id }).then((res) => {
      set_guidance_display(res.data["guidance_list"]);
      console.log(res.data["guidance_list"]);
    });
    Dataservice.priority_list().then((res) => {
      set_priotity_display(res.data["Priority List"]);
      console.log(res.data["Priority List"]);
    });
  };

  React.useEffect(() => {
    if (selection == "continuetest") {
      initialStep();
    }
    if (selection == "deletetest") {
      deleteTest();
    }
  }, [topicid, selection]);

  const onChange1 = (e) => {
    console.log("radio checked", e.target.value);
    setRadio(e.target.value);
  };

  const submitSolution = async (qcode) => {
    console.log("1");
    console.log("radio response===>", radio);
    response_solution({
      topicid: topicid,
      qcode: String(qcode),
      response: radio,
    })
      .then((res) => {
        setRadio("");
        setUserResponseData(res.data.question);
        setQuestion(res.data.question);
        setQuestion1(res.data.question);
        setquizEnd(res.data.quizEnd);
        setCount(count + 1);
        setCount1(count1 + 1);
        console.log("response===>", res.data.question);
      })
      .catch((err) => {
        console.log(err);
      });

    set_display_solution(true);
  };

  const [display_solution, set_display_solution] = useState(false);

  console.log("length,count==>", question?.length, count);

  const nextSubmit = (data) => {
    console.log("length==>", question?.length, question);
    console.log("count==>", count);
   
    if (count + 1 <= question?.length) {
 
      if ((data.ifparentyes !== 0 || data.ifparentno !== 0 )||(data.ifparentyes == 0 && data.ifparentno == 0) ) {
      
        submitSolution(data.qcode);

        setPrevious(false);
      }

      // if (data.ifparentno !== 0) {
       
      //   submitSolution(data.qcode);

      //   setPrevious(false);
      // }

      // if (data.ifparentyes == 0 && data.ifparentno == 0) {
        
      //   submitSolution(data.qcode);
      //   setPrevious(false);
      // }
    } else {
      message.success("You have reached the end of your Topic quiz");
    }
  };

  const nextSubmit1 = (data) => {
    console.log("current question result==>", data);
        console.log("2 ==>");
    console.log("length==>", question1?.length);
    console.log("count==>", count1);

    if (count1 + 1 <= question1?.length) {
      if (data.ifparentyes !== 0) {
        submitSolution(data.qcode);
        setPrevious1(false);
      }

      if (data.ifparentno !== 0) {
        submitSolution(data.qcode);
        setPrevious1(false);
      }
      if (data.ifparentyes == 0 && data.ifparentno == 0) {
        submitSolution(data.qcode);
        setPrevious1(false);
      }
      console.log(data.qcode,"data.qcode ");
    } else {
      message("You have reached the end of your Topic quiz");
    }
  };

  const reverseResponse = async (qcode) => {
    console.log("radio response===>", radio);
    reverse_response({ topicid: topicid, qcode: String(qcode) })
      .then((res) => {
        console.log("previous data respone==>", res);
        setUserResponseData(res.data.question);
        // setQuestion(res.data.question);
        // setQuestion1(res.data.question);
        setRadio("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="container">
        <div align="center" style={{ marginBottom: 10, marginTop: 10 }}>
          <StyledButton
            onClick={() => {
              history.push(
                `/adminfunction/custom_topic_data/${localStorage.getItem(
                  "CrID"
                )}`
              );
            }}
          >
            Exit
          </StyledButton>
        </div>

        {selection == "continuetest" ? (
          <>
            {question1 &&
              question1
                .filter((data) => data.Validqcode === "Valid")
                ?.map((data, i) => {
                  if (i == count1) {
                    console.log(count1 +"count1");
                    console.log(count1 +"count1");
                   
                    console.table(data.Validqcode +"count1 data");
                    if (data.Validqcode === "Valid") {
                      console.log("data ==>" ,data);
                          console.log(
                        "count1===>",
                        count1,
                        "i==>",
                        i,
                        "len===>",
                        question1.filter((data) => data.Validqcode == "Valid")
                          .length - 1,
                        "barr====",
                        (100 * i) /
                          (question1.filter(
                            (data) => data.Validqcode == "Valid"
                          ).length -
                            1)
                      );
                      return (
                        <>
                          <div
                            className="testBorder bg-white"
                            style={{
                              border: "1px solid #dee2e6",
                              height: "300px",
                              marginBottom: "20px",
                            }}
                          >
                            <div className="question p-3">
                              <div class="progress">
                                <div
                                  class="bar"
                                  style={{
                                    width:
                                      "" +
                                      (100 * i) /
                                        (question1.filter(
                                          (data) => data.Validqcode == "Valid"
                                        ).length -
                                          1) +
                                      "%",
                                  }}
                                >
                                
                                  <p class="percent"></p>
                                </div>
                              </div>
                              <div className="head_div"></div>
                              <div
                                className="d-flex flex-row "
                                style={{
                                  float: "left",
                                  padding: 20,
                                  width: "100%",
                                }}
                              >
                                <h3 style={{ color: theme }}>Q.</h3>
                                <h5 className="mt-1 ml-2">
                                  &nbsp;{data.qdescr}
                                  
                                </h5>
                              </div>
                              <br />
                              <br />
                              <div className="ans ml-2">
                                <div
                                  className="d-flex flex-row question-title"
                                  style={{ float: "left" }}
                                >
                                  <h3
                                    style={{
                                      color: theme,
                                      fontSize: "20px",
                                    }}
                                  >
                                    Response :&nbsp;
                                  </h3>

                                  <Radio.Group
                                    onChange={(e) => {
                                      setRadio(e.target.value);
                                    }}
                                  >
                                    <Radio value={"Y"}>Yes</Radio>
                                    <Radio value={"N"}>No</Radio>
                                  </Radio.Group>
                                </div>
                                <div
                                  style={{
                                    float: "right",
                                    width: "95%",
                                    marginTop: 15,
                                  }}
                                >
                                  {/* For rendering of prev btn */}
                                  {count1 !== 0 ? (
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() => {
                                        setCount1(count1 - 1);
                                        setPrevious1(true);
                                        console.log("previou  button click");
                                        reverseResponse(data.qcode);
                                      }}
                                    >
                                      <i className="fa fa-angle-left"></i>
                                      &nbsp; Previous
                                    </button>
                                  ) : (
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  )}
                                  &nbsp; &nbsp;
                                  {question1.length !== count1 ? (
                                    <button
                                      className="btn btn-success"
                                      type="button"
                                      onClick={() => {
                                        
                                        console.log("next button");
                                        radio !== ""
                                          ? nextSubmit1(data)
                                          : message.info(
                                              "please select response"
                                            );
                                      }}
                                    >
                                      Next&nbsp;
                                      <i className="fa fa-angle-right"></i>
                                    </button>
                                    
                                  ) : (
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <h6 className="" style={{ paddingTop: "10px" }}>
                            <strong>Category : </strong>{" "}
                            {
                              category_display?.filter(
                                (datas) => datas.repcatid === data.repcatid
                              )[0]?.repcatdesc
                            }
                          </h6>
                          <h6 className="">
                            <strong>Priority : </strong>
                            {
                              priotity_display?.filter(
                                (datas) => datas.priorityid === data.priorityid
                              )[0]?.prioritydesc
                            }
                          </h6>
                          <div className="Guidance-display">
                            <strong
                              style={{
                                width: "100%",
                                float: "left",
                                fontSize: "17px",
                              }}
                            >
                              Guidance
                            </strong>{" "}
                            <span
                              className="Width100 bg-white"
                              style={{
                                border: "1px solid #dee2e6",
                                padding: "10px",
                                width: "50%",
                                float: "left",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              {
                                guidance_display?.filter(
                                  (datas) =>
                                    datas.guidanceid === data.guidanceid
                                )[0]?.cuzguidedesc
                              }
                            </span>
                          </div>
                        </>
                      );
                    } else {
                      console.log("count for else part==>", count1);
                      if (previous1) {
                        setCount1(count1 - 1);
                      } else {
                        console.log("next part of else");
                        setCount1(count1 + 1);
                        setPrevious1(false);
                      }
                    }
                  }
                })}
          </>
        ) : (
          <>
            {question &&
              question
                .filter((data) => data.Validqcode == "Valid")
                ?.map((data, i) => {
                  if (i === count) {
                    console.log(count +"count");
                    if (data.Validqcode == "Valid") {
                      console.log("data ==> ", data);
                      console.log(
                        "count===>",
                        count,
                        "i==>",
                        i,
                        "len===>",
                        question.filter((data) => data.Validqcode == "Valid")
                          .length - 1,
                        "barr====",
                        (100 * i) /
                          (question.filter((data) => data.Validqcode == "Valid")
                            .length -
                            1)
                      );
                      return (
                        <>
                          <div
                            className="testBorder bg-white"
                            style={{
                              border: "1px solid #dee2e6",
                              height: "300px",
                              marginBottom: "20px",
                            }}
                          >
                            <div className="question p-3 ">
                              <div class="progress">
                                <div
                                  class="bar"
                                  style={{
                                    width:
                                      "" +
                                      (100 * i) /
                                        (question.filter(
                                          (data) => data.Validqcode == "Valid"
                                        ).length -
                                          1) +
                                      "%",
                                  }}
                                >
                                  <p class="percent"></p>
                                </div>
                              </div>
                              <div
                                className="d-flex flex-row question-title"
                                style={{
                                  float: "left",
                                  padding: 20,
                                  width: "100%",
                                }}
                              >
                                <h3 style={{ color: theme }}>Q.</h3>
                                <h5 className="mt-1 ml-2">
                                  &nbsp;{data.qdescr}
                               
                                </h5>
                              </div>
                              <div className="ans ml-2">
                                <div
                                  className="d-flex flex-row question-title"
                                  style={{ float: "left" }}
                                >
                                  <h3
                                    style={{
                                      color: theme,
                                      fontSize: "20px",
                                    }}
                                  >
                                    Response :&nbsp;
                                  </h3>

                                  <Radio.Group
                                    onChange={(e) => {
                                      setRadio(e.target.value);
                                    }}
                                  >
                                    <Radio value={"Y"}>Yes</Radio>
                                    <Radio value={"N"}>No</Radio>
                                  </Radio.Group>
                                </div>
                                <div
                                  style={{
                                    float: "right",
                                    width: "95%",
                                    marginTop: 15,
                                  }}
                                >
                                  {/* For rendering of prev btn */}
                                  {count !== 0 ? (
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() => {
                                        setCount(count - 1);
                                        setPrevious(true);
                                        console.log("previou  button click");
                                        reverseResponse(data.qcode);
                                      }}
                                    >
                                      <i className="fa fa-angle-left"></i>
                                      &nbsp; Previous
                                    </button>
                                  ) : (
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  )}
                                  &nbsp; &nbsp;
                                  {question.length !== count ? (
                                    <button
                                      className="btn btn-success"
                                      type="button"
                                      onClick={() => {
                                        console.log("next button");
                                        radio !== ""
                                          ? nextSubmit(data)
                                          : message.info(
                                              "please select response"
                                            );
                                      }}
                                    >
                                      Next&nbsp;
                                      <i className="fa fa-angle-right"></i>
                                    </button>
                                  ) : (
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <h6 className="" style={{ paddingTop: "10px" }}>
                            <strong>Category : </strong>{" "}
                            {
                              category_display?.filter(
                                (datas) => datas.repcatid === data.repcatid
                              )[0]?.repcatdesc
                            }
                          </h6>
                          <h6 className="">
                            <strong>Priority : </strong>
                            {
                              priotity_display?.filter(
                                (datas) => datas.priorityid === data.priorityid
                              )[0]?.prioritydesc
                            }
                          </h6>
                          <div className="Guidance-display">
                            <strong
                              style={{
                                width: "100%",
                                float: "left",
                                fontSize: "17px",
                              }}
                            >
                              Guidance
                            </strong>{" "}
                            <span
                              className="Width100 bg-white"
                              style={{
                                border: "1px solid #dee2e6",
                                padding: "10px",
                                width: "50%",
                                float: "left",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              {
                                guidance_display?.filter(
                                  (datas) =>
                                    datas.guidanceid === data.guidanceid
                                )[0]?.cuzguidedesc
                              }
                            </span>
                          </div>
                        </>
                      );
                    } else {
                      console.log("count for else part==>", count);
                      if (previous) {
                        setCount(count - 1);
                      } else {
                        console.log("next part of else");
                        setCount(count + 1);
                        setPrevious(false);
                      }
                    }
                  }
                })}
          </>
        )}

        <div style={{ textAlign: "center", marginBottom: 50 }}>
          
          {/* {count + 1 === userResponseData?.length && count !== 0 && (
            <>
              <h6 style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
                You have reached the end of your Topic quiz.
              </h6>
            </>
          )} */}
          {/* {count1 + 1 === userResponseData?.length && count1 !== 0 && (
            <>
              <h6 style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
               You have reached the end of your Topic quiz.
              </h6>
            </>
          )} */}
            {quizEnd == true && (
            <>
              <h6 style={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
               You have reached the end of your Topic quiz.
              </h6>
            </>
          )}
        </div>
      </div>

      {/* ---------- Answers of previous questions in a stack view ----------- */}

      <div
        className="container marLeft"
        style={{ float: "left", marginLeft: "6%", marginTop: "2%" }}
      >
        <strong style={{ width: "100%", float: "left", fontSize: "17px" }}>
          Previous Responses
        </strong>
        <div className="d-flex row">
          <div className="col-md-12 col-lg-12">
            {userResponseData &&
              userResponseData?.map((data, i) => {
                if (data.response !== null) {
                  return (
                    <>
                      <div className="mt-2 mb-2 border1">
                        <div className="question bg-white p-3">
                          <div
                            className="d-flex flex-row question-title"
                            style={{
                              float: "left",
                              padding: 20,
                              width: "100%",
                            }}
                          >
                            <h3 style={{ color: theme }}>Q.</h3>
                            <h5 className="mt-1 ml-2">&nbsp;{data.qdescr}
                           
                            </h5>
                          </div>
                          <br />
                          <br />
                          <div className="ans">
                            <div
                              className="d-flex flex-row question-title"
                              style={{ float: "left" }}
                            >
                              <h3
                                style={{ color: theme, fontSize: "20px" }}
                              >
                                Response :&nbsp;
                              </h3>

                              <Radio.Group
                                value={
                                  data.response == "Y"
                                    ? "Y"
                                    : data.response == "N"
                                    ? "N"
                                    : ""
                                }
                              >
                                <Radio
                                  value={
                                    data.response == "Y"
                                      ? "Y"
                                      : data.response == "N"
                                      ? "N"
                                      : ""
                                  }
                                  style={{ fontWeight: "bold", fontSize: 16 }}
                                >
                                  {data.response == "Y" ? "Yes" : "No"}
                                </Radio>
                              </Radio.Group>
                            </div>
                            <StyledButton
                              style={{
                                opacity: 0,
                                marginLeft: "83px",
                                marginTop: "12px",
                              }}
                              onClick={() => {
                                //submitSolution(data.qcode);
                              }}
                            >
                              Submit
                            </StyledButton>
                            <br />
                          </div>

                          <div className="ans" style={{ fontSize: "15px" }}>
                            <strong>Solution Description : </strong>
                            {data.solutiondesc
                              ? data.solutiondesc
                              : "No description provided"}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CuzDriveTest;
