import React from 'react'
import MainHeader from '../components/MainHeader'
import SelectTable from '../components/SelectTable'



const AfterGetStarted = () => {
    return (
        <div> 
           <MainHeader/>
           <SelectTable/>
        </div>
    )
}

export default AfterGetStarted
