import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
   //"pk_test_51KVaSACxPlnlMJHkeKJGiTuRHcCsZbgB5yHz1uv37JHD7ZT1R4YzF6xTD3rNh1ImmaFBcb06bfiKjyMoyuwC6fgK00B64C1N7H"

  "pk_live_51KVaSACxPlnlMJHkzoWQ9Rg9iNJZwDYj7xKSbDGMlpEgpMTIig4DrkxhsS10P3P7uOJD1k6pDVtZq3m2WDpu1paR00r1TkeGPv"
);



const MainPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default MainPage;
