import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import {
  user_session_data_admin_api,
  feature_program_api,
  getAdminPDF_Api,
} from "../../../services/AdminProgramApi";
const theme = localStorage.getItem('brandColor');
const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const BigModalStyle1 = {
  content: {
    width: "600px",
    height: "550px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    justifyItem: "center",

    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
  },
};

const AdminFunctionModal = ({ match }) => {
  const [adm_function_modal, setAdm_function_modal] = useState(true);

  // user_session_data_admin api func call
  const [feature_prog_admin_func_data, setFeature_prog_admin_func_data] =
    useState([]);
  const [admn_custId, setAdmn_custId] = useState();

  const user_session_data_admin_func = async () => {
    user_session_data_admin_api(localStorage.getItem("UserID"))
      .then((res) => {
        if (res.data.code == 200) {
          console.log("user data admin res===>", res.data);
          setAdmn_custId(res.data.userdata[0].customerid);
          localStorage.setItem("UTData", JSON.stringify(res.data.userdata));
          let data = {
            customerid: res.data.userdata[0].customerid,
            userdata: res.data.userdata,
          };

          feature_program_api(data)
            .then((res) => {
              if (res.data.code == 200) {
                console.log("feature res data==>", res.data);
                setFeature_prog_admin_func_data(res.data.admfunction);
              } else {
                message.error(res);
              }
            })
            .catch((err) => {
              console.log("error--->>", err);
            });
        } else {
          message.error(res);
        }
      })
      .catch((err) => {
        console.log("error===>", err);
      });
  };

  const [pdfLinkA, setPdfLinkA] = useState("");
  const getAdminPDF = async () => {
    getAdminPDF_Api()
      .then((res) => {
        console.log(res);
        setPdfLinkA(res.data.new_record[0].pdf_link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    user_session_data_admin_func();
    getAdminPDF();
  }, []);

  const history = useHistory();

  return (
    <>
      <ReactModal style={BigModalStyle1} isOpen={adm_function_modal}>
        <div className="mt-4">
          <div className="text-center">
            <a
              onClick={() => {
                window.open(
                  `http://cuzdrivenew.reviewmydata.com${JSON.parse(pdfLinkA)}`,
                  "_blank"
                );
              }}
            >
              <i
                style={{ color: theme, fontSize: "25px" }}
                class="fas fa-info-circle"
              ></i>
            </a>
          </div>

          {feature_prog_admin_func_data
            ?.filter((data) => data.admfunction == "Edit Customer Data")
            .map((data) => {
              if (data.admfunction == "Edit Customer Data") {
                return (
                  <div>
                    <StyledButton1
                      onClick={() => {
                        history.push(
                          `/adminfunction/customer_data/${admn_custId}`
                        );
                      }}
                    >
                      Customer Data
                    </StyledButton1>
                  </div>
                );
              }
            })}
          {feature_prog_admin_func_data
            ?.filter((data) => data.admfunction == "Edit Location List")
            .map((data) => {
              if (data.admfunction == "Edit Location List") {
                return (
                  <div>
                    <StyledButton1
                      onClick={() => {
                        history.push(
                          `/adminfunction/location-list/${admn_custId}`
                        );
                      }}
                    >
                      Locations
                    </StyledButton1>
                  </div>
                );
              }
            })}
          {feature_prog_admin_func_data
            ?.filter((data) => data.admfunction == "Edit Admin/User")
            .map((data) => {
              if (data.admfunction == "Edit Admin/User") {
                return (
                  <div>
                    <StyledButton1
                      onClick={() => {
                        history.push(
                          `/adminfunction/admin_user/${admn_custId}`
                        );
                      }}
                    >
                      Admins and Users
                    </StyledButton1>
                  </div>
                );
              }
            })}
          {feature_prog_admin_func_data
            ?.filter((data) => data.admfunction == "Edit Admin/User Locations")
            .map((data) => {
              if (data.admfunction == "Edit Admin/User Locations") {
                return (
                  <div>
                    <StyledButton1
                      onClick={() => {
                        history.push(
                          `/adminfunction/admin_user_locations/${admn_custId}`
                        );
                      }}
                    >
                      Admin and User Locations
                    </StyledButton1>
                  </div>
                );
              }
            })}
          {feature_prog_admin_func_data
            ?.filter((data) => data.admfunction == "Edit Standard Topics")
            .map((data) => {
              if (data.admfunction == "Edit Standard Topics") {
                return (
                  <div>
                    <StyledButton1
                      onClick={() => {
                        history.push(
                          `/adminfunction/standard_topics/${admn_custId}`
                        );
                      }}
                    >
                      Standard Topics
                    </StyledButton1>
                  </div>
                );
              }
            })}
          {feature_prog_admin_func_data
            ?.filter((data) => data.admfunction == "Edit Custom Topic Data")
            .map((data) => {
              if (data.admfunction == "Edit Custom Topic Data") {
                return (
                  <div>
                    <StyledButton1
                      onClick={() => {
                        history.push(
                          `/adminfunction/custom_topic_data/${admn_custId}`
                        );
                      }}
                    >
                      Custom Topics
                    </StyledButton1>
                  </div>
                );
              }
            })}
          {feature_prog_admin_func_data
            ?.filter((data) => data.admfunction == "Edit Topic Locations")
            .map((data) => {
              if (data.admfunction == "Edit Topic Locations") {
                return (
                  <div>
                    <StyledButton1
                      onClick={() => {
                        history.push(
                          `/adminfunction/topic_location/${admn_custId}`
                        );
                      }}
                    >
                      Topics at Locations
                    </StyledButton1>
                  </div>
                );
              }
            })}
          {feature_prog_admin_func_data
            ?.filter((data) => data.admfunction == "Edit Admin/User")
            .map((data) => {})}
          {feature_prog_admin_func_data
            ?.filter(
              (data) => data.admfunction == "Edit User Topic Permissions"
            )
            .map((data) => {
              if (data.admfunction == "Edit User Topic Permissions") {
                return (
                  <div>
                    <StyledButton1
                      onClick={() => {
                        history.push(
                          `/adminfunction/user_topic_permissions/${admn_custId}`
                        );
                      }}
                    >
                      User Permissions for Topics
                    </StyledButton1>
                  </div>
                );
              }
            })}

          <div>
            <StyledButton1
              onClick={() => {
                history.push("/Extquestion2");
              }}
            >
              Back
            </StyledButton1>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default AdminFunctionModal;
