/**
 * Use the CSS tab above to style your Element's container.
 */
import React , { useState } from "react";
import { 
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement, 
  useStripe,useElements} from "@stripe/react-stripe-js";
import "./Styles.css";
 


const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      width: "100%",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const error = {
  color: "red",

  padding: "10px",
  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',  fontSize: "18px",
  
};


// CardNumberElement.on('change', function(event) {
//   var displayError = document.getElementById('card-errors');
//   if (event.error) {
//     displayError.textContent = event.error.message;
//     alert( displayError.textContent);
//   } else {
//     displayError.textContent = '';
//   }
// });



function CardSection() {


  const [CardNumberError, setCardNumberError] = useState(null);
  const [CardExpiryError, setCardExpiryError] = useState(null);
  const [CardCvcError, setCardCvcError] = useState(null);
  
  const stripe = useStripe();
  const elements = useElements();

  const handleCardNumberChange = async (event) =>{
  // function handleCardNumberChange(event) {


  console.log("functions");
    setCardNumberError(event.error ? event.error.message : null);

    const numbercard = elements.getElement(CardElement); 

  }
  // function handleCardExpiryChange(event) {
    
  //   setCardExpiryError(event.error ? event.error.message : null);
  // }

  // function handleCardCvcChange(event) {
  //   setCardCvcError(event.error ? event.error.message : null);
  // }
    
  return (
    <> 

      <label className="mt-2">  
     
        <CardElement options={CARD_ELEMENT_OPTIONS}  onChange={handleCardNumberChange} />
      </label>


      {CardNumberError && <div style={error}>{CardNumberError}</div>}

       
        

      {/* <label style={{marginTop : "8px" , fontFamily: '"Helvetica Neue", Helvetica, sans-serif',  fontSize: "16px",}}>
        Card number
        <CardNumberElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardNumberChange} />
      </label>

      {CardNumberError && <div style={error}>{CardNumberError}</div>}
      <label style={{marginTop : "8px" ,  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',  fontSize: "16px",}}>
        Expiration date
        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardExpiryChange} />
      </label>

      {CardExpiryError && <div style={error}>{CardExpiryError}</div>}
      <label style={{marginTop : "8px" , fontFamily: '"Helvetica Neue", Helvetica, sans-serif',  fontSize: "16px",}}>
        CVC
        <CardCvcElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardCvcChange}/>
      </label>

      {CardCvcError && <div style={error}>{CardCvcError}</div>} */}



 
    </>
  );
}

export default CardSection;
