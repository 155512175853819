import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Button, Card, Table, Modal, message } from "antd";
import { useParams, useHistory } from "react-router";

import styled from "styled-components";
import {
    add_grouping_api,
    sectionselect_reportinglist_api,
    edit_grouping_api,
    delete_grouping_api
} from "../../../services/AdminProgramApi";
import {
    ExclamationCircleOutlined,
    InfoCircleTwoTone,
    EditTwoTone,
  } from "@ant-design/icons";

import axios from "axios";
//import { BASE_URL } from "../url/baseurl";

const theme = localStorage.getItem('brandColor');
const { confirm } = Modal;

const StyledDiv = styled.div`
  margin-top: 20px;
  position: relative;
  left: 3.68%;
  width: 92.6%;
`;

const StyledTable = styled.table`
  width: 100%;
  color: #757373;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  color: #ffffff;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
  padding-left: 5px;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const StyledButton = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  padding: 0px 20px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 100%;
`;

const InputStyle = {
    width: "300px",
    border: "none",
    backgroundColor: "#EEEEEE",
};

const StyledFormInputDiv = styled.div`
  display: flex;

  margin-top: 4px;

  width: 100%;
`;

const FormDivStyle = {
    position: "relative",
    display: "inline",
    width: "100%",
};

const FormDivStyleTwo = {
    position: "relative",
    display: "inline",
    width: "100%",
};



const Reporting_group = ({ match }) => {
    const history = useHistory();
    console.log("propdata", match);

    const customerid = match.params.customerid;
    const enterrepcatdescref = useRef();

    const [reporting_list_data, setReporting_list_data] = useState([]);
    const [add_report_modal, setAdd_report_modal] = useState(false);
    const [edit_report_modal, setEdit_report_modal] = useState(false);
    const [custgroupdesc, setCustgroupdesc] = useState("");
    const [custgroupid, setCustgroupid] = useState("");


    const reporting_list = async () => {
        sectionselect_reportinglist_api(customerid)
            .then((res) => {
                if (res.data.code == 200) {
                    console.log("message ==>", res.data.result);
                    setReporting_list_data(res.data.result);

                } else {
                    message.error(res.data.message);
                }
            })
            .catch((err) => {
                console.log("error===>>", err);
            });
    };

    const addGrouping = () => {
        add_grouping_api({
            Customerid: customerid,
            Custgroupdesc: custgroupdesc,
        })

            .then((res) => {
                if (res.data.code === 200) {
                    setAdd_report_modal(false);
                    reporting_list();
                    message.success("Reporting Group added successfully");

                    console.log("res Add group =>", res.data);
                } else {
                    message.error("No Reporting Group was entered");
                }
            })
            .catch((err) => {
                console.log("guidance add error===>", err);
                message.error("No Reporting Group was entered");
            });
    };

    const EditGrouping = () => {
        edit_grouping_api({
            Customerid: customerid,
            Custgroupdesc: custgroupdesc,
            custgroupid: custgroupid,
        })

            .then((res) => {
                if (res.data.code === 200) {
                    setEdit_report_modal(false);
                    reporting_list();
                    message.success("Reporting Group changed successfully");
                     console.log("res Add group =>", res.data);
                } else {
                    message.error("No Reporting Group was entered");
                }
            })
            .catch((err) => {
                
                console.log("guidance add error===>", err);
                message.error("No Reporting Group was entered");
            });
    };

    const DeleteGrouping = async (custgroupid) => {
        confirm({
           
            title: "Are you sure you want to delete this Reporting Group?",
            icon: <ExclamationCircleOutlined />,
            cancelText: "Yes",
            cancelButtonProps: { style: { backgroundColor: '#ff8c24', color: 'white', borderColor: '#ff8c24', borderRadius: '20px' } },
            okText: "Cancel",
            okButtonProps: { style: { backgroundColor: 'red', color: 'white', borderColor: 'red', borderRadius: '20px' } },
            onCancel() {
                delete_grouping_api({
                    Customerid: customerid,
                    custgroupid: custgroupid,
                })
                  .then((res) => {
                    // UserListApi()
                    console.log("delete response===>", res);
        
                    if (res.data.code == "200") {
                      if (
                        String(res.data.message) ==
                        "Remove this Reporting Group from Locations before deleting."
                      )
                        message.info(
                          "Remove this Reporting Group from Locations before deleting."
                        );
                      else {
                        message.success("Reporting Group deleted successfully");
                        //window.location.reload();
                        reporting_list();
                      }
                    } else {
                      message.error("Something went wrong please try again");
                    }
                  })
                  .catch((err) => {
                    message.error("Something went wrong please try again");
                    // console.log("delete response===>", err);
                  });
              },
              onOk() {
                console.log("Cancel");
              },
            });
    };

    const resetFields = () => {
        setCustgroupdesc("");
    }

    useEffect(() => {
        reporting_list();


    }, [customerid]);

    return (
        <>
            <div className="mt-4 row text-center" align="center">
                <div className="col-6 Width100">
                    <StyledButton1
                        onClick={() => {
                            history.push("/adminfunction/location-list/" + match.params.customerid);
                        }}
                    >
                        Go Back
                    </StyledButton1>
                </div>
                <div className="col-6 Width100">
                    <StyledButton1
                        onClick={() => {
                            setAdd_report_modal(true);
                        }}
                    >
                        Add Reporting Group
                    </StyledButton1>
                </div>
            </div>

            <div>
                {/* --------------- Add Modal ------------ */}
                <ReactModal className="ModalStyle1 Width90" isOpen={add_report_modal}>
                    <div>
                        {" "}
                        <StyledForm>
                            {" "}
                            <div align="center" className="mb-3">
                                <h4>Add Reporting Group</h4>
                            </div>
                            <StyledFormInputDiv>
                                <div style={FormDivStyle}>
                                    <StyledLabel htmlFor="repcatdesc">
                                        {" "}
                                        Reporting Group Description :
                                    </StyledLabel>
                                </div>
                                <div style={FormDivStyleTwo}>
                                    <textarea
                                        style={{ width: "100%" }}
                                        //ref={enterrepcatdescref}
                                        type="textarea"
                                        rows="3"
                                        id="repcatdesc"
                                        onChange={(e) => {
                                            setCustgroupdesc(e.target.value);
                                          }}
                                    value={custgroupdesc}
                                    />
                                </div>
                            </StyledFormInputDiv>
                        </StyledForm>
                        <div className="mt-2" align="center">
                            <StyledButton
                                style={{
                                    width: "30%",
                                    backgroundColor: theme,
                                }}

                                onClick={() => {
                                    addGrouping();
                                    resetFields();
                                }}
                            >
                                Submit
                            </StyledButton>
                            <StyledButton
                                style={{
                                    width: "30%",
                                    backgroundColor: "red",
                                }}
                                onClick={() => {
                                    setAdd_report_modal(false);
                                }}
                            >
                                Cancel
                            </StyledButton>
                        </div>
                    </div>
                </ReactModal>
            </div>

            <div>
                {/* --------------- Edit Modal ------------ */}
                <ReactModal className="ModalStyle1 Width90" isOpen={edit_report_modal}>
                    <div>
                        {" "}
                        <StyledForm>
                            {" "}
                            <div align="center" className="mb-3">
                                <h4>Edit Reporting Group</h4>
                            </div>
                            <StyledFormInputDiv>
                                <div style={FormDivStyle}>
                                    <StyledLabel htmlFor="repcatdesc">
                                        {" "}
                                        Reporting Group Description :
                                    </StyledLabel>
                                </div>
                                <div style={FormDivStyleTwo}>
                                    <textarea
                                        style={{ width: "100%" }}
                                        //ref={enterrepcatdescref}
                                        type="textarea"
                                        rows="3"
                                        id="repcatdesc"
                                    value={custgroupdesc}
                                    onChange={(e) => {
                                        setCustgroupdesc(e.target.value);
                                      }}
                                    />
                                </div>
                            </StyledFormInputDiv>
                        </StyledForm>
                    </div>
                    <div align="center">
                        <StyledButton
                            style={{
                                width: "30%",
                                height: 30,
                                marginTop: 50,
                            }}
                            onClick={() => {
                                EditGrouping();
                                //setEdit_report_modal(false);
                            }}
                        >
                            Submit
                        </StyledButton>
                        <StyledButton
                            style={{
                                width: "30%",
                                height: 30,
                                backgroundColor: "red",
                                marginLeft: 10,
                            }}
                            onClick={() => {
                                resetFields();
                                setEdit_report_modal(false);
                            }}
                        >
                            Cancel
                        </StyledButton>
                    </div>
                </ReactModal>
            </div>

            <StyledDiv className="tableFixHeadXXLarge mt-4">
                <StyledTable class="table-auto mt-4">
                    <thead>
                        <ScrollTableHeader>
                            <StyledTableHeaderData>
                                Reporting Group{" "}
                            </StyledTableHeaderData>
                            <StyledTableHeaderData>Action</StyledTableHeaderData>
                        </ScrollTableHeader>
                    </thead>
                    <tbody>

                        {reporting_list_data?.map((data, i) => {
                            return (
                                <StyledTableRow
                                    style={{
                                        backgroundColor: data.color,
                                        color: data.color == "red" ? "white" : "black",
                                        fontWeight: 600,
                                    }}
                                    title={data.message}
                                >
                                    <td>{data.custgroupdesc}</td>
                                    <td>
                                        <EditOutlined
                                            style={{ paddingRight: 14, color: "#3E79F7" }}
                                           
                                                
                                                onClick={() => {
                                                  setEdit_report_modal(true);
                                                //   setActive(data.activel);
                                                //   setLocation(data.locationdesc);
                                                //   setLocationid(data.locationid);
                                                  setCustgroupdesc(data.custgroupdesc);
                                                   setCustgroupid(data.custgroupid);
                                                //   setSector(data.sectorid);
                                                //   setCountryid(data.countryid);
                                                //   setCountry(data.countrycode);
                                                }}
                                              
                                            
                                        />

                                        <DeleteOutlined
                                            onClick={() => {
                                                // setCustgroupdesc(data.custgroupdesc);
                                                // setCustgroupid(data.custgroupid);
                                                DeleteGrouping( data.custgroupid);
                                            }}
                                            style={{ color: "red" }}
                                        />
                                    </td>
                                </StyledTableRow>
                            );
                        })}

                    </tbody>
                </StyledTable>
               
            </StyledDiv>

        </>
    );
}
export default Reporting_group;