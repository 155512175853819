import React, { useState, useRef } from "react";
import MainHeader from "../components/MainHeader";
import QuestionTableSearch from "../components/QuestionTableSearch";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import CuzTopicUIQuestionTable from "../components/CuzTopicUIQuestionTable";
import ReactModal from "react-modal";
import axios from "axios";
import { BASE_URL } from "../url/baseurl";

const theme = localStorage.getItem('brandColor');

const StyledDivButtons = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  color: darkgrey;
  font-weight: 500;
  text-decoration: none;

  &:active {
    text-decoration: none;
    color: darkgrey;
  }
  &:hover {
    text-decoration: none;
    color: grey;
    cursor: pointer;
  }
`;

const StyledButton = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  padding: 0px 20px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;


const ModalStyle = {
  content: {
    width: "70%",
    height: "70%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
  },
};

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
  margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledInput = styled.input`
  display: inline;
  width: 70%;
  
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputStyle = {
  border: "none",
  backgroundColor: "#EEEEEE",
};

const StyledFormInputDiv = styled.div`
  display: flex;
  
  margin-top: 4px;

  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "30%",
};

const FormDivStyleTwo = {
  position: "relative",
  display: "inline",
  width: "70%",

};




const CuzTopicUI = () => {


  const [isAddQuestion, setIsAddQuestion] = useState(false);
  const enterteduseridref = useRef()
  const entertedtopicidref = useRef()
  const entertedqcoderef = useRef()
  const entertedqdescref = useRef()
  const entertedquestionseqref = useRef()
  const entertedifparentyesref = useRef()
  const entertedifparentnoref = useRef()
  const entertedpriorityidref = useRef()
  const entertedguidanceidref = useRef()
  const entertedsolutionifref = useRef()
  const entertedsolutiondescref = useRef()
  const entertedrepcatidref = useRef()
  const entertedactiveqref = useRef()

  const [new_qcode, set_new_qcode] = useState(0);


  const showQuestionModal = async () => {
    // let res= await axios.post(`${BASE_URL}/api/get_qcode`,{topicid:})
    // console.log(res)
    setIsAddQuestion(true);
  };

  const closeQuestionModal = () => {
    setIsAddQuestion(false);
  };

  const questionSubmitHandler = async (event) => {
    event.preventDefault();
    console.log('hello')
    const questionObject = {
      userid: enterteduseridref.current.value,
      topicid: entertedtopicidref.current.value,
      qcode: entertedqcoderef.current.value,
      qdescr: entertedqdescref.current.value,
      questionseq: entertedquestionseqref.current.value,
      ifparentyes: entertedifparentyesref.current.value,
      ifparentno: entertedifparentnoref.current.value,
      priorityid: entertedpriorityidref.current.value,
      guidanceid: entertedguidanceidref.current.value,
      solutionif: entertedsolutionifref.current.value,
      solutiondesc: entertedsolutiondescref.current.value,
      repcatid: entertedrepcatidref.current.value,
      activeq: entertedactiveqref.current.value
    }
    console.log(questionObject);
    // sendQuestionDataToAPIHandler(questionObject);
  };

  // const sendQuestionDataToAPIHandler = async (data) => {

  //   const response = await axios.post(`${BASE_URL}/cuzdrive/public/api/add_question` , data)
  //   .then((res) => console.log(`res: ${res}`))
  //   .catch((err) => console.log(`err: ${err.response.data}`))
  // } 

  return (
    <div>
      <ReactModal
        isOpen={isAddQuestion}
        style={ModalStyle}
        onRequestClose={closeQuestionModal}
      >
        <StyledForm onSubmit={questionSubmitHandler}>
          <StyledFormInputDiv style={{ width: '600px' }}>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="userid"> User Id </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={enterteduseridref} style={InputStyle} type="number" id="userid" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv style={{ width: '600px' }}>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="topicid"> Topic Id </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedtopicidref} style={InputStyle} type="number" id="topicid" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="qcode"> Question Code </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedqcoderef} style={InputStyle} type="number" id="qcode" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="qdesc"> Question Description </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedqdescref} style={InputStyle} type="text" id="qdesc" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="questionseq">
                {" "}
                Question Sequence{" "}
              </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedquestionseqref} style={InputStyle} type="number" id="questionseq" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="isparentyes"> Is Parent Yes </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedifparentyesref} style={InputStyle} type="number" id="isparentyes" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="isparentno"> Is Parent No </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedifparentnoref} style={InputStyle} type="number" id="isparentno" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="priorityid"> Priority Id </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedpriorityidref} style={InputStyle} type="number" id="priorityid" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="guidanceid"> Guidance Id </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedguidanceidref} style={InputStyle} type="number" id="guidanceid" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="solutionif"> Solution If </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedsolutionifref} style={InputStyle} type="text" id="solutionif" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="solutiondesc">
                {" "}
                Solution Description{" "}
              </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedsolutiondescref} style={InputStyle} type="text" id="solutiondesc" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="repcatid"> Repcat Id </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedrepcatidref} style={InputStyle} type="number" id="repcatid" />
            </div>
          </StyledFormInputDiv>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="activeq"> Active </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput ref={entertedactiveqref} style={InputStyle} type="text" id="activeq" />
            </div>
          </StyledFormInputDiv>
          <StyledButton style={{
            marginTop: '10px',
            width: '30%',
            marginLeft: '25%',
            backgroundColor: theme,
            color: 'white',
          }}>Submit</StyledButton>
          
        </StyledForm>
      </ReactModal>
      <MainHeader />
      <QuestionTableSearch />
      <StyledDivButtons>
        <StyledLink to="/welcome">
          <StyledButton>Exit To Home</StyledButton>
        </StyledLink>
        <StyledLink to="/welcome">
          <StyledButton>Exit To Admin</StyledButton>
        </StyledLink>
        {/* <StyledLink> */}
          <StyledButton>Warning</StyledButton>
        {/* </StyledLink> */}
        {/* <StyledLink > */}
          <StyledButton onClick={showQuestionModal}>Add Question</StyledButton>
        {/* </StyledLink> */}
      </StyledDivButtons>

      <CuzTopicUIQuestionTable />
    </div>
  );
};

export default CuzTopicUI;
