import React from "react";
import styled from "styled-components";
const theme = localStorage.getItem('brandColor');


const StyledInputDiv = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 85vw;
  min-width: 1000px;
  height: auto;
  justify-self: center;
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 50px;
  margin: 0 auto;
  margin-top: 25px;
`;

const StyledDiv = styled.div`
  margin: 13.5px;
`;
const StyledInput = styled.input`
  border: 1px solid #e4e4e4;
  border-top: none;
  border-left: none;
  border-right: none;

  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: #afaaaa;

  &::active {
    border: 1px solid #e4e4e4;
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;
const StyledButton = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
`;

const HorizontalDivider = {
  width: "36px",
  height: "0px",
  marginTop: "2%",
  border: "0.5px solid #C5C1C1",
  transform: "rotate(90deg)",
};

const QuestionTableSearch = () => {
  return (
    <StyledInputDiv>
      <StyledDiv>
        <StyledInput placeholder="Filter Value" type="text" />
      </StyledDiv>
      <div style={HorizontalDivider} />
      <StyledDiv>
        <StyledInput placeholder="Filter Value" type="text" />
      </StyledDiv>
      <div style={HorizontalDivider} />
      <StyledDiv>
        <StyledInput placeholder="Filter Value" type="text" />
      </StyledDiv>
      <div style={HorizontalDivider} />
      <StyledDiv>
        <StyledInput placeholder="Date" type="date" />
      </StyledDiv>
      <div style={HorizontalDivider} />
      <StyledButton style={{}}>Search</StyledButton>
    </StyledInputDiv>
  );
};

export default QuestionTableSearch;
