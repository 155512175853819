import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import print from "print-js";
import { PrinterOutlined, DownloadOutlined } from "@ant-design/icons";
import ReactModal from "react-modal";
import { message } from "antd";
import {
  free_sugg_build_report_and_access_privilliges_api,
  free_sugg_update_build_report_and_access_privilliges_api,
} from "../../services/NewUI_API";

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;

  margin-top: 4px;

  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const StyledButton = styled.button`
  background: #ff8c24;
  width: 250px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 10px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: #ff8c24;
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: #ff8c24;
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const ModalStyle = {
  content: {
    width: "550px",
    height: "350px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    justifyContent: "center",
  },
};

function Suggestion_Program(props) {
  let history = useHistory();
  const params = useParams();
  const topiccode = params.topicid;
  console.log(topiccode);

  const [SuggestionData, setSuggestionData] = useState([]);
  const [guidance, setGuidance] = useState("withguidance");
  const [guidanceToggle, setGuidanceToggle] = useState(true);

  const [edit_suggestion_modal, setEdit_suggestion_modal] = useState(false);
  const [qualifier, setQualifier] = useState("");
  const [riskScore, setRiskScore] = useState("");
  const [suggSoln, setSuggSoln] = useState("");
  const [category, setCategory] = useState("");
  const [suggCompleted, setSuggCompleted] = useState("");
  const [qcode, setQcode] = useState("");
  const [topicCode, setTopicCode] = useState("");
  const [periodCode, setPeriodCode] = useState("");

  const SuggestionDataF = async () => {
    let SuggData = {
      topiccode: parseInt(topiccode),
      userid: parseInt(localStorage.getItem("UserID")),
      guideselect: guidance,
      useremail: localStorage.getItem("EmailUser"),
    };
    free_sugg_build_report_and_access_privilliges_api(SuggData)
      .then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          setSuggestionData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    SuggestionDataF();
  }, [guidance]);

  const handleGuidance = () => {
    let guidenceselect;

    if (guidanceToggle) {
      guidenceselect = "withguidance";
    } else {
      guidenceselect = "withoutguidance";
    }
    setGuidance(guidenceselect);
  };

  useEffect(() => {
    handleGuidance();
  }, [guidanceToggle]);

  const UpdateSuggestionData = async () => {
    let data = {
      topiccode: parseInt(topicCode),
      userid: parseInt(localStorage.getItem("UserID")),
      customerid: parseInt(localStorage.getItem("CrID")),
      periodcode: parseInt(periodCode),
      qcode: parseInt(qcode),
      completed: suggCompleted,
    };

    free_sugg_update_build_report_and_access_privilliges_api(data)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          message.success("Updated Sucessfully!");
          setEdit_suggestion_modal(false);
          SuggestionDataF();
        } else if (res.data.message == "nocustuser") {
          message.warning(
            "An Assigned To email must be an active user for this Customer and set up for this Topic and Location. Your administrator can help get this user set up."
          );
        } else if (res.data.message == "inactiveuser") {
          message.warning(
            "The Assigned To email is no longer an active user for this Customer. Your administrator can reactivate this user and assign them to this Topic and Location."
          );
        } else if (res.data.message == "invalidusertop") {
          message.warning(
            "The Assigned To email must first be set up for this Topic and Location. Your administrator can add this person."
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function download_csv(e) {
    var csv = SuggestionData?.map(function (d) {
      let temp = {
        Qualifier: d.topicdesc,
        Risk_Score: d.riskscore,
        Suggested_Solution: d.solutiondesc,
        Category: d.g1desc,
        Suggestion_Completed: d.completed,
      };
      return JSON.stringify(Object.values(temp));
    })

      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = "suggestion.csv";
    hiddenElement.click();
  }

  const printReport = () => {
    print({
      printable: SuggestionData,
      properties: [
        "topicdesc",
        "riskscore",
        "solutiondesc",
        "g1desc",
        "completed",
      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5;",
    });
  };

  return (
    <>
      <div className="container">
        <div className="text-center mt-4">
          <span style={{ fontSize: 30, fontWeight: "bold", color: "#ff8c24" }}>
            SUGGESTIONS
          </span>
          <p style={{ fontWeight: "bold", fontSize: 16, letterSpacing: 0.5, margin:0 }}>
            The results of your completed questionnaire are below.
            {/* <br />
            You can edit and change suggestions as necessary. */}
          </p>
        </div>

        <div className="d-flex justify-content-center">
          {/* <div className="col-lg-3"> */}
          <StyledButton
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            Exit
          </StyledButton>
          {/* </div> */}

          {/* <div className="col-lg-3">
            <StyledButton
              onClick={() => {
                setGuidanceToggle(!guidanceToggle);
              }}
            >
              {guidanceToggle ? "Without Guidance" : "With Guidance"}
            </StyledButton>
          </div> */}

          {/* <div className="col-lg-3">
            <StyledButton
              onClick={() => {
                download_csv();
              }}
            >
              Download Report
              <DownloadOutlined />
            </StyledButton>
          </div>

          <div className="col-lg-3">
            <StyledButton
              onClick={() => {
                printReport();
              }}
            >
              Print Report
              <PrinterOutlined />
            </StyledButton>
          </div> */}
        </div>

        <div>
          {/* --------------- Edit Modal ------------ */}
          <ReactModal style={ModalStyle} isOpen={edit_suggestion_modal}>
            <div>
              {" "}
              <StyledForm>
                {" "}
                <div align="center" className="mb-3">
                  <h4>Edit Suggestions</h4>
                </div>
                <StyledFormInputDiv>
                  <div style={FormDivStyle}>
                    <StyledLabel htmlFor="qdesc">Qualifier</StyledLabel>
                  </div>
                  <div style={{ width: "100%", alignSelf: "center" }}>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid black",
                      }}
                      placeholder="Enter Qualifier"
                      value={qualifier}
                      disabled
                      onChange={(e) => {
                        setQualifier(e.target.value);
                      }}
                    />
                  </div>
                </StyledFormInputDiv>
                <StyledFormInputDiv>
                  <div style={FormDivStyle}>
                    <StyledLabel htmlFor="qdesc">Risk Score</StyledLabel>
                  </div>
                  <div style={{ width: "100%", alignSelf: "center" }}>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid black",
                      }}
                      placeholder="Enter Risk Score"
                      value={riskScore}
                      disabled
                      onChange={(e) => {
                        setRiskScore(e.target.value);
                      }}
                    />
                  </div>
                </StyledFormInputDiv>
                <StyledFormInputDiv>
                  <div style={FormDivStyle}>
                    <StyledLabel htmlFor="qdesc">
                      Suggested Solutions
                    </StyledLabel>
                  </div>
                  <div style={{ width: "100%", alignSelf: "center" }}>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid black",
                      }}
                      placeholder="Enter Suggested Solutions"
                      value={suggSoln}
                      disabled
                      onChange={(e) => {
                        setSuggSoln(e.target.value);
                      }}
                    />
                  </div>
                </StyledFormInputDiv>
                <StyledFormInputDiv>
                  <div style={FormDivStyle}>
                    <StyledLabel htmlFor="qdesc">Category</StyledLabel>
                  </div>
                  <div style={{ width: "100%", alignSelf: "center" }}>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid black",
                      }}
                      placeholder="Enter Category"
                      value={category}
                      disabled
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    />
                  </div>
                </StyledFormInputDiv>
                <StyledFormInputDiv>
                  <div style={FormDivStyle}>
                    <StyledLabel htmlFor="qdesc">
                      Suggestion Completed
                    </StyledLabel>
                  </div>
                  <div style={{ width: "100%", alignSelf: "center" }}>
                    <select
                      onChange={(e) => {
                        setSuggCompleted(e.target.value);
                      }}
                      style={{
                        width: "100%",
                        alignItems: "center",
                        border: "1px solid black",
                      }}
                    >
                      <option
                        value="Yes"
                        selected={suggCompleted == "Yes" ? true : false}
                      >
                        Yes
                      </option>
                      <option
                        value="No"
                        selected={suggCompleted == "No" ? true : false}
                      >
                        No
                      </option>
                      <option
                        value="Inprocess"
                        selected={suggCompleted == "Inprocess" ? true : false}
                      >
                        In Process
                      </option>
                    </select>
                  </div>
                </StyledFormInputDiv>
              </StyledForm>
            </div>
            <div align="center">
              <StyledButton
                style={{
                  width: "30%",
                  height: 30,
                  marginTop: 50,
                }}
                onClick={() => {
                  UpdateSuggestionData();
                }}
              >
                Save
              </StyledButton>
              <StyledButton
                style={{
                  width: "30%",
                  height: 30,
                  backgroundColor: "red",
                  marginLeft: 10,
                }}
                onClick={() => {
                  setEdit_suggestion_modal(false);
                }}
              >
                Cancel
              </StyledButton>
            </div>
          </ReactModal>
        </div>

        <div className="mt-4 tableFixHead">
          <StyledTable class="table-auto ">
            <thead>
              <ScrollTableHeader>
                <StyledTableHeaderData>S.No</StyledTableHeaderData>
                <StyledTableHeaderData>Topic</StyledTableHeaderData>
                <StyledTableHeaderData>Period</StyledTableHeaderData>
                <StyledTableHeaderData>Risk Score</StyledTableHeaderData>
                <StyledTableHeaderData>
                  Suggested Solutions
                </StyledTableHeaderData>
                <StyledTableHeaderData>Category</StyledTableHeaderData>
                <StyledTableHeaderData>
                  Suggestion Completed
                </StyledTableHeaderData>
                <StyledTableHeaderData>Action</StyledTableHeaderData>
              </ScrollTableHeader>
            </thead>

            <tbody>
              {SuggestionData &&
                SuggestionData?.sort((a, b) => b.riskscore - a.riskscore)?.map(
                  (data, index) => {
                    return (
                      <StyledTableRow>
                        <td style={{ color: "black", fontWeight: "bold" }}>
                          {index + 1}
                        </td>
                        <td style={{ color: "black", fontWeight: "bold" }}>
                          {data.topicdesc}
                        </td>
                        <td style={{ color: "black", fontWeight: "bold" }}>
                          {data.perioddesc}
                        </td>
                        <td style={{ color: "black", fontWeight: "bold" }}>
                          {data.riskscore}
                        </td>
                        <td style={{ color: "black", fontWeight: "bold" }}>
                          {data.solutiondesc}
                        </td>
                        <td style={{ color: "black", fontWeight: "bold" }}>
                          {data.g1catdesc}
                        </td>
                        <td style={{ color: "black", fontWeight: "bold" }}>
                          {data.completed}
                        </td>
                        <td>
                          <a
                            onClick={() => {
                              setQualifier(data.topicdesc);
                              setRiskScore(data.riskscore);
                              setSuggSoln(data.solutiondesc);
                              setCategory(data.g1catdesc);                              // g1desc;
                              setSuggCompleted(data.completed);
                              setQcode(data.qcode);
                              setPeriodCode(data.periodcode);
                              setTopicCode(data.topiccode);

                              setEdit_suggestion_modal(true);
                            }}
                          >
                            <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                          </a>
                        </td>
                      </StyledTableRow>
                    );
                  }
                )}
            </tbody>
          </StyledTable>
        </div>
      </div>
    </>
  );
}

export default Suggestion_Program;
