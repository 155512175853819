import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { BASE_URL } from "../../url/baseurl";
import print from "print-js";
import { Popover, Tooltip, message } from "antd";
import axios from "axios";
import $ from "jquery";
import {
  PrinterOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ReactModal from "react-modal";
import moment from "moment";
import { DataGrid, GridColumns, GridRowsProp, GridOverlay  } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});


const CustomNoRowsOverlay = () => {
  return (
    <GridOverlay>
      <div>No rows found</div>
    </GridOverlay>
  );
};



function Esuggest() {


  $(document).on("change", ".select-all1", function () {
    if ($(this).is(":checked")) {
      $('[name="check[1]"]').each(function () {
        $(this).prop("checked", true);
      });
    } else {
      $('[name="check[1]"]').each(function () {
        $(this).prop("checked", false);
      });
    }
  });


  const history = useHistory();

  const [guidanceToggle, setGuidanceToggle] = useState(false);

  const [sortRev, setSortRev] = useState(false);
  const [sortBySno, setSortBySno] = useState(true);
  const [sortByRisk, setSortByRisk] = useState(false);
  const [sortByCategory, setSortByCategory] = useState(false);
  const [sortByAssignTo, setSortByAssignTo] = useState(false);
  const [sortByDueDate, setSortByDueDate] = useState(false);
  const [sortBySuggestionComplete, setSortBySuggestionComplete] =
    useState(false);

  const [sugg_modal, setSugg_modal] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [pageSize, setPageSize] = useState(100);

  const classes = useStyles();

  let currentperiod = localStorage.getItem("R_PeriodCode");

  // =========<<<<<<< suggestedit api call func >>>>>>>>=====================================

  const suggestedit_api_func = async () => {
    let data = {
      userid: localStorage.getItem("UserID"),
      topicid: localStorage.getItem("R_TopicId"),
      topiccodes: localStorage.getItem("R_TopicCodes"),
      cuztopic: localStorage.getItem("R_CuzTopic"),
      cuzdesc: localStorage.getItem("R_CuzDesc"),
      locationid: localStorage.getItem("R_LocationId"),
      locationdesc: localStorage.getItem("R_LocationDesc"),
      periodcode: localStorage.getItem("R_PeriodCode"),
      perioddesc: localStorage.getItem("R_PeriodDesc"),
      countrycode: localStorage.getItem("R_CountryCode"),
      suggmessage: localStorage.getItem("R_SuggPrivilege"),
    };

    let res = await axios.put(BASE_URL + "/api/suggestedit", data);
    console.log("suggest edit api res===>>", res);
  };

  useEffect(() => {
    suggestedit_api_func();
  }, []);

  // =============<<<<<<<<<< user_session_data_esuggest api func   >>>>>>>>>>>>>===============

  const [user_session_data, setuser_session_data] = useState([]);
  const [list, setList] = useState([]);

  const user_session_data_esuggest_api = async () => {
    localStorage.setItem("Earlierperiod", localStorage.getItem("R_PeriodCode"))
    let res = await axios.get(
      BASE_URL +
      `/api/user_session_data_esuggest?userid=${localStorage.getItem(
        "UserID"
      )}`
    );
    setuser_session_data(res.data.userdata[0]);
    setList(res.data.list);
    console.log(res);
  };

  useEffect(() => {
    user_session_data_esuggest_api();
  }, []);

  const handleGuidance = () => {
    // setGuidanceToggle(!guidanceToggle);
    let guidenceselect;

    if (guidanceToggle) {
      guidenceselect = "withguidance";
    } else {
      guidenceselect = "withoutguidance";
    }
    setGuidence_select(guidenceselect);
    build_report_and_access_privilliges_api(guidenceselect);
  };

  useEffect(() => {
    handleGuidance();
  }, [guidanceToggle]);
  // ============<<<<<<<<<<<< build_report_and_access_privilliges >>>>>>>>>>>>>================

  const [data_privilege, setData_privilege] = useState([]);
  const [guidence_select, setGuidence_select] = useState("withoutguidance");

  const build_report_and_access_privilliges_api = async (guidenceselect) => {

    // if(localStorage.getItem("Earlierperiod") == "" || localStorage.getItem("Earlierperiod") == 'undefined' ){
    //   localStorage.setItem("Earlierperiod",localStorage.getItem("R_PeriodCode") )
    // }


    let Periodcode;
    //if (!selectear)
    if(localStorage.getItem("R_PeriodCode") == localStorage.getItem("Earlierperiod"))
    {

     
      Periodcode = localStorage.getItem("R_PeriodCode");
      // if(copyperi){
      //   Periodcode = localStorage.getItem("copyperiod");
      // }else{
      //   Periodcode = localStorage.getItem("R_PeriodCode");
      // }

    }
    else {
      Periodcode = localStorage.getItem("Earlierperiod");
    }



    let data = {
      suggprivilege: localStorage.getItem("R_SuggPrivilege"),
      cuztopic: localStorage.getItem("R_CuzTopic"),
      topicid: localStorage.getItem("R_TopicId"),
      periodcode: Periodcode,
      locationid: localStorage.getItem("R_LocationId"),
      userid: localStorage.getItem("UserID"),
      guideselect:
        guidenceselect === "withoutguidance"
          ? "withoutguidance"
          : "withguidance",
      useremail: user_session_data.useremail,
    };

    let res = await axios.get(
      BASE_URL +
      `/api/build_report_and_access_privilliges?
        suggprivilege=${data.suggprivilege}&
        cuztopic=${data.cuztopic}&
        topicid=${data.topicid}&
        periodcode=${data.periodcode}&
        locationid=${data.locationid}&
        userid=${data.userid}&
        guideselect=${data.guideselect}&
        useremail=${data.useremail}
        `
    );
    if (!sortByRisk && !setSortBySno) {
      setData_privilege(res.data.data);
    } else if (sortByRisk) {
      setData_privilege(
        sortRev
          ? res.data.data.sort((a, b) => a.priorityid - b.priorityid)?.reverse()
          : res.data.data.sort((a, b) => a.priorityid - b.priorityid)
      );
    } else if (sortBySno) {
      setData_privilege(sortRev ? res.data.data?.reverse() : res.data.data);
    } else if (sortByCategory) {
      setData_privilege(
        sortRev
          ? res.data.data
            .sort((a, b) => {
              const x = a.repcatdesc.toUpperCase();
              const y = b.repcatdesc.toUpperCase();
              return x === y ? 0 : x > y ? 1 : -1;
            })
            ?.reverse()
          : res.data.data.sort((a, b) => {
            const x = a.repcatdesc.toUpperCase();
            const y = b.repcatdesc.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
          })
      );
    } else if (sortByAssignTo) {
      setData_privilege(
        sortRev
          ? res.data.data
            .sort((a, b) => {
              const x = a.qownerid;
              const y = b.qownerid;
              return x === y ? 0 : x > y ? 1 : -1;
            })
            ?.reverse()
          : res.data.data.sort((a, b) => {
            const x = a.qownerid;
            const y = b.qownerid;
            return x === y ? 0 : x > y ? 1 : -1;
          })
      );
    } else if (sortByDueDate) {
      setData_privilege(
        sortRev
          ? res.data.data.sort((a, b) => a.qduedate - b.qduedate)?.reverse()
          : res.data.data.sort((a, b) => a.qduedate - b.qduedate)
      );
    } else if (sortBySuggestionComplete) {
      setData_privilege(
        sortRev
          ? res.data.data
            .sort((a, b) => {
              const x = a.completed.toUpperCase();
              const y = b.completed.toUpperCase();
              return x === y ? 0 : x > y ? 1 : -1;
            })
            ?.reverse()
          : res.data.data.sort((a, b) => {
            const x = a.completed.toUpperCase();
            const y = b.completed.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
          })
      );
    }
    //   setData_privilege(sortBySno? res.data.data.reverse(): res.data.data);

    console.log("Response ->", res.data.data);
    console.log("Data priv ->", data_privilege);
  };

  useEffect(() => {
    build_report_and_access_privilliges_api();
  }, [
    user_session_data,
    setSortBySno,
    sortByRisk,
    sortRev,
    sortByAssignTo,
    sortByDueDate,
    sortBySuggestionComplete,
  ]);

  //   useEffect(  () => {

  // }, [sorting]);

  // =========<<<< hooks for modal filed >>>>>>>>>>>>>>>>================

  const [assigned_to, setAssigned_to] = useState("");
  const [due_date, setDue_date] = useState("");
  const [web_address, setWeb_address] = useState("");
  const [link_name, setLink_name] = useState("");
  const [sugg_completed, setSugg_completed] = useState("");

  const [assigned_to_data, setAssigned_to_data] = useState("fvfdgvfds");
  const [due_date_data, setDue_date_data] = useState("");
  const [web_address_data, setWeb_address_data] = useState("");
  const [link_name_data, setLink_name_data] = useState("");
  const [sugg_completed_data, setSugg_completed_data] = useState("");

  const [qcode, setQcode] = useState("");
  //const [formErrors, setFormErrors] = useState({});
  //const [formIsValid, setFormIsValid] = useState(false);

  // ===============<<<<<<<<< form error func >>>>>>>>>>>>>>>==========================
  let formIsValid = true;
  const handleFormValidation = () => {
    let formErrors = {};
    //let formIsValid = true;
    if (!assigned_to_data) {
      formIsValid = false;
      formErrors["email"] = "Assigned to is required.";
    }

    if (typeof assigned_to_data !== "undefined" && assigned_to_data !== null) {
      let lastAtPos = assigned_to_data.lastIndexOf("@");
      let lastDotPos = assigned_to_data.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          assigned_to_data.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          assigned_to_data.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        formErrors["email"] = "Email is not valid";
      }
      if (!due_date_data) {
        formIsValid = false;
        formErrors["date"] = "Due Date is required.";
      }
      //   if (!web_address_data) {
      //     formIsValid = false;
      //     formErrors["web"] = "Web Address is required.";
      //   }

      //     const urlRegEx =
      //    ;
      // if (!web_address_data) {
      //     formIsValid = false;
      //     formErrors["web"] = "Web Address is required.";

      // }
      // else
      //   if (
      //     !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
      //       String(web_address_data).toLowerCase()
      //     )
      //   ) {
      //     formIsValid = false;
      //     formErrors["web"] = "Invalid Web Address";
      //   }

      // if (!link_name_data) {
      //     formIsValid = false;
      //     formErrors["linkname"] = "Link Name is required.";

      // }
      // else
      //   if (
      //     !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
      //       String(link_name_data).toLowerCase()
      //     )
      //   ) {
      //     formIsValid = false;
      //     formErrors["linkname"] = "Invalid Link Name";
      //   }
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  // ==============<<<<<<<< submit modal data >>>>>>>>>>>>>>>>=============================

  const submit_data = async () => {
    if (handleFormValidation()) {

      let Periodcode1;
      // if(!copyperi){
      //    Periodcode1 = localStorage.getItem("R_PeriodCode");
      // }else{
      //   Periodcode1 = localStorage.getItem("copyperiod");
      // }



      let data = {
        topicid: localStorage.getItem("R_TopicId"),
        periodcode: localStorage.getItem("R_PeriodCode"),
        locationid: localStorage.getItem("R_LocationId"),
        qcode: qcode,
        qownerid: assigned_to_data,
        qduedate: moment(due_date_data).format("YYYY-MM-DD"),
        completed: sugg_completed_data,
        qwebaddr: web_address_data,
        qlinkname: link_name_data,
        customerid: user_session_data.customerid,
      };

      await axios
        .put(BASE_URL + "/api/update_build_report_and_access_privilliges", data)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("Updated Sucessfully!");
            setSugg_modal(false);
            build_report_and_access_privilliges_api();
          } else {
            message.warning(
              "An Assigned To email must be an active user for this Customer and set up for this Topic and Location. Your administrator can help get this user set up."
            );
          }
        })
        .catch((err) => {
          console.log("error==>", err);
        });
    }
  };
  // ==============<<<<<<< csv data download >>>>>>>>>>====================

  const [headers, setHeaders] = useState([
    { label: "Guidance", key: "Guidance" },
  ]);
  const [data_csv, setData_csv] = useState();

  // const csv_download = () => {

  //     var csvdata = data_privilege?.map((d) => {

  //         let temp = {
  //             Guidance: d.Guidance
  //         }
  //         console.log("temp csv data=====>", temp)
  //         setData_csv(temp)
  //     })

  // }
  // const printReport=(e)=> {

  // }
  const handleChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState((prevState) => {
      let { list, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        list = list?.map((item) => ({ ...item, isChecked: checked }));
      } else {
        list = list?.map((item) =>
          item.name === itemName ? { ...item, isChecked: checked } : item
        );
        allChecked = list.every((item) => item.isChecked);
      }
      return { list, allChecked };
    });
  };

  const content1 = (
    <div style={{ width: 150 }} className="text-center">
      <p
        style={{ color: "rgb(0,96,33)", fontSize: 20, cursor: "pointer" }}
        onClick={() => download_csv("withoutguidance")}
      >
        Without Guidance
      </p>
      <p
        style={{ color: "rgb(0,96,33)", fontSize: 20, cursor: "pointer" }}
        onClick={() => download_csv("withguidance")}
      >
        With Guidance
      </p>
    </div>
  );

  const content2 = (
    <div style={{ width: 150 }} className="text-center">
      <p
        style={{ color: "rgb(0,96,33)", fontSize: 20, cursor: "pointer" }}
        onClick={() => printReport("withoutguidance")}
      >
        Without Guidance
      </p>
      <p
        style={{ color: "rgb(0,96,33)", fontSize: 20, cursor: "pointer" }}
        onClick={() => printReport("withguidance")}
      >
        With Guidance
      </p>
    </div>
  );

  function download_csv(e) {
    if (e === "withoutguidance") {
      let table_header = {
        prioritydesc: "Risk Level",
        solutiondesc: "Suggested Solution",
        repcatdesc: "Category",
        qownerid: "Assigned To",
        qduedate: "Due Date",
        completed: "Suggestion Completed?",
        qwebaddr: "Web Address",
        qlinkname: "Link Name",
      };

      let hprepaiire1 = JSON.stringify(Object.values(table_header));
      let varrr = hprepaiire1 + "\n";
      let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

      var csv = data_privilege
        ?.map(function (d) {
          let temp = {
            Risklevel: d.prioritydesc,
            Suggested_Solution: d.solutiondesc,
            Category: d.repcatdesc,
            Assigned_To: d.qownerid,
            Due_Date: d.qduedate,
            Suggestion_Completed: d.completed,
            Web_Address: d.qwebaddr,
            Link_Name: d.qlinkname,
          };
          return JSON.stringify(Object.values(temp));
        })

        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      let temporary = vaarrsfs + csv;
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURI(temporary);
      hiddenElement.target = "_blank";
      hiddenElement.download = "suggestion_report.csv";
      hiddenElement.click();
    } else {
      let table_header = {
        prioritydesc: "Risk Level",
        solutiondesc: "Suggested Solution",
        Guidance: "Guidance",
        repcatdesc: "Category",
        qownerid: "Assigned To",
        qduedate: "Due Date",
        completed: "Suggestion Completed?",
        qwebaddr: "Web Address",
        qlinkname: "Link Name",
      };

      let hprepaiire1 = JSON.stringify(Object.values(table_header));
      let varrr = hprepaiire1 + "\n";
      let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

      var csv = data_privilege
        ?.map(function (d) {
          let temp = {
            Risklevel: d.prioritydesc,
            Suggested_Solution: d.solutiondesc,
            Guidance: d.Guidance,
            Category: d.repcatdesc,
            Assigned_To: d.qownerid,
            Due_Date: d.qduedate,
            Suggestion_Completed: d.completed,
            Web_Address: d.qwebaddr,
            Link_Name: d.qlinkname,
          };
          return JSON.stringify(Object.values(temp));
        })

        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
      let temporary = vaarrsfs + csv;

      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURI(temporary);
      hiddenElement.target = "_blank";
      hiddenElement.download = "suggestion_report.csv";
      hiddenElement.click();
    }
  }

  const printReport = (e) => {
    if (e === "withoutguidance") {
      print({
        printable: data_privilege,
        properties: [
          { field: "prioritydesc", displayName: "Risk Level" },
          { field: "solutiondesc", displayName: "Suggested Solution" },
          { field: "repcatdesc", displayName: "Category" },
          { field: "qownerid", displayName: "Assigned To" },
          { field: "qduedate", displayName: "Due Date" },
          { field: "completed", displayName: "Suggestion Completed?" },
          { field: "qwebaddr", displayName: "Web Address" },
          { field: "qlinkname", displayName: "Link Name" },
        ],

        type: "json",
        gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
        gridStyle: "border: 2px solid #3971A5;",
      });
    } else {
      print({
        printable: data_privilege,
        properties: [
          { field: "prioritydesc", displayName: "Risk Level" },
          { field: "solutiondesc", displayName: "Suggested Solution" },
          { field: "Guidance", displayName: "Guidance" },
          { field: "repcatdesc", displayName: "Category" },
          { field: "qownerid", displayName: "Assigned To" },
          { field: "qduedate", displayName: "Due Date" },
          { field: "completed", displayName: "Suggestion Completed?" },
          { field: "qwebaddr", displayName: "Web Address" },
          { field: "qlinkname", displayName: "Link Name" },
        ],
        type: "json",
        gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
        gridStyle: "border: 2px solid #3971A5;",
      });
    }
  };


  ///////////////////View Prior Reports////////////////////////


  const [peroidList, setPeroidlList] = useState();
  const [selectperoid, setSelectperoid] = useState([]);
  const [prior_modal, setPrior_modal] = useState(false);
  const [selectear, setSelectear] = useState(false);
  const [selectcode, setSelectcode] = useState(false);
  const [selectradio, setSelectradio] = useState(false);
  const [period_data_json, setPeriod_data_json] = useState([]);
  const [selectperiodcode, setSelectperiodcode] = useState();
  const [viewperiodcode, setViewperiodcode] = useState(false);
  const [radiocheck, setRadiocheck] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectdata, setSelectdata] = useState();


  const ViewPriorReport_api = async () => {
    let data = {
      locationid: localStorage.getItem("R_LocationId"),
      topicid: localStorage.getItem("R_TopicId"),
      userid: localStorage.getItem("UserID"),
      periodcode: localStorage.getItem("R_PeriodCode"),
    }


    let res = await axios.post(
      BASE_URL +
      `/api/ViewPriorReport`,
      data
    );

    if (res.data.code === 200) {
      console.log(res.data);
      setPeroidlList(res.data.result);
      setPrior_modal(true);
      console.log(selectperiodcode === "undefined", "uu")
      //setSelectperoid("")


      // if(selectperoid == ""){
      //   setRadiocheck(data.periodcode === '01');
      // //const isChecked = data.periodcode === '01';
      // }else{
      //   setRadiocheck(data.periodcode === selectperoid[0].periodcode);
      //   //const isChecked = data.periodcode === selectperoid[0].periodcode; 
      // }




    } else {
      console.log("error==>", res);
      message.error("No quiz was completed for this topic and location in a prior period");
    }

  };



  // const sortedArray = Object.values(peroidList).sort((a, b) => {
  //   if (a.perioddesc < b.perioddesc) return 1;
  //   if (a.perioddesc > b.perioddesc) return -1;
  //   return 0;
  // });



  useEffect(() => {
    console.log(qcode, "88");
  }, [qcode]);


  const selectperiodear = async () => {
    // if (selectperoid == "") {
    //   message.error("Not Selected Earlier Period");
    // }else{
    setPrior_modal(false);
    build_report_and_access_privilliges_api();
    // }

  }



  // ==============<<<<<<<< Copy_Prior_data >>>>>>>>>>>>>>>>=============================

  const [qcode1, setQcode1] = useState("");
  const [copyprior, setCopyprior] = useState(false);
  const [copydata, setCopydata] = useState([]);
  const [copyperi, setCopyperi] = useState(false);

  const Copy_Prior_data = async () => {

    let Periodcode;
    if (copyperi) {
      Periodcode = localStorage.getItem("copyperiod");
    } else {
      Periodcode = localStorage.getItem("R_PeriodCode");
    }


    let data = {
      topicid: localStorage.getItem("R_TopicId"),
      periodcode: localStorage.getItem("R_PeriodCode"),
      locationid: localStorage.getItem("R_LocationId"),
      qcode: localStorage.getItem("qcode"),
      customerid: user_session_data.customerid,
    };

    await axios
      .post(BASE_URL + "/api/Copy_Prior", data)
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data);
          setCopydata(res.data.result);
          setCopyprior(false)
          console.log(copydata.length > 0 ? copydata[0].qownerid : null, "t");
console.log(copydata,"copy")

        } else {
          console.log("error==>", res);
          setCopyprior(true)
          //message.error("No quiz was completed for this topic and location in a prior period");
        }
      })
      .catch((err) => {
        console.log("error==>", err);
      });

  };

  useEffect(() => {
    // Copy_Prior_data();
  }, []);



  // const copy_data = async () => {
  //   if (copydata.length > 0) {
  //     const { qownerid, qduedate, qwebaddr, qlinkname, completed, qcode } = copydata[0];
  //     setAssigned_to_data(qownerid);
  //     setDue_date_data(qduedate);
  //     setWeb_address_data(qwebaddr);
  //     setLink_name_data(qlinkname);
  //     setSugg_completed_data(completed);
  //     console.log(qcode, "qcode");
  //     setQcode(qcode);
  //     setQcode1(qcode);
  //     console.log(qcode1, "code");
  //     console.log(assigned_to_data, "assign");
  //   }
  // }


  let SNo = 0;
  const rows1 =data_privilege && Array.isArray(data_privilege)
  ? data_privilege.map((item) => {  
    return {
      ...item,
      SNo: SNo + 1,
      id: SNo++,
    };
  })
  : [];

  const rows = rows1;

  const columns: GridColumns = [
    {
      field: "SNo",
      headerName: "S.No",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "prioritydesc",
      headerName: "Risk Level",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="right"
            html={true}
            multiline={true}
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 9
                ? `${params.value.substring(0, 9)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "solutiondesc",
      headerName: "Suggested Solution",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",

      renderCell: (params) => {
        return (
          <Tooltip
            placement="right"
            html={true}
            multiline={true}
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "Guidance",
      headerName: "Guidance",
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      hide: guidence_select === "withoutguidance",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="right"
            html={true}
            multiline={true}
            title={params.value}
          >
            {params.value?.length > 12
                ? `${params.value.substring(0, 12)}...`
                : params.value}
            {/* {params.value?.slice(0, 12)}... */}
          </Tooltip>
        );

        // console.log(params.value);
      },
    },
    {
      field: "repcatdesc",
      headerName: "Category",
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="right"
            html={true}
            multiline={true}
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 14
                ? `${params.value.substring(0, 14)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "qownerid",
      headerName: "Assigned To",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="right"
            html={true}
            multiline={true}
            title={params.value}
          >
            {params.value?.length > 28
                ? `${params.value.substring(0, 28)}...`
                : params.value}
            {/* params.value?.slice(0, 28)}... */}
          </Tooltip>
        );

        // console.log(params.value);
      },
    },
    {
      field: "qduedate",
      headerName: "Due Date",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="right"
            html={true}
            multiline={true}
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "completed",
      headerName: "Suggestion Completed?",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="right"
            html={true}
            multiline={true}
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "qwebaddr",
      headerName: "Web Address",
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="right"
            html={true}
            multiline={true}
            title={params.value}
          >
            {params.value?.slice(0, 12)}...
          </Tooltip>
        );

        // console.log(params.value);
      },
    },
    {
      field: "qlinkname",
      headerName: "Link Name",
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",

      renderCell: (params) => {
        return (
          <Tooltip
            placement="right"
            html={true}
            multiline={true}
            title={params.value}
          >
            {params.value?.slice(0, 12)}...
          </Tooltip>
        );

        // console.log(params.value);
      },
    },
   
    {
      field: "id",
      hide: selectear,
      headerName: selectear ? "" : "Action",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName:  selectear ? "" : "super-app-theme--header",
      // renderHeader: selectear ? undefined : () => (
      //   <span className="super-app-theme--header">Action</span>
      // ),
      renderCell: (params) => (
         selectear === false && (
        <EditOutlined
          onClick={() => {
            const rowData = params.row;
           
            setAssigned_to(rowData.qowneredit);
            setDue_date(rowData.qdueedit);
            setWeb_address(rowData.qwebedit);
            setLink_name(rowData.qlinkedit);
            setSugg_completed(rowData.completeedit);

            setAssigned_to_data(rowData.qownerid);
            setDue_date_data(rowData.qduedate);
            setWeb_address_data(rowData.qwebaddr);
            setLink_name_data(rowData.qlinkname);
            setSugg_completed_data(rowData.completed);
            localStorage.setItem("qcode",rowData.qcode )
            setQcode(rowData.qcode);
            Copy_Prior_data();
            setSugg_modal(true);

          }}
        />
         )
      ),
    }
    
    
    
  ];


  return (
    <div>
      <div className="container-fluid">
        <div align="center" style={{ marginTop: 20 }}>
          {/* <h4>
            {user_session_data.firstname} with {user_session_data.custdesc} at
            the {localStorage.getItem("R_LocationDesc")} location
          </h4> */}

          <h4>
            Suggestion Report for {localStorage.getItem("R_CuzDesc")} at {localStorage.getItem("R_LocationDesc")}
          </h4>

        </div>

        <div className="row" align="center">
          {/* <div className="col-lg-3"> */}
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
            <StyledButton className="row1-btn"
              // style={{ width: 170 }}
              onClick={() => {
                localStorage.setItem('Earlierperiod', localStorage.getItem("R_PeriodCode"))
                history.push("/suggestion_report");
                setSelectperoid("");
              }}
            >
              Exit
            </StyledButton>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
            <StyledButton className="row1-btn"
              onClick={() => {
                setGuidanceToggle(!guidanceToggle);
              }}
            >
              {guidanceToggle ? "Without Guidance" : "With Guidance"}
            </StyledButton>
          </div>

          {list &&
            list?.map((data, index) => {
              return (
                <>
                  {list[index] &&
                    list[index].listvalue == "Download Reports" ? (
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                      <Popover
                        placement="bottom"
                        content={content1}
                        class="btn btn-success btn-primo "
                        trigger="click"
                        style={{ borderColor: "green" }}
                      >
                        <StyledButton className="row1-btn"
                        // onClick={() => {
                        //   download_csv()

                        // }}
                        >
                          Download Report &nbsp;
                          <DownloadOutlined style={{ fontSize: 20 }} />
                        </StyledButton>
                      </Popover>
                    </div>
                  ) : (
                    ""
                  )}

                  {list[index] && list[index].listvalue == "Print Reports" ? (
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                      <Popover
                        placement="bottom"
                        content={content2}
                        class="btn btn-success btn-primo "
                        trigger="click"
                        style={{ borderColor: "green" }}
                      >
                        <StyledButton className="row1-btn"
                        // onClick={() => {
                        //   download_csv()

                        // }}
                        >
                          Print Report &nbsp;
                          <PrinterOutlined style={{ fontSize: 20 }} />
                        </StyledButton>
                      </Popover>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}



          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
            <StyledButton className="row1-btn"
              onClick={() => {
                ViewPriorReport_api();

              }}
            >
              View Prior Reports
            </StyledButton>
          </div>


        </div>






        {/* <div className="mt-5 table-responsive tableFixHead">
          <StyledTable>
            <thead>
              <StyledTableHeader>
                <StyledTableHeaderData
                  style={{ width: "5%" }}
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByRisk(false);
                    setSortByCategory(false);
                    setSortByAssignTo(false);
                    setSortByDueDate(false);
                    setSortBySuggestionComplete(false);
                    setSortBySno(true);
                  }}
                >
                  S.No &nbsp;
                  <i class="fas fa-sort" />
                </StyledTableHeaderData>
                <StyledTableHeaderData
                  style={{ width: "8%" }}
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortBySno(false);
                    setSortByCategory(false);
                    setSortByAssignTo(false);
                    setSortByDueDate(false);
                    setSortBySuggestionComplete(false);
                    setSortByRisk(true);
                  }}
                >
                  Risk Level &nbsp;
                  <i class="fas fa-sort" />
                </StyledTableHeaderData>

                <StyledTableHeaderData style={{ width: "15%" }}>
                  &nbsp; Suggested Solution
                 
                </StyledTableHeaderData>
                {guidence_select != "withoutguidance" ? (
                  <StyledTableHeaderData>
                    Guidance
                   
                  </StyledTableHeaderData>
                ) : (
                  ""
                )}
                <StyledTableHeaderData
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByRisk(false);
                    setSortBySno(false);
                    setSortByAssignTo(false);
                    setSortByDueDate(false);
                    setSortBySuggestionComplete(false);
                    setSortByCategory(true);
                  }}
                >
                  Category &nbsp;
                  <i class="fas fa-sort" />
                </StyledTableHeaderData>

                <StyledTableHeaderData
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByRisk(false);
                    setSortBySno(false);
                    setSortByCategory(false);
                    setSortByDueDate(false);
                    setSortBySuggestionComplete(false);
                    setSortByAssignTo(true);
                  }}
                >
                  &nbsp; Assigned To &nbsp;
                  <i class="fas fa-sort" />
                </StyledTableHeaderData>
                <StyledTableHeaderData
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByRisk(false);
                    setSortBySno(false);
                    setSortByCategory(false);
                    setSortBySuggestionComplete(false);
                    setSortByAssignTo(false);
                    setSortByDueDate(true);
                  }}
                >
                  Due Date &nbsp;
                  <i class="fas fa-sort" />
                </StyledTableHeaderData>

                <StyledTableHeaderData
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByRisk(false);
                    setSortBySno(false);
                    setSortByCategory(false);
                    setSortByDueDate(false);
                    setSortByAssignTo(false);
                    setSortBySuggestionComplete(true);
                  }}
                >
                  Suggestion Completed? &nbsp;
                  <i class="fas fa-sort" />
                </StyledTableHeaderData>
                <StyledTableHeaderData>
                  &nbsp; Web Address
                </StyledTableHeaderData>

                <StyledTableHeaderData>&nbsp; Link Name</StyledTableHeaderData>

                {(!selectear || selectperoid ==[])   && (
                  <StyledTableHeaderData style={{ width: "5%" }}>
                    Action
                  </StyledTableHeaderData>
                )}
              </StyledTableHeader>
            </thead>

            <tbody>
              {data_privilege &&
                data_privilege?.map((data, index) => {
                  return (
                    <StyledTableRow>
                      <td>
                        {sortRev ? data_privilege.length - index : index + 1}
                      </td>
                      <td>{data.prioritydesc}</td>
                      <td>{data.solutiondesc}</td>
                      {guidence_select != "withoutguidance" ? (
                        <td>
                          {data.Guidance && (
                            <Tooltip
                              placement="right"
                              html={true}
                              multiline={true}
                              title={
                                <div style={{ whiteSpace: "pre-line" }}>
                                  {data.Guidance}
                                </div>
                              }
                            >
                              {data.Guidance?.slice(0, 30)}...
                            </Tooltip>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      <td>{data.repcatdesc}</td>
                      <td>
                        {" "}
                        <Tooltip
                          placement="right"
                          html={true}
                          multiline={true}
                          title={data.qownerid}
                        >
                          {data.qownerid?.slice(0, 12)}...
                        </Tooltip>
                      </td>
                      <td>{data.qduedate}</td>
                      <td>{data.completed}</td>
                      <td>
                        <Tooltip
                          placement="right"
                          html={true}
                          multiline={true}
                          title={data.qwebaddr}
                        >
                          {data.qwebaddr?.slice(0, 12)}...
                        </Tooltip>
                      </td>
                      

                      <td>
                        <Tooltip
                          placement="right"
                          html={true}
                          multiline={true}
                          title={data.qlinkname}
                        >
                          {data.qlinkname?.slice(0, 12)}...
                        </Tooltip>
                      </td>

                      
                      {!selectear  && (
                        <td>
                          <EditOutlined
                            onClick={() => {
                              setAssigned_to(data.qowneredit);
                              setDue_date(data.qdueedit);
                              setWeb_address(data.qwebedit);
                              setLink_name(data.qlinkedit);
                              setSugg_completed(data.completeedit);

                              setAssigned_to_data(data.qownerid);
                              setDue_date_data(data.qduedate);
                              setWeb_address_data(data.qwebaddr);
                              setLink_name_data(data.qlinkname);
                              setSugg_completed_data(data.completed);
                              console.log(data.qcode,"qcode");
                              localStorage.setItem("qcode",data.qcode )
                              setQcode(data.qcode);
                              setQcode1(data.qcode)
                              console.log(qcode1,"code");
                              setSugg_modal(true);
                              Copy_Prior_data();

                            }}
                          />
                        </td>
                      )}
                    </StyledTableRow>
                  );
                })}
            </tbody>
          </StyledTable>
        </div> */}



        <div
          style={{ height: '70vh', width: "100%", marginTop: 30 }}
          className={classes.root}
        >
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff8c24",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            // components={{
            //   NoRowsOverlay: CustomNoRowsOverlay,
            // }}
            rows={rows}
            getRowId={(r) => r.id}
            id="_id"
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[ 100]}
            pagination
            hideFooterPagination={ rows.length <= pageSize}
            // initialState={{

            //   pagination: {
            //     pageSize: 10,
            //   },
            // }}
            // pageSize={pageSize}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            // rowsPerPageOptions={[5, 10, 20, 50, 100]}
            // pagination
            //  processRowUpdate={processRowUpdate}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>


      </div>

      <ReactModal className="ModalStyle1 Width90" isOpen={sugg_modal}>
        <div>
          {" "}
          <StyledForm>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc"> Assigned To</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={assigned_to_data || ""}
                  type="email"
                  disabled={assigned_to == "N" ? true : false}
                  onChange={(e) => {
                    console.log(e.target.value, "e.target.value")
                    setAssigned_to_data(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.email && (
              <p
                style={{
                  color: "red",
                  fontSize: ".8rem",
                  marginTop: "0px",
                }}
              >
                {formErrors.email}
              </p>
            )}

            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc"> Due Date </StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  type="date"
                  date-format="YYYY-MM-DD"
                  value={due_date_data || ""}
                  disabled={due_date == "N" ? true : false}
                  onChange={(e) => {
                    setDue_date_data(e.target.value);
                  }}
                  id="qdesc"
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.date && (
              <p
                style={{
                  color: "red",
                  fontSize: ".8rem",
                  marginTop: "0px",
                }}
              >
                {formErrors.date}
              </p>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">
                  {" "}
                  Suggestion Completed{" "}
                </StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  disabled={sugg_completed == "N" ? true : false}
                  onChange={(e) => {
                    setSugg_completed_data(e.target.value);
                  }}
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                >

                  <option
                    value=" "
                    selected={sugg_completed_data == " " ? true : false}
                  >
                 None
                  </option>
                  <option
                    value="Y"
                    selected={sugg_completed_data == "Y" ? true : false}
                  >
                    Y
                  </option>
                  <option
                    value="N"
                    selected={sugg_completed_data == "N" ? true : false}
                  >
                    N
                  </option>
                  <option
                    value="Inprocess"
                    selected={sugg_completed_data == "Inprocess" ? true : false}
                  >
                    Inprocess
                  </option>
                </select>
              </div>
            </StyledFormInputDiv>

            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc"> Web Address </StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  type="url"
                  value={web_address_data || ""}
                  disabled={web_address == "N" ? true : false}
                  pattern="https?://.+"
                  required
                  onChange={(e) => {
                    setWeb_address_data(e.target.value);
                  }}
                  id="qdesc"
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.web && (
              <p
                style={{
                  color: "red",
                  fontSize: ".8rem",
                  marginTop: "0px",
                }}
              >
                {formErrors.web}
              </p>
            )}

            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc"> Link Name </StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  type="text"
                  value={link_name_data || ""}
                  disabled={link_name == "N" ? true : false}
                  onChange={(e) => {
                    setLink_name_data(e.target.value);
                  }}
                  id="qdesc"
                />
              </div>
            </StyledFormInputDiv>
            {/* {formErrors.linkname && (
              <p
                style={{
                  color: "red",
                  fontSize: ".8rem",
                  marginTop: "0px",
                }}
              >
                {formErrors.linkname}
              </p>
            )} */}
          </StyledForm>
        </div>

        <div align="center">
          <StyledButton
            className={`styled-button ${copyprior ? 'disabled' : ''}`}
            disabled={copyprior}
            style={{ width: 100, height: 30, marginRight: 10, }}
            onClick={() => {
              console.log("1")
              //copy_data();
             if(copydata[0].qownerid == null && copydata[0].qlinkname == null && copydata[0].qwebaddr == null && copydata[0].completed == null && copydata[0].qduedate == null ){
              message.error(
                "No prior period result is available."
              );
             }else{
              
              setAssigned_to_data(copydata[0].qownerid);
              setDue_date_data(copydata[0].qduedate);
              setWeb_address_data(copydata[0].qwebaddr);
              setLink_name_data(copydata[0].qlinkname);
              setSugg_completed_data(copydata[0].completed);
              setQcode(copydata[0].qcode);
              localStorage.setItem("copyperiod", copydata[0].periodcode)
              console.log(sugg_completed_data, "0")
              console.log(assigned_to_data, "1")
              setCopyperi(true)
              message.success(
                "Copied"
              );

             }

            }}
          >
            Copy Prior
          </StyledButton>
          <StyledButton
            style={{ width: 100, height: 30 }}
            onClick={() => {
              setCopyperi(true)
              submit_data();
            }}
          >
            Submit
          </StyledButton>
          <StyledButton
            style={{
              width: 100,
              height: 30,
              backgroundColor: "red",
              marginLeft: 10,
            }}
            onClick={() => {
              setCopyperi(false)
              setSugg_modal(false);
              formIsValid = true;
              setFormErrors({});
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </ReactModal>



      <ReactModal className="ModalStyle1 Width90" isOpen={prior_modal}>

        <h6 style={{ textAlign: "center", position: 'sticky', top: '0', zIndex: "1", padding: "12px 0px", background: "white", fontWeight: "600" }}>Earlier Periods</h6>

        <div>
          {" "}
          {/* <div>
          <h6 style={{textAlign: "center", position: 'sticky',top: '0', zIndex: "1" }}>Earlier Periods</h6>
          </div> */}
          <StyledTable class="table table-bordered">

            <thead>
              <StyledTableHeader>
                <StyledTableHeaderData>S.No</StyledTableHeaderData>
                <StyledTableHeaderData style={{ textAlign: "center" }}>Period</StyledTableHeaderData>
                <StyledTableHeaderData></StyledTableHeaderData>
                {/* <StyledTableHeaderData>
                          <input
                            type="checkbox"
                            className="checkbox select-all1 form-check-input"
                            
                          />
                        </StyledTableHeaderData> */}
              </StyledTableHeader>
            </thead>
            <tbody>
              {peroidList &&
                peroidList?.map((data, index) => {
                  const isChecked = data.periodcode === localStorage.getItem("R_PeriodCode");
                  const isRowSelected = selectedRowIndex === index;
                  return (
                    <>
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{data.perioddesc}</td>
                        <td>
                          <input

                            name="check[1]"
                            key={index}
                            type="radio"
                            value={selectradio}
                            //checked={data.periodcode === selectperiodcode}
                            //checked={data.periodcode === selectperiodcode || (selectperiodcode === "" && data.periodcode === '01')}
                            //checked={data.periodcode === selectperiodcode || (selectperoid === "" && data.periodcode === '01')}
                            // checked={selectperiodcode !== "" ? data.periodcode === selectperiodcode : data.periodcode === '01' }
                            //checked={(selectperiodcode !== "" && data.periodcode === selectperiodcode) || (selectperiodcode === "" && data.periodcode === '01')}

                            //  checked={
                            //   (selectperiodcode !== "" && data.periodcode === selectperiodcode) ||
                            //   (selectperiodcode !== "undefined"  && data.periodcode === '01')
                            // }

                            // checked={
                            //   (radiocheck == true && data.periodcode === selectperiodcode) ||
                            //   ( radiocheck== false && (localStorage.getItem("Earlierperiod") === localStorage.getItem("R_PeriodCode") || data.periodcode === selectperiodcode)) 


                            // }

                            checked={
                              (selectperoid !== "" && data.periodcode === selectperiodcode) ||
                              (selectperoid == "" && data.periodcode === localStorage.getItem("R_PeriodCode"))
                            }



                            onClick={(e) => {
                              console.log(data.periodcode, localStorage.getItem("R_PeriodCode"), "data.periodcode")
                              console.log(data.periodcode === localStorage.getItem("R_PeriodCode"))
                              //setSelectear(true)
                              setSelectradio(true)
                              setSelectedRowIndex(index);
                              let newarray = [];

                              newarray.push(data);

                              setSelectperoid((prevState) => {
                                //localStorage.setItem("Earlierperiod",newarray[0].periodcode )
                                if (newarray[0].periodcode === localStorage.getItem("R_PeriodCode")) {
                                  //localStorage.setItem("Earlierperiod",localStorage.getItem("R_PeriodCode") )
                                  //setRadiocheck(false)
                                  //setSelectear(false)
                                  console.log(radiocheck, "radiocheck")
                                  localStorage.setItem("Earlierperiod", newarray[0].periodcode)
                                  //setSelectear(false);
                                  //setViewperiodcode(false);
                                  //setViewperiodcode(false)
                                } else {
                                  //setRadiocheck(true)
                                  // setSelectear(true);
                                  //setViewperiodcode(true)
                                  localStorage.setItem("Earlierperiod", newarray[0].periodcode)
                                }

                                setSelectperiodcode(newarray[0].periodcode);
                                console.log(newarray[0].periodcode);

                                return newarray;
                              });

                              console.log(selectperoid, "select");
                              console.log(selectear, "selectear");
                            }}





                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </StyledTable>

          <div align="center">
            <StyledButton
              style={{ width: 100, height: 30 }}
              onClick={() => {

                setSelectdata(selectperiodcode)
                // if(!radiocheck){
                //   setSelectear(false)
                // }


                //localStorage.setItem("Earlierperiod",selectperiodcode )
                selectperiodear();
                if (selectperiodcode != localStorage.getItem("R_PeriodCode") && selectperoid != "") {
                  //setRadiocheck(true)
                  setSelectear(true);
                  setViewperiodcode(true)
                  console.log(selectperiodcode, "oo")
                }




                else if (localStorage.getItem("R_PeriodCode") === selectperiodcode) {
                  setSelectear(false)
                  setViewperiodcode(false)
                  // setRadiocheck(false)
                } else if (selectperoid == "") {
                  setSelectear(false)
                  setViewperiodcode(false)
                  //setRadiocheck(false)
                } else if (localStorage.getItem("Earlierperiod") === localStorage.getItem("R_PeriodCode")) {
                  setSelectear(false)
                }
                else {
                  setSelectear(false)
                  setViewperiodcode(false)
                  //setRadiocheck(false)
                }

                // setPrior_modal(false);
                // build_report_and_access_privilliges_api();

              }}
            >
              View Prior
            </StyledButton>
            <StyledButton
              style={{
                width: 100,
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {

                setSelectedRowIndex(null);
                //setSelectperoid("")


                // if(selectperiodcode != '01' ){
                //   setRadiocheck(false)

                //   console.log(selectperiodcode,"oo")
                // }else if (selectperiodcode == '01' ){

                //   setRadiocheck(true)
                // }



                console.log(selectear, "selectear")
                if (selectradio && !viewperiodcode) {
                  // setRadiocheck(false)
                  // if(viewperiodcode){
                  //   setSelectear(true);
                  // }else{
                  //   setSelectear(false);
                  // }
                  //setRadiocheck(false)
                  setSelectear(false);
                  // setViewperiodcode(false)

                }

                setPrior_modal(false);
                console.log("selectear:", selectear);
                console.log("selectperiodcode", selectcode);
              }}
            >
              Cancel
            </StyledButton>
          </div>


        </div>
      </ReactModal>




    </div>
  );
}

export default Esuggest;
