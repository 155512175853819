import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import {
  client_change,
  manage_client,
  change_plan,
} from "../../services/ClientProgramApi";
import axios from "axios";
import { BASE_URL } from "../../url/baseurl";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const StyledButtonEC = styled.button`
  background: ${theme};
  width: 180px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  font-size: 15px !important;
  color: white;
  font-weight: 400;
  border-color: none;
  border: none;
`;

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const ManageClient = ({ match }) => {
  const history = useHistory();
  console.log("propdata", match);

  const [edit_client_modal, setEdit_client_modal] = useState(false);
  const [change_enrolled_plan_modal, setChange_enrolled_plan_modal] =
    useState(false);

  const [clientName, setClientName] = useState("");
  const [enrolledPlan, setEnrolledPlan] = useState("");
  const [remainDays, setRemainDays] = useState("");
  const [activeClient, setActiveClient] = useState("");
  const [client_id, setClient_id] = useState("");
  const [mngClient, setMngClient] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  // ----------------------- Validation -------------------------
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!activeClient) {
      formIsValid = false;
      formErrors["activeClient_error"] = "Active Client is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const customerid = match.params.customerid;

  const editClientData = async () => {
    let data = {
      customerid: customerid,
      clientid: client_id,
      reminday: remainDays,
      activecc: activeClient,
    };

    let res = await axios.get(
      `${BASE_URL}/api/client_change?customerid=${customerid}&
    clientid=${client_id}&
    reminday=${remainDays}&
    activecc=${activeClient}
    `,
      data
    );

    if (res.data.code == 200) {
      setEdit_client_modal(false);
      mngeClient(customerid);
      message.success("Successfully Updated");
    } else {
      message.error(res.data.message);
    }
  };

  const mngeClient = async (customerid) => {
    manage_client(customerid)
      .then((res) => {
        console.log(res);

        if (res.data.code === 200) {
          setMngClient(res.data.Clientlist);
        } else {
          message.error("No client has been enrolled");
        }
        console.log("enrolldata", res.data.Clientlist);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    mngeClient(customerid);
    setTimeout(() => {
      console.log("message", mngClient);
      console.log(customerid);
    }, 700);
  }, []);

  return (
    <div className="container-fluid">
      <div className="mt-4 d-flex justify-content-center">
        <StyledButton1
          onClick={() => {
            history.push("/Extquestion2/true");
          }}
        >
          Return to Client Menu
        </StyledButton1>
      </div>

      <div>
        {/* --------------- Edit Details Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90" isOpen={edit_client_modal}>
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Edit Client</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Client Name</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    style={{
                      width: "80%",
                      marginLeft: "20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={clientName}
                    disabled={true}
                    onChange={(e) => {
                      setClientName(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Enrolled Plan</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    style={{
                      width: "80%",
                      marginLeft: "20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={enrolledPlan}
                    disabled={true}
                    onChange={(e) => {
                      setEnrolledPlan(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Reminder Days</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="number"
                    style={{
                      width: "80%",
                      marginLeft: "20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    disabled={true}
                    value={remainDays}
                    onChange={(e) => {
                      setRemainDays(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Active Client</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "80%",
                      marginLeft: "20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={activeClient}
                    onChange={(e) => {
                      setActiveClient(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Client
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.activeClient_error && (
                <div className="offset-8">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.activeClient_error}
                  </p>
                </div>
              )}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation()) {
                  editClientData();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save Changes
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setEdit_client_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      {/* --------------- Enroll Client Button Modal ------------ */}
      <ReactModal className="ModalStyle2 Width90" isOpen={change_enrolled_plan_modal}>
        <div>
          <div align="center" className="mb-3">
            <h4>
              Are you sure you want to Change the Enrolled Plan?
            </h4>
            <StyledButton
              style={{ width: "40%", height: 30, marginTop: 50 }}
              onClick={() => {
                history.push("/subpymt_program");
              }}
            >
              Change Enrolled Plan
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setChange_enrolled_plan_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </ReactModal>

      <div className="m-4 table-responsive tableFixHeadXXLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData></StyledTableHeaderData>
              <StyledTableHeaderData>S.No</StyledTableHeaderData>
              <StyledTableHeaderData>Client's Name</StyledTableHeaderData>
              <StyledTableHeaderData>Enrolled Plan</StyledTableHeaderData>
              <StyledTableHeaderData>Reminder Days</StyledTableHeaderData>
              <StyledTableHeaderData>Active Client</StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 20 }}>
                Action
              </StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {mngClient &&
              mngClient?.map((data, index) => (
                <StyledTableRow>
                  <td></td>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td style={{ color: "black" }}>{data.custdesc}</td>
                  <td style={{ color: "black" }}>{data.pymtdesc}</td>
                  <td style={{ color: "black" }}>{data.reminday}</td>
                  <td style={{ color: "black" }}>{data.activecc}</td>
                  <td>
                    <a
                      onClick={() => {
                        setClientName(data.custdesc);
                        setEnrolledPlan(data.pymtdesc);
                        setRemainDays(data.reminday);
                        setActiveClient(data.activecc);
                        setClient_id(data.clientid);
                        setEdit_client_modal(true);
                      }}
                    >
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                    &nbsp; &nbsp;
                    <StyledButtonEC
                      style={{ alignContent: "flex-start" }}
                      onClick={() => {
                        setChange_enrolled_plan_modal(true);
                      }}
                    >
                      Change Enrolled Plan
                    </StyledButtonEC>
                  </td>
                </StyledTableRow>
              ))}
          </tbody>
        </StyledTable>
      </div>
    </div>
  );
};

export default ManageClient;
