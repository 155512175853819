import React, { useEffect, useState,useCallback } from "react";
import styled from "styled-components";
import {message} from "antd";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, GridCellParams , 
  GridCellModes} from "@mui/x-data-grid";
// import { GridCellModesModel } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { Tooltip } from "@mui/material";
import {
  Add_User_Location,
  report_group_topic_location_api,
  Assign_Users_Locations,
  Assign_Users_Locations1,
  Get_custloc_combinations,
} from "../../services/AdminProgramApi";

const theme = localStorage.getItem("brandColor");

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const AssignUsers_Locations = (props) => {
  const [custlocList, setcustlocList] = useState(props.list);
  const classes = useStyles();
  const cellClickRef = React.useRef(null);
  const [UsersLocations, setUsersLocations] = useState(props.data);
  const [customerid, setcustomerid] = useState(props.data);
  const [pageSize, setPageSize] = useState(5);
  const [validRow, setValidRow] = useState([]);

  const [promiseArguments, setPromiseArguments] = useState(null);
  const [selection, setSelection] = useState([]);
  const noButtonRef = React.useRef(null);
  const [snackbar, setSnackbar] = useState(null);
  const [cellModesModel, setCellModesModel] = useState({});

  const [reportgroup, setReportgroup] = useState("");
  const [descGroup, setDescGroup] = useState([]);
  const [groupid, setGroupid] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(false);






  const Get_custloc_combinations_api = () => {

    Get_custloc_combinations({
      customerid: customerid,
      Custgroupid: reportgroup,
      Groupid: groupid,
    })
      .then((res) => {
        if (res.data.code === 200) {
          // message.success("Updated Successfully");
          setcustlocList(res.data.result);
          // setRun1("Assign Users to Locations");

          //console.log(" Dummy result ==>", res.data.result);
        } else if (res.data.code === 300) {
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };



  const columns = [
    {
      field: "useremail",
      headerName: "User Email",
      type: "text",
      // width: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // editable: true,
    },
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      // type: "text",
      type: "singleSelect",
     
      valueOptions: ({ row }) => { const options = [];
        custlocList?.map((type) =>options.push(type.locationdesc))
        return options
      },
  
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      editable: () => true
      
      // editable: (params) => {   const options = false;
      //   console.log("location ==>",params.vale);
    
      // },
    },
    {
      field: "countrycode",
      headerName: "Location Country",
      type: "text",
      flex: 1,
      align: "center",
      headerAlign: "center",
      // width: 200,
       valueGetter: getcountrycode,
      headerClassName: "super-app-theme--header",
      // editable: true,
    },
    {
      field: "custlocid",
      headerName: "Customer Location ID",
      type: "text",
      headerClassName: "super-app-theme--header",
      valueGetter: getcustlocid,
      
      flex: 1,
      align: "center",
      headerAlign: "center",
      // editable: true,
    },
  ];
  
  useEffect(() => {
   // Assign_Users_Locations_api();
    ReceiveLocList();
    getReportlocation();
    Get_custloc_combinations_api();
   
  }, []);
  
  const ReceiveLocList = async () => {
    console.log("ReceiveLocList");
    const upd_obj = UsersLocations.map((obj) => {
      if(obj.locationdesc != null){
   
        validRow.push(obj.id);

      }

    });
    
  };

  const Assign_Users_Locations_api = () => {
    //console.log("Topic_Location_api");
    Assign_Users_Locations1({
      customerid: customerid,
      Custgroupid: reportgroup,
      Groupid: groupid,
    })
      .then((res) => {
        if (res.data.code === 200) {
          // message.success("Updated Successfully");
         // setUsersLocations("");
          setUsersLocations(res.data.result);
        //  setRun1("Assign Users to Locations");

          //console.log(" Dummy result ==>", res.data.result);
        } else if (res.data.code === 300) {
        } else {
          setUsersLocations("");
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const getReportlocation = async () => {
    report_group_topic_location_api({
      customerid: customerid[0].customerid,

    })

      .then((res) => {
        if (res.data.code == 200) {
          console.log("res group data===>>", res.data);
          setDescGroup(res.data.result);
          console.log("res group data===>>", res.data.result);
             setGroupid(res.data.result[0].custgroupid)

          //  }
          console.log("gid", groupid);
        } else {
          console.log("error");
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error===>>", err);
      });
  };


  
  function getcountrycode(params) {
   
    let value = '';
    const filterArray = UsersLocations.map((obj) => {
      if (obj.id == params.row.id) {

        value = obj.countrycode ;
      }
    });
    return value;
  }

  function getcustlocid(params) {
   
    let value = '';
    const filterArray = UsersLocations.map((obj) => {
      if (obj.id == params.row.id) {
 
        value = obj.custlocid ;
      }
    });

 
    return value;
  }

  const rows = UsersLocations;
  
  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) =>
          setTimeout(() => {
            if (user.name?.trim() === "") {
              reject();
            } else {
              resolve(user);
            }
          }, 200)
        ),
      []
    );
  };

  const mutateRow = useFakeMutation();
  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
       
        const mutation = computeMutation(newRow, oldRow);
        
        if (mutation) {
        
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
         
          resolve(oldRow); 
        }
      }),
    []
  );

  const processRowSelection = (arr1, arr2) => {
    let res = [];
    if (Array.isArray(arr1)) {
    res = arr1.filter((el) => {
      return arr2.find((element) => {
        return element === el.id;
      });
    });
  }

    return res;
  };

  function computeMutation(newRow, oldRow) {

    if (newRow.useremail !== oldRow.useremail) {
      return `User Email from '${oldRow.useremail}' to '${newRow.useremail}'`;
    }
    if (newRow.locationdesc !== oldRow.locationdesc) {
      return `Location from '${oldRow.locationdesc || ""}' to '${
        newRow.locationdesc || ""
      }'`;
    }
    if (newRow.countrycode !== oldRow.countrycode) {
      return `Location Country from '${oldRow.countrycode || ""}' to '${
        newRow.countrycode || ""
      }'`;
    }
    if (newRow.custlocid !== oldRow.custlocid) {
      return `Customer Location ID
 from '${oldRow.custlocid || ""}' to '${
        newRow.custlocid || ""
      }'`;
    }


    return null;
  }

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        // TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };
 
  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;
    //console.log("handleYes");
    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({ children: "Successfully Saved", severity: "success" });
      resolve(response);
      //console.log("handleYes ==>", newRow.id);
      const upd_obj = UsersLocations.map((obj) => {
        if (obj.id == newRow.id) {
         let newArray ={};
          const upd_obj2 = custlocList.map((list) => {  
            if(list.locationdesc == newRow.locationdesc ){
            

              newArray = {
                customerid: newRow.customerid,
                useremail: newRow.useremail,
                locationdesc: newRow.locationdesc,
                locationid: list.locationid,
                custlocid: list.custlocid,
         
                countrycode: list.countrycode,
                id: newRow.id,
              };
            }
           
            });

            return newArray;
       
        } else {
          return obj;
        }
        // return obj;
      });
      
      setUsersLocations(upd_obj);

      setPromiseArguments(null);
    } catch (error) {
      setSnackbar({ children: "Name can't be empty", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const CheckRowEdit = (id) => {
    // console.log("row id ==>",id);
   var rowValid =true;
    const upd_obj = validRow.map((obj) => { 
      if(obj == id){
        rowValid =false;

      }

    });

    return rowValid;
    

  }

  const ProcessValue = () => {
   
    if (selection.length == 0) {
      message.error("No records available for this selection");
      return 1;
    }
    const res = validateProcess();
    if (res) {
      console.log("valid ==>", res);
      Add_User_Location({
            data: selection,
      })
        .then((res) => {
          if (res.data.code === 200) {
            console.log("res.data.code ==>", res.data.code);
            message.success("Updated Successfully");
            props.parentCallback2("exit");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((e) => {
          //console.log(e);
        });

    } else {
      console.log("Invalid ==>", res);
      message.error("Record is invalid, Please enter valid value");
    }
  };
  const validateProcess = () => {
    const upd_obj = selection.map((obj) => {
      if(obj.locationid == null){
        return false
      }
      return true;
    });
  
    const res = upd_obj.every((n) => {
      return n == true;
    });
    console.log("Result ==>",res);
    return res;
  };
  
  const handleCellClick = React.useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit }
        }
      };
    });
  }, []);


  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <>
      <div className="container" style={{ overflow: "none" }}>
        <div className=" mt-3">
          <div className="row text-center">
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <StyledButton1
              style={{ backgroundColor: "red" }}
                onClick={() => {
                  props.parentCallback2("");
                }}
              >
                Cancel
              </StyledButton1>
            </div>


            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">


<div class="btn-group drop " role="group">
  <select
    style={{ borderColor: "#ff8c24" }}
    type="text"
    defaultValue={groupid}
    withAll={true}
    multi={true}
   value={reportgroup}
    onClick={() => {
      getReportlocation();
      console.log("location");
      console.log("group", descGroup);
      console.log(reportgroup, "Reportgroup")
      
    }}
    
    // onClick={(e) => {
    //   getReportlocation();

    //  }}
     onChange={(e) => {

                      
      setSelectedOptions("true");
      setReportgroup(e.target.value);
      console.log("value", e.target.value);
    }}
  >
    <option value={descGroup.custgroupid}>All</option>
    {descGroup?.map((data, i) => {

      //   console.log(country)
      return (
        <option value={data.custgroupid}>
          {data.custgroupdesc?.substring(0, 26)}
        </option>
      );
    })}
  
  </select>
  <button class="btn btn-default ant-btn-default1" type="button" tabindex="-1"
    onClick={() => {
      Assign_Users_Locations_api();
      Get_custloc_combinations_api();
     // getgrouplocation();
      console.log("Add Locations");
    }}
  >Run</button>

</div>

</div>


            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <StyledButton1
                onClick={() => {
       
                  ProcessValue();
                }}
              >
                Process Records
              </StyledButton1>
            </div>
            
          </div>

          <div
            style={{ height: 400, width: "100%", marginTop: 15 }}
            className={classes.root}
          >

           {renderConfirmDialog()}
            <DataGrid
              sx={{
                ".MuiDataGrid-columnHeaders": {
                  backgroundColor: "#ff8c24",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
              }}
              rows={rows}
              columns={columns}
            
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              pagination
              processRowUpdate={processRowUpdate}
              checkboxSelection
              disableSelectionOnClick={true}
            //  onCellClick={() => (cellClickRef.current = true)}
              onSelectionModelChange={(newSelection) => {
                const result = processRowSelection(
                  UsersLocations,
                  newSelection
                );
                setSelection(result);
                console.log("result", result);
              }}





// onSelectionModelChange={(id) => {
//   const selectedIDs = new Set(id);
//   const selectedRowData = rows.filter((row) =>
//     selectedIDs.has(row.id.toString())
//   );
//   console.log(selectedRowData);
// }}


             // editMode="cell"
              
              // isCellEditable={(params) =>  { 
              //   const value = CheckRowEdit(params.row.id) ; 
                
               
              //   return value;
              // } 
              //}
              cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
              experimentalFeatures={{ newEditingApi: true }}
            />
             {!!snackbar && (
                    <Snackbar
                      open
                      onClose={handleCloseSnackbar}
                      autoHideDuration={6000}
                    >
                      <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                  )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignUsers_Locations;
