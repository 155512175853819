import styled from "styled-components";
import React, { useState, useRef } from "react";
import { useParams, useHistory } from "react-router";
import ReactModal from "react-modal";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Button, Card, Table, Modal, message } from "antd";
import {
  ExclamationCircleOutlined,
  InfoCircleTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import { BASE_URL } from "../url/baseurl";
import axios from "axios";
import { guidance_delete } from "../services/GuidanceApi";
const theme = localStorage.getItem('brandColor');

const { confirm } = Modal;

const StyledDiv = styled.div`
  margin-top: 20px;
  position: relative;
  left: 3.68%;
  width: 92.6%;
`;

const StyledTable = styled.table`
  width: 100%;
  color: #757373;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  color: #ffffff;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
  padding-left: 5px;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledButton = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  padding: 0px 20px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const GuidanceData = ({ datas, warning }) => {
  const params = useParams();
  const ModalStyle = {
    content: {
      width: "50%",
      height: "50%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // display: "flex",
      // justifyContent: "center",
    },
  };

  const [editRCat, setEditRCat] = useState(false);
  const [editRCatData, setEditRCatData] = useState("");
  const [editRCatDataId, setEditRCatDataId] = useState("");
  const entercuzguidedescref = useRef();

  const showEditRCatModal = async (result, data) => {
    setEditRCatData(result);
    setEditRCatDataId(data);
    setEditRCat(true);
  };

  const closeEditModal = () => {
    setEditRCat(false);
  };

  const questionSubmitHandler = async (event) => {
    event.preventDefault();
    const questionObject = {
      // userid: localStorage.getItem("UserID"),
      topicid: params.id,
      guidanceid: String(editRCatDataId),
      cuzguidedesc: entercuzguidedescref.current.value,
    };
    console.log(questionObject);
    sendQuestionDataToAPIHandler(questionObject);
  };

  const sendQuestionDataToAPIHandler = async (data) => {
    console.log("basantjanghel");
    const response = await axios
      .put(`${BASE_URL}/api/update_cuzguidance`, data)
      .then((res) => {
        console.log("====Guidance add>", res);
        //  window.location.reload()
        if (res.data.code === 200) {
          message.success("Guidance  updated successfully");
          window.location.reload();
        } else {
          message.error("Something went wrong,please try again");
        }
      })
      .catch((err) => {
        console.log("guidance add error===>", err);
        message.error("Something went wrong,please try again");
      });
  };

  const deleteUser = async (topicid, guidanceid) => {
    confirm({
      title: "You sure you want to delete this Guidance text?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "cancel",
      onOk() {
        guidance_delete({
          topicid: params.id,
          guidanceid: guidanceid,
        })
          .then((res) => {
            // UserListApi()
            console.log("delete response===>", res);

            if (res.data.code == "200") {
              if (
                String(res.data.message) ==
                "you cannot delete this cuzguidedesc because it is used in cuzquestion"
              )
                message.info(
                  "Remove this Guidance from other Questions before deleting"
                );
              else {
                message.success("Guidance deleted successfully");
                window.location.reload();
              }
            } else {
              message.info("Something went wrong please try again");
            }
          })
          .catch((err) => {
              console.log(err);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <StyledDiv className="tableFixHeadXXLarge">
      <StyledTable class="table-auto">
        <thead>
          <ScrollTableHeader>
            <StyledTableHeaderData>Guidance Id.</StyledTableHeaderData>
            <StyledTableHeaderData>Guidance Description </StyledTableHeaderData>
            <StyledTableHeaderData>Action </StyledTableHeaderData>
          </ScrollTableHeader>
        </thead>
        <tbody>
          {datas && datas.length == 0 ? (
            <div>
              <br />
              <h6>No data</h6>
            </div>
          ) : (
            datas &&
            datas?.map((result, index) => {
              return (
                <StyledTableRow
                  style={{
                    backgroundColor: result.color,
                    color: result.color == "red" ? "white" : "Black",
                    fontWeight: 600,
                  }}
                  title={result.message}
                >
                  <td>{result.guidanceid}</td>
                  <td width="70%" className="Guidance-display">
                    {result.cuzguidedesc}
                  </td>

                  <td>
                    <EditOutlined
                      style={{ paddingRight: 14, color: "#3E79F7" }}
                      onClick={() => {
                        showEditRCatModal(
                          result.cuzguidedesc,
                          result.guidanceid
                        );
                      }}
                    />
                    <DeleteOutlined
                      onClick={() => {
                        deleteUser(result.topicid, result.guidanceid);
                      }}
                      style={{ color: "red" }}
                    />
                  </td>
                </StyledTableRow>
              );
            })
          )}
          </tbody>
      </StyledTable>

      <ReactModal
        isOpen={editRCat}
        className="ModalStyle2 Width90"
        onRequestClose={closeEditModal}
      >
        <StyledForm onSubmit={questionSubmitHandler}>
          <h4 style={{ textAlign: "center" }}>Edit Guidance</h4>
          <StyledFormInputDiv>
            <StyledLabel htmlFor="cuzguidence">
              {" "}
              Guidance Description
            </StyledLabel>

            <textarea
              rows="7"
              ref={entercuzguidedescref}
              defaultValue={editRCatData}
              id="cuzguidence"
              placeholder="Enter Guidance Description"
              style={{ width: 550 }}
            />
          </StyledFormInputDiv>
          <div className="mt-2" align="center">
            <StyledButton
              style={{
                width: "30%",
                backgroundColor: theme,
              }}
            >
              Submit
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                backgroundColor: "red",
              }}
              onClick={() => {
                closeEditModal();
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </StyledForm>
      </ReactModal>
    </StyledDiv>
  );
};

export default GuidanceData;
