import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { message } from "antd";
import { useHistory } from 'react-router-dom';
import { update_ai_query_parameters, get_ai_templates_parameters } from "../../../../services/AdminProgramApi";

ReactModal.setAppElement('#root');

const theme = localStorage.getItem('brandColor');

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ExampleValue = styled.p`
  margin-left: 10px;
    margin-top: 10px;
  margin-bottom:0;
  font-size: 0.8rem;
  color: #888;
`;

const ModalContent = ({
  closeModal,
  initialValues,
  onSubmitSuccess,
  match,
}) => {
  //const customerid = match.params.customerid;
  const history  = useHistory();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [templateParams, setTemplateParams] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [saveText, setsaveText] = useState("Save"); // Track active field
  const customer_id = +initialValues.customerid;
  console.log("modalcustomer",customer_id)
  useEffect(() => {
    const getAiTemplateParameters = async () => {
      try {
        const templ_typeid = +initialValues.templ_typeid;
        const res = await get_ai_templates_parameters({ templ_typeid });

        if (res.data.code === 200) {
          const initialFormData = {};
          const errorFormData = {};
          res.data.template_params.forEach((param) => {
            initialFormData[param.parm_code] = '';
            if (param.required === 'Y') {
              errorFormData[param.parm_code] = '';
            }
          });
          setFormData(initialFormData);
          setFormErrors(errorFormData);
          setTemplateParams(res.data.template_params);
          setIsOpen(true);
        }
      } catch (error) {
        console.error("Error fetching custom topic data:", error);
        message.error("Failed to fetch template parameters");
      }
    };

    getAiTemplateParameters();
  }, [initialValues.templ_typeid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear previous errors for the field
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: null });
    }
    console.log(formData)
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setActiveField(null)
    // Validate form data
    const errors = {};
    Object.keys(formData).forEach((key) => {
      const data = formData[key];
      if (data.trim() === '' && formErrors.hasOwnProperty(key)) {
        errors[key] = `${key} is required`;
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const filterData = {};
      setIsSaveDisabled(true);
      setsaveText("Saving...");
      Object.keys(formData).forEach(key => {
        const element = formData[key];
        if (element.trim().length > 0) {
          filterData[key] = element; // Add key-value pair to filterData object
        }
      });
      const response = await update_ai_query_parameters({
        formData:filterData,
        customerid: initialValues.customerid,
        userid: initialValues.userid,
        templ_typeid: initialValues.templ_typeid,
        td_code: initialValues.td_code
      });

      console.log(response)

      if (response.data.code === 200) {
        setIsSaveDisabled(false);
        console.log(history);
        history.push(`/adminfunction/ai_result_list/${customer_id}`);
        message.success("Data saved successfully");                
        closeModal();
      } else {
        setIsSaveDisabled(false);
        setsaveText("Save");
        message.error(response.data.message);        
      }
    } catch (error) {
      setIsSaveDisabled(false);
      setsaveText("Save");
      console.error("Error saving data:", error);
      message.error("Failed to save data");      
    }

  };

  const resetFields = () => {
    setFormData({});
    setFormErrors({});
    closeModal();
  };

  const handleInputFocus = (paramdisplayed) => {
    setActiveField(paramdisplayed); // Set active field
  };



  return (
    <ReactModal className="ModalStyle1 Width90" isOpen={isOpen}>
      <StyledForm>
        <div align="center" className="mb-3">
          <h4>Fine Tune Query</h4>
        </div>

        {templateParams.map((param, index) => (
          <div key={index}>
            <StyledFormInputDiv>
              <StyledLabel htmlFor={param.parm_code}>
                {param.parm_displayed}{" "}
                <span style={{
                  fontWeight: param.required === 'Y' ? 'bold' : 'normal',
                  color: param.required === 'Y' ? 'red' : 'inherit',
                  opacity: param.required === 'Y' ? 1 : 0.5
                }}>
                  {param.required === 'Y' ? '*' : '(Optional)'}
                </span>
              </StyledLabel>
              <input
                type="text"
                name={param.parm_code}
                value={formData[param.parm_code] || ''}
                onChange={handleChange}
                onFocus={() => handleInputFocus(param.parm_code)}
                placeholder="Enter value"
                style={{ width: "50%", border: formErrors[param.parm_code] ? "1px solid red" : "1px solid black" }}
              />
            </StyledFormInputDiv>         
            {formErrors[param.parm_code] && (
              <div className="offset-6 mt-2">
                <p className="mb-0" style={{ color: "red", fontSize: ".8rem", marginTop: "0px" }}>
                  {/* {formErrors[param.parm_code] } */}
                  {param.parm_displayed} is required
                </p>
              </div>
            )}
               {activeField === param.parm_code && param.parm_descr && (
                <ExampleValue>{param.parm_descr}</ExampleValue>
              )}
          </div>
        ))}

        <div align="center">
          <StyledButton disabled={isSaveDisabled} style={{ width: "30%", height: 30, marginTop: 50 }} onClick={handleSave}>
          {saveText}
          </StyledButton>
          <StyledButton style={{ width: "30%", height: 30, backgroundColor: "red", marginLeft: 10 }} onClick={resetFields}>
            Cancel
          </StyledButton>
        </div>
      </StyledForm>
    </ReactModal>
  );
};

export default ModalContent;
