import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: darkgrey;
  font-weight: 500;
  text-decoration: none;
  margin-top: 25px;
  
  &:active {
    text-decoration: none;
    color: darkgrey;
  }
  &:hover {
    text-decoration: none;
    color: grey;
    cursor: pointer;
  }
`;

const MoreStyledLink = styled(Link)`
  color: darkgrey;
  font-weight: 500;
  text-decoration: none;
  margin-top: 25px;
  margin-right: 90%;
  &:active {
    text-decoration: none;
    color: darkgrey;
  }
  &:hover {
    text-decoration: none;
    color: grey;
    cursor: pointer;
  }
`;

const StyledNav = styled.nav`
  display: flex;
  justify-self: center;
  justify-content: space-between;
  width: 90%;
`;

const WelcomeHeader = () => {
  return (
    <StyledNav>
      {/* <Link>
        {" "}
        <img
          src={logo}
          alt="logo"
          style={{
            width: "64px",
            height: "75px",
          }}
        />
      </Link>
      <MoreStyledLink> CuzTopic </MoreStyledLink>
      <StyledLink to="/welcome"> Home </StyledLink> */}
    </StyledNav>
  );
};

export default WelcomeHeader;
