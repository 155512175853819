import React, { useState } from "react";
import { forgot_password_verify_api } from "../../services/NewUI_API";
import { message } from "antd";
import { useHistory } from "react-router-dom";
const theme = localStorage.getItem('brandColor');
function ForgotPassword(props) {
  const history = useHistory();
  console.log(props);

  localStorage.setItem("F_Token", props.match.params.token);

  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [formErrors, setFormErrors] = useState([]);

  const handleFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!new_password) {
      formIsValid = false;
      formErrors["newpasswordError"] = "Password is required.";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,20}$/.test(
        new_password
      )
    ) {
      formIsValid = false;
      formErrors["newpasswordError"] =
        "Invalid Password. Please use 12 mixed characters or more. (A,a,1,@)";
    }

    if (!confirm_password) {
      formIsValid = false;
      formErrors["confirmpasswordError"] = "Confirm password is required";
    }

    if (new_password !== confirm_password) {
      formIsValid = false;
      formErrors["passwordError"] = "Password is not matching";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const ForgotPasswordVarify = () => {
    if (handleFormValidation()) {
      forgot_password_verify_api({
        password: new_password,
        confirm_password: confirm_password,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.code === 200) {
            message.success("Password Changed Successfully");
            
            localStorage.clear();
            window.location.href = "/login";
          }
        })
        .catch((e) => {
          message.error("Something went wrong");
          console.log(e);
        });
    } else {
      message.error("Invalid credentials");
    }
  };

  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  function myFunction1() {
    var x = document.getElementById("myInput1");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const allowed = {
    uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
    lowers: "qwertyuiopasdfghjklzxcvbnm",
    numbers: "1234567890",
    symbols: "!@#$%^&*",
  };
  const getRandomCharFromString = (str) =>
    str.charAt(Math.floor(Math.random() * str.length));
  const generatePassword = (length = 12) => {
    // password will be @Param-length, default to 8, and have at least one upper, one lower, one number and one symbol
    let pwd = "";
    pwd += getRandomCharFromString(allowed.uppers); //pwd will have at least one upper
    pwd += getRandomCharFromString(allowed.lowers); //pwd will have at least one lower
    pwd += getRandomCharFromString(allowed.numbers); //pwd will have at least one number
    pwd += getRandomCharFromString(allowed.symbols); //pwd will have at least one symbolo
    for (let i = pwd.length; i < length; i++)
      pwd += getRandomCharFromString(Object.values(allowed).join("")); //fill the rest of the pwd with random characters
    setNew_password(pwd);
    setConfirm_password(pwd);
    return pwd;
  };

  return (
    <>
      <div className="container">
        <div
          class="card mx-auto mt-5 Width100"
          style={{ width: "45%", borderRadius: 20 }}
        >
          <div
            class="card-header pt-3 pb-3 text-center"
            style={{
              background: theme,
              color: "white",
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            Change Your Password
          </div>

          <div class="card-body">
            <div class="form-group mt-3">
              <label style={{ fontWeight: "bold", fontSize: 16 }}>
                Password
              </label>
              <div class="input-group">
                <input
                  type="password"
                  id="myInput"
                  className="form-control"
                  value={new_password}
                  onChange={(e) => setNew_password(e.target.value)}
                  placeholder=" Enter Password"
                  class="form-control"
                />
                <span
                  class="input-group-text"
                  style={{ background: "white", border: "1 px solid gray" }}
                >
                  {" "}
                  &nbsp;
                  <a onClick={() => generatePassword()}>
                    <i class="fa fa-plus-square"></i>
                  </a>
                </span>
                <span
                  class="input-group-text"
                  style={{ background: "white", border: "1 px solid gray" }}
                >
                  {" "}
                  &nbsp;
                  <a onClick={() => myFunction()}>
                    <i class="fas fa-eye"></i>
                  </a>
                </span>
              </div>
            </div>
            {formErrors.newpasswordError && (
              <div>
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.newpasswordError}
                </p>
              </div>
            )}

            <div class="form-group mt-3">
              <label style={{ fontWeight: "bold", fontSize: 16 }}>
                Confirm Password
              </label>
              <div class="input-group">
                <input
                  type="password"
                  id="myInput1"
                  className="form-control"
                  value={confirm_password}
                  onChange={(e) => {
                    setConfirm_password(e.target.value);
                    console.log(e.target.value);
                  }}
                  placeholder="Enter Confirm Password"
                  required=""
                />
                <span
                  class="input-group-text"
                  style={{ background: "white", border: "1 px solid gray" }}
                >
                  {" "}
                  &nbsp;
                  <a onClick={() => myFunction1()}>
                    <i class="fas fa-eye"></i>
                  </a>
                </span>
              </div>
            </div>
            {formErrors.confirmpasswordError && (
              <div>
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.confirmpasswordError}
                </p>
              </div>
            )}

            <div className="text-center mt-3">
              <button
                type="button"
                style={{
                  width: "35%",
                  paddingTop: 5,
                  paddingBottom: 5,
                  color: theme,
                  background: "white",
                  fontSize: 20,
                  fontWeight: "bold",
                  borderRadius: "25px",
                  border: `1px solid ${theme}`,
                }}
                onClick={() => {
                  ForgotPasswordVarify();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
