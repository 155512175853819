import React from "react";
import styled from "styled-components";
import MainHeader from "../components/MainHeader";
import InfoIcon from "../assets/icons8-information-64 1.png";
import { Link } from "react-router-dom";
import QuestionTableSearch from "../components/QuestionTableSearch";
const theme = localStorage.getItem('brandColor');
const StyledLink = styled(Link)`
    color: white;
    font-weight: 500;
    text-decoration: none;
    border: none;
    box-shadow: none;
   
    &:hover{
        text-decoration: none;
        color: white;
        cursor: pointer;
    }
`;

const StyledDivButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;

`

const StyledDiv = styled.div`
  width: 90vw;
  justify-content: center;
  margin: 0 auto;
  margin-top: 25px;
`;

const StyledTable = styled.table`
  width: 100%;
  color: #757373;
`;

const StyledButton = styled.button`
  background: ${theme};
  border-radius: 20px;
  height: 25px;
  padding-bottom: 25px;
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 5px;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  color: #ffffff;
  
`;
const StyledTableRow = styled.tr`
  font-weight: bold;
`;

const Priority = () => {
  return (
    <div style={{
      position: "absolute",
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100vw',
    }}>
      <MainHeader />
      <QuestionTableSearch />
      <StyledDivButtons>
        <StyledLink to="/welcome">
          <StyledButton>Exit To Admin</StyledButton>
        </StyledLink>
        <StyledLink to="/welcome">
          <StyledButton>Exit To Home</StyledButton>
        </StyledLink>
        {/* <StyledLink> */}
          <StyledButton>Delete</StyledButton>
        {/* </StyledLink> */}
        {/* <StyledLink> */}
          <StyledButton>Warning</StyledButton>
        {/* </StyledLink> */}
        {/* <StyledLink> */}
          <StyledButton>Save</StyledButton>
        {/* </StyledLink> */}
      </StyledDivButtons>
      <StyledDiv>
        <StyledTable>
          <thead>
            <StyledTableHeader>
              <th>Topic ID.</th>
              <th>Customer ID</th>
              <th>CustDesc</th>
              <th>First Name</th>
              <th>Email</th>
              <th>Access Role</th>
              <th>Adm-Cust</th>
              <th></th>
            </StyledTableHeader>
          </thead>
          <tbody>
            <StyledTableRow>
              <td>123</td>
              <td>456</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>loremipsum@gmail.com</td>
              <td>loremmipsum</td>
              <td>loremmipsum</td>
              <td>
                <img src={InfoIcon} alt='InfoImage' />
              </td>
            </StyledTableRow>
            <StyledTableRow>
              <td>123</td>
              <td>456</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>loremipsum@gmail.com</td>
              <td>loremmipsum</td>
              <td>loremmipsum</td>
              <td>
                <img src={InfoIcon} alt='InfoImage' />
              </td>
            </StyledTableRow>
            <StyledTableRow>
              <td>123</td>
              <td>456</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>loremipsum@gmail.com</td>
              <td>loremmipsum</td>
              <td>loremmipsum</td>
              <td>
                <img src={InfoIcon} alt='InfoImage' />
              </td>
            </StyledTableRow>
            <StyledTableRow>
              <td>123</td>
              <td>456</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>loremipsum@gmail.com</td>
              <td>loremmipsum</td>
              <td>loremmipsum</td>
              <td>
                <img src={InfoIcon} alt='InfoImage' />
              </td>
            </StyledTableRow>
            <StyledTableRow>
              <td>123</td>
              <td>456</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>loremipsum@gmail.com</td>
              <td>loremmipsum</td>
              <td>loremmipsum</td>
              <td>
                <img src={InfoIcon} alt='InfoImage' />
              </td>
            </StyledTableRow>

            <StyledTableRow>
              <td>123</td>
              <td>456</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>loremipsum@gmail.com</td>
              <td>loremmipsum</td>
              <td>loremmipsum</td>
              <td>
                <img src={InfoIcon} alt='InfoImage' />
              </td>
            </StyledTableRow>
            <StyledTableRow>
              <td>123</td>
              <td>456</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>loremipsum@gmail.com</td>
              <td>loremmipsum</td>
              <td>loremmipsum</td>
              <td>
                <img src={InfoIcon} alt='InfoImage' />
              </td>
            </StyledTableRow>
            <StyledTableRow>
              <td>123</td>
              <td>456</td>
              <td>lorem ipsum</td>
              <td>lorem ipsum</td>
              <td>loremipsum@gmail.com</td>
              <td>loremmipsum</td>
              <td>loremmipsum</td>
              <td>
                <img src={InfoIcon} alt='InfoImage' />
              </td>
            </StyledTableRow>
          </tbody>
        </StyledTable>
      </StyledDiv>
    </div>
  );
};

export default Priority;
