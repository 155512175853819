import axios from "axios";
import { BASE_URL } from "../url/baseurl";

// ------------- Enroll Client APIs -----------------------

export const user_session_data_client = async (data) => {
  let response = axios.get(
    `${BASE_URL}/api/user_session_data_client?userid=${data}`
  );
  return response;
};
export const enroll_client = async (data) => {
  let response = axios.get(`${BASE_URL}/api/enroll_client?customerid=${data}`);
  return response;
};

export const edit_client = async () => {
  let response = axios.get(`${BASE_URL}/api/edit_client`);
  return response;
};

export const change_client = async (data) => {
  console.log(data);
  let response = axios.put(`${BASE_URL}/api/change_client`, data);
  return response;
};
export const client_enroll = async (data) => {
  let response = axios.put(`${BASE_URL}/api/client_enroll`, data);
  return response;
};

export const delete_client = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/delete_client`, data);
  return response;
};
export const new_client = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/new_client`, data);
  return response;
};

// ---------------------------- Manage Client APIs ------------------------

export const client_change = async (data) => {
  console.log(data);
  let response = axios.get(`${BASE_URL}/api/client_change?customerid=${data}`);
  return response;
};

export const manage_client = async (data) => {
  console.log(data);
  let response = axios.get(`${BASE_URL}/api/manage_client?customerid=${data}`);
  return response;
};

export const change_plan = async (data) => {
  console.log(data);
  let response = axios.put(`${BASE_URL}/api/change_plan`, data);
  return response;
};

// ---------------------------- Topic Assignment APIs ------------------------

export const assign_topic = async (data) => {
  console.log(data);
  let response = axios.get(`${BASE_URL}/api/assign_topic?customerid=${data}`);
  return response;
};

export const new_assign = async (data) => {
  console.log(data);
  let response = axios.get(`${BASE_URL}/api/new_assign?customerid=${data}`);
  return response;
};
 
export const add_assign = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/add_assign`, data);
  return response;
};

// -----------------------------Client Topic Categories-----------------------------

export const assigned_cat = async (data) => {
  console.log(data);
  let response = axios.get(`${BASE_URL}/api/assignedcat?customerid=${data}`, {customerid:data});
  return response;
};

export const edittopcat = async (data) => {
  console.log(data);
  let response = axios.get(`${BASE_URL}/api/edittopcat?customerid=${data}`);
  return response;
};

export const newcat = async (data) => {
  let response = axios.put(`${BASE_URL}/api/newcat`, data);
  return response;
};

export const editcat = async (data) => {
  console.log(data);
  let response = axios.get(`${BASE_URL}/api/editcat?customerid=${data}`);
  return response;
};

export const changecat = async (data) => {
  let response = axios.put(`${BASE_URL}/api/changecat`, data);
  return response;
};

export const addcat = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/addcat`, data);
  return response;
};