import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
import {
  sectionselect_topiclocs_api,
  selection_statustoploc_api,
  selection_newtoploc_api,
  selection_addtopicloc_api,
} from "../../../services/AdminProgramApi";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});





const Topic_Locations = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  console.log("propdata", match);

  const [edit_tpc_loc_modal, setEdit_tpc_loc_modal] = useState(false);
  const [add_tpc_loc_modal, setAdd_tpc_loc_modal] = useState(false);
  const [topic, setTopic] = useState("");
  const [location, setLocation] = useState("");
  const [active, setActive] = useState("");

  // --------------------- Sorting Hooks ----------------------
  const [sortBySno, setSortBySno] = useState(true);
  const [sortByTopic, setSortByTopic] = useState(false);
  const [sortByLocation, setSortByLocation] = useState(false);
  const [sortRev, setSortRev] = useState(false);

  // const [mngClient, setMngClient] = useState([])
  const [formErrors, setFormErrors] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  // ----------------------- Validation -------------------------
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!topicid) {
      formIsValid = false;
      formErrors["topicid_error"] = "Topic is required.";
    }
    if (!locationid) {
      formIsValid = false;
      formErrors["locationid_error"] = "Location is required.";
    }
    if (!active) {
      formIsValid = false;
      formErrors["active_error"] = "Active is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  // ------- Reset Fields Function --------
  const resetFields = () => {
    setActive("");
    setTopicid("");
    setLocationid("");
    setFormErrors([]);
  };

  const customerid = match.params.customerid;
  console.log(customerid);

  // --------------------sectionselect_topiclocs_api func call------
  const [sectionselect_topiclocs_apilist, setSectionselect_topiclocs_apilist] =
    useState([]);
  const [topiclist, setTopiclist] = useState([]);

  const sectionselect_topiclocs_api_func = async () => {
    sectionselect_topiclocs_api(customerid)
      .then((res) => {
        if (res.data.code == 200) {
          console.log("sectionselect_topiclocs_api res===>", res.data);
          // setSectionselect_topiclocs_apilist(res.data.Activetoploc)
          setTopiclist(res.data.Topiclist);
        }
        if (sortBySno) {
          setSectionselect_topiclocs_apilist(
            sortRev ? res.data.Activetoploc?.reverse() : res.data.Activetoploc
          );
        } else if (sortByTopic) {
          setSectionselect_topiclocs_apilist(
            sortRev
              ? res.data.Activetoploc?.sort((a, b) => {
                  const x = a.cuzdesc.toUpperCase();
                  const y = b.cuzdesc.toUpperCase();
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
              : res.data.Activetoploc?.sort((a, b) => {
                  const x = a.cuzdesc.toUpperCase();
                  const y = b.cuzdesc.toUpperCase();
                  return x === y ? 0 : x > y ? 1 : -1;
                })
          );
        } else if (sortByLocation) {
          setSectionselect_topiclocs_apilist(
            sortRev
              ? res.data.Activetoploc?.sort((a, b) => {
                  const x = a.locationdesc.toUpperCase();
                  const y = b.locationdesc.toUpperCase();
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
              : res.data.Activetoploc?.sort((a, b) => {
                  const x = a.locationdesc.toUpperCase();
                  const y = b.locationdesc.toUpperCase();
                  return x === y ? 0 : x > y ? 1 : -1;
                })
          );
        } else {
          setSectionselect_topiclocs_apilist(res.data.Activetoploc);
        }
      })
      .catch((err) => {
        console.log("sectionselect_topiclocs_api error==>", err);
      });
  };

  useEffect(() => {
    sectionselect_topiclocs_api_func();
  }, [customerid, sortBySno, sortRev, sortByTopic, sortByLocation]);

  // -------------------selection_statustoploc_api func call----------------------
  const [topicid, setTopicid] = useState();
  const [locationid, setLocationid] = useState();

  const selection_statustoploc_api_func = async () => {
    let data = {
      topicid: topicid,
      locationid: locationid,
      activetl: active,
    };
    selection_statustoploc_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          sectionselect_topiclocs_api_func();
          setEdit_tpc_loc_modal(false);
          resetFields();
          message.success("Updated Succesfull !");
        } else message.error(res.data.message);
      })
      .catch((err) => {
        console.log("selection_statustoploc_api error==>", err);
      });
  };

  // ---------------------selection_newtoploc_api func call------------------

  const [eligibletopics, setEligibletopics] = useState([]);
  const [Eligiblelocation, setEligiblelocation] = useState([]);

  const selection_newtoploc_api_func = async () => {
    let data = {
      customerid: customerid,
      Topiclist: topiclist,
      userdata: JSON.parse(localStorage.getItem("UTData"))
    };
    selection_newtoploc_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          console.log("selection_newtoploc_api res==>", res.data);
          setEligiblelocation(res.data.Eligiblelocation);
          setEligibletopics(res.data.Eligibletopics);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("selection_newtoploc_api error==>", err);
      });
  };

  // -------------------------selection_addtopicloc_api func call-----------------------

  const selection_addtopicloc_api_func = async () => {
    let data = {
      topicid: topicid,
      locationid: locationid,
      activetl: active,
      customerid: customerid,
    };
    selection_addtopicloc_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          setAdd_tpc_loc_modal(false);
          resetFields();
          sectionselect_topiclocs_api_func();
          message.success("Added Successfully");
        } else if (res.data.message == "toplocexist") {
          message.error("This topic/location combination already exists.");
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("selection_addtopicloc_api error==>", err);
      });
  };

  function sortTopics(a, b) {
    // a should come before b in the sorted order
    if (a.cuzdesc < b.cuzdesc) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.cuzdesc > b.cuzdesc) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }

  function sortLocations(a, b) {
    // a should come before b in the sorted order
    if (a.locationdesc < b.locationdesc) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.locationdesc > b.locationdesc) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }




  const EditButton = ({ data, setTopic, setLocation, setLocationid, setTopicid, setActive, setEdit_tpc_loc_modal }) => {
    const handleEditClick = () => {
      setTopic(data.cuzdesc);
      setLocation(data.locationdesc);
      setLocationid(data.locationid);
      setTopicid(data.topicid);
      setActive(data.activetl);
      setEdit_tpc_loc_modal(true);
    };
  
    return (
      <a onClick={handleEditClick}>
       <i class="fas fa-pencil-alt" aria-hidden="true"></i>
      </a>
    );
  };



  // let SNo = 1;
  // const rowsWithSerialNumber = sectionselect_topiclocs_apilist.map((sectionselect_topiclocs_apilist) => {
  //   return {
  //     ...sectionselect_topiclocs_apilist,
  //     SNo: SNo++,
  //   };
  // });


  let SNo = 1;
const rowsWithSerialNumber = sectionselect_topiclocs_apilist && Array.isArray(sectionselect_topiclocs_apilist)
  ? sectionselect_topiclocs_apilist.map((item) => {
      return {
        ...item,
        SNo: SNo++,
      };
    })
  : [];


  const rows1 = rowsWithSerialNumber;

  const columnsUsers: GridColumns = [

    {
      field: "SNo",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.4,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },
    
    {
      field: "cuzdesc",
      headerName: "Topic",
      // minwidth: 200,
      flex: 1,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 35
                ? `${params.value.substring(0, 35)}...`
                : params.value}
          </Tooltip>
        );
      },
      
    },
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
     // type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 35
                ? `${params.value.substring(0, 35)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    
    {
      field: "activetl",
      headerName: "Active",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },
   
    {
      field: "Action",
      headerName: "Action",
      flex: 0.5,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <EditButton
            data={params.row}
            setTopic={setTopic}
            setLocation={setLocation}
            setLocationid={setLocationid}
            setTopicid={setTopicid}
            //setUserRole={setUserRole}
            setActive={setActive}
            setEdit_tpc_loc_modal={setEdit_tpc_loc_modal}
          />
          
        </div>
      ),
    },
  ];





  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center" align="center">
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/adminfunctionmodal");
            }}
          >
            Return to Admin Menu
          </StyledButton1>
        </div>
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              selection_newtoploc_api_func();
              setAdd_tpc_loc_modal(true);
            }}
          >
            Add Topic Location
          </StyledButton1>
        </div>
      </div>

      <div>
        {/* --------------- Edit Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90" isOpen={edit_tpc_loc_modal}>
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Change Topic Location Active Flag</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Topic</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "125%",
                      marginLeft: "-20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Topic"
                    value={topic}
                    disabled={true}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Location</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "125%",
                      marginLeft: "-20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Location"
                    value={location}
                    disabled={true}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Active</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "125%",
                      marginLeft: "-20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                    }}
                  >
                    <option value="Y" selected={active == "Y" ? true : false}>
                      Yes
                    </option>
                    <option value="N" selected={active == "N" ? true : false}>
                      No
                    </option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {/* {formErrors.activeClient_error && (
                      <div className="offset-6 mt-2">
                        <p
                          style={{
                            color: "red",
                            fontSize: ".8rem",
                            marginTop: "0px",
                          }}
                        >
                          {formErrors.activeClient_error}
                        </p>
                      </div>
                    )} */}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                selection_statustoploc_api_func();
              }}
            >
              Save
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                resetFields();
                setEdit_tpc_loc_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      <div>
        {/* --------------- Add Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90" isOpen={add_tpc_loc_modal}>
          <div>
            {" "}
            <StyledForm>
              <div align="center" className="mb-3">
                <h4>Add Topic Location</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Topic</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "125%",
                      marginLeft: "-20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Topic"
                    value={topicid}
                    // disabled={true}
                    onChange={(e) => {
                      setTopicid(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Topic
                    </option>
                    {eligibletopics?.sort(sortTopics)?.map((data) => {
                      return (
                        <option value={data.topicid}>{data.cuzdesc}</option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.topicid_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.topicid_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Location</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "125%",
                      marginLeft: "-20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Location"
                    value={locationid}
                    // disabled={true}
                    onChange={(e) => {
                      setLocationid(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Location
                    </option>
                    {Eligiblelocation?.sort(sortLocations)?.map((data) => {
                      return (
                        <option value={data.locationid}>
                          {data.locationdesc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.locationid_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.locationid_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Active</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "125%",
                      marginLeft: "-20%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Active
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.active_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.active_error}
                  </p>
                </div>
              )}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "50%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation()) {
                  selection_addtopicloc_api_func();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save New Topic Location
            </StyledButton>
            <StyledButton
              style={{
                width: "40%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                resetFields();
                setAdd_tpc_loc_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>




      <div
          style={{ height: "80vh", width: "100%", marginTop: 15 }}
          className={classes.root}
        >
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff8c24",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            rows={rows1}
            getRowId={(r) => r.id}
            id="_id"
            columns={columnsUsers}
           
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[ 100]}
             pagination
            hideFooterPagination={ rows1.length <= pageSize}
            
            experimentalFeatures={{ newEditingApi: true }}
          />

        </div>

      {/* <div className="m-4 table-responsive tableFixHeadXXLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData>
                S.No{" "}
                <a
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByTopic(false);
                    setSortBySno(true);
                    setSortByLocation(false);
                  }}
                >
                  <i class="fas fa-sort" />
                </a>
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Topic{" "}
                <a
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByLocation(false);
                    setSortBySno(false);
                    setSortByTopic(true);
                  }}
                >
                  <i class="fas fa-sort" />
                </a>
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Location{" "}
                <a
                  onClick={() => {
                    setSortRev(!sortRev);
                    setSortByTopic(false);
                    setSortBySno(false);
                    setSortByLocation(true);
                  }}
                >
                  <i class="fas fa-sort" />
                </a>
              </StyledTableHeaderData>
              <StyledTableHeaderData>Active</StyledTableHeaderData>
              <StyledTableHeaderData>Action</StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {sectionselect_topiclocs_apilist?.map((data, index) => {
              return (
                <StyledTableRow>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td style={{ color: "black" }}>{data.cuzdesc}</td>
                  <td style={{ color: "black" }}>{data.locationdesc}</td>
                  <td style={{ color: "black" }}>{data.activetl}</td>
                  <td>
                    <a
                      onClick={() => {
                        setTopic(data.cuzdesc);
                        setLocation(data.locationdesc);
                        setLocationid(data.locationid);
                        setTopicid(data.topicid);
                        setActive(data.activetl);
                        setEdit_tpc_loc_modal(true);
                      }}
                    >
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                  </td>
                </StyledTableRow>
              );
            })}
          </tbody>
        </StyledTable>
      </div> */}
    </div>
  );
};

export default Topic_Locations;
