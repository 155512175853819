import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import {
  dashboard_all_topic_grp_topic_api,
  dashboard_topicprogram_api,
  free_test_availability_api,
  highlight_topicgrp_api,
} from "../../services/NewUI_API";
import ReactModal from "react-modal";
import styled from "styled-components";
import $ from "jquery";
import { getPDF } from "../../services/AdminProgramApi";
import { BASE_URL } from "../../url/baseurl";

const StyledButton1 = styled.button`
  background: #ff8c24;
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;
  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const StyledButtonCB = styled.button`
  background: #ff8c24;
  width: 300px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 20px;
  padding: 5px 10px;
`;

const BigModalStyle1 = {
  content: {
    width: 300,
    height: 300,
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    right: "auto",
    bottom: "auto",
    display: "flex",
    justifyItem: "center",
    alignItem: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
};

const ModalStyle = {
  content: {
    width: "500px",
    height: "310px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    justifyContent: "center",
  },
};

const Dashboard = () => {
  const history = useHistory();

  const [TopicsGroupData, setTopicsGroupData] = useState([]);
  const [TopicsData, setTopicsData] = useState([]);
  const [Data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [MD, setMD] = useState();
  const [tCode, setTCode] = useState();
  const [highlightTG, setHighlightTG] = useState([]);
  const [adm_function_modal, setAdm_function_modal] = useState(false);

  const [Takequiz, setTakequiz] = useState("");
  const [Continuequiz, setContinuequiz] = useState("");
  const [Deletequiz, setDeletequiz] = useState("");
  const [topicStatus, setTopicStatus] = useState("");
  const [cb_Modal, setCb_Modal] = useState(false);
  const [getTopicid, setGetTopicid] = useState([]);
  const [freeUserPDF, setFreeUserPDF] = useState("");

  useEffect(() => {
    getFreeUserPDF();
  }, []);

  const getFreeUserPDF = async () => {
    getPDF('Free_User').then((res) => {
      setFreeUserPDF(res.data.new_record[0].pdf_link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ReceiveDashboardTopicProgram = async () => {
    dashboard_topicprogram_api()
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          setTopicsGroupData(res.data.Topicdesc);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ReceiveDashboardTopicProgramById = async () => {
    dashboard_all_topic_grp_topic_api(Data)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          setTopicsData(res.data.Topicdesc);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const HighlightTopicGrp = async () => {
    highlight_topicgrp_api(localStorage.getItem("UserID"))
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          setHighlightTG(res.data.Topicgroupcode);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    ReceiveDashboardTopicProgram();
    HighlightTopicGrp();
  }, []);

  useEffect(() => {
    ReceiveDashboardTopicProgramById();
  }, [count]);

  // -------------------- Continue & Begin Test ---------------------
  const show_CB_Modal = (result) => {
    setCb_Modal(true);
    console.log(result);
    setGetTopicid(result.topiccode);
    localStorage.setItem("TopDesc", result.topicdesc);
    let data = {
      userid: parseInt(localStorage.getItem("UserID")),
      topiccodes: result.topiccode,
    };
    free_test_availability_api(data)
      .then((res) => {
        console.log(res);
        localStorage.setItem("FreeTestData", JSON.stringify(res.data));
        setTopicStatus(res.data.laststatus);
        setTakequiz(res.data.Takequiz);
        setDeletequiz(res.data.Deletequiz);
        setContinuequiz(res.data.Continuequiz);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  $(document).on("change", ".select-all", function () {
    if ($(this).is(":checked")) {
      $('[name="check[1]"]').each(function () {
        $(this).prop("checked", true);
      });
    } else {
      $('[name="check[1]"]').each(function () {
        $(this).prop("checked", false);
      });
    }
  });

  const ArrSet = new Set();
  const filteredArr = highlightTG.filter(el => {
    const duplicate = ArrSet.has(el.topgrpcode);
    ArrSet.add(el.topgrpcode);
    return !duplicate;
  });
  console.log(filteredArr);

  let MainTGC = filteredArr.map(a => a.topgrpcode);
  console.log(MainTGC);

  return (
    <>
      <div className="container">
        <div className="text-center mt-5">
          <span style={{ fontSize: 30, fontWeight: "bold", color: "#ff8c24" }}>
            DASHBOARD
          </span>
          <p style={{ fontWeight: "bold", fontSize: 16, letterSpacing: 0.5 }}>
            Please select one of the topics below to start.
          </p>
        </div>
        <div className="text-center">
          <a
            onClick={() => {
              window.open(
                `${BASE_URL}${JSON.parse(
                  freeUserPDF
                )}`,
                "_blank"
              );
            }}
          >
            <i
              style={{ color: "#ff8c24", fontSize: "25px" }}
              class="fas fa-info-circle"
            ></i>
          </a>
        </div>

        <div className="mt-3">
          <label style={{ fontWeight: "bold", fontSize: 16 }}>
            Filter by :
          </label>
          <div class="form-check mt-3 mb-3">
            <input
              class="form-check-input select-all"
              type="checkbox"
              id="flexCheckDefault"
              onClick={(e) => {
                {
                  TopicsGroupData &&
                    TopicsGroupData?.map((data, index) => {
                      if (e.target.checked == true) {
                        if (!Data.includes(parseInt(e.target.value))) {
                          Data.push(data.topgrpcode);
                          setData(Data);
                          console.log(Data);
                          setCount(count + 1);
                        }
                      } else {
                        Data.pop(data.topgrpcode);
                        setData(Data);
                        setCount(count - 1);
                        console.log(Data);
                      }
                    });
                }
              }}
            />
            <label
              style={{ color: "black", fontWeight: "bold", marginLeft: 5 }}
              class="form-check-label"
            >
              Select All
            </label>
          </div>

          <div class="form-check mt-3 mb-3">
            {TopicsGroupData?.map((item, index) => (
              <div style={{ display: "inline-flex", marginRight: 35 }}>
                <input
                  value={item?.topgrpcode}
                  name="check[1]"
                  class="form-check-input "
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={(e) => {
                    if (e.target.checked == true) {
                      if (!Data.includes(parseInt(e.target.value))) {
                        Data.push(parseInt(e.target.value));
                        setData(Data);
                        console.log(Data);
                        setCount(count + 1);
                      }
                    } else {
                      Data.splice(Data.indexOf(parseInt(e.target.value)), 1);
                      setData(Data);
                      setCount(count - 1);
                      console.log(Data);
                    }
                  }}
                />
                <label
                  style={{
                    color: item.topgrpcode == MainTGC[index] ? "crimson" : "black",
                    fontWeight: "bold",
                    marginLeft: 5
                  }}
                  class="form-check-label"
                >
                  {item.topgrpdesc}
                </label>
              </div>
            ))}
          </div>
          <div className="row">
            {count > 0
              ? TopicsData?.map((item) => (
                  <div
                    className="card Width100"
                    style={{
                      border: "1px solid #ff8c24",
                      width: "31%",
                      marginRight: "2%",
                      marginBottom: 20,
                    }}
                  >
                    <div
                      className="card-body text-center"
                      onClick={() => {
                        setMD(item);
                        setAdm_function_modal(true);
                        setTCode(item?.topiccode);
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        {item?.topicdesc}
                      </p>
                      <p
                        style={{
                          fontWeight: "lighter",
                          fontSize: 15,
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        {item?.topicsummary}
                      </p>
                    </div>
                    <div>
                      {
                        highlightTG?.map((data) => {
                          return (
                          data.topiccode === item?.topiccode ?
                          <>
                          <p
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                              color: "crimson",
                              fontSize: 20,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <i class="fas fa-check-circle"></i>
                            &nbsp;
                            Started/Completed
                          </p>
                          </>
                          :
                          ""
                          )
                        })
                      }
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>

        {/* ------------------------ Box onclick modal ------------------- */}
        <ReactModal style={BigModalStyle1} isOpen={adm_function_modal}>
          <div>
            <StyledButton1
              onClick={() => {
                show_CB_Modal(MD);
              }}
            >
              Take the Quiz
            </StyledButton1>
          </div>
          <div>
            <StyledButton1
              onClick={() => {
                history.push(`/suggestion_program/${tCode}`);
              }}
            >
              Suggestion Report
            </StyledButton1>
          </div>
          <div>
            <StyledButton1
              onClick={() => {
                setAdm_function_modal(false);
              }}
            >
              Close
            </StyledButton1>
          </div>
        </ReactModal>

        {/* ------------------ Continue & Begin Modal ----------------- */}
        <ReactModal
          isOpen={cb_Modal}
          style={ModalStyle}
          onRequestClose={() => {
            setCb_Modal(false);
          }}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            {topicStatus == "Completed" && (
              <h4>
                This topic quiz was already completed during the current period.
              </h4>
            )}
            {topicStatus == "Inprocess" && (
              <h4>This topic quiz is in process.</h4>
            )}
            {topicStatus == "Available" && <h4>Start the topic quiz</h4>}
          </div>
          <div className="text-center">
            {Deletequiz === "Y" && (
              <StyledButtonCB
                onClick={() => {
                  history.push("/freetest/" + getTopicid + "/delquiz");
                }}
              >
                Delete previous quiz and restart
              </StyledButtonCB>
            )}
            {Continuequiz === "Y" && (
              <StyledButtonCB
                onClick={() => {
                  history.push("/freetest/" + getTopicid + "/contquiz");
                }}
              >
                Continue previous quiz
              </StyledButtonCB>
            )}
            {Takequiz === "Y" && (
              <StyledButtonCB
                onClick={() => {
                  history.push("/freetest/" + getTopicid + "/delquiz");
                }}
              >
                Start new quiz
              </StyledButtonCB>
            )}
          </div>

          <div align="center">
            <StyledButtonCB
              style={{ background: "red" }}
              onClick={() => {
                setCb_Modal(false);
              }}
            >
              Cancel
            </StyledButtonCB>
          </div>
        </ReactModal>
      </div>
    </>
  );
};

export default Dashboard;
