import styled from "styled-components";
import InfoIcon from "../assets/icons8-information-64 1.png";
import React, { useState, useRef, useEffect } from "react";
import { QuestionTableInputAPI } from "../services/QuestionTableInputAPI";
import { useParams, useHistory, Link } from "react-router-dom";
import ReactModal from "react-modal";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Tooltip, Modal, message } from "antd";
import {
  ExclamationCircleOutlined,
  InfoCircleTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import swal from "sweetalert";
import axios from "axios";
import { QuestionDelete } from "../services/QuestionTableInputAPI";
import { BASE_URL } from "../url/baseurl";

import * as Database from "../services/Database";
import { rule } from "postcss";

const theme = localStorage.getItem('brandColor');

const { confirm } = Modal;

const StyledDiv = styled.div`
  padding-top: 20px;
  position: relative;
  left: 3.68%;
  width: 92.6%;
`;

const StyledTable = styled.table`
  width: 100%;
  color: #757373;
`;

const StyledTableHeader = styled.tr`
  background:  ${theme};
  color: #ffffff;
`;
const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
  border-bottom: 1px solid #9d9d9d;

`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
  padding-left: 5px;
`;

const StyledLink = styled(Link)`
  color: darkgrey;
  font-weight: 500;
  text-decoration: none;

  &:active {
    text-decoration: none;
    color: darkgrey;
  }
  &:hover {
    text-decoration: none;
    color: grey;
    cursor: pointer;
  }
`;

const StyledButton = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  padding: 0px 20px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;

const StyledDivButtons = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
`;

const ModalStyle = {
  content: {
    width: "70%",
    height: "70%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
  },
};

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
  // margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 100%;
`;

const StyledInput = styled.input`
  display: inline;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledTextarea = styled.textarea`
  display: inline;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputStyle = {
  border: "1px solid black",
  // backgroundColor: "#EEEEEE",
};

const StyledFormInputDiv = styled.div`
  display: flex;

  margin-top: 4px;

  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const FormDivStyleTwo = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const StyledInputDiv = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 85vw;
  min-width: 1000px;
  height: auto;
  justify-self: center;
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 50px;
  margin: 0 auto;
  margin-top: 25px;
`;

const StyledDiv1 = styled.div`
  margin: 13.5px;
`;
const StyledInput1 = styled.input`
  border: 1px solid #e4e4e4;
  border-top: none;
  border-left: none;
  border-right: none;

  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: #afaaaa;

  &::active {
    border: 1px solid #e4e4e4;
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;
const StyledButton1 = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
`;

const HorizontalDivider = {
  width: "36px",
  height: "0px",
  marginTop: "2%",
  border: "0.5px solid #C5C1C1",
  transform: "rotate(90deg)",
};

const QuestionTableInput = ({ datas3, warning }) => {
  console.log("props value====>", datas3);

  const [datas, setDatas] = useState([]);
  const [datas1, setDatas1] = useState([]);
  const params = useParams();

  useEffect(() => {
    setDatas(datas3);
    setDatas1(datas3);
  }, [datas3]);

  const [edits, set_edits] = useState(false);
  const [q_no, set_q_no] = useState(0);

  console.log("this is table datas===>", datas);
  const ModalStyle = {
    content: {
      width: "55%",
      height: "70%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // display: "flex",
      // justifyContent: "center",
    },
  };

  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const entertedqcoderef = useRef();
  const entertedquestionref = useRef();
  const entertedquestionseqref = useRef();
  const entertedifparentyesref = useRef(0);
  const entertedifparentnoref = useRef(0);
  const entertedpriorityidref = useRef();
  const entertedguidanceidref = useRef();
  const entertedsolutionifref = useRef();
  const entertedsolutiondescref = useRef();
  const entertedactiveqref = useRef();
  const entertedreportingref = useRef();
  const [priority, set_priotity] = useState([]);
  const [guidance, set_guidance] = useState([]);
  const [category, set_categorylist] = useState([]);
  const [editQuestionData, setEditQuestionData] = useState([]);

  // const QuestionTableInputAPIs = async () => {
  //   await QuestionTableInputAPI({ topicid: params.id })
  //     .then((res) => {
  //       // alert(JSON.stringify(res))
  //       console.log("table  response====>", res);
  //       setDatas(
  //         res.data.existing_questionario?.map((data) => {
  //           data.color = "white";
  //           data.message = "";
  //           return data;
  //         })
  //       );
  //       setDatas1(
  //         res.data.existing_questionario?.map((data) => {
  //           data.color = "white";
  //           data.message = "";
  //           return data;
  //         })
  //       );
  //       // console.log(res.data.existing_questionario);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    // QuestionTableInputAPIs();
    get_all();
  }, []);

  const showQuestionModal = async (result) => {
    setIsEditQuestion(true);
    setEditQuestionData(result);
  };

  const closeQuestionModal = () => {
    setIsEditQuestion(false);
  };

  const questionSubmitHandler = async (event) => {
    event.preventDefault();
    const questionObject = {
      userid: localStorage.getItem("UserID"),
      topicid: params.id,
      qcode: entertedqcoderef.current.value,
      qdescr: entertedquestionref.current.value,
      questionseq: entertedquestionseqref.current.value,
      ifparentyes: entertedifparentyesref.current.value,
      // === "Yes" ? 1 : entertedifparentyesref.current.value === "No" ? 0 : "",
      ifparentno: entertedifparentnoref.current.value,
      // === "Yes" ? 1 : entertedifparentnoref.current.value === "No" ? 0 : "",
      priorityid: entertedpriorityidref.current.value,
      guidanceid: entertedguidanceidref.current.value,
      solutionif: entertedsolutionifref.current.value,
      // === "Yes" ? "Y" : entertedsolutionifref.current.value === "No" ? "N" : "-",
      solutiondesc:
        entertedsolutiondescref.current.value == ""
          ? "-"
          : entertedsolutiondescref.current.value,
      repcatid: entertedreportingref.current.value,
      activeq: entertedactiveqref.current.value,
      // === "yes" ? 'Y' : 'N',
    };
    console.log(questionObject);
    sendQuestionDataToAPIHandler(questionObject);
  };

  const sendQuestionDataToAPIHandler = async (data) => {
    const response = await axios
      .post(`${BASE_URL}/api/updateCreateCuzque`, data)
      .then((res) => {
        console.log(res);
        if (res.data.message === "Parantinfirst") {
          message.info(
            "Remove Question Numbers from 'Next Step...Yes' and 'Next Step...No' on the first record. Only 0 allowed in each field.",
            20
          );
        }
        if (res.data.message === "nextyes") {
          message.info(
            "Change the 'Next step if this question # is answered Yes' to a Question Number with a lower Question Sequence.",
            20
          );
        }
        if (res.data.message === "nextno") {
          message.info(
            "Change the 'Next step if this question # is answered No' to a Question Number with a lower Question Sequence.",
            20
          );
        }
        if (res.data.message === "childsequence") {
          message.info(
            "Change the 'Next step if this question # is answered Yes or No' to a Question Number with a lower Question Sequence.",
            20
          );
        }
        if (res.data.message === "parentinactive") {
          message.info(
            "Remove the inactive code from 'Next step if this question # is answered Yes or No'.",
            20
          );
        }
        if (res.data.message === "deletesolution") {
          message.info(
            "Remove text from Solution because 'Solution given = '-' or (skip)'",
            15
          );
        }
        if (res.data.message === "addsolution") {
          message.info(
            "Add a Solution because 'Solution given = Yes or No'",
            15
          );
        }
        if (res.data.message === "toomanycodes") {
          message.info(
            "Remove one 'Next step if this question # is answered Yes or No'",
            15
          );
        }
        if (res.data.message === "missingcodes") {
          message.info(
            "Add a Question Number to either 'Next step if this question # is answered Yes or No'",
            15
          );
        }
        if (res.data.message === "notinactive") {
          message.info(
            "Remove this Question Number from either 'Next step if this question # is answered Yes or No' before inactivating it.",
            20
          );
        }
        if (res.data.message === "warninactive") {
          message.info(
            "This inactive Question Number is being used in a 'Next step' field",
            20
          );
        }
        if (
          res.data.message ===
          "ifparentyes or ifparentno should be less than the qcode"
        ) {
          message.info(
            "A Next Step question # must not reference a question # that comes after it",
            13
          );
        }
        if (res.data.message === "updated") {
          message.success("Question updated successfully", 10);
          window.location.reload();
        }
        if (res.data.message === "ifparentno is using invalid qcode") {
          message.info(
            "A Next Step question # must not reference a question # which is invalid",
            13
          );
        }
        if (res.data.message === "ifparentyes is using invalid qcode") {
          message.info(
            "A Next Step question # must not reference a question # which is invalid",
            13
          );
        }
        if (res.data.message === "inserted") {
          message.success("Question added successfully", 10);
          // QuestionTableInputAPIs();
          window.location.reload();
        }
        // else{
        //   message.success("Question Added Successfully")
        // }
      })
      .catch((err) => console.log(`err: ${err.response}`));
  };

  async function get_all() {
    Database.priority_list().then((res) => {
      set_priotity(res.data["Priority List"]);
      console.log(res.data["Priority List"]);
    });
    Database.ReportingCategoryList({ topicid: params.id }).then((res) => {
      set_categorylist(res.data["Reporting Category List"]);
      console.log(res.data["Reporting Category List"]);
    });
    Database.guidance_list({ topicid: params.id }).then((res) => {
      set_guidance(res.data["guidance_list"]);
      console.log(res.data["guidance_list"]);
    });
  }

  useEffect(() => {
    get_all();
  }, []);

  const deleteUser = async (topicid, qcode) => {
    confirm({
      title: "Are you sure you want to permanently remove this record?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        QuestionDelete({
          topicid: topicid,
          qcode: qcode,
        })
          .then((res) => {
            // UserListApi()
            console.log("delete response===>", res.data.message);

            if (res.data.message == "deleted") {
              message.success("Question deleted successfully");
              // QuestionTableInputAPIs();
              window.location.reload();
            }
            if (res.data.message == "nodeletecuz") {
              message.info(
                "Remove this Qcode from other Questions before deleting"
              );
            }
          })
          .catch((err) => {
            // message.error("Something went wrong please try again");
            // console.log("delete response===>", err);
            message.error("Something went wrong please try again");
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  //.............................filter......................................
  const [qNoSearch, setQNoSearch] = useState("");
  const [qSearch, setQSearch] = useState("");
  const [qSequenceSearch, setQSequenceSearch] = useState("");
  const [guidanceSearch, setQGuidanceSearch] = useState("");
  const [activateQsearch, setActivateQSearch] = useState("");

  const searchSubmit = () => {
    let filter_data = [];

    if (qNoSearch !== "") {
      filter_data = [
        ...filter_data,
        ...datas.filter((item) =>
          String(item.qcode).toLowerCase().includes(qNoSearch?.toLowerCase())
        ),
      ];

      console.log(
        datas.filter((item) => String(item.qcode) === String(qNoSearch))
      );
    }
    if (qSearch !== "") {
      filter_data = [
        ...filter_data,
        ...datas.filter((item) =>
          String(item.qdescr).toLowerCase().includes(qSearch?.toLowerCase())
        ),
      ];
    }

    if (qSequenceSearch !== "") {
      filter_data = [
        ...filter_data,
        ...datas.filter((item) =>
          String(item.questionseq)
            .toLowerCase()
            .includes(qSequenceSearch?.toLowerCase())
        ),
      ];
    }
    if (guidanceSearch !== "") {
      filter_data = [
        ...filter_data,
        ...datas.filter((item) =>
          String(item.cuzguidedesc)
            .toLowerCase()
            .includes(guidanceSearch?.toLowerCase())
        ),
      ];
    }
    if (activateQsearch !== "") {
      filter_data = [
        ...filter_data,
        ...datas.filter((item) =>
          String(item.activeq)
            .toLowerCase()
            .includes(activateQsearch?.toLowerCase())
        ),
      ];
    }

    console.log("Filter data", filter_data);

    setDatas(filter_data.length !== 0 ? filter_data : datas1);
  };

  return (
    <>
      <StyledInputDiv className="EXTQ FilterS">
        <StyledDiv1>
          <StyledInput1
            style={{ width: "" }}
            placeholder="Filter Q.No."
            type="text"
            onChange={(e) => {
              setQNoSearch(e.target.value);
            }}
          />
        </StyledDiv1>
        <div className="HorizontalDivider" style={HorizontalDivider} />
        <StyledDiv1>
          <StyledInput1
            placeholder="Filter Q."
            type="text"
            onChange={(e) => {
              setQSearch(e.target.value);
            }}
          />
        </StyledDiv1>
        <div className="HorizontalDivider" style={HorizontalDivider} />
        <StyledDiv1>
          <StyledInput1
            placeholder="Filter Q. Sequence"
            type="text"
            onChange={(e) => {
              setQSequenceSearch(e.target.value);
            }}
          />
        </StyledDiv1>
        <div className="HorizontalDivider" style={HorizontalDivider} />
        <StyledDiv1>
          <StyledInput1
            placeholder="Filter Guidance"
            type="text"
            onChange={(e) => {
              setQGuidanceSearch(e.target.value);
            }}
          />
        </StyledDiv1>
        <div className="HorizontalDivider" style={HorizontalDivider} />
        <StyledDiv1>
          <StyledInput1
            placeholder="Filter Activate Q."
            type="text"
            onChange={(e) => {
              setActivateQSearch(e.target.value);
            }}
          />
        </StyledDiv1>
        <div className="HorizontalDivider" style={HorizontalDivider} />
        <StyledButton1 style={{}} onClick={() => searchSubmit()}>
          Search
        </StyledButton1>
      </StyledInputDiv>
      <StyledDiv>
        <div className="table-responsive tableFixHeadXLarge">
          <StyledTable>
            <thead>
              <ScrollTableHeader>
                <StyledTableHeaderData>Question No.</StyledTableHeaderData>
                <StyledTableHeaderData>Question </StyledTableHeaderData>
                <StyledTableHeaderData>Question sequence</StyledTableHeaderData>
                <StyledTableHeaderData>
                  Next step if this question # is answered Yes
                </StyledTableHeaderData>
                <StyledTableHeaderData>
                  Next step if this question # is answered No
                </StyledTableHeaderData>
                <StyledTableHeaderData>Priority</StyledTableHeaderData>
                <StyledTableHeaderData style={{width:'16%'}} >Guidance</StyledTableHeaderData>
                <StyledTableHeaderData>
                  Solution given when answer equals this
                </StyledTableHeaderData>
                <StyledTableHeaderData style={{width:'14%'}} >
                  Solution
                </StyledTableHeaderData>
                <StyledTableHeaderData style={{width:'7%'}}>
                  Reporting Category
                </StyledTableHeaderData>
                <StyledTableHeaderData>Activate Question</StyledTableHeaderData>
                <StyledTableHeaderData></StyledTableHeaderData>
              </ScrollTableHeader>
            </thead>
            <tbody>
              {datas && datas.length == 0 ? (
                <div style={{ textAlign: "center" }}>
                  <br />
                  <h6>No data</h6>
                </div>
              ) : (
                datas?.map((result, index) => {
                  return (
                    <StyledTableRow
                      style={{
                        backgroundColor: result.color,
                        color:"black",
                        fontWeight: "600",
                      }}
                      title={result.message}
                    >
                      <td>{result.qcode}</td>
                      <td>{result.qdescr}</td>
                      <td>{result.questionseq}</td>
                      <td>{result.ifparentyes}</td>
                      <td>{result.ifparentno}</td>
                      <td>{result.prioritydesc}</td>
                      <td className="Guidance-display">
                        {result.cuzguidedesc && (
                          <Tooltip
                            placement="right"
                            html={true}
                            multiline={true}
                            title={
                              <div style={{ whiteSpace: "pre-line" }}>
                                {result.cuzguidedesc}
                              </div>
                            }
                          >
                            {result.cuzguidedesc.length >79 ? result.cuzguidedesc?.slice(0, 80) +'...' : result.cuzguidedesc}
                          </Tooltip>
                        )}
                      </td>
                      <td>{result.solutionif}</td>
                      <td className="Guidance-display">
                        {result.solutiondesc && (
                          result.solutiondesc == '-' ? result.solutiondesc :
                          <Tooltip
                            placement="right"
                            html={true}
                            multiline={true}
                            title={
                              <div style={{ whiteSpace: "pre-line" }}>
                                {result.solutiondesc}
                              </div>
                            }
                          >
                            {result.solutiondesc.length >69 ? result.solutiondesc?.slice(0, 70) +'...' : result.solutiondesc}
                            {/* {result.solutiondesc?.slice(0, 80)}... */}
                          </Tooltip>
                        )}
                      </td>
                      {/* <td>{result.solutiondesc}</td> */}
                      <td>{result.repcatdesc}</td>
                      <td>{result.activeq}</td>
                      <td width="70px">
                        <EditOutlined
                          style={{ paddingRight: 5, color: "#3E79F7" }}
                          onClick={() => {
                            showQuestionModal([result]);
                          }}
                        />
                        <DeleteOutlined
                          onClick={() => {
                            deleteUser(result.topicid, result.qcode);
                          }}
                          style={{ color: "red" }}
                        />
                      </td>
                    </StyledTableRow>
                  );
                })
              )}
            </tbody>
          </StyledTable>
        </div>

        <ReactModal
          isOpen={isEditQuestion}
          className="ModalStyle4 Width90"
          onRequestClose={closeQuestionModal}
        >
          <StyledForm onSubmit={questionSubmitHandler}>
            <h4 style={{ textAlign: "center" }}>Edit Question</h4>
            {editQuestionData &&
              editQuestionData?.map((result) => {
                return (
                  <>
                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="qcode">
                          {" "}
                          Question Code{" "}
                        </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        <StyledInput
                          ref={entertedqcoderef}
                          style={InputStyle}
                          type="number"
                          id="qcode"
                          defaultValue={result.qcode}
                          // onChange={(e)=>{alert(e.target.value)}}
                          disabled

                          // value={new_qcode}
                        />
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="Question"> Question </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        <StyledTextarea
                          ref={entertedquestionref}
                          style={InputStyle}
                          defaultValue={result.qdescr}
                          type="text"
                          id="question"
                          rows={3}
                        />
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="topicid">
                          {" "}
                          Question Sequence
                        </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        <StyledInput
                          ref={entertedquestionseqref}
                          style={InputStyle}
                          type="number"
                          id="topicid"
                          // value={qseq}
                          defaultValue={result.questionseq}
                          // disabled
                        />
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="ifyes">
                          {" "}
                          Next step if this question # is answered Yes{" "}
                        </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        <StyledInput
                          ref={entertedifparentyesref}
                          style={InputStyle}
                          type="number"
                          defaultValue={result.ifparentyes}
                          // placeholder="Yes or No"
                          id="qdesc"
                        />
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="ifyes">
                          {" "}
                          Next step if this question # is answered No{" "}
                        </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        <StyledInput
                          ref={entertedifparentnoref}
                          style={InputStyle}
                          defaultValue={result.ifparentno}
                          type="number"
                          id="qdesc"
                          // placeholder="Yes or No"
                        />
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="ifyes"> Priority </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        {/* <StyledInput
              ref={entertedpriorityidref}
              style={InputStyle}
              type="text"
              id="qdesc"
            /> */}
                        <select
                          style={{ width: "100%" }}
                          ref={entertedpriorityidref}
                          defaultValue={result.priorityid}
                        >
                          {priority &&
                            priority?.map((data) => {
                              return (
                                <option value={data.priorityid}>
                                  {data.prioritydesc}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="ifyes"> Guidance </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        {/* <StyledInput
              
              style={InputStyle}
              type="text"
              id="qdesc"
            /> */}
                        <select
                          style={{ width: "100%" }}
                          ref={entertedguidanceidref}
                          defaultValue={result.guidanceid}
                        >
                          {guidance?.map((data, i) => {
                            return (
                              <option
                                className="select-option"
                                value={data.guidanceid}
                                title={data.cuzguidedesc}
                              >
                                {data.guidanceid}.{" "}
                                {data.cuzguidedesc.length > 71
                                  ? data.cuzguidedesc.substring(0, 70) + "..."
                                  : data.cuzguidedesc}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="ifyes">
                          {" "}
                          Solution given when answer equals this{" "}
                        </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        <select
                          style={{ width: "100%" }}
                          ref={entertedsolutionifref}
                          defaultValue={result.solutionif}
                        >
                          <option value="N">No</option>
                          <option value="Y">Yes</option>
                          <option value="-">skip</option>
                        </select>
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="ifyes"> Solution </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        <StyledTextarea
                          ref={entertedsolutiondescref}
                          defaultValue={result.solutiondesc}
                          style={InputStyle}
                          type="text"
                          id="qdesc"
                          rows={3}
                        />
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="ifyes">
                          {" "}
                          Reporting Category{" "}
                        </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        {/* <StyledInput
              ref={entertedreportingref}
              style={InputStyle}
              type="text"
              id="qdesc"
              /> */}
                        <select
                          style={{ width: "100%" }}
                          ref={entertedreportingref}
                          defaultValue={result.repcatid}
                        >
                          {category?.map((data, i) => {
                            return (
                              <option value={data.repcatid}>
                                {data.repcatdesc}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </StyledFormInputDiv>

                    <StyledFormInputDiv>
                      <div style={FormDivStyle}>
                        <StyledLabel htmlFor="ifyes">
                          {" "}
                          Activate Question{" "}
                        </StyledLabel>
                      </div>
                      <div style={FormDivStyleTwo}>
                        <select
                          style={{ width: "100%" }}
                          ref={entertedactiveqref}
                          defaultValue={result.activeq}
                        >
                          <option value="Y">Yes</option>
                          <option value="N">No</option>
                          {/* <option>skip</option> */}
                        </select>
                      </div>
                    </StyledFormInputDiv>

                    <div className="mt-2" align="center">
                      <StyledButton
                        style={{
                          width: "30%",
                          backgroundColor: theme,
                        }}
                      >
                        Submit
                      </StyledButton>
                      <StyledButton
                        style={{
                          width: "30%",
                          backgroundColor: "red",
                        }}
                        onClick={() => {
                          closeQuestionModal();
                        }}
                      >
                        Cancel
                      </StyledButton>
                    </div>
                  </>
                );
              })}
          </StyledForm>
        </ReactModal>
      </StyledDiv>
    </>
  );
};

export default QuestionTableInput;
