import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
import {
  admin_user_data,
  user_session_data_admin_api,
  feature_program_api,
  add_new_admin_user,
  delete_admin_user,
  update_change_admin_user,
} from "../../../services/AdminProgramApi";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;

  margin-top: 4px;

  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});


const Admin_User = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  // console.log("propdata", match);

  const [edit_admin_user_modal, setEdit_admin_user_modal] = useState(false);
  const [add_admin_user_modal, setAdd_admin_user_modal] = useState(false);
  const [delete_admin_user_modal, setDelete_admin_user_modal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userType, setUserType] = useState("");
  const [userTypeId, setUserTypeId] = useState("");
  const [allLocations, setAllLocations] = useState("");
  const [userRole, setUserRole] = useState("");
  const [active, setActive] = useState("");
  const [limit, setLimit] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  const [adminRole, setAdminRole] = useState("");
  const [adminLoc, setAdminLoc] = useState("");

  const [userAdminData, setUserAdminData] = useState([]);
  const [userAdminList, setUserAdminList] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  // const [userid, setUserid] = useState(localStorage.getItem("UserID"));
  const customerid = match.params.customerid;

  // ----------------------- Validation -------------------------
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!userEmail) {
      formIsValid = false;
      formErrors["userEmail_error"] = "User Email is required.";
    }
    if (!userTypeId) {
      formIsValid = false;
      formErrors["userTypeId_error"] = "User Type is required.";
    }
    if (!allLocations) {
      formIsValid = false;
      formErrors["allLocations_error"] = "All Locations is required.";
    }
    // if (!userRole) {
    //   formIsValid = false;
    //   formErrors["userRole_error"] = "User Role is required.";
    // }
    if (!active) {
      formIsValid = false;
      formErrors["active_error"] = "Active is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  // ------- Reset Fields Function --------
  const resetFields = () => {
    setUserEmail("");
    setUserTypeId("");
    setAllLocations("");
    setUserRole("");
    setUserType("");
    setActive("");
    setFormErrors([]);
  };

  // user_session_data_admin api func call
  const [feature_prog_admin_func_data, setFeature_prog_admin_func_data] =
    useState([]);
  const [admn_custId, setAdmn_custId] = useState();

  const user_session_data_admin_func1 = async () => {
    user_session_data_admin_api(localStorage.getItem("UserID"))
      .then((res) => {
        if (res.data.code == 200) {
          console.log("user data admin res===>", res.data);
          setAdmn_custId(res.data.userdata[0].customerid);

          let data = {
            customerid: res.data.userdata[0].customerid,
            userdata: res.data.userdata,
          };

          feature_program_api(data)
            .then((res2) => {
              if (res2.data.code == 200) {
                console.log("feature res2 data==>", res2.data);
                setFeature_prog_admin_func_data(res2.data.admfunction);
                // setAdmn_custId(res2.data.userdata[0].customerid)

                var Admtypelist = [];
                var accessrole = "";

                Admtypelist = res2.data.Admtypelist;
                accessrole = res2.data.accessrole;
                console.log("=======>", Admtypelist);
                console.log("=======>", accessrole);

                var data1 = {
                  userdata: res.data.userdata,
                  Admtypelist: Admtypelist,
                  accessrole: accessrole,
                  customerid: customerid,
                };

                admin_user_data(data1)
                  .then((res3) => {
                    console.log(res3);
                    if (res3.data.code === 200) {
                      setUserAdminData(res3.data.Eligibleusers);
                      setUserAdminList(res3.data.Admtypelist);
                      setAdminRole(res3.data.Accessrole);
                      setAdminLoc(res3.data.Accessloc);
                      setLimit(res3.data.Limit);
                    } else {
                      message.error(res3);
                    }
                  })
                  .catch((err) => {
                    console.log("error aud--->>", err);
                  });
              } else {
                message.error(res2);
              }
            })
            .catch((err) => {
              console.log("error frd--->>", err);
            });
        } else {
          message.error(res);
        }
      })
      .catch((err) => {
        console.log("error usd===>", err);
      });
  };

  useEffect(() => {
    user_session_data_admin_func1();
    console.log("message", userAdminData);
  }, []);

  useEffect(() => {
    for (let i = 0; i < userAdminList.length; i++) {
      if (userAdminList[i].admintypeid == userTypeId) {
        setUserType(userAdminList[i].admindesc);
        console.log(userType);
        console.log(userTypeId);
      }
    }
  }, [userTypeId]);



  const EditAdminUserButton = ({
    data,
    setUserEmail,
    setUserType,
    setUserTypeId,
    setAllLocations,
    setUserRole,
    setActive,
    setEdit_admin_user_modal,
  }) => {
    const handleEditClick = () => {
      setUserEmail(data.useremail);
      setUserType(data.admindesc);
      setUserTypeId(data.admintypeid);
      setAllLocations(data.anylocation);
      setUserRole(data.userrole);
      setActive(data.activeu);
  
      setEdit_admin_user_modal(true);
    };
  
    return (
      <a onClick={handleEditClick}>
        <i className="fas fa-pencil-alt" aria-hidden="true"></i>
      </a>
    );
  };
  
  const DeleteAdminUserButton = ({ data, setUserEmail, setDelete_admin_user_modal }) => {
    const handleDeleteClick = () => {
      setUserEmail(data.useremail);
      setDelete_admin_user_modal(true);
    };
  
    return (
      <a onClick={handleDeleteClick}>
        <i className="fas fa-trash" style={{ color: "red" }} aria-hidden="true"></i>
      </a>
    );
  };

  let SNo = 1;
  const rowsWithSerialNumber = userAdminData.map((userAdminData) => {
    return {
      ...userAdminData,
      SNo: SNo++,
    };
  });


  const rows1  = rowsWithSerialNumber;
  const columnsUsers: GridColumns = [

    {
      field: "SNo",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.2,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },
    
    {
      field: "useremail",
      headerName: "User Email",
      // minwidth: 200,
      flex: 1,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
      
    },
    {
      field: "admindesc",
      headerName: "User Type",
      // width: 200,
     // type: "number",
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 22
                ? `${params.value.substring(0, 22)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "anylocation",
      headerName: "All Locations",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "userrole",
      headerName: "User Role",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },
    
    },
    {
      field: "activeu",
      headerName: "Active",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },
    // {
    //   field: "activel",
    //   headerName: "Active",
    //   flex: 1,
    //   align: "center",
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    // },
    {
      field: "Action",
      headerName: "Action",
      flex: 0.5,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <EditAdminUserButton
            data={params.row}
            setUserEmail={setUserEmail}
            setUserType={setUserType}
            setUserTypeId={setUserTypeId}
            setAllLocations={setAllLocations}
            setUserRole={setUserRole}
            setActive={setActive}
            setEdit_admin_user_modal={setEdit_admin_user_modal}
          />
          &nbsp; &nbsp;
          <DeleteAdminUserButton
            data={params.row}
            setUserEmail={setUserEmail}
            setDelete_admin_user_modal={setDelete_admin_user_modal}
          />
        </div>
      ),
    },
  ];






  //---------------- Add Admin/User API Function -------------------------

  const addAdmin_User = () => {
    add_new_admin_user({
      selection: "addadmin",
      customerid: parseInt(customerid),
      useremail: userEmail,
      admintypeid: parseInt(userTypeId),
      admindesc: userType,
      anylocation: allLocations,
      userrole: userRole,
      activeu: active,
      Limit: limit,
      userdata: JSON.parse(localStorage.getItem("UTData")),
    })
      .then((res) => {
        if (res.data.code === 200) {
          setAdd_admin_user_modal(false);
          resetFields();
          message.success("User/Admin added successfully");
          console.log("res added Admin/User =>", res.data);
          user_session_data_admin_func1();
        } else if (res.data.message === "userexists") {
          message.error("This admin/user already exists.");
        } else if (res.data.message === "anothercust") {
          message.error(
            "This admin/user is already active with another customer.  A user cannot use the same email for two different customers."
          );
        } else if (res.data.message === "maxuserlimit") {
          message.error(
            `You will exceed your user limit of ${limit?.map(
              (data) => data.userlimit
            )}. Consider Deactiving another user that is no longer participating or upgrade to a higher payment plan.`
          );
        } else if (res.data.message === "maxadmlimit") {
          message.error(
            `You will exceed your administrator limit of ${limit?.map(
              (data) => data.admlimit
            )}. Consider Deactiving another admin that is no longer participating or upgrade to a higher payment plan.`
          );
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //---------------- Update Admin/User API Function -------------------------

  const updateAdmin_User = () => {
    update_change_admin_user({
      selection: "changeadmin",
      customerid: parseInt(customerid),
      useremail: userEmail,
      admintypeid: parseInt(userTypeId),
      admindesc: userType,
      anylocation: allLocations,
      userrole: userRole,
      activeu: active,
      Limit: limit,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setEdit_admin_user_modal(false);
          resetFields();
          message.success("Admin/User updated successfully");
          console.log("res updated Admin/User =>", res.data);
          user_session_data_admin_func1();
        } else if (res.data.message === "activeloc") {
          message.error(
            "First delete the admin/user access to specific locations before giving them 'All Location' privileges."
          );
        } else if (res.data.message === "activeuser") {
          message.error(
            "The user your are trying to activate is an active member with another customer. A user cannot use the same email for two different customers."
          );
        }else if (res.data.message === "Subscription Owner") {
          message.error(
            "You cannot deactivate a Subscription Owner. First remove this user from the Subscription Owner Email."
          );
        }
         else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //---------------- Delete Admin/User API Function -------------------------

  const deleteAdmin_User_Location = () => {
    delete_admin_user({
      selection: "deleteadmin",
      customerid: parseInt(customerid),
      useremail: userEmail,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setDelete_admin_user_modal(false);
          message.success("Admin/User deleted successfully");
          user_session_data_admin_func1();
          console.log("res Delete Admin/User =>", res.data);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center" align="center">
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/adminfunctionmodal");
            }}
          >
            Return to Admin Menu
          </StyledButton1>
        </div>
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              setAdd_admin_user_modal(true);
            }}
          >
            Add Admin/User
          </StyledButton1>
        </div>
      </div>

      <div>
        {/* --------------- Edit Modal ------------ */}
        <ReactModal
          className="ModalStyle1 Width90"
          isOpen={edit_admin_user_modal}
        >
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Edit Admin/User</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">User Email</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter User Email"
                    value={userEmail}
                    disabled={true}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">User Type</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={userTypeId}
                    onChange={(e) => {
                      setUserTypeId(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select User Type
                    </option>
                    {userAdminList &&
                      userAdminList?.map((data, index) => {
                        return (
                          <option
                            value={data.admintypeid}
                            label={data.admindesc}
                          >
                            {data.admindesc}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">All Locations</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={allLocations}
                    onChange={(e) => {
                      setAllLocations(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option
                      hidden={
                        adminRole == "Locationadm" || adminLoc == "Loclimit"
                          ? true
                          : false
                      }
                      value="Y"
                    >
                      Yes
                    </option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">User Role</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter User Role"
                    value={userRole}
                    onChange={(e) => {
                      setUserRole(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Active</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation()) {
                  updateAdmin_User();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                resetFields();
                setEdit_admin_user_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      <div>
        {/* --------------- Add Modal ------------ */}
        <ReactModal
          className="ModalStyle1 Width90"
          isOpen={add_admin_user_modal}
        >
          <div>
            {" "}
            <StyledForm>
              <div align="center" className="mb-3">
                <h4>Add Admin/User</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">User Email</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter User Email"
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.userEmail_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.userEmail_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">User Type</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={userTypeId}
                    onChange={(e) => {
                      setUserTypeId(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select User Type
                    </option>
                    {userAdminList &&
                      userAdminList?.map((data, index) => {
                        return (
                          <option
                            value={data.admintypeid}
                            label={data.admindesc}
                          >
                            {data.admindesc}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.userTypeId_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.userTypeId_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">All Locations</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={allLocations}
                    onChange={(e) => {
                      setAllLocations(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option
                      hidden={adminRole == "Locationadm" ? true : false}
                      value="Y"
                    >
                      Yes
                    </option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.allLocations_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.allLocations_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">User Role</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter User Role"
                    value={userRole}
                    onChange={(e) => {
                      setUserRole(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Active</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.active_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.active_error}
                  </p>
                </div>
              )}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "40%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation()) {
                  addAdmin_User();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save New Admin/User
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                resetFields();
                setAdd_admin_user_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      <div>
        {/* --------------- Delete Details Modal ------------ */}
        <ReactModal
          className="ModalStyle1 Width90"
          isOpen={delete_admin_user_modal}
        >
          <div>
            <div align="center" className="mb-3">
              <h4>
                Are you sure you want to remove this admin/user's privileges?
              </h4>
              <StyledButton
                style={{ width: "30%", height: 30, marginTop: 50 }}
                onClick={() => deleteAdmin_User_Location()}
              >
                Yes
              </StyledButton>
              <StyledButton
                style={{
                  width: "30%",
                  height: 30,
                  backgroundColor: "red",
                  marginLeft: 10,
                }}
                onClick={() => {
                  setDelete_admin_user_modal(false);
                }}
              >
                Cancel
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      </div>

      <div className="m-4 d-flex justify-content-between">
        <div>
          User Limit : &nbsp;
          {limit?.map((data) => {
            return (
              <input
                type="text"
                disabled
                style={{
                  width: "50px",
                  textAlign: "center",
                  border: "1px solid black",
                }}
                value={data.userlimit}
              />
            );
          })}
        </div>
        <div>
          Admin Limit : &nbsp;
          {limit?.map((data) => {
            return (
              <input
                type="text"
                disabled
                style={{
                  width: "50px",
                  textAlign: "center",
                  border: "1px solid black",
                }}
                value={data.admlimit}
              />
            );
          })}
        </div>
      </div>

      <div
                  style={{ height: '72vh', width: "100%", marginTop: 15 }}
                  className={classes.root}
                >
      <DataGrid
                    sx={{
                      ".MuiDataGrid-columnHeaders": {
                        backgroundColor: "#ff8c24",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                    }}
                    rows={rows1}
                    getRowId={(r) => r.id}
                    id="_id"
                    columns={columnsUsers}
                    // initialState={{

                    //   pagination: {
                    //     pageSize: 10,
                    //   },
                    // }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[ 100]}
                    pagination
                    hideFooterPagination={ rows1.length <= pageSize}
                  //  processRowUpdate={processRowUpdate}
                    experimentalFeatures={{ newEditingApi: true }}
                  />

</div>


      {/* <div className="m-4 table-responsive tableFixHeadXLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData style={{ paddingRight: 30 }}>
                S.No
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                User Email
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                User Type
              </StyledTableHeaderData>
              <StyledTableHeaderData>All Locations</StyledTableHeaderData>
              <StyledTableHeaderData>User Role</StyledTableHeaderData>
              <StyledTableHeaderData>Active</StyledTableHeaderData>
              <StyledTableHeaderData>Action</StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {userAdminData &&
              userAdminData?.map((data, index) => (
                <StyledTableRow>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td style={{ color: "black" }}>{data.useremail}</td>
                  <td style={{ color: "black" }}>{data.admindesc}</td>
                  <td style={{ color: "black" }}>{data.anylocation}</td>
                  <td style={{ color: "black" }}>{data.userrole}</td>
                  <td style={{ color: "black" }}>{data.activeu}</td>
                  <td>
                    <a
                      onClick={() => {
                        setUserEmail(data.useremail);
                        setUserType(data.admindesc);
                        setUserTypeId(data.admintypeid);
                        setAllLocations(data.anylocation);
                        setUserRole(data.userrole);
                        setActive(data.activeu);

                        setEdit_admin_user_modal(true);
                      }}
                    >
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                    &nbsp; &nbsp;
                    <a
                      onClick={() => {
                        setUserEmail(data.useremail);

                        setDelete_admin_user_modal(true);
                      }}
                    >
                      <i
                        class="fas fa-trash"
                        style={{ color: "red" }}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </td>
                </StyledTableRow>
              ))}
          </tbody>
        </StyledTable>
      </div> */}
    </div>
  );
};

export default Admin_User;
