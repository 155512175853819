import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Form from 'react-bootstrap/Form';
import { message } from "antd";
import {
  receive_custdata,
  update_custdata,
  receive_homecountry_sectors, upload_brandIcon
} from "../../../services/AdminProgramApi";
import { BASE_URL } from "../../../url/baseurl";
const theme = localStorage.getItem('brandColor');


const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;



const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledButtonMS = styled.button`
  background: ${theme};
  width: 170px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  font-size: 15px !important;
  color: white;
  font-weight: 400;
  border-color: none;
  border: none;
`;

const StyledButton1 = styled.button`
background: ${theme};
width: 250px;
border-radius: 30px;
height: 45px;
text-align: center;
color: white;
font-size: 16px !important;
font-weight: 500;
border-color: none;
border: none;
margin-top: 25px;
padding: 5px 10px;
`;

const BigModalStyle1 = {
  content: {
    width: "600px",
    height: "550px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    justifyItem: "center",

    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    // boxShadow:"0 .5rem 1rem rgba(0,0,0,.15)"
  },
};

const Customer_Data = ({ match }) => {
  const history = useHistory();
  console.log("propdata", match);
  

  const [edit_cust_data_modal, setEdit_cust_data_modal] = useState(false);
  const [manage_sub_modal, setManage_sub_modal] = useState(false);
  const [custName, setCustName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [sectorId, setSectorId] = useState("");
  const [accountOwnerEmail, setAccountOwnerEmail] = useState("");
  const [subscriptionOwnerEmail, setSubscriptionOwnerEmail] = useState("");
  const [emailReminder, setEmailReminder] = useState("");
  const [emailWelcome, setEmailWelcome] = useState("");
  const [emailTopic, setEmailTopic] = useState("");
  const [customer, setCustomer] = useState([]);
  const [sectorsList, setSectorsList] = useState([]);
  const [homeCountryList, setHomeCountryList] = useState([]);
  const [userid, setUserid] = useState(localStorage.getItem("UserID"));

  const [brandColor, setBrandColor] = useState("");
  const [brandHeader, setBrandHeader] = useState("");
  const [brandIcon, setBrandIcon] = useState("");

  

  const [formErrors, setFormErrors] = useState([]);
  const [defaultBrand, setDefaultBrand] = useState([])
  const [disabled, setDisabled] = useState(false);
  const [switchState, setSwitchState] = useState(false);

  
  const [stripePortal, setStripePortal] = useState([]);
  const [planType, setPlanType] = useState([]);
   

  const handleClick = () => {
    setDisabled(disabled)
  };

  // ----------------------- Validation -------------------------
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!custName) {
      formIsValid = false;
      formErrors["custName_error"] = "Customer Name is required.";
    }
    if (!countryCode) {
      formIsValid = false;
      formErrors["homeCountry_error"] = "Home Country is required.";
    }
    if (!sectorId) {
      formIsValid = false;
      formErrors["sector_error"] = "Sector is required.";
    }
    if (!accountOwnerEmail) {
      formIsValid = false;
      formErrors["accountOwnerEmail_error"] = "Owner Email is required.";
    }
    if (!subscriptionOwnerEmail) {
      formIsValid = false;
      formErrors["subscriptionOwnerEmail_error"] = "Subscription Owner Email is required.";
    }
    if (!emailReminder) {
      formIsValid = false;
      formErrors["emailReminder_error"] = "Email Reminder is required.";
    }
    if (!emailWelcome) {
      formIsValid = false;
      formErrors["emailWelcome_error"] = "Email Welcome is required.";
    }
    if (!emailTopic) {
      formIsValid = false;
      formErrors["emailTopic_error"] = "Email Welcome is required.";
    }
    if (!brandColor) {
      formIsValid = false;
      formErrors["brandColor_error"] = "Brand color is required.";
    }
    if (!brandHeader) {
      formIsValid = false;
      formErrors["brandHeader_error"] = "Brand Header  is required.";
    }
    if (!brandIcon) {
      formIsValid = false;
      formErrors["brandIcon_error"] = "Brand icon is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const customerid = match.params.customerid;

  
  const ReceiveCustData = async () => {
    receive_custdata({
      customerid: customerid,
      userid: userid,
    })
      .then((res) => {
        console.log(res);

        if (res.data.code === 200) {
          setCustomer(res.data.customer);
          setDefaultBrand(res.data.defaultbrand);
          let customer = res.data.customer;
          let brand = res.data.defaultbrand;
          if(customer[0].brandcolor == brand.colordefault[0].elmvalue && customer[0].brandheader == brand.headerdefault[0].elmvalue && customer[0].brandicon == brand.icondefault[0].elmvalue){
            setSwitchState(true);
            setDisabled(true);
          }else{
            setSwitchState(false);
            setDisabled(false);
          }
          {/*if(customer[0].plantype == 'Contract'){
            setPlanType(true);
          }else{
            setPlanType(false);
          }*/}
          
          if(customer[0].pymtsched == 'Contract'){
            setPlanType(true);
          }else{
            setPlanType(false);
          }
         
        } else {
          message.error(res.data.message);
        }
       
        console.log("custData", res.data.customer);
        console.log("default", res.data.defaultbrand);
      })
      .catch((e) => {
        console.log(e);
      });
  };
 
  const ReceiveLocList = async () => {
    receive_homecountry_sectors("homecountry")
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          setHomeCountryList(res.data.country_list);
        } else {
          message.error(res.data.message);
        }
        console.log("LocList", res.data.country_list);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ReceiveSectorList = async () => {
    receive_homecountry_sectors("sectors")
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          setSectorsList(res.data.sector_list);
        } else {
          message.error(res.data.message);
        }
        console.log("SectorList", res.data.sector_list);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const EditCustData = () => {
    update_custdata({
      custdesc: custName,
      countrycode: countryCode,
      sectorid: parseInt(sectorId),
      emailsugg: emailReminder,
      emailuser: emailWelcome,
      emailtopic: emailTopic,
      email: accountOwnerEmail,
      subemail: subscriptionOwnerEmail,
      customerid: parseInt(customerid),
      brandicon: brandIcon,
      brandcolor: brandColor,
      brandheader: brandHeader
    })
      .then((res) => {
        if (res.data.code === 200) {
          setEdit_cust_data_modal(false);
          message.success("Updated Successfully");
          localStorage.setItem('brandColor', brandColor);
          localStorage.setItem('brandHeader', brandHeader);
          localStorage.setItem('brandIcon', brandIcon);
          
          ReceiveCustData();
          ReceiveLocList();
          ReceiveSectorList();
          // window.location.reload();
         
        } else if (res.data.newemail == "notfound") {
          message.error(
            "The owner email you entered is not an active user for this customer. The user must have logged into this site at least once."
          );
          ReceiveCustData();
        }else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
     
  };
 
  const FileUpload = (file) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("image", file, file.name);

    upload_brandIcon(formData)
      .then((res) => {
        if (res.data.code === 200) {
          setBrandIcon(res.data.image);
          message.success("Image Uploaded Successfully");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    ReceiveCustData();
    ReceiveLocList();
    ReceiveSectorList();
    console.log("message", customer);
  }, []);

   const Default = () => {
    if (disabled === true) {
      setBrandColor(brandColor);
      setBrandHeader(brandHeader);
      setBrandIcon(brandIcon);
      
    }else {
      setBrandColor(defaultBrand.colordefault[0].elmvalue);
      setBrandHeader(defaultBrand.headerdefault[0].elmvalue);
      setBrandIcon(defaultBrand.icondefault[0].elmvalue);
      
    }


  
   };

  

   const Stripeportal = () => {
    if (BASE_URL == 'https://test.guvrix.com') {
      setStripePortal( 'https://billing.stripe.com/p/login/test_6oE3dnboZawX6SQ9AA');
    }else if(BASE_URL == 'https://member.guvrix.com'){
      setStripePortal( 'https://billing.stripe.com/p/login/bIY4gH9I8c8Q88gdQQ');
    }
   }

const brand = () => {
     if(brandColor == defaultBrand.colordefault[0].elmvalue && brandHeader == defaultBrand.headerdefault[0].elmvalue && brandIcon == defaultBrand.icondefault[0].elmvalue){
           setDisabled(true);
          }else{
            setDisabled(false);
          }
        }   
      
  
  
  return (
    <>
    <div className="container">
    <div align="center">
              <h4 style={{ paddingTop: "3%" }}>Customer Data</h4>
            </div>

       <div className="row text-center buttonhide">
      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <StyledButton1
          //style={{ alignContent: "flex-start" }}
          onClick={() => {
            history.push("/adminfunctionmodal");
          }}
        >
          Return to Admin Menu
        </StyledButton1>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <StyledButton1
        onClick={() => {
          setCustName(customer[0].custdesc);
          setCountryCode(customer[0].countrycode);
          setSectorId(customer[0].sectorid);
          setEmailReminder(customer[0].emailsugg);
          setEmailWelcome(customer[0].emailuser);
          setEmailTopic(customer[0].emailtopic);
          setAccountOwnerEmail(customer[0].email);
          setSubscriptionOwnerEmail(customer[0].subemail);
          setBrandColor(customer[0].brandcolor);
          setBrandHeader(customer[0].brandheader);
          setBrandIcon(customer[0].brandicon);

          setEdit_cust_data_modal(true);
        }}>
          Edit Record
        </StyledButton1>
      </div>
      {/* <div hidden={planType}
       className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
        <StyledButton1
          style={{ alignContent: "flex-start" }}
          onClick={() => {
            setManage_sub_modal(true);
            localStorage.setItem("ManageSub", 1002);
          }}
        >
          Manage Subscription
        </StyledButton1>
        </div> */}
      <div
      hidden={planType}
       className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
      <a href={stripePortal} target="_blank" rel="noopener noreferrer">
        <StyledButton1
        onClick={() => {
           Stripeportal();
           
        }}
        >
          Stripe Portal
        </StyledButton1>
        </a>
      </div>
      </div>

      

      <div>
        {/* --------------- Edit Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90 " isOpen={edit_cust_data_modal}>
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3 mt-2">
                <h4>Edit Customer Data</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Customer Name</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Customer Name"
                    value={custName}
                    onChange={(e) => {
                      setCustName(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.custName_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.custName_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Home Country</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      // marginLeft: "30%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={countryCode}
                    onChange={(e) => {
                      setCountryCode(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Home Country
                    </option>
                    {homeCountryList &&
                      homeCountryList?.map((data, index) => {
                        return (
                          <option
                            value={data.countrycode}
                            label={data.countryfulldesc}
                          >
                            {data.countryfulldesc}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.homeCountry_error && (
                <div className="offset-8">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.homeCountry_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Sector</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={sectorId}
                    onChange={(e) => {
                      setSectorId(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Sectors
                    </option>
                    {sectorsList &&
                      sectorsList?.map((data, index) => {
                        return (
                          <option value={data.sectorid} label={data.sectordesc}>
                            {data.sectordesc}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.sector_error && (
                <div className="offset-8">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.sector_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Account Owner Email</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="email"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Owner Email"
                    value={accountOwnerEmail}
                    onChange={(e) => {
                      setAccountOwnerEmail(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.accountOwnerEmail_error && (
                <div className="offset-8">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.accountOwnerEmail_error}
                  </p>
                </div>
              )}

<StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Subscription Owner Email</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="email"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Owner Email"
                    value={subscriptionOwnerEmail}
                    onChange={(e) => {
                      setSubscriptionOwnerEmail(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.subscriptionOwnerEmail_error && (
                <div className="offset-8">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.subscriptionOwnerEmail_error}
                  </p>
                </div>
              )}


              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">
                    Auto email due date reminder
                  </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={emailReminder}
                    onChange={(e) => {
                      setEmailReminder(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.emailReminder_error && (
                <div className="offset-8">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.emailReminder_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">
                  Auto email topic reminder
                  </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={emailTopic}
                    onChange={(e) => {
                      setEmailTopic(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.emailTopic_error && (
                <div className="offset-8">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.emailTopic_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">
                    Auto email welcome to new users
                  </StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={emailWelcome}
                    onChange={(e) => {
                      setEmailWelcome(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.emailWelcome_error && (
                <div className="offset-8">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.emailWelcome_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Brand Color</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                  name="brandcolor"
                    type="color"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                     
                      
                    }}
                    disabled={disabled}
                    value={brandColor}
                    onChange={(e) => {
                      setBrandColor(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                  
                </div>
              </StyledFormInputDiv>
              {formErrors.brandColor_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.brandColor_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Brand Header Color</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                  name="brandheader"
                    type="color"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    disabled={disabled}
                    value={brandHeader}
                    onChange={(e) => {
                      setBrandHeader(e.target.value);
                      console.log(e.target.value);
                    }}
                    
                  />
                  
                </div>
              </StyledFormInputDiv>
              {formErrors.brandHeader_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.brandHeader_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Brand Icon</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="file"
                    style={{
                      width: "89px",
                      alignItems: "center",
                      border: "1px solid black",
                    
                    }}
                    disabled={disabled}
                    onChange={(e) => {
                      FileUpload(e.target.files[0]);
                    }}
                  />
                  {brandIcon != "" && <img src={BASE_URL + '/uploads/' + brandIcon} width={85} />}
                </div>
              </StyledFormInputDiv>
              {formErrors.brandIcon_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.brandIcon_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Guvrix Brand Default</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <Form.Check
                 style={{ width: "100%" }}
                    type="switch"
                    id="custom-switch"
                   
                     value={defaultBrand}
                     defaultChecked={switchState}
                     //default={switchState}
                    
                    onChange={() => {
                      setDisabled(!disabled)
                      console.log(disabled);
                      
                      Default();
                     
                    }}
                  ></Form.Check>
                  
                     
               
                </div>
              </StyledFormInputDiv>
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 30,
                marginBottom:25
              }}
              onClick={() => {
                if (handleValidation()) {
                  EditCustData();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setEdit_cust_data_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      {/* --------------- Manage Sub Button Modal ------------ */}
      {/* <ReactModal className="ModalStyle2 Width90"  isOpen={manage_sub_modal}>
        <div>
          <div align="center" className="mb-3">
            <h4 >
              Are you sure you want to Manage Subscription?
            </h4>
            <StyledButton
              style={{ width: "50%", height: 30, marginTop: 50 }}
              onClick={() => {
                // ManageSub_Function();
                history.push("/enroll_ui");
              }}
            >
              Manage Subscription
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setManage_sub_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </ReactModal> */}

      <div className="center">
      <div align="center" className="pt-3 pb-2 border">
      {customer &&
              customer?.map((data, index) => (
      <table className="line">
      
    <tr >
        <th>Customer Name</th>
        <td>{data.custdesc}</td>
    </tr>
    <tr >
        <th>Home Country</th>
        <td>{data.countryfulldesc}</td>
      </tr>
      
    <tr>
        <th>Sector</th>
        <td style={{
                      color: `${data.sectordesc == "<please change default>"
                          ? "red"
                          : "black"
                        }`,
                      fontWeight: `${data.sectordesc == "<please change default>"
                          ? "bold"
                          : ""
                        }`,
                    }}>{data.sectordesc}</td>
    </tr>
    <tr>
        <th>Account Owner Email</th>
        <td>{data.email}</td>
    </tr>
    <tr>
        <th>Subscription Owner Email</th>
        <td>{data.subemail}</td>
    </tr>
    <tr>
        <th>Auto email due date reminder</th>
        <td>{data.emailsugg}</td>
    </tr>
    <tr>
        <th>Auto email topic reminder</th>
        <td>{data.emailtopic}</td>
    </tr>
    <tr>
        <th>Auto email welcome to new users</th>
        <td>{data.emailuser}</td>
    </tr>
   
    <tr>
        <th>Brand Color</th>
        <td>{data.brandcolor}</td>
    </tr>
    <tr>
        <th> Brand Header Color</th>
        <td>{data.brandheader}</td>
    </tr>
    <tr>
        <th> Brand Icon</th>
        <td><img src={BASE_URL + '/uploads/' + data.brandicon} width={85} /></td>
    </tr>
    <tr>
        <th> Enrolled Plan</th>
        <td>{data.pymtdesc}</td>
    </tr>
    <tr>
        <th>Plan Type</th>
        <td>{data.plantype}</td>
    </tr>
    <tr>
        <th> Plan Start Date</th>
        <td>{data.startdate}</td>
    </tr>
    <tr>
        <th> Renew By Date</th>
        <td>{data.renewdate}</td>
    </tr>
   
</table>
 ))}
</div>
</div>
      {/*<div className="m-4 table-responsive tableFixHeadXLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData>S.No</StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Customer{<br />}Name
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Home{<br />}Country
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Sector
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Account Owner{<br />}Email
              </StyledTableHeaderData>
              <StyledTableHeaderData>
                Auto email due{<br />}date reminder
              </StyledTableHeaderData>
              <StyledTableHeaderData>
                Auto email welcome{<br />}to new users
              </StyledTableHeaderData>
              <StyledTableHeaderData>
                Brand Color
              </StyledTableHeaderData>
              <StyledTableHeaderData>
                Brand Header
              </StyledTableHeaderData>
              <StyledTableHeaderData>
                Brand Icon
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Enrolled{<br />}Plan
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Plan{<br />}Start Date
              </StyledTableHeaderData>
              {/*<StyledTableHeaderData style={{ paddingLeft: 25 }}>
                Action
            </StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {customer &&
              customer?.map((data, index) => (
                <StyledTableRow>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td style={{ color: "black" }}>{data.custdesc}</td>
                  <td style={{ color: "black" }}>{data.countryfulldesc}</td>
                  {/* <td style={{color: "black"}}>{data.sectordesc}</td> *
                  <td
                    style={{
                      color: `${data.sectordesc == "<please change default>"
                          ? "red"
                          : "black"
                        }`,
                      fontWeight: `${data.sectordesc == "<please change default>"
                          ? "bold"
                          : ""
                        }`,
                    }}
                  >
                    {data.sectordesc}
                  </td>
                  <td style={{ color: "black" }}>{data.email}</td>
                  <td style={{ color: "black" }}>{data.emailsugg}</td>
                  <td style={{ color: "black" }}>{data.emailuser}</td>
                  <td style={{ color: "black" }}>{data.brandcolor}</td>
                  <td style={{ color: "black" }}>{data.brandheader}</td>
                  <td style={{ color: "black" }}>
                    <img src={BASE_URL + '/uploads/' + data.brandicon} width={85} />
                  </td>
                  <td style={{ color: "black" }}>{data.pymtdesc}</td>
                  <td style={{ color: "black" }}>{data.startdate}</td>
                  {/*<td>
                    <a
                      onClick={() => {
                        setCustName(data.custdesc);
                        setCountryCode(data.countrycode);
                        setSectorId(data.sectorid);
                        setEmailReminder(data.emailsugg);
                        setEmailWelcome(data.emailuser);
                        setAccountOwnerEmail(data.email);
                        setBrandColor(data.brandcolor);
                        setBrandHeader(data.brandheader);
                        setBrandIcon(data.brandicon);

                        setEdit_cust_data_modal(true);
                      }}
                    >
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                    &nbsp; &nbsp;
                    <StyledButtonMS
                      style={{ alignContent: "flex-start" }}
                      onClick={() => {
                        setManage_sub_modal(true);
                        localStorage.setItem("ManageSub", 1002);
                      }}
                    >
                      Manage Subscription
                    </StyledButtonMS>
                    </td>
                </StyledTableRow>
              ))}
          </tbody>
        </StyledTable>
                  </div>*/}
                  </div>
    </>
  );
};

export default Customer_Data;
