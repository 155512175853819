import axios from "axios";
import { BASE_URL } from '../url/baseurl'

export const guidance_table_data_api = async (data) => {
    let resposne = axios.post(`${BASE_URL}/api/guidance_list`, data);
    return resposne;
}

export const guidance_delete = async (data) => {
    let resposne = axios.post(`${BASE_URL}/api/delete_cuzguidance`, data);
    return resposne;
}