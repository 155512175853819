import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory,useParams} from "react-router-dom";
import { AfterGetStartedAPI } from "../services/AfterGetStartedAPI";
import ReactModal from "react-modal";
import * as Dataservice from "../services/Database"
import QuestionTableSearch from "./QuestionTableSearch";
import MainHeader from "./MainHeader"

const theme = localStorage.getItem('brandColor');

const StyledLink = styled(Link)`
  color: darkgrey;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  left: 3.68%;
  top: 28.71%;
  width: 92.6%;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledButton = styled.button`
  background: ${theme};
  width: 93px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 5px;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;
const StyledTableRow = styled.tr`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding-left: 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledStatus = styled.td`
  color: green;
`;

const StyledInputDiv = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 85vw;
  min-width: 1000px;
  height: auto;
  justify-self: center;
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 50px;
  margin: 0 auto;
  margin-top: 25px;
`;

const StyledDiv1 = styled.div`
  margin: 13.5px;
`;
const StyledInput = styled.input`
  border: 1px solid #e4e4e4;
  border-top: none;
  border-left: none;
  border-right: none;

  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: #afaaaa;

  &::active {
    border: 1px solid #e4e4e4;
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;
const StyledButton1 = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
`;

const HorizontalDivider = {
    width: "36px",
    height: "0px",
    marginTop: "2%",
    border: "0.5px solid #C5C1C1",
    transform: "rotate(90deg)",
};






const TopicDevelopment = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [result, set_result] = useState({})
    const [modal, set_modal] = useState(false)
    const [cust, set_cust] = useState([])
    const [cuz, set_cuz] = useState([])

    const[cuzDetail,setCuzDetail]=useState("")



    // list
    const [priority, set_priotity] = useState([])
    const [categorylist, set_categorylist] = useState([])
    const [guidance, set_guidance] = useState([])
    const [select_modal, set_select_modal] = useState(false)
    const [statusSearch, setStatusSearch] = useState("")
    const [topicSearch, setTopicSearch] = useState("")



    const history = useHistory()
    const ModalStyle = {
        content: {
            width: "70%",
            // height: "70%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
        },
    };

    const getTableData = async () => {
       

        Dataservice.cuz_details({ custid: params.customerid, topicid: params.id}).then((res) => {
            set_cust(res.data.customer_description)
            console.log("data cust =====>", res);
            set_cuz(res.data.cuz_description)
        })

            
    };



    React.useEffect(() => {
        getTableData();
    }, []);

    async function get_all() {
        Dataservice.priority_list().then((res) => {
            set_priotity(res.data["Priority List"]);
            console.log(res.data["Priority List"]);
        })
        Dataservice.ReportingCategoryList({ topicid: result.topicid }).then((res) => {
            set_categorylist(res.data["Reporting Category List"]);
            console.log(res.data["Reporting Category List"]);
        })
        Dataservice.guidance_list({ topicid: result.topicid }).then((res) => {
            set_guidance(res.data["guidance_list"]);
            console.log(res.data["guidance_list"]);
        })
        set_select_modal(true)
    }

   


    // data.filter((item) => (item.name === entervalue))



    // const searchSubmit = () => {
    //     let filter_data = []

    //     console.log(data);

    //     if (statusSearch !== "") {

    //         filter_data = [...filter_data, ...data.filter(item =>
    //         (
    //             // Object.keys(o).some(()=>{
    //             //   // console.log("k print",k);
    //             //   console.log(o.tstatusid)
    //             String(item.tstatusid)
    //                 .toLowerCase()
    //                 .includes(statusSearch?.toLowerCase())

    //         ))
    //             //  ))
    //         ]

    //         // item.tstatusid.toLowerCase() === statusSearch.toLowerCase())

    //     }
    //     if (topicSearch !== "") {

    //         filter_data = [...filter_data, ...data.filter((item) =>
    //         (String(item.cuzdesc)
    //             .toLowerCase()
    //             .includes(topicSearch?.toLowerCase())))];
    //     }

    //     console.log("Filter data", filter_data)
    //     setData(filter_data.length !== 0 ? filter_data : data1);

    // }


    return (<>
        <MainHeader />
        {/* <QuestionTableSearch/> */}
        {/* <StyledInputDiv>
            <StyledDiv1>
                <StyledInput style={{ width: "239px" }} placeholder="Filter Custom Topic Title" type="text" onChange={(e) => { setTopicSearch(e.target.value) }} />
            </StyledDiv1>
            <div style={HorizontalDivider} />
            <StyledDiv1>
                <StyledInput placeholder="Filter Status" type="text" onChange={(e) => { setStatusSearch(e.target.value) }} />
            </StyledDiv1>
           
            <div style={HorizontalDivider} />
            <StyledButton1 style={{}} onClick={() => searchSubmit()}>Search</StyledButton1>
        </StyledInputDiv> */}
        <StyledDiv>

            <StyledTable class="table-auto">
                <thead>
                    <StyledTableHeader>
                        <StyledTableHeaderData>Customer Name</StyledTableHeaderData>
                        <StyledTableHeaderData>Topic Description</StyledTableHeaderData>
                        <StyledTableHeaderData></StyledTableHeaderData>
                        {/* <StyledTableHeaderData>Status</StyledTableHeaderData>
                        <StyledTableHeaderData>Summary</StyledTableHeaderData>
                        <StyledTableHeaderData></StyledTableHeaderData> */}
                    </StyledTableHeader>
                </thead>
                <tbody>

                    <StyledTableRow><td>
                                {cust?.map((data) => {
                                 return data.custdesc
                                })
                                }
                               </td>
                              <td>
                                {cuz?.map((data) => {
                                 return data.cuzdesc
                                })
                                }
                                </td>
                               
                                <td>
                                  
                                    <StyledButton onClick={() => { history.push("/ExtQuestion/" + params.id) }}>Proceed</StyledButton>
                                  
                                </td>
                            </StyledTableRow>
                                     
                </tbody>
            </StyledTable>
            {/* modal box here */}
        
        </StyledDiv>
    </>
    );
};





export default TopicDevelopment;