import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";

import {
  sectionselect_locationlist_api,
  sectionselect_reportinglist_api,
  sectionselect_custlocidlist_api,
  needlist_loccountry_locsectors_api,
  add_new_loc,
  update_location,
} from "../../../services/AdminProgramApi";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  // flex: 1;
  // margin-right: 10px;
  // display: inline;
  // width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;

  margin-top: 4px;

  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});



const Location_List = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  console.log("propdata", match);
  console.log(localStorage.getItem('brandColor'),"brandcolor");

  const [edit_loc_list_modal, setEdit_loc_list_modal] = useState(false);
  const [add_location_modal, setAdd_location_modal] = useState(false);
  const [location, setLocation] = useState("");
  const [reporting, setReporting] = useState("");
  const [country, setCountry] = useState("");
  const [interLoc, setInterLoc] = useState("");
  const [sector, setSector] = useState("");
  const [active, setActive] = useState("");
  const [run1, setRun1] = useState("");
  const [pageSize, setPageSize] = useState(100);

  const [formErrors, setFormErrors] = useState([]);

  // ----------------------- Validation -------------------------
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!location) {
      formIsValid = false;
      formErrors["location_error"] = "Location is required.";
    }
    if (!country) {
      formIsValid = false;
      formErrors["country_error"] = "Country is required.";
    }
    if (!sector) {
      formIsValid = false;
      formErrors["sector_error"] = "Sector is required.";
    }
    if (!active) {
      formIsValid = false;
      formErrors["active_error"] = "Active is required.";
    }
    if (interLoc != !interLoc) {
       var res =false;
      const upd_obj = custlocid_list_data.map((obj) => {
        if (obj.custlocid == interLoc) {
          console.log("interloc ==>",interLoc);
          res = true;
        }
      });
      console.log("upd_obj ==>",upd_obj);
      if(res == true){ 
      
      formIsValid = false;
      formErrors["interLoc_error"] = "Please enter a unique Internal Location identifier.";
    }
    }
    console.log("interLoc ==>",interLoc);
    console.log("reporting ==>",reporting);

    setFormErrors(formErrors);
    return formIsValid;
  };
    const handleValidation2 = () => {
    let formErrors = {};
    let formIsValid = true;
   
    if (!location) {
      formIsValid = false;
      formErrors["location_error"] = "Location is required.";
    }
    if (!country) {
      formIsValid = false;
      formErrors["country_error"] = "Country is required.";
    }
    if (!sector) {
      formIsValid = false;
      formErrors["sector_error"] = "Sector is required.";
    }
    if (!active) {
      formIsValid = false;
      formErrors["active_error"] = "Active is required.";
    }
    if (interLoc != !interLoc  ) {
       var res =false;
      const upd_obj = selection_list_data.map((obj) => {
        
        if (obj.locationid != locationid ) {
          // if(obj.custlocid == null){
          //   res = false;
          // }
          // else 
          if(interLoc != null){
          if (obj.custlocid  == interLoc ) {       
          console.log("interloc ==>",interLoc);
          res = true;
        }
      }
        
      }
      });
      console.log("upd_obj ==>",upd_obj);
      if(res == true){ 
      
      formIsValid = false;
      formErrors["interLoc_error"] = "Please enter a unique Internal Location identifier.";
    }
    }
    console.log("interLoc ==>",interLoc);
    console.log("reporting ==>",reporting);

    setFormErrors(formErrors);
    return formIsValid;
  };

  // ------- Reset Fields Function --------
  const resetFields = () => {
    setLocation("");
    setCountry("");
    setInterLoc("");
    setReporting("");
    setSector("");
    setActive("");
    setFormErrors([]);
  };

  // hooks
  const [locationid, setLocationid] = useState();
  const [locationdesc, setLocationdesc] = useState();
  const [countryid, setCountryid] = useState();
  const [countrydesc, setCountrydesc] = useState();
  const [sectorid, setSectorid] = useState();
  const [sectordesc, setSectordesc] = useState();
  //const [active, setActive] = useState()

  const customerid = match.params.customerid;
  // selection list api func call
  const [selection_list_data, setSelection_list_data] = useState([]);
  const [locationLimit, setLocationLimit] = useState([]);

  const sectionselect_locationlist_function = async () => {
    sectionselect_locationlist_api(customerid)
      .then((res) => {
        if (res.data.code == 200) {
          console.log("res selection list data===>>", res.data);
          setSelection_list_data(res.data.Custloclist);
          setLocationLimit(res.data.Locatelimit);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error===>>", err);
      });
  };

  useEffect(() => {
    sectionselect_locationlist_function();
  }, [customerid]);

  useEffect(() => {
    country_list();
    sector_list();
    reporting_list();
    custlocid_list();
    
  }, [customerid]);

  // sector list func
  const [sector_list_data, setSector_list_data] = useState([]);
  const [reporting_list_data, setReporting_list_data] = useState([]);
  const [custlocid_list_data, setCustlocid_list_data] = useState([]);
  const [country_list_data, setCountry_list_data] = useState([]);
  const [list_data, setList_data] = useState([]);
  const sector_list = async () => {
    let data = "locsectors";
    needlist_loccountry_locsectors_api(data)
      .then((res) => {
        if ((res.data.code = 200)) {
          console.log("sector list res==>", res.data);
          setSector_list_data(res.data.sector_list_for_locations);
        }
      })
      .catch((err) => {
        console.log("error===>", err);
      });
  };
 
  const reporting_list = async () => {
    sectionselect_reportinglist_api(customerid)
    .then((res) => {
      if (res.data.code == 200) {
      console.log("message ==>",res.data.result);
        setReporting_list_data(res.data.result);
        
      } else {
        message.error(res.data.message);
      }
    })
    .catch((err) => {
      console.log("error===>>", err);
    });
  };
  
  const custlocid_list = async () => {
    sectionselect_custlocidlist_api(customerid)
    .then((res) => {
      if (res.data.code == 200) {
      console.log("location data ==>",res.data.result);
        setCustlocid_list_data(res.data.result);
        
      } else {
        message.error(res.data.message);
      }
    })
    .catch((err) => {
      console.log("error===>>", err);
    });
  };

  const country_list = async () => {
    let data = "loccountry";
    needlist_loccountry_locsectors_api(data)
      .then((res) => {
        if ((res.data.code = 200)) {
          console.log("country list res==>", res.data);
          setCountry_list_data(res.data.country_list_for_locations);
        }
      })
      .catch((err) => {
        console.log("error===>", err);
      });
  };

  //---------------- Add Location API Function -------------------------

  const addLocation = () => {
    add_new_loc({
      Customerid: parseInt(customerid),
      Locationdesc: location,
      Countrycode: country,
      InterLoc : interLoc,
      Custgroupid: parseInt(reporting),
      Sectorid: parseInt(sector),
      Activel: active,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setAdd_location_modal(false);
          resetFields();
          setList_data(res.data.data);
          message.success("Location added successfully");
          sectionselect_locationlist_function();
          console.log("res Add Loc =>", res.data);
        } else if (res.data.message == "locationlimit") {
          message.error(
            `You will exceed your location limit of ${locationLimit?.map(
              (data) => data.loclimit
            )}. Consider Deactivating another location first or upgrading to a higher payment plan.`
          );
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  let SNo = 1;
  const rowsWithSerialNumber = selection_list_data.map((selection_list_data) => {
    return {
      ...selection_list_data,
      SNo: SNo++,
    };
  });


  // const row: GridRows =[


  //   selection_list_data?.map((data, index) => {
  //     return (
  //       <StyledTableRow>
  //         <td style={{ color: "black" }}>{index + 1}</td>
  //         <td style={{ color: "black" }}>{data.locationdesc}</td>
  //         <td style={{ color: "black" }}>
  //           {data.countrycode}, {data.countryfulldesc}
  //         </td>
  //         <td style={{ color: "black" }}>{data.custlocid}</td>
  //         <td style={{ color: "black" }}>{data.custgroupdesc}</td>
  //         <td
  //           style={{
  //             color: `${
  //               data.sectordesc == "<please change default>"
  //                 ? "red"
  //                 : "black"
  //             }`,
  //             fontWeight: `${
  //               data.sectordesc == "<please change default>"
  //                 ? "bold"
  //                 : ""
  //             }`,
  //           }}
  //         >
  //           {data.sectordesc}
  //         </td>
  //         <td style={{ color: "black" }}>{data.activel}</td>
  //         <td>
  //           <a
  //             onClick={() => {
  //               setEdit_loc_list_modal(true);
  //               setActive(data.activel);
  //               setLocation(data.locationdesc);
  //               setLocationid(data.locationid);
  //               setInterLoc(data.custlocid);
  //               setReporting(data.custgroupid);
  //               setSector(data.sectorid);
  //               setCountryid(data.countryid);
  //               setCountry(data.countrycode);
  //             }}
  //           >
  //             <i class="fas fa-pencil-alt" aria-hidden="true"></i>
  //           </a>
  //         </td>
  //       </StyledTableRow>
  //     );
  //   })

  // ];

  // useEffect(() => {
  //   const locationrow = [
  //     selection_list_data?.map((data, index) => {
  //       S.No :  {index + 1}
  //     })
  //   ];
  // }, []);

  const rows1  = rowsWithSerialNumber;
//const rows1 : GridRowsProp = selection_list_data;
  // const locationrow = [
  //   selection_list_data?.map((data, index) => {
  //     S.No :  {index + 1}
  //   })
  // ];


  const EditLocationButton = ({
    data,
    setEdit_loc_list_modal,
    setActive,
    setLocation,
    setLocationid,
    setInterLoc,
    setReporting,
    setSector,
    setCountryid,
    setCountry,
  }) => {
    const handleEditClick = () => {
      setEdit_loc_list_modal(true);
      setActive(data.activel);
      setLocation(data.locationdesc);
      setLocationid(data.locationid);
      setInterLoc(data.custlocid);
      setReporting(data.custgroupid);
      setSector(data.sectorid);
      setCountryid(data.countryid);
      setCountry(data.countrycode);
    };
  
    return (
      <a onClick={handleEditClick}>
        <i className="fas fa-pencil-alt" aria-hidden="true"></i>
      </a>
    );
  };
  


const serialno = 0;

  const columnsUsers: GridColumns = [
    {
      field: "SNo",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.2,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },
    {
      field: "locationdesc",
      headerName: "Location",
      // minwidth: 200,
      flex: 1,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "countryfulldesc",
      headerName: "Country",
      // width: 200,
     // type: "number",
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 25
                ? `${params.value.substring(0, 25)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
      // cellClassName: (params) => {
      //   const isValid = validateUserType(params.value);

      //   return clsx("super-app", {
      //     negative: params.value < 0,
      //     positive: isValid === false,
      //   });
      // },
      // renderCell: (params) => {
      //   const isValid = validateUserType(params.value);

      //   if (isValid == false) {
      //     return (
      //       <Tooltip title="This is not a valid User Type code.">
      //         <span className="table-cell-trucate">{params.value}</span>
      //       </Tooltip>
      //     );
      //   }
      // },
    },
    {
      field: "custlocid",
      headerName: " Internal Location",
      // type: "text",
      //type: "singleSelect",
      //valueOptions: ["Y", "N"],
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 20
                ? `${params.value.substring(0, 20)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,

      // cellClassName: (params) => {
      //   // console.log("All Location ",params.value);
      //   const isValid = validateAllLocations(params);
      //   console.log("isValid ==>", isValid["res"]);

      //   return clsx("super-app", {
      //     positive: isValid["res"] === false || params.value == null,
      //   });
      // },
      // renderCell: (params) => {
      //   const isValid = validateAllLocations(params);
      //   console.log("validateAllLocations==>", params.id);
      //   if (isValid["res"] == false) {
      //     return (
      //       <Tooltip title={isValid["message"]}>
      //         <span className="table-cell-trucate">{params.value}</span>
      //       </Tooltip>
      //     );
      //   }

      //   // console.log(params.value);
      // },
    },
    {
      field: "custgroupdesc",
      headerName: "Reporting Group",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 20
                ? `${params.value.substring(0, 20)}...`
                : params.value}
          </Tooltip>
        );
      },
     //editable: true,
      // cellClassName: (params) =>
      //   clsx("super-app", {

      //     positive: params.value == null || params.value == "",
      //   }),
      // renderCell: (params) => {
      //   console.log("UserRole ", params.value);
      //   if (params.value == null || params.value == "") {
      //     console.log("user Role error",)
      //     return (
      //       <Tooltip title="A User Role is required.">
      //         <span className="table-cell">-</span>
      //       </Tooltip>
      //     );
      //   }

      //   // console.log(params.value);
      // },
    },
    {
      field: "sectordesc",
      headerName: "Sector",
      type: "text",
      headerClassName: "super-app-theme--header",
      // cellClassName: (params) => {
      //   // const isValid = validateLocationID(params.value);
      //   //console.log("isValid ==>", isValid);

      //   return clsx("super-app", {
      //     positive: params.value == null || params.value == '',
      //     // positive: params.value == null,
      //   });
      // },

      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 25
                ? `${params.value.substring(0, 25)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },
    {
      field: "activel",
      headerName: "Active",
      // type: "text",
      //type: "singleSelect",
     // valueOptions: ["Y", "N"],
      // width: 200,
      flex: 0.3,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 25
                ? `${params.value.substring(0, 25)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      // type: "text",
      //type: "singleSelect",
     // valueOptions: ["Y", "N"],
      // width: 200,
      flex: 0.3,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => (
        <EditLocationButton
          data={params.row}
          setEdit_loc_list_modal={setEdit_loc_list_modal}
          setActive={setActive}
          setLocation={setLocation}
          setLocationid={setLocationid}
          setInterLoc={setInterLoc}
          setReporting={setReporting}
          setSector={setSector}
          setCountryid={setCountryid}
          setCountry={setCountry}
        />
      ),
      //editable: true,
      // cellClassName: (params) =>
      //   clsx("super-app", {
      //     // negative: params.value != 0,
      //     positive: params.value != "Y" && params.value != "N",
      //   }),
    },
  ];




  //---------------- Edit Location API Function -------------------------

  const editLocation = () => {
    let data = {
      Activel: active,
      Customerid: parseInt(customerid),
      Locationid: parseInt(locationid),
      Locationdesc: location,  
       InterLoc : interLoc,
      Custgroupid: reporting,
      Sectorid: parseInt(sector),
      Countrycode: country,
    };

    
    update_location(data)
      .then((res) => {
        if (res.data.code === 200) {
          setEdit_loc_list_modal(false);
          resetFields();
          message.success("Updated Successfully");
          sectionselect_locationlist_function();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center" align="center">
        <div className="col-4 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/adminfunctionmodal");
            }}
          >
            Return to Admin Menu
          </StyledButton1>
        </div>
        <div className="col-4 Width100">
          <StyledButton1
            onClick={() => {
              setAdd_location_modal(true);
            }}
          >
            Add Location
          </StyledButton1>
        </div>
        <div className="col-4 Width100">
          <StyledButton1
            onClick={() => 
               history.push("/reporting_group/" + match.params.customerid)
            }
          >
            Reporting Group
          </StyledButton1>
        </div>
      </div>

      <div>
        {/* --------------- Edit Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90" isOpen={edit_loc_list_modal}>
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Edit Location</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Location</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "self-start" }}>
                  <input
                    type="text"
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Location"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    // disabled={true}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Country</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    type="text"
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Country"
                    value={country}
                    // onClick={() => {
                    //   country_list()
                    // }}

                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                    {/* <option value="" selected disabled hidden>Select country</option> */}
                    {country_list_data?.map((data, i) => {
                      //   console.log(country)
                      return (
                        <option value={data.countrycode}>
                          {data.countrycode} {data.countryfulldesc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>

                     {/* ‘Internal Location’ */}
                     <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Internal Location</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Internal Location"
                    value={interLoc}
                    onClick={() => {
                      // country_list();
                    }}
                    onChange={(e) => {
                      setInterLoc(e.target.value);
                    }}
                  />
                   
                </div>
              </StyledFormInputDiv>
              {formErrors.interLoc_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.interLoc_error}
                  </p>
                </div>
              )}

              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Reporting Group</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    type="text"
                    onClick={() => {
                      sector_list();
                    }}
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Reporting Group"
                    value={reporting}
                    onChange={(e) => {
                                setReporting(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Reporting Group
                    </option>
                    {reporting_list_data?.map((data, i) => {
                      return (
                        <option value={data.custgroupid}>{data.custgroupdesc}</option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>


              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Sector</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    type="text"
                    // onClick={() => {
                    //   sector_list()
                    // }}
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Sector"
                    value={sector}
                    onChange={(e) => {
                      setSector(e.target.value);
                    }}
                  >
                    {sector_list_data?.map((data, i) => {
                      return (
                        <option value={data.sectorid}>{data.sectordesc}</option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Active</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {/* {formErrors.activeClient_error && (
                      <div className="offset-6 mt-2">
                        <p
                          style={{
                            color: "red",
                            fontSize: ".8rem",
                            marginTop: "0px",
                          }}
                        >
                          {formErrors.activeClient_error}
                        </p>
                      </div>
                    )} */}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation2()) {
                editLocation();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                resetFields();
                setEdit_loc_list_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      <div>
        {/* --------------- Add Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90" isOpen={add_location_modal}>
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Add Location</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Location</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Location"
                    value={location}
                    // disabled={true}
                    onChange={(e) => {
                      setLocation(e.target.value);
                      //console.log(e.target.value)
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.location_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.location_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Country</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    type="text"
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Country"
                    value={country}
                    onClick={() => {
                      country_list();
                    }}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select country
                    </option>
                    {country_list_data?.map((data, i) => {
                      return (
                        <option value={data.countrycode}>
                          {data.countrycode} {data.countryfulldesc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.country_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.country_error}
                  </p>
                </div>
              )}

                       {/* ‘Internal Location’ */}
               <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Internal Location</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Internal Location"
                    value={interLoc}
                    onClick={() => {
                      // country_list();
                    }}
                    onChange={(e) => {
                      setInterLoc(e.target.value);
                    }}
                  />
                   
                </div>
              </StyledFormInputDiv>
              {formErrors.interLoc_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.interLoc_error}
                  </p>
                </div>
              )}

              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Reporting Group</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    type="text"
                    onClick={() => {
                      sector_list();
                    }}
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Reporting Group"
                    value={reporting}
                    onChange={(e) => {
                                setReporting(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Reporting Group
                    </option>
                    {reporting_list_data?.map((data, i) => {
                      return (
                        <option value={data.custgroupid}>{data.custgroupdesc}</option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>

              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Sector</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    type="text"
                    onClick={() => {
                      sector_list();
                    }}
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Sector"
                    value={sector}
                    onChange={(e) => {
                      setSector(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select Sector
                    </option>
                    {sector_list_data?.map((data, i) => {
                      return (
                        <option value={data.sectorid}>{data.sectordesc}</option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.sector_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.sector_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Active</StyledLabel>
                </div>
                <div style={{ width: "100%" }}>
                  <select
                    style={{
                      width: "125%",
                      marginLeft: "-25%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </StyledFormInputDiv>
              {formErrors.active_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.active_error}
                  </p>
                </div>
              )}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "40%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation()) {
                  addLocation();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save New Location
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setAdd_location_modal(false);
                resetFields();
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      <div className="m-4">
        <div>
          Location Limit : &nbsp;
          {locationLimit?.map((data, index) => {
            return (
              <input
                disabled
                style={{
                  width: "50px",
                  textAlign: "center",
                  border: "1px solid black",
                }}
                value={data.loclimit}
              />
            );
          })}
        </div>
      </div>

      {/* //<div className="m-4 table-responsive tableFixHeadXLarge"> */}
      <div
                  style={{ height: '70vh', width: "100%", marginTop: 15 }}
                  className={classes.root}
                >

      <DataGrid
                    sx={{
                      ".MuiDataGrid-columnHeaders": {
                        backgroundColor: "#ff8c24",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                    }}
                    rows={rows1}
                    getRowId={(r) => r.locationid}
                    id="_id"
                    columns={columnsUsers}
                    // initialState={{

                    //   pagination: {
                    //     pageSize: 10,
                    //   },
                    // }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[ 100]}
                    pagination
                    hideFooterPagination={ rows1.length <= pageSize}
                  //  processRowUpdate={processRowUpdate}
                    experimentalFeatures={{ newEditingApi: true }}
                  />

</div>
        {/* <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData>S.No</StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Location
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Country
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                 Internal Location
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                 Reporting Group
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Sector
              </StyledTableHeaderData>
              <StyledTableHeaderData>Active</StyledTableHeaderData>
              <StyledTableHeaderData>Action</StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {selection_list_data?.map((data, index) => {
              return (
                <StyledTableRow>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td style={{ color: "black" }}>{data.locationdesc}</td>
                  <td style={{ color: "black" }}>
                    {data.countrycode}, {data.countryfulldesc}
                  </td>
                  <td style={{ color: "black" }}>{data.custlocid}</td>
                  <td style={{ color: "black" }}>{data.custgroupdesc}</td>
                  <td
                    style={{
                      color: `${
                        data.sectordesc == "<please change default>"
                          ? "red"
                          : "black"
                      }`,
                      fontWeight: `${
                        data.sectordesc == "<please change default>"
                          ? "bold"
                          : ""
                      }`,
                    }}
                  >
                    {data.sectordesc}
                  </td>
                  <td style={{ color: "black" }}>{data.activel}</td>
                  <td>
                    <a
                      onClick={() => {
                        setEdit_loc_list_modal(true);
                        setActive(data.activel);
                        setLocation(data.locationdesc);
                        setLocationid(data.locationid);
                        setInterLoc(data.custlocid);
                        setReporting(data.custgroupid);
                        setSector(data.sectorid);
                        setCountryid(data.countryid);
                        setCountry(data.countrycode);
                      }}
                    >
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                  </td>
                </StyledTableRow>
              );
            })}
          </tbody>
        </StyledTable> */}
      </div>
    /* </div> */
  );
};

export default Location_List;
