import React from "react";
import styled from "styled-components";
import { Link, useParams, useHistory } from "react-router-dom";
const theme = localStorage.getItem('brandColor');
const StyledButton = styled.button`
  background: ${theme};
  width: 350px;
  border-radius: 30px;
  height: 55px;
  text-align: center;
  color: white;
  font-size: 20px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

function ViewCustomerReport() {
  const history = useHistory();

  return (
    <div>
      <div className='mt-5' align="center">
        <div>
          <StyledButton
            onClick={() => {
              //   history.push("")
            }}
          >
            Completed Topics Report
          </StyledButton>
        </div>

        <div>
          <StyledButton
            onClick={() => {
              //setCalled_func_name("suggestion_report");
              //history.push('/suggestion_report')
            }}
          >
            Topic History Comparison Report
          </StyledButton>
        </div>
      </div>
    </div>
  );
}

export default ViewCustomerReport;
