import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../url/baseurl";

const theme = localStorage.getItem('brandColor');

const StyledDivButtons = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
`;



const StyledButton = styled.button`
  background: ${theme};
  width: 330px;
  border-radius: 30px;
  height: 55px;
  text-align: center;
  color: white;
  font-size: 20px !important;
  font-weight: 500;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;


const ModalStyle = {
    content: {
        width: "70%",
        height: "70%",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
    },
};

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
  margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledInput = styled.input`
  display: inline;
  width: 70%;
  
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputStyle = {
    border: "none",
    backgroundColor: "#EEEEEE",
};

const StyledFormInputDiv = styled.div`
  display: flex;
  
  margin-top: 4px;

  width: 100%;
`;

const FormDivStyle = {
    position: "relative",
    display: "inline",
    width: "30%",
};

const FormDivStyleTwo = {
    position: "relative",
    display: "inline",
    width: "70%",

};


const Demosetup = () => {

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [spinner, setSpinner] = useState(false);

    const history = useHistory();

    const demodatasetup_check = async () => {
      
      setDisabled(true);
      setSpinner(true);
      
     //console.log(disabled,"hi");
      let res = await axios.post(
        BASE_URL +
        `/api/demodatasetup_check`
        
      );
      if (res.data.code == 200) {
        message.success(res.data.message);
        
        setDisabled(false);
        setSpinner(false);
        //console.log(loading,"hi");
      }
      
    }


    return (
        <>
            <div align="center">
                <h4 style={{ paddingTop: "3%" }}>Demo Setup</h4>
            </div>
            <div className="text-center">
                <StyledButton
                disabled={disabled}
                //disabled={disabled === false || loading === true} 
                 onClick={() =>{
                  demodatasetup_check();
                 
                  console.log("demo")
                 }}>{spinner && ( <span><i class="fa fa-spinner fa-spin" ></i></span>)}
                    Copy Prod Masters to Demodata
                </StyledButton>
            </div>
            <div className="text-center">
                <StyledButton onClick={() =>{history.push("copydemo");
                
            }}>
                    Copy Demodata to Demo
                </StyledButton>
            </div>
            <div className="text-center">
                <StyledButton onClick={() =>{history.push("support_menu");
                
            }}>
                    Exit
                </StyledButton>
            </div>
        </>
    );
};

export default Demosetup;