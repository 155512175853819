import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { get_Ai_Query_Results } from "../../../services/AdminProgramApi";
import { Tooltip } from "antd";
import { makeStyles } from "@material-ui/styles";
import { useParams } from 'react-router-dom';

const theme = localStorage.getItem('brandColor');

const StyledButton = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: 500;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const StyledButtonST = styled.button`
  background: ${theme};
  width: 80px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  font-size: 15px !important;
  color: white;
  font-weight: 400;
  border-color: none;
  border: none;
`;

const useStyles = makeStyles({
  root: {
    height: '400px', // Adjust this height as needed
    '& .super-app-theme--header': {
      backgroundColor: '#ff8c24',
      border: '1px solid #ff8c24',
      color: '#FFFFFF',
      fontWeight: '600',
    },
  },
});

const AI_Assistant_list = ({ match }) => {
  const customerid = useParams();
  const customerids = match.params.customerid;  
  const history = useHistory();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(100);
  const [aiQueryResults, setAiQueryResults] = useState([]);
  const [has_custom_topics, set_has_custom_topics]   = useState(0)
  const [alert_modal, set_alert_modal] = useState(false);  
  useEffect(() => {    
    getAiQueryResultParameters(customerid);
  }, []);

  const getAiQueryResultParameters = async (customerid) => {
    try{          
      const res = await get_Ai_Query_Results(customerid);
      if(res.data.code === 200){
        setAiQueryResults(res.data.results);   
        set_has_custom_topics(res.data.custom_topics);
      }
      else{
        console.log("status : ","failure");
      }
    }
    catch{
      console.log("Api failed")
    }
  }
 
  const SelectButton = ({ data  }) => {
    const handleStartClick = () => {
      const {Query_Code }  = data;      
       history.push("/adminfunction/ai_result/"+Query_Code);
    };

    return (
      <StyledButtonST onClick={handleStartClick} style={{ width: 100 }}>
        Select
      </StyledButtonST>
    );
  };

  const ConvertButton = ({ data  }) => {
    const handleStartClick = () => {
      const { Query_Code }  = data;        
      if(has_custom_topics == 0){
        set_alert_modal(true); 
      }else{
        history.push("/adminfunction/available_custom_topics/"+Query_Code);
      }      
    };

    return (
      <StyledButtonST onClick={handleStartClick} style={{ width: 100 }}>
        Convert
      </StyledButtonST>
    );
  };



  const columnsUsers = [
    {
      field: "TD_Name",
      headerName: "Template",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          {params.value?.length > 30
            ? `${params.value.substring(0, 30)}...`
            : params.value}
        </Tooltip>
      ),
    },
    {
      field: "parm_queries",
      headerName: "Parameters",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          {params.value?.length > 200
            ? `${params.value.substring(0, 200)}...`
            : params.value}
        </Tooltip>
      ),
    },
    {
      field: "Query_Date",
      headerName: "Query Date",
      type: "text",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          {params.value?.length > 200
            ? `${params.value.substring(0, 200)}...`
            : params.value}
        </Tooltip>

      ),
    },
    {
      field: "Action1",
      headerName: "Select",
      type: "text",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div>
             <SelectButton
            data={params.row}
            
          />
        </div>

      ),
    },
    {
      field: "Action2",
      headerName: "Convert",
      type: "text",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div>
             <Tooltip title="Convert this query to a custom topic. First create a Custom Topic using the 'Add Custom Topic' button in the Custom Topic Admin menu. The topic status must equal 'New'." arrow>
      <div>
        <ConvertButton data={params.row} />
      </div>
    </Tooltip>
        </div>

      ),
    },
  ];

  const rows = aiQueryResults.map((data, index) => ({
    ...data,
    id: index + 1,
    SNo: index + 1,
  }));

  const closeModal = () => {
    set_alert_modal(false);
  };

  return (
    <div className="container-fluid">
      <ReactModal 
        className="ModalStyle1 Width90" 
        isOpen={alert_modal}        
        onRequestClose={closeModal}>
          <div className="text-end">
            <a onClick={closeModal}>
              <i className="fas fa-times-circle fa-lg" aria-hidden="true"></i>
            </a>
          </div>
          <div align="center">
            <h5>No valid custom topics found</h5>
            <p>First create an empty Custom Topic with a Status='New'.</p>
            <StyledButton 
            style={{ marginTop: 20 }} 
            onClick={() => {
              history.push(`/adminfunction/custom_topic_data/${customerids}`);
            }}
            >
              Go to Custom Topic
            </StyledButton>
          </div>           
      </ReactModal>
      <div className="mt-4 row text-center" align="center">
        <div className=" d-flex justify-content-around" align="center">
          <StyledButton
            onClick={() => {
              history.push(`/adminfunction/ai_assistant/${customerids}`);
            }}
          >
            Return to AI Assistant
          </StyledButton>
        </div>      
        <div className={classes.root}  style={{ height: '78vh', width: "100%", marginTop: 30 , overflow: 'hidden'}}>
          <DataGrid
              sx={{
                ".MuiDataGrid-columnHeaders": {
                  backgroundColor: "#ff8c24",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
              }}
            rows={rows}
            columns={columnsUsers}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[100]}
            pagination
            hideFooterPagination={rows.length <= pageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default AI_Assistant_list;
