import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import _ from 'lodash';
import { message } from "antd";
import {
  receive_homecountry_sectors,
  Add_Location_Data,
  Add_Users_Data,
  Get_customer_custlocid,
  Get_customer_Custgroup,
  Get_Topic_Location,
  Add_Topic_Location_Data,
  Get_Location,
  Get_Topic_User_Location,
  Assign_Users_Locations,
  Get_custloc_combinations,
  Demo_weclcome_email,
  Validate_UserEmail,
  Add_User_Topic_Location_Data,
  report_group_topic_location_api
} from "../../services/AdminProgramApi";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { BASE_URL } from "../../url/baseurl";
import AssignUsers_Locations from "./AssignUsers_Locations";

//
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { Tooltip } from "@mui/material";
// style
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { red } from "@mui/material/colors";

const theme = localStorage.getItem("brandColor");

const StyledButton = styled.button`
  background: ${theme};
  width: 330px;
  border-radius: 35px;
  height: 55px;
  text-align: center;
  color: white;
  font-size: 18px !important;
  font-weight: 500;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;
const StyledDiv = styled.div`
  justify-content: center;
  margin: 0 auto;
`;
const StyledTable = styled.table`
  width: 100%;
  height: 3%;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
  text-align:center;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;
const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});

const CuzbatchMenu = (props) => {
  const classes = useStyles();

  const [run1, setRun1] = useState("");
  const [DataTable, setDataTable] = useState(false);
  const [UsersDataTable, setUsersDataTable] = useState(false);
  const [customerid, setcustomerid] = useState(props.data);
  const [homeCountryList, setHomeCountryList] = useState([]);
  const [sectorsList, setSectorsList] = useState([]);
  const [custlocidList, setCustlocidList] = useState([]);
  const [custgroupList, setCustgroupList] = useState([]);
  const [Delete, setDelete] = useState("");

  const [dataList, setDataList] = useState([]);
  const [dataList1, setDataList1] = useState([]);
  const [TopicUserLocations, setTopicUserLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [TopLocations, setTopLocations] = useState([]);
  const [userDataList, setUserDataList] = useState([]);
  const [userEmailList, setUserEmailList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);

  const [UsersLocations, setUsersLocations] = useState([]);
  const [UsersLocationsList, setUsersLocationsList] = useState([]);

  // Data Grid

  const [promiseArguments, setPromiseArguments] = useState(null);
  const noButtonRef = React.useRef(null);
  const [snackbar, setSnackbar] = useState(null);

  //  ‘Add Topics to Locations'
  const [promiseArguments1, setPromiseArguments1] = useState(null);
  const noButtonRef1 = React.useRef(null);
  const [snackbar1, setSnackbar1] = useState(null);
  const [selection, setSelection] = useState([]);
  const [reportgroup, setReportgroup] = useState("");
  const [descGroup, setDescGroup] = useState([]);
  const [groupid, setGroupid] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(false);


  //  ‘Add Users'
  const [promiseArguments2, setPromiseArguments2] = useState(null);
  const noButtonRef2 = React.useRef(null);
  const [snackbar2, setSnackbar2] = useState(null);

  const [promiseArguments3, setPromiseArguments3] = useState(null);
  const noButtonRef3 = React.useRef(null);
  const [snackbar3, setSnackbar3] = useState(null);
  const [selection1, setSelection1] = useState([]);

  //  pagination
  const [pageSize, setPageSize] = useState(5);

  // sample sheet
  const [pdfLinkA, setPdfLinkA] = useState("/images/Admin Help Text.pdf");


  //const [dublicate, setDublicate] = useState([]);
  const [dublicate, setDublicate] = useState("");
  const [dublicate1, setDublicate1] = useState([]);

  const handleCallback = (childData) => {
    console.log("childData ==>", childData);
    if (childData == "") {
      setRun1(childData);
    } if (childData == "exit") {
      props.parentCallback("");
    }
    // console.log("customer_data",customer_data);
    //console.log(childData);
  };


  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) =>
          setTimeout(() => {
            if (user.name?.trim() === "") {
              reject();
            } else {
              resolve(user);
            }
          }, 200)
        ),
      []
    );
  };

  const mutateRow = useFakeMutation();

  const handleCloseSnackbar = () => setSnackbar(null);

  const value = async () => {
    //console.log("vasanth ==>", props.data);
    //console.log("customerid ==>", customerid);
  };
  let finalvalue;
  //  First


  const getReportlocation = async () => {
    report_group_topic_location_api({
      customerid: customerid[0].customerid,

    })

      .then((res) => {
        if (res.data.code == 200) {
          console.log("res group data===>>", res.data);
          setDescGroup(res.data.result);
          console.log("res group data===>>", res.data.result);
             setGroupid(res.data.result[0].custgroupid)

          //  }
          console.log("gid", groupid);
        } else {
          console.log("error");
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error===>>", err);
      });
  };


  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      //console.log("upload");
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];

        const worksheet = workbook.Sheets[sheetName];
        const result = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });
        if (result.length > 0) {
          var array = result[0];
          if (
            array[0] === "LocationName" &&
            array[1] === "Countrycode" &&
            array[2] === "ReportingGroup" &&
            array[3] === "Sectorid" &&
            array[4] === "CustomerLocationID" &&
            array[5] === "Active"
          ) {
            //console.log("valid");
            setDataTable(true);
          } else {
            setDataTable(false);
            message.error("Invalid Record, please verify sample spreadsheet");
          }

          //console.log("result ==> ", result[0][0]);
        }
        console.log("result ==> ", result);
        const json = XLSX.utils.sheet_to_json(worksheet);
        console.log("json ==> ", json);
        json.forEach((item, i) => {
          item.id = i + 1;
        });
        // //console.log(JSON.stringify(json,null, 2));
        finalvalue = json;
        //console.log("json ==> ", finalvalue);
        setDataList1(json);
        console.log(setDataList1);
        const upd_obj11 = custgroupList.map((obj) => {
         
          for(let i=0; i<json.length ; i++){
          if(json[i]['ReportingGroup'] == obj.custgroupid ){
            json[i]["id"] = i+1;
            json[i]['ReportingGroup'] = `${obj.custgroupid}    -     ${obj.custgroupdesc}`;
            json[i]['LocationName'] =  json[i]['LocationName'];
            json[i]['Countrycode'] = json[i]['Countrycode'];
            //json[i]['Sectorid'] = `${obj1.sectorid}    +     ${obj1.sectordesc}`;
            json[i]['CustomerLocationID'] = json[i]['CustomerLocationID'];
            json[i]['Active'] =  json[i]['Active'];
          }else{
            json[i]["id"] = i+1;
            json[i]['ReportingGroup'] = json[i]['ReportingGroup'];
            json[i]['LocationName'] =  json[i]['LocationName'];
            json[i]['Countrycode'] = json[i]['Countrycode'];
            json[i]['Sectorid'] = json[i]['Sectorid'];
            json[i]['CustomerLocationID'] = json[i]['CustomerLocationID'];
            json[i]['Active'] =  json[i]['Active'];
          }
        }
        });
     

      const upd_obj1 = sectorsList.map((obj1) => {
        for(let i=0; i<json.length ; i++){
        if( json[i]['Sectorid'] == obj1.sectorid){
         
          
          
          json[i]['Sectorid'] = `${obj1.sectorid}    -     ${obj1.sectordesc}`;
         
        }else{
          
         
          json[i]['Sectorid'] = json[i]['Sectorid'];
         
        }
      }
      });
    


        setDataList(json);
        //setDataList(upd_obj11);
      };

      // const upd_obj11 = custgroupList.map((obj) => {
      //   if(dataList1[0]['ReportingGroup'] == obj.custgroupid){
      //     dataList1[0]['ReportingGroup'] = `${obj.custgroupid}    +     ${obj.custgroupdesc}`;
      //     dataList1[0]['LocationName'] =  dataList1[0]['LocationName'];
      //     dataList1[0]['Countrycode'] = dataList1[0]['Countrycode'];
      //     dataList1[0]['Sectorid'] = dataList1[0]['Sectorid'];
      //     dataList1[0]['CustomerLocationID'] = dataList1[0]['CustomerLocationID'];
      //     dataList1[0]['Active'] =  dataList1[0]['Active'];
      //   }else{
      //     dataList1[0]['ReportingGroup'] = dataList1[0]['ReportingGroup'];
      //     dataList1[0]['LocationName'] =  dataList1[0]['LocationName'];
      //     dataList1[0]['Countrycode'] = dataList1[0]['Countrycode'];
      //     dataList1[0]['Sectorid'] = dataList1[0]['Sectorid'];
      //     dataList1[0]['CustomerLocationID'] = dataList1[0]['CustomerLocationID'];
      //     dataList1[0]['Active'] =  dataList1[0]['Active'];
      //   }
      // });
      // setDataList(upd_obj11);

      reader.readAsArrayBuffer(e.target.files[0]);
      //console.log(e.target.files[0]);
    }
  };

  const Topic_Location_api = () => {
    //console.log("Topic_Location_api");
    Get_Topic_Location({
      customerid: customerid,
      Custgroupid: reportgroup,
      Groupid: groupid,
    })
      .then((res) => {
        if (res.data.code === 200) {
          // message.success("Updated Successfully");
          setTopLocations(res.data.result);
          setRun1("Add Topics to Locations");
          //console.log("TopLocations", TopLocations);
        } else if (res.data.code === 300) {
        } else {
          setTopLocations("");
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const Topic_User_Location_api = () => {
    console.log("Topic_Location_api");
    Get_Topic_User_Location({
      customerid: customerid,
      selection: selection1,

    }).then((res) => {
      if (res.data.code === 200) {
        // message.success("Updated Successfully");
        setTopicUserLocations(res.data.topicresult);
        setRun1("topicuser");
        //setLocations(res.data.location);
        // setRun1("Assign Permissions to Topic-Locations");

        console.log('TopLocations', TopLocations);
      } else if (res.data.code === 300) {

      } else if (res.data.code === 400) {

        message.error(res.data.message);

      }
    })
      .catch((e) => {
        //console.log(e);
      });

  };



  const Location_api = () => {
    console.log("Topic_Location_api");
    Get_Location({
      customerid: customerid,
      Custgroupid: reportgroup,
      Groupid: groupid,

    }).then((res) => {
      if (res.data.code === 200) {
        // message.success("Updated Successfully");
        setLocations(res.data.custlocation);
        setRun1("Assign Permissions to Topic-Locations");

        console.log('locations', locations);
      } else if (res.data.code === 300) {

      } else {
        setLocations("");
        message.error(res.data.message);

      }
    })
      .catch((e) => {
        //console.log(e);
      });

  };

  const Add_User_Topic_Location = () => {
    console.log("prossess selection ==>", selection1);
    if (selection1.length > 0) {
      console.log("valid");
      Add_User_Topic_Location_Data({
        customerid: customerid,
        data: selection,
      })
        .then((res) => {
          if (res.data.code === 200) {
            message.success("Updated Successfully");
            props.parentCallback("");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    } else {
      console.log("Invalid");
      message.error("No records available for this selection");
    }
  };


  const ReceiveLocList = async () => {
    receive_homecountry_sectors("homecountry")
      .then((res) => {
        // //console.log(res);
        if (res.data.code === 200) {
          setHomeCountryList(res.data.country_list);
        } else {
          message.error(res.data.message);
        }
        // //console.log("LocList", res.data.country_list);
      })
      .catch((e) => {
        // //console.log(e);
      });
    ////console.log("ReceiveLocList");
  };
  const ReceiveSectorList = async () => {
    receive_homecountry_sectors("sectors")
      .then((res) => {
        ////console.log(res);
        if (res.data.code === 200) {
          setSectorsList(res.data.sector_list);
        } else {
          message.error(res.data.message);
        }
        ////console.log("SectorList", res.data.sector_list);
      })
      .catch((e) => {
        ////console.log(e);
      });
  };
  const ReceiveCustlocid = async () => {
    Get_customer_custlocid({
      customerid: customerid,
    })
      .then((res) => {
        ////console.log(res);
        if (res.data.code === 200) {
          setCustlocidList(res.data.custlocid_list);
        } else {
          message.error(res.data.message);
        }
        ////console.log("SectorList", res.data.sector_list);
      })
      .catch((e) => {
        ////console.log(e);
      });
  };
  const ReceiveCustgroup = async () => {
    Get_customer_Custgroup({
      customerid: customerid,
    })
      .then((res) => {
        ////console.log(res);
        if (res.data.code === 200) {
          setCustgroupList(res.data.custgroup);
        } else {
          message.error(res.data.message);
        }
        ////console.log("SectorList", res.data.sector_list);
      })
      .catch((e) => {
        ////console.log(e);
      });
  };
  const ReceiveUserEmail = async () => {
    receive_homecountry_sectors("useremail")
      .then((res) => {
        ////console.log(res);
        if (res.data.code === 200) {
          setUserEmailList(res.data.useremail);
        } else {
          message.error(res.data.message);
        }
        ////console.log("SectorList", res.data.sector_list);
      })
      .catch((e) => {
        ////console.log(e);
      });
  };

  const ReceiveUserType = async () => {
    receive_homecountry_sectors("admintypeid")
      .then((res) => {
        ////console.log(res);
        if (res.data.code === 200) {
          setUserTypeList(res.data.admintypeid);
        } else {
          message.error(res.data.message);
        }
        ////console.log("SectorList", res.data.sector_list);
      })
      .catch((e) => {
        ////console.log(e);
      });
  };

  const processRowSelection1 = (arr1, arr2) => {
    let res = [];
    //     console.log("arr1",arr1);
    // console.log("arr2",arr2);
    res = arr1.filter(el => {
      return arr2.find(element => {
        return element === el.locationid;
      });
    });
    console.log("res", res);
    return res;
  }

  useEffect(() => {
    value();
    ReceiveLocList();
    ReceiveSectorList();
    ReceiveCustlocid();
    ReceiveUserEmail();
    ReceiveUserType();
    ReceiveCustgroup();
    getReportlocation();
  }, []);

  const validateProcess = () => {
    const upd_obj = dataList.map((obj) => {
      if (Object.keys(obj).length != 7 && Object.keys(obj).length != 6) {
        // console.log("Error length==>",Object.keys(obj).length);

        // console.log("Error length==>", obj);
        // console.log("end");

        return false;
      }
      //   if (Object.keys(obj).length == 6) {
      //     const updarray = dataList.map((data) => {
      //       if (data.id == obj.id) {
      //         return {
      //           LocationName: obj.LocationName,
      //           Countrycode: obj.Countrycode,
      //           LocationGroup: '',
      //           Sectorid: obj.Sectorid,
      //           CustomerLocationID: obj.CustomerLocationID,
      //           Active: obj.Active,
      //           id: obj.id,
      //         };
      //       } else {
      //         return data;
      //       }
      //     });
      //     setDataList(updarray);
      // }

      if (obj.LocationName == null || "") {
        // console.log("Error LocationName ==>", obj.LocationName);
        return false;
      }
      const ValidCountrycode = validateCountrycode(obj.Countrycode);
      // console.log("ValidCountrycode ==>", ValidCountrycode);
      if (ValidCountrycode == false) {
        // console.log("Error Countrycode ==>", obj.Countrycode);
        return false;
      }
      const ValidCustGroup = validateCustGroup(obj.ReportingGroup);
      if (ValidCustGroup == false && obj.ReportingGroup != null && obj.ReportingGroup != '') {
        console.log("ValidCustGroup");
        return false;
      }
      const ValidSectorid = validateSectorid(obj.Sectorid);

      if (ValidSectorid == false) {
        // console.log("Error Sectorid ==>", obj.Sectorid);
        return false;
      }
      const ValidLocationID = validateLocationID(obj.CustomerLocationID);
      if (ValidLocationID == false || obj.CustomerLocationID == null || obj.CustomerLocationID == '') {
        // console.log("Error ValidLocationID ==>", obj.CustomerLocationID);
        return false;
      }

      if (obj.Active != "Y" && obj.Active != "N") {
        console.log("Error Active ==>", obj.Active);
        return false;
      }

      return true;
    });

    const res = upd_obj.every((n) => {
      return n == true;
    });

    // console.log("res ==>", res);
    return res;
  };
  const ProcessValue = () => {
    console.log("Process ==>", dataList);
    if (dataList.length == 0) {
      message.error("Please upload spreadsheet");
      return 1;
    }
    const res = validateProcess();
    if (res) {
      console.log("valid ==>", res);
      Add_Location_Data({
        customerid: customerid,
        data: dataList,
      })
        .then((res) => {
          if (res.data.code === 200) {
            console.log("res.data.code ==>", res.data.code);
            message.success("Updated Successfully");
            props.parentCallback("");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((e) => {

        });

    } else {
      console.log("Invalid ==>", res);
      message.error("Record is invalid, Please enter valid value");
    }
  };

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;
    //console.log("handleYes");
    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({ children: "Successfully Saved", severity: "success" });
      resolve(response);
      //console.log("handleYes ==>", newRow.id);
      const upd_obj = dataList.map((obj) => {
        if (obj.id == newRow.id) {
          return {
            LocationName: newRow.LocationName,
            Countrycode: newRow.Countrycode,
            ReportingGroup: newRow.ReportingGroup,
            Sectorid: newRow.Sectorid,
            CustomerLocationID: newRow.CustomerLocationID,
            Active: newRow.Active,
            id: newRow.id,
          };
        } else {
          return obj;
        }
        // return obj;
      });

      // console.log("updated ==>", upd_obj);
      setDataList(upd_obj);
      setPromiseArguments(null);
    } catch (error) {
      setSnackbar({ children: "Name can't be empty", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  function computeMutation(newRow, oldRow) {
    // //console.log("computeMutation",oldRow);
    if (newRow.LocationName !== oldRow.LocationName) {
      return `Location Name from '${oldRow.LocationName}' to '${newRow.LocationName}'`;
    }
    if (newRow.Countrycode !== oldRow.Countrycode) {
      return `Countrycode from '${oldRow.Countrycode || ""}' to '${newRow.Countrycode || ""
        }'`;
    }
    if (newRow.ReportingGroup !== oldRow.ReportingGroup) {
      return `ReportingGroup from '${oldRow.ReportingGroup || ""}' to '${newRow.ReportingGroup || ""
        }'`;
    }
    if (newRow.Sectorid !== oldRow.Sectorid) {
      return `Sectorid from '${oldRow.Sectorid || ""}' to '${newRow.Sectorid || ""
        }'`;
    }
    if (newRow.CustomerLocationID !== oldRow.CustomerLocationID) {
      return `Customer Location ID
 from '${oldRow.CustomerLocationID || ""}' to '${newRow.CustomerLocationID || ""
        }'`;
    }

    if (newRow.Active !== oldRow.Active) {
      return `Active from '${oldRow.Active || ""}' to '${newRow.Active || ""}'`;
    }

    return null;
  }

  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        //console.log("processRowUpdate");
        //console.log("resolve", resolve);
        //console.log("reject", reject);
        const mutation = computeMutation(newRow, oldRow);
        //console.log("processRowUpdate ==>", mutation);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          //console.log("True ==>", newRow);
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          //console.log("false");
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  function validateSectorid(data) {
    var res = false;

    const upd_obj = sectorsList.map((obj) => {
      if (`${obj.sectorid}    -     ${obj.sectordesc}` == data) {
        res = true;
      }
    });


    return res;
  }

  function validateCountrycode(data) {
    var res = false;

    const upd_obj = homeCountryList.map((obj) => {
      if (obj.countrycode == data) {
        res = true;
      }
    });


    return res;
  }

  function validateCustGroup(data) {
    // console.log("custgrouoList ==>", custgroupList);
    // console.log("data ==>", data);
    var res = false;



    const upd_obj = custgroupList.map((obj) => {
      if (`${obj.custgroupid}    -     ${obj.custgroupdesc}` == data) {
        res = true;
      }
    });
    // console.log("res ==>",res);

    return res;
  }

  function validateLocationID(data) {
    var res = true;
    var res1 = 0;

    const upd_obj1 = dataList.map((obj) => {
      if (obj.CustomerLocationID == data) {
        res1 += 1;

        if (res1 > 1) {
          res = false;
        }
      }
    });

    //  Test by DataBase Dublicate
    // console.log(" ===>",custlocidList.length);
    const upd_obj = custlocidList.map((obj) => {
      if (obj.custlocid == data) {
        res = false;
      }
    });
    return res;
  }
  const columns: GridColumns = [
    {
      field: "LocationName",
      headerName: "LocationName",
      // width: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      cellClassName: (params) =>
        clsx("super-app", {
          // negative: params.value != 0,
          positive: params.value == null || params.value == "",
        }),
    },
    {
      field: "Countrycode",
      headerName: "Countrycode",
      // width: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      cellClassName: (params) => {
        // console.log("Countrycode ==>", params.value);

        const isValid = validateCountrycode(params.value);
        //console.log("isValid ==>", isValid);

        return clsx("super-app", {
          negative: params.value < 0,
          positive: isValid === false,
        });
      },
    },
    {
      field: "ReportingGroup",
      headerName: "ReportingGroup",
      // width: 200,
      flex: 1,
      align: "center",
     // type: "number",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      type: "singleSelect",
     
      valueOptions: ({ row }) => {
         const options = [];
         custgroupList?.map((type) =>options.push( `${type.custgroupid}    -     ${type.custgroupdesc}`))
        return options
      },
      cellClassName: (params) => {

        // console.log("custgrouoList ==>", params.value);

        const isValid = validateCustGroup(params.value);
        // console.log("isValid ==>", isValid);

        return clsx("super-app", {
          negative: params.value < 0,
          positive: isValid === false && params.value != null && params.value != '',
        });
      },
      renderCell: (params) => {
        const isValid = validateCustGroup(params.value);
        // console.log("validateUserEmail2==>", isValid);
        if (isValid === false && params.value != null && params.value != '') {
          return (
            <Tooltip title="Reporting Group is not set up.">
              <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>
          );
        }

        // console.log(params.value);
      },
    },
    {
      field: "Sectorid",
      headerName: "Sectorid",
     // type: "number",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      editable: true,

      type: "singleSelect",
     
      valueOptions: ({ row }) => {
         const options = [];
         sectorsList?.map((type) =>options.push( `${type.sectorid}    -     ${type.sectordesc}`))
        return options
      },

      cellClassName: (params) => {
        const isValid = validateSectorid(params.value);
        //console.log("isValid ==>", isValid);

        return clsx("super-app", {
          positive: isValid === false || params.value == null,
        });
      },
    },
    {
      field: "CustomerLocationID",
      headerName: "CustomerLocationID",
      type: "text",
      headerClassName: "super-app-theme--header",
      cellClassName: (params) => {
        const isValid = validateLocationID(params.value);
        //console.log("isValid ==>", isValid);

        return clsx("super-app", {
          // positive: params.value == null ||  params.value == '',
          positive: isValid === false || params.value == null || params.value == '',
        });
      },

      flex: 1,
      align: "center",
      headerAlign: "center",
      editable: true,
    },
    {
      field: "Active",
      headerName: "Active",
      type: "text",
      // width: 200,
      flex: 1,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      editable: true,
      cellClassName: (params) =>
        clsx("super-app", {
          // negative: params.value != 0,
          positive: params.value != "Y" && params.value != "N",
        }),
    },
  ];
  const rows: GridRowsProp = dataList;

  // ‘Add Topics to Locations

  const columns1 = [
    {
      field: "cuzdesc",
      headerName: "Topic",
      // width: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "countrycode",
      headerName: "Location Country",
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      // width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "activetl",
      headerName: "Active",
      type: "singleSelect",
      headerClassName: "super-app-theme--header",
      valueOptions: ["Y", "N"],

      cellClassName: (params) => {
        const isValid = validateLocationID(params.value);
        //console.log("isValid ==>", isValid);

        return clsx("super-app", {
          // positive: params.value == null ||  params.value == '',
          positive: isValid > 1 || params.value == null,
        });
      },
      //   cellClassName: (params) =>
      // clsx('super-app', {
      //   // negative: params.value != 0,
      //   positive: params.value == null ||  params.value == '',
      // }),
      // width: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      editable: true,
    },
  ];
  const rows1 = TopLocations;


  const locationcolumns = [
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

    },
    {
      field: "countrycode",
      headerName: "Location Country",
      // width: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    }
  ];
  const location = locations;

  const topiclocationcolumns = [
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

    },
    {
      field: "cuzdesc",
      headerName: "Topic",
      // width: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "useremail",
      headerName: "UserEmail",
      // width: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "privilege",
      headerName: "Privilege",
      // width: 200,
      //defaultValue: 'Edit',
      type: "singleSelect",
      valueOptions: ["Edit", "View"],
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      editable: true,
    }
  ];
  const topiclocation = TopicUserLocations;


  const processRowUpdate1 = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const mutation = computeMutation1(newRow, oldRow);
        console.log("processRowUpdate ==>", newRow);
        if (mutation) {
          //console.log("True ==>", newRow);
          setPromiseArguments1({ resolve, reject, newRow, oldRow });
        } else {
          //console.log("false");
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  function computeMutation1(newRow, oldRow) {
    // //console.log("computeMutation",oldRow);
    if (newRow.activetl !== oldRow.activetl) {
      return `Active from '${oldRow.activetl}' to '${newRow.activetl}'`;
    }

    return null;
  }

  const renderConfirmDialog1 = () => {
    if (!promiseArguments1) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments1;
    const mutation = computeMutation1(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments1}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef1} onClick={handleNo1}>
            No
          </Button>
          <Button onClick={handleYes1}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleYes1 = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments1;
    //console.log("handleYes");
    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar1({ children: "Successfully Saved", severity: "success" });
      resolve(response);
      //console.log("handleYes ==>", newRow.id);
      const upd_obj = TopLocations.map((obj) => {
        if (obj.id == newRow.id) {
          return {
            activetl: newRow.activetl,
            countrycode: newRow.countrycode,
            cuzdesc: newRow.cuzdesc,
            locationdesc: newRow.locationdesc,
            locationid: newRow.locationid,
            topicid: newRow.topicid,
            id: newRow.id,
          };
        } else {
          return obj;
        }
        // return obj;
      });

      // console.log("updated ==>", upd_obj);
      setTopLocations(upd_obj);
      setPromiseArguments1(null);
    } catch (error) {
      setSnackbar1({ children: "Name can't be empty", severity: "error" });
      reject(oldRow);
      setPromiseArguments1(null);
    }
  };

  const handleNo1 = () => {
    const { oldRow, resolve } = promiseArguments1;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments1(null);
  };

  const handleCloseSnackbar1 = () => setSnackbar1(null);

  const processRowUpdate3 = useCallback(



    (newRow, oldRow) =>
      new Promise((resolve, reject) => {





        const mutation = computeMutation3(newRow, oldRow);
        console.log("processRowUpdate ==>", newRow);
        if (mutation) {
          //console.log("True ==>", newRow);
          setPromiseArguments3({ resolve, reject, newRow, oldRow });
        } else {
          //console.log("false");
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  function computeMutation3(newRow, oldRow) {
    // //console.log("computeMutation",oldRow);
    if (newRow.privilege !== oldRow.privilege) {
      return `Privilege from '${oldRow.privilege}' to '${newRow.privilege}'`;
    }

    return null;
  }

  const renderConfirmDialog3 = () => {
    if (!promiseArguments3) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments3;
    const mutation = computeMutation3(newRow, oldRow);



    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments3}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef3} onClick={handleNo3}>
            No
          </Button>
          <Button onClick={handleYes3}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleYes3 = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments3;
    //console.log("handleYes");
    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar3({ children: "Successfully Saved", severity: "success" });
      resolve(response);
      //console.log("handleYes ==>", newRow.id);
      const upd_obj = TopicUserLocations.map((obj) => {
        if (obj.id == newRow.id) {
          return {
            locationdesc: newRow.locationdesc,
            cuzdesc: newRow.cuzdesc,
            useremail: newRow.useremail,
            privilege: newRow.privilege,
            locationid: newRow.locationid,
            topicid: newRow.topicid,
            id: newRow.id,
            customerid: newRow.customerid,
          };
        } else {
          return obj;
        }
        // return obj;
      });

      // console.log("updated ==>", upd_obj);
      setTopicUserLocations(upd_obj);
      setPromiseArguments3(null);
    } catch (error) {
      setSnackbar3({ children: "Name can't be empty", severity: "error" });
      reject(oldRow);
      setPromiseArguments3(null);
    }
  };

  const handleNo3 = () => {
    const { oldRow, resolve } = promiseArguments3;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments3(null);
  };



  const processRowSelection = (arr1, arr2) => {
    let res = [];
    //     console.log("arr1",arr1);
    // console.log("arr2",arr2);
    res = arr1.filter((el) => {
      return arr2.find((element) => {
        return element === el.id;
      });
    });
    //  console.log("res",res);
    return res;
  };

  const Add_Topic_Location = () => {
    console.log("prossess selection ==>", selection);
    if (selection.length > 0) {
      console.log("valid");
      Add_Topic_Location_Data({
        data: selection,
      })
        .then((res) => {
          if (res.data.code === 200) {
            message.success("Updated Successfully");
            props.parentCallback("");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    } else {
      console.log("Invalid");
      message.error("No records available for this selection");
    }
  };

  // Add Users
  const readUploadFile_Users = (e) => {
    e.preventDefault();
    if (e.target.files) {
      //console.log("upload");
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];

        const worksheet = workbook.Sheets[sheetName];
        const result = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });
        if (result.length > 0) {
          var array = result[0];
          if (
            array[0] === "UserEmail" &&
            array[1] === "UserType" &&
            array[2] === "AllLocations" &&
            array[3] === "UserRole" &&
            array[4] === "CustomerLocationID" &&
            array[5] === "Active"
          ) {
            // console.log("valid");
            setUsersDataTable(true);
          } else {
            setUsersDataTable(false);
            message.error("Invalid Record, please verify sample spreadsheet");
          }

          console.log("result ==> ", result[0][0]);
        }
        console.log("result ==> ", result);
        const json = XLSX.utils.sheet_to_json(worksheet);
        console.log("json ==> ", json);
        json.forEach((item, i) => {
          item.id = i + 1;
        });
        // console.log(JSON.stringify(json,null, 2));
        finalvalue = json;
        console.log("json ==> ", finalvalue);
        setUserDataList(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      //console.log(e.target.files[0]);
    }
  };

  const columnsUsers: GridColumns = [
    {
      field: "UserEmail",
      headerName: "UserEmail",
      // minwidth: 200,
      flex: 1,
      type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      editable: true,

      cellClassName: (params) => {
        // console.log("Countrycode ==>", params.value);

        const isValid = validateUserEmail(params.value);
        // console.log("isValid ==>", isValid);

        return clsx("super-app", {
          negative: params.value < 0,
          positive: isValid["res"] === false,
        });
      },
      renderCell: (params) => {
        const isValid = validateUserEmail(params.value);
        // console.log("validateUserEmail2==>", isValid);
        if (isValid["res"] == false) {
          return (
            <Tooltip title={isValid["message"]}>
              <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>
          );
        }

        // console.log(params.value);
      },
    },
    {
      field: "UserType",
      headerName: "UserType",
      // width: 200,
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      cellClassName: (params) => {
        const isValid = validateUserType(params.value);

        return clsx("super-app", {
          negative: params.value < 0,
          positive: isValid === false,
        });
      },
      renderCell: (params) => {
        const isValid = validateUserType(params.value);

        if (isValid == false) {
          return (
            <Tooltip title="This is not a valid User Type code.">
              <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "AllLocations",
      headerName: "AllLocations",
      // type: "text",
      type: "singleSelect",
      valueOptions: ["Y", "N"],
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      editable: true,

      cellClassName: (params) => {
        // console.log("All Location ",params.value);
        const isValid = validateAllLocations(params);
        console.log("isValid ==>", isValid["res"]);

        return clsx("super-app", {
          positive: isValid["res"] === false || params.value == null,
        });
      },
      renderCell: (params) => {
        const isValid = validateAllLocations(params);
        console.log("validateAllLocations==>", params.id);
        if (isValid["res"] == false) {
          return (
            <Tooltip title={isValid["message"]}>
              <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>
          );
        }

        // console.log(params.value);
      },
    },
    {
      field: "UserRole",
      headerName: "UserRole",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 1,

      align: "center",
      headerAlign: "center",
      editable: true,
      cellClassName: (params) =>
        clsx("super-app", {

          positive: params.value == null || params.value == "",
        }),
      renderCell: (params) => {
        console.log("UserRole ", params.value);
        if (params.value == null || params.value == "") {
          console.log("user Role error",)
          return (
            <Tooltip title="A User Role is required.">
              <span className="table-cell">-</span>
            </Tooltip>
          );
        }

        // console.log(params.value);
      },
    },
    {
      field: "CustomerLocationID",
      headerName: "CustomerLocationID",
      type: "text",
      headerClassName: "super-app-theme--header",
      cellClassName: (params) => {
        // const isValid = validateLocationID(params.value);
        //console.log("isValid ==>", isValid);

        return clsx("super-app", {
          positive: params.value == null || params.value == '',
          // positive: params.value == null,
        });
      },

      flex: 1,
      align: "center",
      headerAlign: "center",
      editable: true,
    },
    {
      field: "Active",
      headerName: "Active",
      // type: "text",
      type: "singleSelect",
      valueOptions: ["Y", "N"],
      // width: 200,
      flex: 1,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      editable: true,
      cellClassName: (params) =>
        clsx("super-app", {
          // negative: params.value != 0,
          positive: params.value != "Y" && params.value != "N",
        }),
    },
  ];
  const rowsUsers = userDataList;

  const processRowUpdate2 = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const mutation = computeMutation2(newRow, oldRow);
        console.log("processRowUpdate ==>", newRow);
        if (mutation) {
          //console.log("True ==>", newRow);
          setPromiseArguments2({ resolve, reject, newRow, oldRow });
        } else {
          //console.log("false");
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  function computeMutation2(newRow, oldRow) {
    // //console.log("computeMutation",oldRow);
    if (newRow.UserEmail !== oldRow.UserEmail) {
      return `User Email from '${oldRow.UserEmail}' to '${newRow.UserEmail}'`;
    }
    if (newRow.UserType !== oldRow.UserType) {
      return `User Type from '${oldRow.UserType || ""}' to '${newRow.UserType || ""
        }'`;
    }
    if (newRow.AllLocations !== oldRow.AllLocations) {
      return `All Locations from '${oldRow.AllLocations || ""}' to '${newRow.AllLocations || ""
        }'`;
    }
    if (newRow.UserRole !== oldRow.UserRole) {
      return `User Role from '${oldRow.UserRole || ""}' to '${newRow.UserRole || ""
        }'`;
    }
    if (newRow.CustomerLocationID !== oldRow.CustomerLocationID) {
      return `Customer Location ID
 from '${oldRow.CustomerLocationID || ""}' to '${newRow.CustomerLocationID || ""
        }'`;
    }

    if (newRow.Active !== oldRow.Active) {
      return `Active from '${oldRow.Active || ""}' to '${newRow.Active || ""}'`;
    }

    return null;
  }

  const renderConfirmDialog2 = () => {
    if (!promiseArguments2) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments2;
    const mutation = computeMutation2(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments2}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef2} onClick={handleNo2}>
            No
          </Button>
          <Button onClick={handleYes2}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleYes2 = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments2;

    //console.log("handleYes");
    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar2({ children: "Successfully Saved", severity: "success" });
      resolve(response);

      console.log("handleYes ==>", newRow.id);
      const upd_obj = userDataList.map((obj) => {
        if (obj.id == newRow.id) {

          return {
            UserEmail: newRow.UserEmail,
            UserType: newRow.UserType,
            AllLocations: newRow.AllLocations,
            UserRole: newRow.UserRole,

            CustomerLocationID: newRow.CustomerLocationID,
            Active: newRow.Active,
            id: newRow.id,
          };
        } else {
          return obj;
        }
        // return obj;
      });

      // console.log("updated ==>", upd_obj);
      setUserDataList(upd_obj);
      setPromiseArguments2(null);
    } catch (error) {
      setSnackbar2({ children: "Name can't be empty", severity: "error" });
      reject(oldRow);
      setPromiseArguments2(null);
    }
  };

  const handleNo2 = () => {
    const { oldRow, resolve } = promiseArguments2;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments2(null);
  };

  const validateUserEmail = (data) => {
    var result = {
      res: true,
      message: "",
    };
    var res1 = 0;
    //userEmailList
    // return false;
    console.log("data 2 ==>", data);
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data)) {
      console.log("Invalid email address");
      let newarray = {
        res: false,
        message: "Invalid email address.",
      };
      result = newarray;
      return newarray;
    }
  
    const upd_obj1 = userDataList.map((obj) => {
      if (obj.UserEmail == data) {
        res1 += 1;
          
        if (res1 > 1) {
          console.log("res1 ==>",res1);
          let newarray = {
            res: false,
            message: "Duplicate User.",
          };
          result = newarray;
          return newarray;
        }
      }
    });




    let duplicatesArr = _.difference(userDataList, _.uniqBy(userDataList, 'UserEmail'), 'UserEmail')

    for (let i = 0; i < duplicatesArr.length; i++) {

      console.log("demo111@gmail.com", duplicatesArr[i]["UserEmail"]);
      if (duplicatesArr[i]["UserEmail"] == data) {
        console.log("demo111@gmail.com", duplicatesArr[i]["UserEmail"]);
        let newarray = {
          res: false,
          message: "Duplicate user.",
        };
        result = newarray;
        return newarray;
      } else {
        console.log("No Duplicate User")
      }
      console.log(data == duplicatesArr[i]["UserEmail"], "Dublicate11");
    }


    console.log(duplicatesArr, "unique");



    const upd_obj = userEmailList.map((obj) => {

      if (obj.useremail == data) {
        if (obj.activeu == "Y") {
          if (obj.customerid == customerid[0]["customerid"]) {

            let newarray = {
              res: false,
              message: "User already setup.",
            };
            result = newarray;
            //return newarray;


            // }

          } else {
            // console.log("User");
            let newarray = {
              res: false,
              message: "User already active for another customer.",
            };
            result = newarray;
          }
          // res = obj.customerid;
        }
        if (obj.activeu == "N") {
          if (obj.customerid == customerid[0]["customerid"]) {
            let newarray = {
              res: false,
              message: "User already setup but no longer active.",
            };
            result = newarray;
          } else {
            console.log("User inactive");
            let newarray = {
              res: false,
              message: "User is inactive for another customer.",
            };
            result = newarray;
          }
        }


      }

    });

    console.log("result ==>", result);
    return result;
  };

  function validateUserType(data) {
    var res = false;
    //console.log("data",data);

    const upd_obj = userTypeList.map((obj) => {
      if (obj.admintypeid == data) {
        res = true;
      }
    });

    return res;
  }

  function validateAllLocations(data) {
    var result = {
      res: false,
      message: "",
    };
    console.log("data", data);
    console.log("Row UserType==>", data.row["UserType"]);

    if (data.value == "Y" || data.value == "N") {
      let newarray = {
        res: true,
        message: "",
      };
      result = newarray;
    }
    if (data.row["UserType"] == 1 && data.value == "N") {
      let newarray = {
        res: false,
        message: "A Master Admin must have access to all locations.",
      };
      result = newarray;
    }
    if (data.row["UserType"] == 2 && data.value == "Y") {
      let newarray = {
        res: false,
        message: "A Location Admin cannot have access to all locations",
      };
      result = newarray;
    }

    // const upd_obj = userDataList.map((obj) => {
    //   if (obj.id == data.id) {
    //     // if(obj.)
    //     console.log("Row id==>",obj.id);
    //     console.log("Row ==>",obj);
    //   }
    // });

    return result;
  }

  function validateAllLocations2(data) {
    var result = {
      res: false,
      message: "",
    };
    console.log("data", data);

    if (data.AllLocations == "Y" || data.AllLocations == "N") {
      let newarray = {
        res: true,
        message: "",
      };
      result = newarray;
    }

    if (data.UserType == 1 && data.AllLocations == "N") {
      let newarray = {
        res: false,
        message: "A Master Admin must have access to all locations.",
      };
      result = newarray;
    }
    if (data.UserType == 2 && data.AllLocations == "Y") {
      let newarray = {
        res: false,
        message: "A Location Admin cannot have access to all locations",
      };
      result = newarray;
    }

    // const upd_obj = userDataList.map((obj) => {
    //   if (obj.id == data.id) {
    //     // if(obj.)
    //     console.log("Row id==>",obj.id);
    //     console.log("Row ==>",obj);
    //   }
    // });

    return result;
  }

  const ProcessUsersValue = () => {
    console.log("Process ==>", userDataList);
    if (userDataList.length == 0) {
      message.error("Please upload spreadsheet");
      return 1;
    }
    const res = validateUsersProcess();
    if (res) {
      console.log("valid ==>", res);
      Add_Users_Data({
        customerid: customerid,
        data: userDataList,
      })
        .then((res) => {
          if (res.data.code === 200) {
            console.log("res.data.code ==>", res.data.code);
            message.success("Updated Successfully");
            props.parentCallback("");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((e) => {
          //console.log(e);
          // message.error("Duplicate user");
        });
      // message.success("Record is valid");
    } else {
      console.log("Invalid ==>", res);
      message.error("Record is invalid, Please enter valid value");
    }
  };

  const validateUsersProcess = () => {
    //console.log("validateProcess", dataList);

    //console.log("dataList.length", dataList.length);
    const upd_obj = userDataList.map((obj) => {
      if (Object.keys(obj).length != 7) {
        // console.log("Error length==>",Object.keys(obj).length);

        // console.log("Error length==>", obj);
        // console.log("end");

        return false;
      }

      const ValidUserEmail = validateUserEmail(obj.UserEmail);
      if (ValidUserEmail["res"] == false) {
        console.log("Error ValidUserEmail ==>", obj.UserEmail);
        return false;
      }

      const ValidUserType = validateUserType(obj.UserType);
      // console.log("ValidUserType ==>", ValidUserType);
      if (ValidUserType == false) {
        console.log("Error UserType ==>", obj.UserType);
        return false;
      }
      // console.log("obj.AllLocations ==>",obj.AllLocations)
      const AllLocationsValidate = validateAllLocations2(obj);
      // console.log("validate AllLocations ==>",AllLocationsValidate)
      if (AllLocationsValidate["res"] == false) {
        // console.log("Error AllLocations ==>", obj.AllLocations);
        return false;
      }

      if (obj.UserRole == null || obj.UserRole == "") {
        console.log("Error ValidLocationID ==>", obj.UserRole);
        return false;
      }

      if (obj.CustomerLocationID == null || obj.CustomerLocationID == "") {
        console.log("Error ValidLocationID ==>", obj.CustomerLocationID);
        return false;
      }

      if (obj.Active != "Y" && obj.Active != "N") {
        console.log("Error Active ==>", obj.Active);
        return false;
      }

      return true;
    });
    // return upd_obj;
    // check each value True
    // console.log("fsfd ", upd_obj);
    const res = upd_obj.every((n) => {
      return n == true;
    });

    console.log("res ==>", res);
    return res;
  };

  const Demo_weclcome_email_api = () => {
    //console.log("Topic_Location_api");
    Demo_weclcome_email({
      customerid: customerid,
    })
      .then((res) => {
        if (res.data.code === 200) {
          // message.success("Updated Successfully");
          // setUsersLocations(res.data.result);
          // setRun1("Assign Users to Locations");

          console.log(" Dummy result ==>", res.data.result);
        } else if (res.data.code === 300) {
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //  Assigned user to
  const Assign_Users_Locations_api = () => {
    //console.log("Topic_Location_api");
    Assign_Users_Locations({
      customerid: customerid,
      Custgroupid: reportgroup,
      Groupid: groupid,
    })
      .then((res) => {
        if (res.data.code === 200) {
          // message.success("Updated Successfully");
          setUsersLocations(res.data.result);
          setRun1("Assign Users to Locations");

          //console.log(" Dummy result ==>", res.data.result);
        } else if (res.data.code === 300) {
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const Get_custloc_combinations_api = () => {

    Get_custloc_combinations({
      customerid: customerid,
      Custgroupid: reportgroup,
      Groupid: groupid,
    })
      .then((res) => {
        if (res.data.code === 200) {
          // message.success("Updated Successfully");
          setUsersLocationsList(res.data.result);
          // setRun1("Assign Users to Locations");

          //console.log(" Dummy result ==>", res.data.result);
        } else if (res.data.code === 300) {
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="container" style={{ overflow: "none" }}>
        {run1 == "" ? (
          <>
            <div className="text-center">
              <a>
                <h4 style={{ paddingTop: "2%" }}>Customer Batching</h4>
              </a>
            </div>

            <div className="text-center">
              <StyledButton
                onClick={() => {
                  setRun1("Add Locations");
                }}
              >
                Add Locations
              </StyledButton>
            </div>

            <div className="text-center">
              <StyledButton
                onClick={() => {
                  // setRun1("Add Topics to Locations");
                  Topic_Location_api();
                }}
              >
                Add Topics to Locations
              </StyledButton>
            </div>

            <div className="text-center">
              <StyledButton
                onClick={() => {
                  setRun1("Add Users");
                }}
              >
                Add Users
              </StyledButton>
            </div>

            <div className="text-center">
              <StyledButton
                onClick={() => {
                  Get_custloc_combinations_api();
                  Assign_Users_Locations_api();
                }}
              >
                Assign Users to Locations
              </StyledButton>
            </div>

            <div className="text-center">
              <StyledButton
                onClick={() => {
                  Location_api();

                  //console.log("add");
                  // setRun1("Assign Permissions to Topic-Locations");

                }}
              >
                Assign Permissions to Topic-Locations
              </StyledButton>
            </div>

            {/* <div className="text-center">
              <StyledButton onClick={() => {
                Demo_weclcome_email_api();
              }}>
               Welcome Email
              </StyledButton>
            </div> */}

            <div className="text-center">
              <StyledButton
                onClick={() => {
                  props.parentCallback("");
                }}
              >
                Exit
              </StyledButton>
            </div>
          </>
        ) : run1 == "Add Locations" ? (
          <>
            <div className=" mt-3">
              <div className="text-center">
                <a>
                  <h4 style={{ paddingTop: "2%" }}>Add Locations</h4>
                </a>
              </div>

              <div className="row text-center">
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  {/* <Tooltip title={Delete}> */}
                  <StyledButton1
                    onClick={() => {
                      // setDataList([]);
                      setRun1("");
                      setDataList([]);
                      setDataTable(false);
                    }}
                  >
                    Return To Batching Menu
                  </StyledButton1>
                  {/* </Tooltip> */}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <StyledButton1
                    onClick={() => {
                      window.open(
                        `https://test.guvrix.com/images/Sample%20Spreadsheet%20Locations.xlsx`,
                        "_blank"
                      );
                    }}
                  >
                    Sample Spreadsheet
                  </StyledButton1>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <StyledButton1
                    onClick={() => {
                      ProcessValue();
                    }}
                  >
                    Process
                  </StyledButton1>
                </div>
              </div>

              <div style={{ marginTop: 50 }} className="text-center">
                <label style={{ marginLeft: 60, fontSize: "16px" }}>
                  <b> Upload Locations : </b>
                </label>
                <input
                  style={{ marginLeft: 60 }}
                  type="file"
                  onChange={readUploadFile}
                  name="upload"
                />
              </div>
            </div>
            {DataTable == true ? (
              <>
                <div
                  style={{ height: 400, width: "100%", marginTop: 15 }}
                  className={classes.root}
                >
                  {renderConfirmDialog()}
                  <DataGrid
                    sx={{
                      ".MuiDataGrid-columnHeaders": {
                        backgroundColor: "#ff8c24",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                    }}
                    rows={rows}
                    columns={columns}
                    // initialState={{

                    //   pagination: {
                    //     pageSize: 10,
                    //   },
                    // }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    pagination
                    processRowUpdate={processRowUpdate}
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                  {!!snackbar && (
                    <Snackbar
                      open
                      onClose={handleCloseSnackbar}
                      autoHideDuration={6000}
                    >
                      <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : run1 == "Add Topics to Locations" ? (
          <>
            <div className=" mt-3">
              <div className="text-center">
                <a>
                  <h4 style={{ paddingTop: "2%" }}>Add Topics to Locations</h4>
                </a>
              </div>
              <div className="row text-center">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <StyledButton1
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      setReportgroup("All");
                      setRun1("");
                    }}
                   
                  >
                    Cancel
                  </StyledButton1>
                </div>



                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">


<div class="btn-group drop " role="group">
  <select
    style={{ borderColor: "#ff8c24" }}
    type="text"
    defaultValue={groupid}
    withAll={true}
    multi={true}
   value={reportgroup}
    onClick={() => {
      getReportlocation();
      console.log("location");
      console.log("group", descGroup);
      console.log(reportgroup, "Reportgroup")
      
    }}
    
    // onClick={(e) => {
    //   getReportlocation();

    //  }}
     onChange={(e) => {

                      
      setSelectedOptions("true");
      setReportgroup(e.target.value);
      console.log("value", e.target.value);
    }}
  >
    <option value={descGroup.custgroupid}>All</option>
    {descGroup?.map((data, i) => {

      //   console.log(country)
      return (
        <option value={data.custgroupid}>
          {data.custgroupdesc?.substring(0, 26)}
        </option>
      );
    })}
  
  </select>
  <button class="btn btn-default ant-btn-default1" type="button" tabindex="-1"
    onClick={() => {
      Topic_Location_api();
     // getgrouplocation();
      console.log("Add Locations");
    }}
  >Run</button>

</div>

</div>




                   <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <StyledButton1
                    onClick={() => {
                      // console.log("process");
                      Add_Topic_Location();
                      // console.log("process",TopLocations);
                    }}
                  >
                    Process Records
                  </StyledButton1>
                </div>
              </div>

              <div
                style={{ height: 400, width: "100%", marginTop: 25 }}
                className={classes.root}
              >
                {renderConfirmDialog1()}
                <DataGrid
                  sx={{
                    ".MuiDataGrid-columnHeaders": {
                      backgroundColor: "#ff8c24", // ${theme}
                    },
                    "&.MuiDataGrid-root": {
                      border: "none",
                    },
                  }}
                  rows={rows1}
                  columns={columns1}
                  // initialState={{

                  //   pagination: {
                  //     pageSize: 10,
                  //   },
                  // }}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  checkboxSelection
                  processRowUpdate={processRowUpdate1}
                  onSelectionModelChange={(newSelection) => {
                    const result = processRowSelection(
                      TopLocations,
                      newSelection
                    );
                    setSelection(result);
                    //console.log("result", result);
                  }}
                  experimentalFeatures={{ newEditingApi: true }}
                />
                {!!snackbar1 && (
                  <Snackbar
                    open
                    onClose={handleCloseSnackbar1}
                    autoHideDuration={6000}
                  >
                    <Alert {...snackbar1} onClose={handleCloseSnackbar1} />
                  </Snackbar>
                )}
              </div>
            </div>
          </>
        ) : run1 == "Add Users" ? (
          <>
            <div className=" mt-3">
              <div className="text-center">
                <a>
                  <h4 style={{ paddingTop: "2%" }}>Add Users</h4>
                </a>
              </div>

              <div className="row text-center">
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <StyledButton1
                    onClick={() => {

                      setUsersDataTable(false);
                      setUserDataList([]);
                      setRun1("");


                    }}
                  >
                    Return To Batching Menu
                  </StyledButton1>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <StyledButton1
                    onClick={() => {
                      window.open(
                        `https://test.guvrix.com/images/Sample%20Spreadsheet%20Users.xlsx`,
                        "_blank"
                      );
                    }}
                  >
                    Sample Spreadsheet
                  </StyledButton1>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <StyledButton1
                    onClick={() => {
                      ProcessUsersValue();
                    }}
                  >
                    Process
                  </StyledButton1>
                </div>
              </div>

              <div style={{ marginTop: 50 }} className="text-center">
                <label style={{ marginLeft: 60, fontSize: "16px" }}>
                  <b> Upload Users : </b>
                </label>
                <input
                  style={{ marginLeft: 60 }}
                  type="file"
                  onChange={readUploadFile_Users}
                  name="upload"
                />
              </div>

              {UsersDataTable == true ? (
                <>
                  <p>UsersDataTable </p>
                  <div
                    style={{ height: 400, width: "100%", marginTop: 15 }}
                    className={classes.root}
                  >
                    {renderConfirmDialog2()}
                    <DataGrid
                      sx={{
                        ".MuiDataGrid-columnHeaders": {
                          backgroundColor: "#ff8c24", // ${theme}
                        },
                        "&.MuiDataGrid-root": {
                          border: "none",
                        },
                      }}
                      rows={rowsUsers}
                      columns={columnsUsers}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      processRowUpdate={processRowUpdate2}
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ) : run1 == "Assign Users to Locations" ? (
          <>
            <AssignUsers_Locations
              data={UsersLocations}
              list={UsersLocationsList}
              parentCallback2={handleCallback}
            />
          </>
        ) : run1 == "Assign Permissions to Topic-Locations" ? (
          <>
            <div className=" mt-3">
              <div className="text-center">
                <a>
                  <h4 style={{ paddingTop: "2%" }}>Assign Permissions to Topic-Locations</h4>
                </a>
              </div>
              <div className="row text-center">
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <StyledButton1
                    onClick={() => {
                      setReportgroup("All");
                      setRun1("");
                    }}
                  >
                    Return To Batching Menu
                  </StyledButton1>
                </div>


                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">


<div class="btn-group drop " role="group">
  <select
    style={{ borderColor: "#ff8c24" }}
    type="text"
    defaultValue={groupid}
    withAll={true}
    multi={true}
   value={reportgroup}
    onClick={() => {
      getReportlocation();
      console.log("location");
      console.log("group", descGroup);
      console.log(reportgroup, "Reportgroup")
      
    }}
    
    // onClick={(e) => {
    //   getReportlocation();

    //  }}
     onChange={(e) => {

                      
      setSelectedOptions("true");
      setReportgroup(e.target.value);
      console.log("value", e.target.value);
    }}
  >
    <option value={descGroup.custgroupid}>All</option>
    {descGroup?.map((data, i) => {

      //   console.log(country)
      return (
        <option value={data.custgroupid}>
          {data.custgroupdesc?.substring(0, 26)}
        </option>
      );
    })}
  
  </select>
  <button class="btn btn-default ant-btn-default1" type="button" tabindex="-1"
    onClick={() => {
      Location_api();
     // getgrouplocation();
      console.log("Add Locations");
    }}
  >Run</button>

</div>

</div>


                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <StyledButton1
                    onClick={() => {
                      if (selection1.length > 0) {
                        Topic_User_Location_api();
                      } else {
                        message.error("Not Selected Location");
                      }
                      console.log("selection1", selection1)
                      console.log("selection1", selection1.length)
                    }}
                  >
                    Next
                  </StyledButton1>
                </div>
              </div>
              <div
                style={{ height: 400, width: "100%", marginTop: 25 }}
                className={classes.root}
              >

                <DataGrid
                  sx={{
                    '.MuiDataGrid-columnHeaders': {
                      backgroundColor: "#ff8c24", // ${theme}
                    },
                    '&.MuiDataGrid-root': {
                      border: 'none',
                    },
                  }}
                  getRowId={(r) => r.locationid}
                  rows={location}
                  columns={locationcolumns}
                  id="_id"
                  //getRowId={(row) => row.no}
                  //getRowId ={(row) => ({id:statId})}
                  //getRowId={(row: any) =>  row.locationdesc + row.countrycode}
                  // initialState={{

                  //   pagination: {
                  //     pageSize: 10,
                  //   },
                  // }}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  checkboxSelection

                  processRowUpdate={processRowUpdate1}


                  onSelectionModelChange={(newSelection) => {

                    const result1 = processRowSelection1(location, newSelection);
                    console.log("location", location)
                    setSelection1(result1);
                    console.log("result1", result1)
                  }}

                  experimentalFeatures={{ newEditingApi: true }}
                />
                {!!snackbar1 && (
                  <Snackbar
                    open
                    onClose={handleCloseSnackbar1}
                    autoHideDuration={6000}
                  >
                    <Alert {...snackbar1} onClose={handleCloseSnackbar1} />
                  </Snackbar>
                )}
              </div>
            </div>
          </>
        ) : run1 == "topicuser" ? (
          <div className=" mt-3">
            <div className="text-center">
              <a>
                <h4 style={{ paddingTop: "2%" }}>Assign Permissions to Topic-Locations</h4>
              </a>
            </div>
            <div className="row text-center">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <StyledButton1
                  style={{ backgroundColor: "red" }}
                  onClick={() => {
                    setRun1("");
                    console.log("topic")
                    setSelection1([]);
                    setSelection([]);
                  }}
                >
                  Cancel
                </StyledButton1>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <StyledButton1
                  onClick={() => {
                    console.log("topic")
                    //Add_User_Topic_Location();
                    if (selection.length > 0) {
                      Add_User_Topic_Location();
                    } else {
                      message.error("Not Selected");
                    }
                  }}
                >
                  Process Records
                </StyledButton1>
              </div>

            </div>
            <div>
              {renderConfirmDialog3()}
              <DataGrid
                sx={{
                  height: 400, width: "100%", marginTop: 5,
                  '.MuiDataGrid-columnHeaders': {
                    backgroundColor: "#ff8c24", // ${theme}
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none',
                  },
                }}
                getRowId={(r) => r.id}
                rows={topiclocation}
                columns={topiclocationcolumns}
                //id="_id"
                //getRowId={(row) => row.no}
                //getRowId ={(row) => ({id:statId})}
                //getRowId={(row: any) =>  row.locationdesc + row.countrycode}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                pagination
                checkboxSelection

                processRowUpdate={processRowUpdate3}


                onSelectionModelChange={(newSelection) => {

                  const result1 = processRowSelection(topiclocation, newSelection);
                  console.log("location", topiclocation)
                  setSelection(result1);
                  console.log("result1", result1)
                }}

                experimentalFeatures={{ newEditingApi: true }}
              />

              {!!snackbar3 && (
                <Snackbar
                  open
                  onClose={handleCloseSnackbar1}
                  autoHideDuration={6000}
                >
                  <Alert {...snackbar3} onClose={handleCloseSnackbar1} />
                </Snackbar>
              )}
            </div>
          </div>


        ) : (
          ""
        )}
      </div>

    </>
  );
};
export default CuzbatchMenu;
