import React, { useEffect, useRef, useState } from "react";
import MainHeader from "../../components/MainHeader";
import { Link, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import "../../App.css";
import axios from "axios";
import { BASE_URL } from "../../url/baseurl";
import { QuestionTableInputAPI } from "../../services/QuestionTableInputAPI";
import { user_session_data_client } from "../../services/ClientProgramApi";
import {
  user_session_data_admin_api,
  feature_program_api,
  getAdminPDF_Api,
  getClientPDF_Api,
  getPDF,
} from "../../services/AdminProgramApi";
import {getupdate_details_api} from "../../services/NewUI_API";
import * as Database from "../../services/Database";
import { DownloadOutlined } from "@ant-design/icons";
import ReactModal from "react-modal";
import Utils from "../../Utils";

import { message } from "antd";

const theme = localStorage.getItem('brandColor');

const StyledTable = styled.table`
  width: 100%;
`;

const StyledButton = styled.button`
  background: ${theme};
  width: 330px;
  border-radius: 30px;
  height: 55px;
  text-align: center;
  color: white;
  font-size: 20px !important;
  font-weight: 500;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;
const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;
const StyledTableRow = styled.tr`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding-left: 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ModalStyle = {
  content: {
    width: "500px",
    // height: "300px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    justifyItem: "center",

    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
  },
};

const BigModalStyle = {
  content: {
    width: "500px",
    height: "400px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    justifyItem: "center",

    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
  },
};

const BigModalStyle1 = {
  content: {
    width: "600px",
    height: "550px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    justifyItem: "center",

    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    // boxShadow:"0 .5rem 1rem rgba(0,0,0,.15)"
  },
};

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledStatus = styled.td`
  color: green;
`;

const StyledInputDiv = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 100%;
  min-width: 500px;
  height: auto;
  justify-self: center;
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 50px;

  margin-top: 50px;
`;

const StyledDiv1 = styled.div`
  margin: 13.5px;
`;
const StyledInput = styled.input`
  border: 1px solid #e4e4e4;
  border-top: none;
  border-left: none;
  border-right: none;

  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: #afaaaa;

  &::active {
    border: 1px solid #e4e4e4;
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;

const HorizontalDivider = {
  width: "36px",
  height: "0px",
  marginTop: "2%",
  border: "0.5px solid #C5C1C1",
  transform: "rotate(90deg)",
};

const ExtQuestion = ({ match }) => {
  console.log(match);
  const history = useHistory();

  const [userid, setUserid] = useState(localStorage.getItem("UserID"));
  const [userData, setUserData] = useState("");
  const [userList, setUserList] = useState("");
  const [cust_id, setCust_id] = useState("");

  const [list, setList] = useState([]);
  const [isAddQuestion, setIsAddQuestion] = useState(false);
  const entertedqcoderef = useRef();
  const entertedquestionref = useRef();
  const entertedquestionseqref = useRef();
  const entertedifparentyesref = useRef(0);
  const entertedifparentnoref = useRef(0);
  const entertedpriorityidref = useRef();
  const entertedguidanceidref = useRef();
  const entertedsolutionifref = useRef();
  const entertedsolutiondescref = useRef();
  const entertedactiveqref = useRef();
  const entertedreportingref = useRef();
  const [priority, set_priotity] = useState([]);
  const [guidance, set_guidance] = useState([]);
  const [category, set_categorylist] = useState([]);
  const [qcode, setQcode] = useState("");
  const [sugg_priv, setSugg_priv] = useState("");
  const [qseq, setQseq] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [topicSearch, setTopicSearch] = useState("");
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data2_sugg, setData2_sugg] = useState([]);
  const [data2_sugg1, setData2_sugg1] = useState([]);
  const [data_new, setData_new] = useState([]);
  const [warning, set_warning] = useState({});
  const [new_qcode, set_new_qcode] = useState(0);
  const [quiz_msg, setQuiz_msg] = useState("");
  const [sugg_msg, setSugg_msg] = useState("");
  //   ----------------------------------------------edit topic api call---------------------------------------
  const [data1, setData1] = useState([]);
  const [userdata, setUserdata] = useState([]);
  const [called_func_name, setCalled_func_name] = useState("");
  const [view_customer_modal, setView_customer_modal] = useState(false);
  const [client_function_modal, setClient_function_modal] = useState(false);
  const [admin_function_modal, setAdmin_function_modal] = useState(false);

  const [edit_modal, setEdit_modal] = useState(false);

  const [edit_topic_modal, setEdit_topic_modal] = useState(false);
  const [sugg_modal, setSugg_modal] = useState(false);
  const [delete_quiz, setDelete_quiz] = useState("");

  const [take_quiz, setTake_quiz] = useState("");
  const [cont_quiz, setCont_quiz] = useState("");
  const [cuztopic, setCuztopic] = useState("");
  const [topicid, setTopicid] = useState();
  const [periodcode, setPeriodcode] = useState();
  const [locationid, setLocationid] = useState();
  const [topiccodes, setTopiccodes] = useState();
  // --------- filter hooks-------=-=-=-=-=-=-=-=-=-=-==

  const [topic_id, setTopic_id] = useState("");
  const [cuz_desc, setCuz_desc] = useState("");
  const [filter_loc, setFilter_loc] = useState("");
  const [filter_country, setFilter_country] = useState("");
  const [filter_period, setFilter_period] = useState("");
  const [filter_status, setFilter_status] = useState("");

  // --------- filter hooks for sugg-------=-=-=-=-=-=-=-=-=-=-==

  const [topic_idsugg, setTopic_idsugg] = useState("");
  const [cuz_descsugg, setCuz_descsugg] = useState("");
  const [filter_locsugg, setFilter_locsugg] = useState("");
  const [filter_countrysugg, setFilter_countrysugg] = useState("");
  const [filter_loc_dessugg, setFilter_loc_dessugg] = useState("");
  const [filter_suggprivilege, setFilter_suggprivilege] = useState("");

  // ------Message ------------------------------------------

  const [showmsg, setShowmsg] = useState(false);
  const [totaldays, setTotaldays] = useState("");

  // -----filter func------------------------------------------

  const [twoFa, setTwoFa] = useState(false);
  const [updated, setUpdated] = useState("");
  const [switchState, setSwitchState] = useState();


  const filter = () => {
    let filter_data = [];

    console.log(data2);

    if (topic_id !== "") {
      filter_data = [
        ...filter_data,
        ...data2.filter((item) =>
          String(item.topicid).toLowerCase().includes(topic_id?.toLowerCase())
        ),
      ];
    }

    if (cuz_desc !== "") {
      filter_data = [
        ...filter_data,
        ...data2.filter((item) =>
          String(item.cuzdesc).toLowerCase().includes(cuz_desc?.toLowerCase())
        ),
      ];
    }

    if (filter_loc !== "") {
      filter_data = [
        ...filter_data,
        ...data2.filter((item) =>
          String(item.locationdesc)
            .toLowerCase()
            .includes(filter_loc?.toLowerCase())
        ),
      ];
    }

    if (filter_country !== "") {
      filter_data = [
        ...filter_data,
        ...data2.filter((item) =>
          String(item.countrycode)
            .toLowerCase()
            .includes(filter_country?.toLowerCase())
        ),
      ];
    }

    if (filter_period !== "") {
      filter_data = [
        ...filter_data,
        ...data2.filter((item) =>
          String(item.perioddesc)
            .toLowerCase()
            .includes(filter_period?.toLowerCase())
        ),
      ];
    }

    if (filter_status !== "") {
      filter_data = [
        ...filter_data,
        ...data2.filter((item) =>
          String(item.laststatus)
            .toLowerCase()
            .includes(filter_status?.toLowerCase())
        ),
      ];
    }

    console.log("Filter data", filter_data);
    setData2(filter_data.length !== 0 ? filter_data : data2);
  };

  useEffect(() => {
    searchSubmit();
  }, [statusSearch, topicSearch]);

  const [pdfLinkA, setPdfLinkA] = useState("");
  const getAdminPDF = async () => {
    getPDF('Admin').then((res) => {
      setPdfLinkA(res.data.new_record[0].pdf_link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [pdfLinkC, setPdfLinkC] = useState("");
  const getClientPDF = async () => {
    getPDF('Client').then((res) => {
      setPdfLinkC(res.data.new_record[0].pdf_link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [paidUserPDF, setPaidUserPDF] = useState("");

  const getPaidUserPDF = async () => {
    getPDF('Paid_User').then((res) => {
      setPaidUserPDF(res.data.new_record[0].pdf_link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAdminPDF();
    getClientPDF();
    getPaidUserPDF();
    getupdate_details();
  }, []);

  const getUserSessionData = async () => {
    user_session_data_client(userid)
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data);

          setUserData(res.data.userdata);
          setUserList(res.data.list);
          setCust_id(res.data.userdata[0]?.customerid);
          console.log("User Data ->", userData);
          console.log("List ->", userList);
          console.log("Customer ID ->", cust_id);

          // if (!cust_id) setCust_id((userData?.customerid))
        }
      })
      .catch((e) => {
        console.log(e);

        message.error("something went wrong");
      });
  };

  const getupdate_details =  async () => {
    getupdate_details_api({

      userid: localStorage.getItem("UserID"),
      
      
    })
      .then((res) => {
        if (res.data.code === 200) {
          let update = res.data.userupdate;
          setUpdated(res.data.userupdate);
          console.log(update);
          if(update[0]['twofa'] == 'Y'){
            setSwitchState(true);
          }else{
            setSwitchState(false);
          }
          console.log("2fa",update)
          console.log("2fa1",switchState)
          //setUpdated(res.data.userupdate[0]['twofa']);
          console.log("update", res.data.userupdate[0]['twofa'])
          console.log(res);

        } else {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (client_function_modal) getUserSessionData();
  }, [client_function_modal]);

  useEffect(() => {
    if (match?.params?.back) setClient_function_modal(match?.params?.back);
  }, []);

  const Edit_topic_data = async () => {
    let userid = localStorage.getItem("UserID");
    let res = axios
      .get(`${BASE_URL}/api/user_session_data?userid=${userid}`, {
        userid: localStorage.getItem("UserID"),
      })
      .then((res) => {
        if (res.data.code == 200) {
          setList(res.data.list);
          localStorage.setItem("customerid", res.data.userdata[0]?.customerid);
          localStorage.setItem("useremail", res.data.userdata[0]?.useremail);
          let P_data = {
            list: res.data.list,
            userdata: res.data.userdata,
            customerid: res.data.userdata[0].customerid,
            useremail: res.data.userdata[0].useremail,
            userid: userid,
          };
          axios
            .post(`${BASE_URL}/api/edit_topic_quiz`, P_data)
            .then((res) => {
              setData2(res.data.topic_information);
              console.log(
                "dataddatadtdatdatdatdtadda",
                res.data.topic_information
              );
              setData_new(res.data.topic_information);
            })
            .catch((e) => {
              console.log(e);
            });
        }
        setUserdata(res.data.userdata);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const get_active_user = async () => {
    let userid = localStorage.getItem("UserID");
    let res = axios
      .get(`${BASE_URL}/api/user_status_data?userid=${userid}`, {
        userid: localStorage.getItem("UserID"),
      })
      .then((res) => {
        if (res.data.code == 200) { 
          setShowmsg(true);
          setTotaldays(res.data.total)
          console.log(res.data.total);
            }
        // setUserdata(res.data.userdata);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const called_func_data = async () => {
    let userid = localStorage.getItem("UserID");
    const cust_id = userdata && userdata[0]?.customerid;
    const user_email = userdata && userdata[0]?.useremail;

    let data = {
      customerid: userdata && userdata[0]?.customerid,
      useremail: userdata && userdata[0]?.useremail,
      userid: localStorage.getItem("UserID"),
    };

    if (called_func_name === "edit_topic") {
      let P_data = {
        list: list,
        userdata: userdata,
        customerid: userdata && userdata[0]?.customerid,
        useremail: userdata && userdata[0]?.useremail,
        userid: localStorage.getItem("UserID"),
      };
      await axios
        .post(`${BASE_URL}/api/edit_topic_quiz`, P_data)
        .then((res) => {
          setData2(res.data.topic_information);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (called_func_name === "suggestion_report") {
      let P_data1 = {
        list: list,
        userdata: userdata,
        customerid: userdata && userdata[0]?.customerid,
        useremail: userdata && userdata[0]?.useremail,
        userid: localStorage.getItem("UserID"),
      };
      await axios
        .post(`${BASE_URL}/api/suggestreport`, P_data1)
        .then((res) => {
          console.log(
            "suggestion_report data ===> ",
            res.data.suggestion_information
          );
          setData2_sugg(res.data.suggestion_information);
          setData2_sugg1(res.data.suggestion_information);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    Edit_topic_data();
    get_active_user();
  }, []);

  useEffect(() => {
    called_func_data();
  }, [called_func_name]);

  const edit_topic = () => {
    let userid = localStorage.getItem("UserID");
    console.log("Edit topic");
    let res = axios
      .get(`${BASE_URL}/api/user_session_data?userid=${userid}`, {
        userid: localStorage.getItem("UserID"),
      })
      .then((res) => {
        //  console.log("user session data===>", res);
        setData1(res.data.Topicuser);
        console.log("userdata ==>", res.data);
        setCalled_func_name("edit_topic");
        // console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const searchSubmit = (e) => {
    let filter_data = [];

    console.log(data);

    if (statusSearch !== "") {
      filter_data = [
        ...filter_data,
        ...data1.filter((item) =>
          String(item.laststatus)
            .toLowerCase()
            .includes(statusSearch?.toLowerCase())
        ),
      ];
    } else if (topicSearch !== "") {
      filter_data = [
        ...filter_data,
        ...data1.filter((item) =>
          String(item.cuzdesc)
            .toLowerCase()
            .includes(topicSearch?.toLowerCase())
        ),
      ];
    } else {
      Edit_topic_data();
    }

    //console.log("Filter data", filter_data);
    setData2(filter_data.length !== 0 ? filter_data : data2);
  };

  const suggestion_report = () => {
    let userid = localStorage.getItem("UserID");
    let res = axios
      .get(`${BASE_URL}/api/user_session_data?userid=${userid}`, {
        userid: localStorage.getItem("UserID"),
      })
      .then((res) => {
        //  console.log("user session data===>", res);
        setData1(res.data.Suggestuser);
        setCalled_func_name("suggestion_report");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const Adminuser = () => {
    let userid = localStorage.getItem("UserID");
    let res = axios
      .get(`${BASE_URL}/api/user_session_data?userid=${userid}`, {
        userid: localStorage.getItem("UserID"),
      })
      .then((res) => {
        setData1(res.data.Adminuser);
        setCalled_func_name("Adminuser");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const Clientuser = () => {
    let userid = localStorage.getItem("UserID");
    let res = axios
      .get(`${BASE_URL}/api/user_session_data?userid=${userid}`, {
        userid: localStorage.getItem("UserID"),
      })
      .then((res) => {
        //console.log("user session data===>", res);
        setData1(res.data.Clientuser);
        setCalled_func_name("Clientuser");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const Reportuser = () => {
    let userid = localStorage.getItem("UserID");
    let res = axios
      .get(`${BASE_URL}/api/user_session_data?userid=${userid}`, {
        userid: localStorage.getItem("UserID"),
      })
      .then((res) => {
        // console.log("user session data===>", res);
        setData1(res.data.Reportuser);
        setCalled_func_name("Reportuser");
        setEdit_modal(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // -----------------------------------------------------------------------------------------------------------------
  const params = useParams();

  const QuestionTableInputAPIs = async () => {
    alert("Work is on progress !");
  };

  async function get_all() {
    Database.priority_list().then((res) => {
      set_priotity(res.data["Priority List"]);
      // console.log(res.data["Priority List"]);
    });
    Database.ReportingCategoryList({ topicid: params.id }).then((res) => {
      set_categorylist(res.data["Reporting Category List"]);
      // console.log(res.data["Reporting Category List"]);
    });
    Database.guidance_list({ topicid: params.id }).then((res) => {
      set_guidance(res.data["guidance_list"]);
      // console.log("guidance id data ===> ", res);
      // console.log(res.data["guidance_list"]);
    });
  }

  // user_session_data_admin api func call
  const [feature_prog_admin_func_data, setFeature_prog_admin_func_data] =
    useState([]);
  const [admn_custId, setAdmn_custId] = useState();

  const user_session_data_admin_func = async () => {
    user_session_data_admin_api(localStorage.getItem("UserID"))
      .then((res) => {
        if (res.data.code == 200) {
          console.log("user data admin res===>", res.data);
          localStorage.setItem("UTData", JSON.stringify(res.data.userdata));
          setAdmn_custId(res.data.userdata[0]?.customerid);

          let data = {
            customerid: res.data.userdata[0]?.customerid,
            userdata: res.data.userdata,
            userid: localStorage.getItem("UserID"),
          };

          feature_program_api(data)
            .then((res) => {
              if (res.data.code == 200) {
                console.log("feature res data==>", res.data);
                setFeature_prog_admin_func_data(res.data.admfunction);
              } else {
                message.error(res);
              }
            })
            .catch((err) => {
              console.log("error--->>", err);
            });
        } else {
          message.error(res);
        }
      })
      .catch((err) => {
        console.log("error===>", err);
      });
  };

  return (
    <div className="container" style={{ overflow: "none", paddingTop: 30 }}>
      <div className="text-center">
        <a
          onClick={() => {
            console.log("pdfLinkA ==>",pdfLinkA);
            window.open(
              `${BASE_URL}${JSON.parse(
                paidUserPDF       //paidUserPDF
              )}`,
              "_blank"
            );
          }}
        >
          <i
            style={{ color: theme, fontSize: "25px" }}
            class="fas fa-info-circle"
          ></i>
        </a>
      </div>
      {list &&
        list?.map((data, index) => {
          return (
            <div align="center">
              {list[index] && list[index]?.listvalue == "Client Functions" ? (
                <div>
                  <StyledButton
                    onClick={() => {
                      setClient_function_modal(true);
                    }}
                  >
                    Client Functions
                  </StyledButton>

                  {/* <-----Client Function Modal-----> */}

                  <ReactModal
                    style={BigModalStyle}
                    isOpen={client_function_modal}
                  >
                    <div className="mt-4" align="center">
                      <div className="text-center">
                        <a
                          onClick={() => {
                            window.open(
                              `${BASE_URL}${JSON.parse(
                                pdfLinkC
                              )}`,
                              "_blank"
                            );
                          }}
                        >
                          <i
                            style={{ color: theme, fontSize: "25px" }}
                            class="fas fa-info-circle"
                          ></i>
                        </a>
                      </div>
                      <div>
                        <StyledButton1
                          onClick={() => {
                            let selection = "enrollclient";
                            history.push(
                              `/clientfunction/en/${selection}/${cust_id}`
                            );
                          }}
                        >
                          Enroll Client{" "}
                        </StyledButton1>
                      </div>
                      <div>
                        <StyledButton1
                          onClick={() => {
                            let selection = "manageclient";
                            history.push(
                              `/clientfunction/mn/${selection}/${cust_id}`
                            );
                          }}
                        >
                          Manage Client{" "}
                        </StyledButton1>
                      </div>
                      <div>
                        <StyledButton1
                          onClick={() => {
                            let selection = "topicassignment";
                            history.push(
                              `/clientfunction/ta/${selection}/${cust_id}`
                            );
                          }}
                        >
                          Topic Assignment{" "}
                        </StyledButton1>
                      </div>
                      <div>
                        <StyledButton1
                          onClick={() => {
                            let selection = "clienttopiccategories";
                            history.push(
                              `/clientfunction/ctc/${selection}/${cust_id}`
                            );
                          }}
                        >
                          Client Topic Categories
                        </StyledButton1>
                      </div>
                      <div>
                        <StyledButton1
                          onClick={() => {
                            setClient_function_modal(false);
                          }}
                        >
                          Back
                        </StyledButton1>
                      </div>
                    </div>
                  </ReactModal>
                </div>
              ) : (
                ""
              )}

              {list[index] &&
              list[index]?.listvalue == "Edit a Topic Questionnaire" ? (
                <div>
                  <StyledButton
                    onClick={() => {
                      history.push("/edittopic");
                    }}
                  >
                    Topic Questionnaire
                  </StyledButton>
                </div>
              ) : (
                ""
              )}

              {list[index] && list[index]?.listvalue == "Suggestion Report" ? (
                <div>
                  <StyledButton
                    onClick={() => {
                      history.push("/suggestion_report");
                    }}
                  >
                    Suggestion Report
                  </StyledButton>
                </div>
              ) : (
                ""
              )}

              {list[index] && list[index]?.listvalue == "Admin Functions" ? (
                <div className="">
                  <div>
                    <StyledButton
                      onClick={() => {
                        setAdmin_function_modal(true);
                        user_session_data_admin_func();
                      }}
                    >
                      Admin Functions
                    </StyledButton>
                  </div>
                  {/* admin func modal================================ */}

                  <ReactModal
                    style={BigModalStyle1}
                    isOpen={admin_function_modal}
                  >
                    <div className="mt-2" align="center">
                      <div className="text-center">
                        <a
                          onClick={() => {
                            window.open(
                              `${BASE_URL}${JSON.parse(
                                pdfLinkA
                              )}`,
                              "_blank"
                            );
                          }}
                        >
                          <i
                            style={{ color: theme, fontSize: "25px" }}
                            class="fas fa-info-circle"
                          ></i>
                        </a>
                      </div>

                      {feature_prog_admin_func_data
                        ?.filter(
                          (data) => data.admfunction == "Edit Customer Data"
                        )
                        .map((data) => {
                          if (data.admfunction == "Edit Customer Data") {
                            return (
                              <div>
                                <StyledButton1
                                  onClick={() => {
                                    history.push(
                                      `/adminfunction/customer_data/${admn_custId}`
                                    );
                                  }}
                                >
                                  Customer Data
                                </StyledButton1>
                              </div>
                            );
                          }
                        })}
                      {feature_prog_admin_func_data
                        ?.filter(
                          (data) => data.admfunction == "Edit Location List"
                        )
                        .map((data) => {
                          if (data.admfunction == "Edit Location List") {
                            return (
                              <div>
                                <StyledButton1
                                  onClick={() => {
                                    history.push(
                                      `/adminfunction/location-list/${admn_custId}`
                                    );
                                  }}
                                >
                                  Locations
                                </StyledButton1>
                              </div>
                            );
                          }
                        })}
                      {feature_prog_admin_func_data
                        ?.filter(
                          (data) => data.admfunction == "Edit Admin/User"
                        )
                        .map((data) => {
                          if (data.admfunction == "Edit Admin/User") {
                            return (
                              <div>
                                <StyledButton1
                                  onClick={() => {
                                    history.push(
                                      `/adminfunction/admin_user/${admn_custId}`
                                    );
                                  }}
                                >
                                  Admins and Users
                                </StyledButton1>
                              </div>
                            );
                          }
                        })}
                      {feature_prog_admin_func_data
                        ?.filter(
                          (data) =>
                            data.admfunction == "Edit Admin/User Locations"
                        )
                        .map((data) => {
                          if (data.admfunction == "Edit Admin/User Locations") {
                            return (
                              <div>
                                <StyledButton1
                                  onClick={() => {
                                    history.push(
                                      `/adminfunction/admin_user_locations/${admn_custId}`
                                    );
                                  }}
                                >
                                  Admin and User Locations
                                </StyledButton1>
                              </div>
                            );
                          }
                        })}
                      {feature_prog_admin_func_data
                        ?.filter(
                          (data) => data.admfunction == "Edit Standard Topics"
                        )
                        .map((data) => {
                          if (data.admfunction == "Edit Standard Topics") {
                            return (
                              <div>
                                <StyledButton1
                                  onClick={() => {
                                    history.push(
                                      `/adminfunction/standard_topics/${admn_custId}`
                                    );
                                  }}
                                >
                                  Standard Topics
                                </StyledButton1>
                              </div>
                            );
                          }
                        })}
                      {feature_prog_admin_func_data
                        ?.filter(
                          (data) => data.admfunction == "Edit Custom Topic Data"
                        )
                        .map((data) => {
                          if (data.admfunction == "Edit Custom Topic Data") {
                            return (
                              <div>
                                <StyledButton1
                                  onClick={() => {
                                    history.push(
                                      `/adminfunction/custom_topic_data/${admn_custId}`
                                    );
                                  }}
                                >
                                  Custom Topics
                                </StyledButton1>
                              </div>
                            );
                          }
                        })}
                      {feature_prog_admin_func_data
                        ?.filter(
                          (data) => data.admfunction == "Edit Topic Locations"
                        )
                        .map((data) => {
                          if (data.admfunction == "Edit Topic Locations") {
                            return (
                              <div>
                                <StyledButton1
                                  onClick={() => {
                                    history.push(
                                      `/adminfunction/topic_location/${admn_custId}`
                                    );
                                  }}
                                >
                                  Topics at Locations
                                </StyledButton1>
                              </div>
                            );
                          }
                        })}
                      {feature_prog_admin_func_data
                        ?.filter(
                          (data) => data.admfunction == "Edit Admin/User"
                        )
                        .map((data) => {})}
                      {feature_prog_admin_func_data
                        ?.filter(
                          (data) =>
                            data.admfunction == "Edit User Topic Permissions"
                        )
                        .map((data) => {
                          if (
                            data.admfunction == "Edit User Topic Permissions"
                          ) {
                            return (
                              <div>
                                <StyledButton1
                                  onClick={() => {
                                    history.push(
                                      `/adminfunction/user_topic_permissions/${admn_custId}`
                                    );
                                  }}
                                >
                                  User Permissions for Topics
                                </StyledButton1>
                              </div>
                            );
                          }
                        })}

                      <div>
                        <StyledButton1
                          onClick={() => {
                            setAdmin_function_modal(false);
                          }}
                        >
                          Back
                        </StyledButton1>
                      </div>
                    </div>
                  </ReactModal>
                </div>
              ) : (
                ""
              )}

              {list[index] &&
              list[index]?.listvalue == "View Customer Reports" ? (
                <div className="">
                  <div>
                    <StyledButton onClick={() => setView_customer_modal(true)}>
                    Performance Reports
                    </StyledButton>
                  </div>
                  <ReactModal style={ModalStyle} isOpen={view_customer_modal}>
                    {/* <div className="mt-5" align="center"> */}
                    <div  align="center">
                      {/* <div>
                        <StyledButton1
                          onClick={() => {
                            history.push("/ecompleted_report");
                          }}
                        >
                          Completed Topics Report
                        </StyledButton1>
                      </div> */}

                      <div>
                        <StyledButton1
                          onClick={() => {
                            history.push("/Topic_History_Cmp_Report");
                          }}
                        >
                          Topic History Comparison Report
                        </StyledButton1>
                      </div>

                      <div>
                        <StyledButton1
                          onClick={() => {
                            history.push("/ecompleted_report", { value: "Completed Topics" });
                          }}
                        >
                          Completed Topics
                        </StyledButton1>
                      </div>

                      <div>
                        <StyledButton1
                          onClick={() => {
                            history.push("/ecompleted_report", { value: "Past Due Topics" });
                          }}
                        >
                          Past Due Topics
                        </StyledButton1>
                      </div>

                      <div>
                        <StyledButton1
                          onClick={() => {
                            history.push("/ecompleted_report", { value: "Assigned Tasks" });
                          }}
                        >
                         Assigned Tasks
                        </StyledButton1>
                      </div>

                      <div>
                        <StyledButton1
                          onClick={() => {
                            history.push("/ecompleted_report", { value: "Outstanding Tasks" });
                          }}
                        >
                          Outstanding Tasks
                        </StyledButton1>
                      </div>

                      <div>
                        <StyledButton1
                          onClick={() => {
                            history.push("/ecompleted_report", { value: "Completed Tasks" });
                          }}
                        >
                          Completed Tasks
                        </StyledButton1>
                      </div>

                      <div>
                        <StyledButton1
                          onClick={() => {
                            setView_customer_modal(false);
                          }}
                        >
                          Back
                        </StyledButton1>
                      </div>
                    </div>
                  </ReactModal>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}

      <div align="center">
        <div>
          <StyledButton
            onClick={() => {
              localStorage.clear();
              window.location.href = "/login";
            }}
          >
            Exit
          </StyledButton>
        </div>
      </div>
      {showmsg == true ?
    (<> 
    <div align="center"   style={{ margin: "26px 0 0", }} >
    <h2 style={{
              color: "red",
              fontSize: 20,
              fontWeight: "bold",              
            }}> You have {totaldays} days to renew your subscription</h2>
    </div>
   </> )
  : (
   ""
 ) }
    </div>
     
   
  

  );

};
export default ExtQuestion;
