import axios from "axios";
import  { BASE_URL} from '../url/baseurl'

export const QuestionTableInputAPI = async (data) => {
    let resposne = axios.post(`${BASE_URL}/api/read_exist_questionario`,data);
    return resposne;
}

export const QuestionDelete = async (data) => {
    let resposne = axios.post(`${BASE_URL}/api/delete_cuttopic`, data);
    return resposne;
}