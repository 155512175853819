import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import ReactModal from "react-modal";
import MainHeader from '../../components/MainHeader'
import { cuz_drive_start } from "../../services/CuzDriveApi";
import { check_status_massage } from "../../services/CuzDriveApi";
import { delete_test } from "../../services/CuzDriveApi";
import { initial_step } from "../../services/CuzDriveApi";

// import * as Dataservice from "../services/Database"
const theme = localStorage.getItem('brandColor');


const StyledLink = styled(Link)`
  color: darkgrey;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  left: 3.68%;
  top: 28.71%;
  width: 92.6%;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledButton = styled.button`
background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  padding: 0px 20px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;
const StyledTableRow = styled.tr`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding-left: 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledStatus = styled.td`
  color: green;
`;

const ModalStyle = {
    content: {
        width: "70%",
        // height: "70%",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
    },
};


const GetStartedCuzDrive = () => {
    const [data, setData] = useState([]);
    const [result, set_result] = useState({})
    const [modal, set_modal] = useState(false)
    const [cust, set_cust] = useState([])
    const [cuz, set_cuz] = useState([])
    const [status_Masaage, set_Status_Message] = useState([])
    const [getTopicid, setGetTopicid] = useState([])


    // list

    const history = useHistory()

    const getTableData = async () => {
        await cuz_drive_start()
            .then((res) => {
                console.log("cuz drive data===>", res)

                setData(res.data.data);
            })

            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        getTableData();
    }, []);

    const show_modal = (result) => {
        set_modal(true);
        setGetTopicid(result.topicid)
        check_status_massage({ topicid: result.topicid })
            .then((res) => {
                console.log("check status mesageddata===>", res)

                set_Status_Message(res.data.status_message)
                // setData(res.data.data);
            })

            .catch((err) => {
                console.log(err);
            });


    }

    // const deleteTest=()=>{
    //     delete_test({topicid:test_delete})
    //     .then((res)=>{
    //         console.log("response delete test==>",res);
    //     })
    //     .catch((err)=>{
    //       console.log(err)
    //     });

    //     initial_step({ topicid: test_delete })
    //         .then((res) => {
    //             console.log("check intial step data===>", res)
    //             // setData(res.data.data);
    //         })

    //         .catch((err) => {
    //             console.log(err);
    //         });

    //     }



    return (
        <>
            <MainHeader />
            <StyledDiv>
                <StyledTable className="table-auto" >
                    <thead>
                        <StyledTableHeader >
                            <StyledTableHeaderData>S.No</StyledTableHeaderData>
                            <StyledTableHeaderData>Custom Topic Title</StyledTableHeaderData>
                            <StyledTableHeaderData>Status</StyledTableHeaderData>
                            <StyledTableHeaderData>Summary</StyledTableHeaderData>
                            {/* <StyledTableHeaderData>Topic Id</StyledTableHeaderData>
                            <StyledTableHeaderData>Customer Id</StyledTableHeaderData>
                            <StyledTableHeaderData>Customer Description</StyledTableHeaderData>
                            <StyledTableHeaderData>User Email</StyledTableHeaderData> */}
                            {/* <StyledTableHeaderData>User Id</StyledTableHeaderData>
                            <StyledTableHeaderData>User Name</StyledTableHeaderData>
                            <StyledTableHeaderData>Cuz Description</StyledTableHeaderData> */}
                            <StyledTableHeaderData></StyledTableHeaderData>
                        </StyledTableHeader>
                    </thead>
                    <tbody>
                        {data && (data.length == 0 || data.length == undefined) ?

                            <td>No data</td>
                            : data?.map((result, index) => {
                                return (
                                    <StyledTableRow>
                                        <td>{index + 1}</td>
                                        <td style={{wordBreak: "break-word" , width: "20%"}}>{result.cuzdesc}</td>
                                        <StyledStatus>{result.tstatusid}</StyledStatus>
                                        <td style={{wordBreak: "break-word" , width: "40%"}}>{result.cuzsummary}</td>

                                        {/* 
                                        <td>{result.topicid}</td>
                                        <td>{result.customerid}</td>
                                        <td>{result.custdesc}</td>
                                        <td>{result.useremail}</td>
                                        <td>{result.firstname}</td>
                                        <td>{result.cuzdesc}</td> */}
                                        <td>
                                            {/* <StyledLink to="/questiontable"> */}
                                            <StyledButton
                                                onClick={() => { show_modal(result) }}
                                            >Select</StyledButton>
                                            {/* </StyledLink> */}
                                        </td>
                                    </StyledTableRow>
                                );
                            })}
                    </tbody>
                </StyledTable>
                {/* modal box here */}
                <ReactModal
                    isOpen={modal}
                    style={ModalStyle}
                    onRequestClose={() => { set_modal(false) }}
                >
                    <div style={{ width: "100%", float: "left", }}>

                        {status_Masaage == "completed" &&
                            <h6>This topic test was completed during the last test drive.</h6>
                        }
                        {status_Masaage == "Inprocess" &&
                            <h6>This topic test is in process.</h6>
                        }
                        {status_Masaage == "noTopic" &&
                            <h6>Start the topic test</h6>
                        }

                    </div>
                    <div>

                        {(status_Masaage == "completed" || status_Masaage == "Inprocess") &&
                            <StyledButton style={{ width: "250px" }}
                                onClick={() => { history.push("/test/" + getTopicid + "/deletetest") }}
                            >Delete previous test and restart</StyledButton>
                        }
                        {/* {status_Masaage == "inprocess " &&
                            <StyledButton
                            //  onClick={() => { deleteTest() }}
                            onClick={() => { deleteTest() }} 
                            >Restart test</StyledButton>
                        } */}
                        {/* {status_Masaage == "completed" &&
                            <StyledButton
                            //  onClick={() => { get_all() }}
                            >Proceed</StyledButton>
                        } */}
                    </div>
                    <span>&nbsp;&nbsp;</span>
                    <StyledButton
                        onClick={() => { history.push("/test/" + getTopicid + "/continuetest") }}
                    >Start/Continue
                    </StyledButton>
                    {/* <div>
                        <StyledButton style={{width:"150px"}}
                         onClick={() => { history.push("/welcome")}}
                        >Exit to Admin</StyledButton>
                    </div> */}
                </ReactModal>


            </StyledDiv>
        </>
    );
};





export default GetStartedCuzDrive;
