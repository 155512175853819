import React from "react";
// import WelcomeHeader from "../components/WelcomeHeader";
import WelcomeHeader from "../../components/WelcomeHeader";
import styled from "styled-components";
import { CssBaseline } from "@material-ui/core";
import { Link } from "react-router-dom";
import BgImage from "../../assets/home_office.png"
const theme = localStorage.getItem('brandColor');
const StyledLink = styled(Link)`
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  text-align:center;
  top: 50%;
  // left: 15%;
  // transform: translate(-50%, -50%);

  &:active {
    text-decoration: none;
    color: darkgrey;
  }
  &:hover {
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
  }
`;

const StyledPara = styled.p`
  position: relative;
  width: 786px;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 43px;
  margin-top: 10%;
  line-height: 59px;
  text-align: center;
  color: #ffffff;
  justify-content: center;
`;

const StyledLine = styled.div`
  position: relative;
  width: 763px;
  height: 0px;
  justify-content: center;
  border: 5px solid #ffffff;
  margin: 0 auto;
  color: white;
  display: flex;
`;

const StyledE1 = styled.div`
  background: #ffffff;
  border-radius: 50%;
  top: 50%;
  left: 5%;
  transform: translate(-50%, -50%);
  position: relative;
  width: 64px;
  height: 64px;
  justify-content: center;
  z-index: 100;
`;
const StyledE2 = styled.div`
  background: ${theme};
  border-radius: 50%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  z-index: 200;
  justify-content: center;
`;

const StyledButton = styled.button`
  position: relative;
  width: 272px;
  height: 39px;
  justify-content: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  margin: 0 auto;
  
  line-height: 34px;
  color: #ffffff;
  z-index: 10;
  border: none;
  background-color: transparent;
  margin-top: 1.7%;
`;

const StyledRectangleOutsideButton = styled.div`
  position: relative;
  width: 353px;
  height: 84px;
  background: ${theme};
  justify-content: center;
  /* margin: 0 auto; */
  // margin-left: 600px;
  border: 2px solid #ffe7b7;
  margin-top: 50px;
  box-sizing: border-box;
  border-radius: 40px;
`;

const StyledLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.45);
`;

const PageStyle = {
  backgroundImage: " url(" + BgImage + ")",
  backgroundSize: "cover",
  height: "100vh",
  width: "100vw",
  fontFamily: "Nunito",
  fontStyle: "normal",
  color: "#f5f5f5",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const Welcome = () => {
  return (
    <>
      <div style={PageStyle}>
        <StyledLayer />
        <WelcomeHeader />
        <StyledPara>
          Welcome to Cuz Topic You are just one click away to all possible
          calculation and analytics
        </StyledPara>
        <div
          style={{
            justifyContent: "center",
            position: "relative",
            marginTop: "50px",
          }}
        >
          <StyledLine>
            <StyledE1>
              <StyledE2></StyledE2>
            </StyledE1>
          </StyledLine>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="row">
            <div className="col-lg-12" align="center">
              <StyledRectangleOutsideButton>
                <StyledButton>
                  <StyledLink to="extquestion2" > Welcome </StyledLink>
                </StyledButton>
              </StyledRectangleOutsideButton>
            </div>
            <div className="col">
              {/* <StyledRectangleOutsideButton>
                <StyledButton>
                  <StyledLink to="/getstartedcuzdrive">Test this Custom Topic </StyledLink>
                </StyledButton>
              </StyledRectangleOutsideButton> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
