import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { getPDF, needlist_loccountry_locsectors_api } from "../../services/AdminProgramApi";
import {
  register_program_api,
  after_successful_login_api,
  custfeature_api,
  user_session_data_access_api,
} from "../../services/NewUI_API";
import { BASE_URL } from "../../url/baseurl";

const Cuz_Signup = () => {
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [c_password, setC_password] = useState("");
  const [location, setLocation] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const [loginPDF, setLoginPDF] = useState("");

  useEffect(() => {
    getLoginPDF();
  }, []);

  const getLoginPDF = async () => {
    getPDF('Login').then((res) => {
      setLoginPDF(res.data.new_record[0].pdf_link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!firstName) {
      formIsValid = false;
      formErrors["firstName_error"] = "First Name is required.";
    }
    if (!email) {
      formIsValid = false;
      formErrors["email_error"] = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      formErrors["email_error"] = "Invalid Email id.";
    }
    if (!password) {
      formIsValid = false;
      formErrors["password_error"] = "Password is required.";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,20}$/.test(
        password
      )
    ) {
      formIsValid = false;
      formErrors["password_error"] =
        "Invalid Password. Please use 12 mixed characters or more. (A,a,1,@)";
    }
    if (!c_password) {
      formIsValid = false;
      formErrors["c_password_error"] = "Verify Password is required.";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,20}$/.test(
        c_password
      )
    ) {
      formIsValid = false;
      formErrors["c_password_error"] =
        "Invalid Password. Please use 12 mixed characters or more. (A,a,1,@)";
    }
    if (!location) {
      formIsValid = false;
      formErrors["location_error"] = "Home Country is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const Country_list = async () => {
    let data = "loccountry";
    needlist_loccountry_locsectors_api(data)
      .then((res) => {
        if ((res.data.code = 200)) {
          console.log("country list res==>", res.data);
          setCountryList(res.data.country_list_for_locations);
        }
      })
      .catch((err) => {
        console.log("error===>", err);
      });
  };

  useEffect(() => {
    Country_list();
  }, []);

  const SignUp_Function = async () => {
    let data = {
      firstname: firstName,
      email: email,
      homecountry: location,
      password: password,
    };
    register_program_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          message.success("Successful");
          localStorage.setItem("E_Userid", res.data.new_record[0].userid);
          console.log("signup res data==>", res.data);

          res.data.Program == "custstatus"
          ? 
            after_successful_login_api({
              userid:
              localStorage.getItem("E_Userid")||  
                localStorage.getItem("UserID") ,
            })
            .then((res3) => {
              if (res3.data.code == 200) {
                console.log(res3);

                let data3 = {
                  userid:
                    localStorage.getItem("UserID") ||
                    localStorage.getItem("E_Userid"),
                };

                custfeature_api(data3)
                  .then((res4) => {
                    console.log(res4);
                    if (res4.data.code === 200) {
                      console.log("cust feature res4 data==>", res4.data);
                      // message.success("Successfully3 !");

                      user_session_data_access_api(
                        localStorage.getItem("UserID") ||
                          localStorage.getItem("E_Userid")
                      )
                        .then((res5) => {
                          console.log(res5);
                          if (res5.data.code === 200) {
                            console.log(
                              "user session res5 data==>",
                              res5.data
                            );
                            // message.success("Successfully4 !");
                            history.push("/login");
                          } else {
                            // message.error(res4.data.message);
                            console.log(res5.data.message);
                          }
                        })
                        .catch((err5) => {
                          console.log("error usd--->>", err5);
                        });
                    } else {
                      // message.error(res3.data.message);
                      console.log(res4.data.message);
                    }
                  })
                  .catch((err4) => {
                    console.log("error cf--->>", err4);
                  });
              } else  if (res3.data.code === 300) {
                console.log("code",res3.data.code );
                //  message.success("Register Successfully!");
                history.push("/login");
              }
              else {
                console.log("code",res3.data.code );
                message.error("Something went wrong");
              }
            })
            .catch((err3) => {
              console.log(err3);
            })
          :
            history.push("/enroll_ui");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error signup===>", err);
      });
  };

  function sortLocations(a, b) {
    // a should come before b in the sorted order
    if (a.countryfulldesc < b.countryfulldesc) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.countryfulldesc > b.countryfulldesc) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }

  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  function myFunction1() {
    var x = document.getElementById("myInput1");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const allowed = {
    uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
    lowers: "qwertyuiopasdfghjklzxcvbnm",
    numbers: "1234567890",
    symbols: "!@#$%^&*",
  };
  const getRandomCharFromString = (str) =>
    str.charAt(Math.floor(Math.random() * str.length));
  const generatePassword = (length = 12) => {
    // password will be @Param-length, default to 8, and have at least one upper, one lower, one number and one symbol
    let pwd = "";
    pwd += getRandomCharFromString(allowed.uppers); //pwd will have at least one upper
    pwd += getRandomCharFromString(allowed.lowers); //pwd will have at least one lower
    pwd += getRandomCharFromString(allowed.numbers); //pwd will have at least one number
    pwd += getRandomCharFromString(allowed.symbols); //pwd will have at least one symbolo
    for (let i = pwd.length; i < length; i++)
      pwd += getRandomCharFromString(Object.values(allowed).join("")); //fill the rest of the pwd with random characters
    setPassword(pwd);
    setC_password(pwd);
    return pwd;
  };

  return (
    <>
      <div className="container">
        <div className="text-center mt-5">
          <span style={{ fontSize: 25, fontWeight: "bold", color: "#ff8c24" }}>
            SIGN UP FORM
          </span>
          <p style={{ fontWeight: "bold", fontSize: 16, letterSpacing: 0.5 }}>
            Please complete the information below
          </p>
        </div>
        <div className="text-center">
            <a
              onClick={() => {
                window.open(
                  `${BASE_URL}${JSON.parse(
                    loginPDF
                  )}`,
                  "_blank"
                );
              }}
            >
              <i
                style={{ color: "#ff8c24", fontSize: "25px" }}
                class="fas fa-info-circle"
              ></i>
            </a>
          </div>
        <div className="mt-3" style={{ marginLeft: "15%", marginRight: "15%" }}>
          <div class="form-group">
            <label style={{ fontWeight: "bold", fontSize: 16 }}>
              Enter Email
            </label>
            <sup style={{ color: "red" }}>*</sup>
            <div className="input-group">
              <input
                type="email"
                class="form-control"
                placeholder="Enter Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <span
                class="input-group-text"
                style={{ background: "white", border: "1 px solid gray" }}
              >
                {" "}
                &nbsp;
                <i class="far fa-envelope"></i>
              </span>
            </div>
          </div>
          {formErrors.email_error && (
            <div>
              <p
                style={{
                  color: "red",
                  fontSize: ".8rem",
                  marginTop: "0px",
                }}
              >
                {formErrors.email_error}
              </p>
            </div>
          )}
          <div class="form-group mt-3">
            <label style={{ fontWeight: "bold", fontSize: 16 }}>
              Create Password
            </label>
            <sup style={{ color: "red" }}>*</sup>
            <div className="input-group">
              <input
                type="password"
                id="myInput"
                class="form-control"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <span
                class="input-group-text"
                style={{ background: "white", border: "1 px solid gray" }}
              >
                {" "}
                &nbsp;
                <a onClick={() => generatePassword()}>
                  <i class="fa fa-plus-square"></i>
                </a>
              </span>
              <span
                class="input-group-text"
                style={{ background: "white", border: "1 px solid gray" }}
              >
                {" "}
                &nbsp;
                <a onClick={() => myFunction()}>
                  <i class="fas fa-eye"></i>
                </a>
              </span>
            </div>
          </div>
          {formErrors.password_error && (
            <div>
              <p
                style={{
                  color: "red",
                  fontSize: ".8rem",
                  marginTop: "0px",
                }}
              >
                {formErrors.password_error}
              </p>
            </div>
          )}
          <div class="form-group mt-3">
            <label style={{ fontWeight: "bold", fontSize: 16 }}>
              Verify Password
            </label>
            <sup style={{ color: "red" }}>*</sup>
            <div className="input-group">
              <input
                type="password"
                id="myInput1"
                class="form-control"
                placeholder="Enter Password"
                value={c_password}
                onChange={(e) => {
                  setC_password(e.target.value);
                }}
              />
              <span
                class="input-group-text"
                style={{ background: "white", border: "1 px solid gray" }}
              >
                {" "}
                &nbsp;
                <a onClick={() => myFunction1()}>
                  <i class="fas fa-eye"></i>
                </a>
              </span>
            </div>
          </div>
          {formErrors.c_password_error && (
            <div>
              <p
                style={{
                  color: "red",
                  fontSize: ".8rem",
                  marginTop: "0px",
                }}
              >
                {formErrors.c_password_error}
              </p>
            </div>
          )}
          <div class="form-group mt-3">
            <label style={{ fontWeight: "bold", fontSize: 16 }}>
              Home Country
            </label>
            <sup style={{ color: "red" }}>*</sup>
            <select
              class="form-control form-select"
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              value={location}
            >
              <option value="" selected disabled hidden>
                Select Country
              </option>
              {countryList?.sort(sortLocations)?.map((data) => {
                return (
                  <option value={data.countrycode}>
                    {data.countryfulldesc}
                  </option>
                );
              })}
            </select>
          </div>
          {formErrors.location_error && (
            <div>
              <p
                style={{
                  color: "red",
                  fontSize: ".8rem",
                  marginTop: "0px",
                }}
              >
                {formErrors.location_error}
              </p>
            </div>
          )}
          <div class="form-group mt-3">
            <label style={{ fontWeight: "bold", fontSize: 16 }}>
              First Name
            </label>
            <sup style={{ color: "red" }}>*</sup>
            <input
              type="text"
              class="form-control"
              placeholder="Enter First Name"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </div>
          {formErrors.firstName_error && (
            <div>
              <p
                style={{
                  color: "red",
                  fontSize: ".8rem",
                  marginTop: "0px",
                }}
              >
                {formErrors.firstName_error}
              </p>
            </div>
          )}

          <button
            className="mt-3 mb-5 mobileButton"
            type="button"
            style={{
              width: "35%",
              paddingTop: 8,
              paddingBottom: 8,
              color: "#ff8c24",
              background: "white",
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: "25px",
              border: "1px solid #ff8c24",
            }}
            onClick={() => {
              if (handleValidation() && password === c_password) {
                SignUp_Function();
              } else {
                message.info(
                  password != c_password
                    ? "Please enter same Password."
                    : "Please complete all data"
                );
              }
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
    </>
  );
};

export default Cuz_Signup;
