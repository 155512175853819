import React, { useEffect, useState } from "react";
import { cuzbatch_filter } from "../../services/AdminProgramApi";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import $ from "jquery";
// import { Link } from "react-router-dom";
import CuzbatchMenu from "./Cuzbatch_menu";
import { message } from "antd";

const theme = localStorage.getItem("brandColor");

const StyledButton2 = styled.button`
  background: ${theme};
  width: 280px;
  border-radius: 22px;
  height: 44px;
  text-align: center;
  color: white;
  font-size: 19px !important;
  font-weight: 500;
  border: none;
  padding: 1px 10px;
  margin-top:5px;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledDiv = styled.div`
  justify-content: center;
  margin: 0 auto;
`;
const Styledarround = styled.div`
  justify-content: space-between;
`;
const StyledTable = styled.table`
  width: 100%;
  height: 3%;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
  text-align:center;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const Cuzbatching = () => {
  const history = useHistory();
  const [run, setRun] = useState("");
  const [customerList, setCustomerList] = useState("");
  //  --------------Filter -------------------------
  const [fCustomerid, setFCustomerid] = useState("");
  const [fCustdesc, setFCustdesc] = useState("");
  const [fcountrycode, setFCountrycode] = useState("");
  const [femail, setFEmail] = useState("");
  const [filterTemp, setFilterTemp] = useState([]);

  //check box select value



  const [customer_data, setCustomer_data] = useState([]);
  const [customer_data_json, setCustomer_data_json] = useState([]);

  //  

  $(document).on("change", ".select-all1", function () {
    if ($(this).is(":checked")) {
      $('[name="check[1]"]').each(function () {
        $(this).prop("checked", true);
      });
    } else {
      $('[name="check[1]"]').each(function () {
        $(this).prop("checked", false);
      });
    }
  });

  const cuzbatch_filter_api = async () => {
    cuzbatch_filter()
      .then((res) => {
        if (res.data.code === 200) {
          setCustomerList(res.data.result);
          //   console.log("data ==>", res.data.result);
        } else {
          // message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCallback = (childData) =>{
    setRun(childData);
    setCustomer_data([]);
    console.log("customer_data",customer_data);
    console.log(childData);

}


  const filter = () => {
    let filter_data = [];
    // console.log(customerList);
    if (fCustomerid !== "") {
      // console.log(fCustomerid);

      filter_data = customerList.filter(function (el) {
        return el.customerid == fCustomerid;
      });
      // console.log( filter_data);

      // filter_data = [
      //   ...filter_data,
      //   ...customerList.filter((item) =>
      //     // String(item.customerid)
      //     item.customerid
      //       // .toLowerCase()
      //       // .includes(fCustomerid?.toLowerCase())
      //   ),
      // ];
    }

    if (fCustdesc !== "") {
      filter_data = [
        ...filter_data,
        ...customerList.filter((item) =>
          String(item.custdesc).toLowerCase().includes(fCustdesc?.toLowerCase())
        ),
      ];
    }
    if (fcountrycode !== "") {
      filter_data = [
        ...filter_data,
        ...customerList.filter((item) =>
          String(item.countrycode)
            .toLowerCase()
            .includes(fcountrycode?.toLowerCase())
        ),
      ];
    }

    if (femail !== "") {
      filter_data = [
        ...filter_data,
        ...customerList.filter((item) =>
          String(item.useremail).toLowerCase().includes(femail?.toLowerCase())
        ),
      ];
    }

    setCustomerList(filter_data.length !== 0 ? filter_data : filterTemp);
  };
  // Pass Vale 


  useEffect(() => {
    cuzbatch_filter_api();
  }, []);

  return (
    <>
      <div className="container" style={{ overflow: "none" }}>
        {run == "" ? (
          <>
            <div className=" mt-3">
              <div className="col" align="center">
                <StyledButton2
                  style={{
                    marginLeft: 60,
                  }}
                  onClick={() => {
                    history.push("Support_menu");
                  }}
                >
                  Return to Support Menu
                </StyledButton2>
                <StyledButton2
                  style={{
                    marginLeft: 60,
                  }}
               
                  onClick={() => {
                    if(customer_data.length >0){
                        setRun("menu")
                    }else{
                      message.error("Not Selected Customer");
                    }
                    // setRun("menu")
                    console.log(" Nextcustomer_data ==>",customer_data);
                   console.log("customer_data ==>",customer_data.length);
                  }}
               
                >
                  Next
                </StyledButton2>
                
              </div>
              {/* <StyledButton2 onClick={() => {
                setRun("menu")}}>
                Next Step
              </StyledButton2> */}
              <div
                className="EXTQ  FilterS"
                style={{
                  width: "100%",
                  marginTop: 20,
                  padding: 20,
                  border: "black solid 1px",
                  borderRadius: 50,
                }}
              >
                <div className="EXTQ">
                  <div className="row filter-row">
                    <div
                      style={{ padding: " 0 10px 4px" }}
                      //   className="col-md-4 filter-col"
                      className="col-md-3 col-lg-3"
                    >
                      <input
                        style={{ width: "100%" }}
                        value={fCustomerid}
                        placeholder="Filter Customerid"
                        onChange={(e) => setFCustomerid(e.target.value)}
                        type="text"
                      />
                    </div>
                    <div
                      style={{ padding: " 0 10px 4px" }}
                      className="col-md-3 col-lg-3"
                    >
                      <input
                        style={{ width: "100%" }}
                        placeholder="Filter Customer Name"
                        onChange={(e) => setFCustdesc(e.target.value)}
                        value={fCustdesc}
                        type="text"
                      />
                    </div>
                    <div
                      style={{ padding: " 0 10px 4px" }}
                      className="col-md-3 col-lg-3"
                    >
                      <input
                        style={{ width: "100%" }}
                        value={fcountrycode}
                        placeholder="Filter Countrycode"
                        onChange={(e) => setFCountrycode(e.target.value)}
                        type="text"
                      />
                    </div>

                    <div
                      style={{ padding: " 0 10px 4px" }}
                      className="col-md-3 col-lg-3"
                    >
                      <input
                        style={{ width: "100%" }}
                        value={femail}
                        placeholder="Filter Email"
                        onChange={(e) => setFEmail(e.target.value)}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col" align="center">
                    <StyledButton1
                      style={{
                        width: "18%",
                        height: 30,
                        marginLeft: 10,
                      }}
                      onClick={filter}
                    >
                      Search
                    </StyledButton1>
                    <StyledButton1
                      style={{
                        width: "18%",
                        height: 30,
                        marginLeft: 10,
                      }}
                     
                      onClick={ ()=>{
                        setFCustomerid('');
                        setFCustdesc("");
                        setFCountrycode("");
                        setFEmail("");
                        cuzbatch_filter_api();
                      }}
                    >
                      Reset
                    </StyledButton1>
                  </div>
                </div>
              </div>

              <div className="m-4 table-responsive tableFixHead">
                <StyledDiv>
                  <StyledTable>
                    <thead>
                      <ScrollTableHeader>
                        <StyledTableHeaderData>
                          {/* <input
                            type="checkbox"
                            className="checkbox select-all1 form-check-input"
                            onClick={(e) => {
                              {
                                customerList &&
                                  customerList?.map((data, index) => {
                                    if (
                                      !customer_data.includes(data.countrycode)
                                    ) {
                                      if (e.target.checked == true) {
                                        customer_data.push(data);
                                        customer_data_json.push({
                                          customerid: data.customerid,
                                          custdesc: data.custdesc,
                                          countrycode: data.countrycode,
                                          useremail: data.useremail,
                                        });
  
                                      } else {
                                        customer_data.pop(data);
                                        customer_data_json.pop({
                                          customerid: data.customerid,
                                          custdesc: data.custdesc,
                                          countrycode: data.countrycode,
                                          useremail: data.useremail,
                                        });

                                      }
                                    }
                                  });
                              }
                            }}
                          /> */}
                        </StyledTableHeaderData>
                        <StyledTableHeaderData>S.No</StyledTableHeaderData>
                        <StyledTableHeaderData>
                          Customer Id
                        </StyledTableHeaderData>
                        <StyledTableHeaderData>
                          Customer Name
                        </StyledTableHeaderData>
                        <StyledTableHeaderData>
                          Countrycode
                        </StyledTableHeaderData>
                        <StyledTableHeaderData>Email</StyledTableHeaderData>
                      </ScrollTableHeader>
                    </thead>
                    <tbody>
                      {customerList &&
                        customerList?.map((data, index) => {
                          return (
                            <>
                              <StyledTableRow>
                                <td align="center">
                                  <input
                                    name="check[1]"
                                    type="radio"
                                    className=" form-check-input"
                                    onClick={(e) => {
                                    
                                      let newarray = [];
                                      
                                      newarray.push(data);
                                    
                                      setCustomer_data(newarray);
                                          //  console.log(
                                          //   "customer data arrray===>",
                                          //   customer_data
                                          // );
                                      // if (
                                      //   !customer_data.includes(data.customerid)
                                      // )
                                        // if (e.target.checked == true) {
                                        //   customer_data.push(data);
                                    
                                        //   // setCustomer_data(data);
                                        //   customer_data_json.push({
                                        //     customerid: data.customerid,
                                        //     custdesc: data.custdesc,
                                        //     countrycode: data.countrycode,
                                        //     useremail: data.useremail,
                                        //   });
                                         
                                        //   console.log(
                                        //     "customer data arrray===>",
                                        //     customer_data_json
                                        //   );
                                        // } else {
                                        //   customer_data.pop(data);
                                        //   customer_data_json.pop({
                                        //     customerid: data.customerid,
                                        //     custdesc: data.custdesc,
                                        //     countrycode: data.countrycode,
                                        //     useremail: data.useremail,
                                        //   });
                                        //   console.log(
                                        //     "customer pop data arrray===>",
                                        //     customer_data,
                                        //     customer_data_json
                                        //   );
                                        // }
                                    }}
                                  />
                                </td>
                                <td align="center" >{index + 1}</td>
                                <td align="center">{data.customerid}</td>
                                <td align="center">{data.custdesc}</td>
                                <td align="center">{data.countrycode}</td>
                                <td align="center">{data.useremail}</td>
                              </StyledTableRow>
                            </>
                          );
                        })}
                    </tbody>
                  </StyledTable>
                </StyledDiv>
              </div>
            </div>
          </>
        ) :  run == "menu" ?(
          <>
       
          <CuzbatchMenu data={customer_data} parentCallback = {handleCallback}/>
          </>
        ):("")}
      </div>
    </>
  );
};

export default Cuzbatching;
