import React, { useEffect, useRef, useState } from "react";
import MainHeader from "../components/MainHeader";
import QuestionTableInput from "../components/QuestionTableInput";
import { Link, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import QuestionTableSearch from "../components/QuestionTableSearch";
import "../App.css";
import ReactModal from "react-modal";
import axios from "axios";
import { BASE_URL } from "../url/baseurl";
import { QuestionTableInputAPI } from "../services/QuestionTableInputAPI";
import * as Database from "../services/Database";
import $ from "jquery";
import { message } from "antd";

const theme = localStorage.getItem('brandColor');

const StyledLink = styled(Link)`
  color: darkgrey;
  font-weight: 500;
  text-decoration: none;
  &:active {
    text-decoration: none;
    color: darkgrey;
  }
  &:hover {
    text-decoration: none;
    color: grey;
    cursor: pointer;
  }
`;

const StyledButton = styled.button`
  background: ${theme};
  width: 150px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  padding: 0px 20px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;

const StyledDivButtons = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
`;

const ModalStyle = {
  content: {
    width: "55%",
    height: "70%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // display: "flex",
    // justifyContent: "center",
  },
};

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
  // margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 100%;
`;

const StyledInput = styled.input`
  display: inline;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledTextarea = styled.textarea`
  display: inline;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputStyle = {
  border: "1px solid black",
  // backgroundColor: "#EEEEEE",
};

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const FormDivStyleTwo = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const ExtQuestion = () => {
  const history = useHistory();

  const [isAddQuestion, setIsAddQuestion] = useState(false);
  const entertedqcoderef = useRef();
  const entertedquestionref = useRef();
  const entertedquestionseqref = useRef();
  const entertedifparentyesref = useRef(0);
  const entertedifparentnoref = useRef(0);
  const entertedpriorityidref = useRef();
  const entertedguidanceidref = useRef();
  const entertedsolutionifref = useRef();
  const entertedsolutiondescref = useRef();
  const entertedactiveqref = useRef();
  const entertedreportingref = useRef();
  const [priority, set_priotity] = useState([]);
  const [guidance, set_guidance] = useState([]);
  const [category, set_categorylist] = useState([]);
  const [qcode, setQcode] = useState("");
  const [qseq, setQseq] = useState("");

  const [c_id, setC_id] = useState(100001);
  const [c_id2, setC_id2] = useState(100002);

  const [data, setData] = useState([]);
  const [warning, set_warning] = useState({});
  const [new_qcode, set_new_qcode] = useState(0);

  const params = useParams();

  const showQuestionModal = async () => {
    let res = await Database.get_qcode({ topicid: params.id });
    set_new_qcode(res.data);
    setIsAddQuestion(true);
    setQcode(res.data.question_code);
    setQseq(res.data.question_seq);
  };

  const closeQuestionModal = () => {
    setIsAddQuestion(false);
  };

  const questionSubmitHandler = async (event) => {
    event.preventDefault();
    const questionObject = {
      userid: localStorage.getItem("UserID"),
      topicid: params.id,
      qcode: entertedqcoderef.current.value,
      qdescr: entertedquestionref.current.value,
      questionseq: entertedquestionseqref.current.value,
      ifparentyes: entertedifparentyesref.current.value,
      // === "Yes" ? 1 : entertedifparentyesref.current.value === "No" ? 0 : "",
      ifparentno: entertedifparentnoref.current.value,
      // === "Yes" ? 1 : entertedifparentnoref.current.value === "No" ? 0 : "",
      priorityid: entertedpriorityidref.current.value,
      guidanceid: entertedguidanceidref.current.value,
      solutionif:
        entertedsolutionifref.current.value === "Yes"
          ? "Y"
          : entertedsolutionifref.current.value === "No"
          ? "N"
          : "-",
      solutiondesc:
        entertedsolutiondescref.current.value == ""
          ? "-"
          : entertedsolutiondescref.current.value,
      repcatid: entertedreportingref.current.value,
      activeq: entertedactiveqref.current.value === "Yes" ? "Y" : "N",
    };
    sendQuestionDataToAPIHandler(questionObject);
  };

  const sendQuestionDataToAPIHandler = async (data) => {
    const response = await axios
      .post(`${BASE_URL}/api/updateCreateCuzque`, data)
      .then((res) => {
        if (res.data.message === "Parantinfirst") {
          message.info(
            "Remove Question Numbers from 'Next Step...Yes' and 'Next Step...No' on the first record. Only 0 allowed in each field.",
            20
          );
        }
        if (res.data.message === "nextyes") {
          message.info(
            "Change the 'Next step if this question # is answered Yes' to a Question Number with a lower Question Sequence.",
            20
          );
        }
        if (res.data.message === "nextno") {
          message.info(
            "Change the 'Next step if this question # is answered No' to a Question Number with a lower Question Sequence.",
            20
          );
        }
        if (res.data.message === "childsequence") {
          message.info(
            "Change the 'Next step if this question # is answered Yes or No' to a Question Number with a lower Question Sequence.",
            20
          );
        }
        if (res.data.message === "parentinactive") {
          message.info(
            "Remove the inactive code from 'Next step if this question # is answered Yes or No'.",
            20
          );
        }
        if (res.data.message === "deletesolution") {
          message.info(
            "Remove text from Solution because 'Solution given = '-' or (skip)'",
            15
          );
        }
        if (res.data.message === "addsolution") {
          message.info(
            "Add a Solution because 'Solution given = Yes or No'",
            15
          );
        }
        if (res.data.message === "toomanycodes") {
          message.info(
            "Remove one 'Next step if this question # is answered Yes or No'",
            15
          );
        }
        if (res.data.message === "missingcodes") {
          message.info(
            "Add a Question Number to either 'Next step if this question # is answered Yes or No'",
            15
          );
        }
        if (res.data.message === "notinactive") {
          message.info(
            "Remove this Question Number from either 'Next step if this question # is answered Yes or No' before inactivating it.",
            20
          );
        }
        if (res.data.message === "updated") {
          message.info("updated", 13);
        }
        if (
          res.data.message ===
          "ifparentyes or ifparentno should be less than the qcode"
        ) {
          message.info(
            "A Next Step question # must not reference a question # that comes after it",
            13
          );
        }
        if (res.data.message === "ifparentno is using invalid qcode") {
          message.info(
            "A Next Step question # must not reference a question # which is invalid",
            13
          );
        }
        if (res.data.message === "warninactive") {
          message.info(
            "This inactive Question Number is being used in a 'Next step' field",
            20
          );
        }
        if (res.data.message === "ifparentyes is using invalid qcode") {
          message.info(
            "A Next Step question # must not reference a question # which is invalid",
            13
          );
        }
        if (res.data.message === "inserted") {
          message.success("Question added successfully", 10);
          QuestionTableInputAPIs();
          window.location.reload();
        }
      })
      .catch((err) => console.log(`err: ${err.response}`));
  };
  const QuestionTableInputAPIs = async () => {
    await QuestionTableInputAPI({ topicid: params.id })
      .then((res) => {
        setData(
          res.data.existing_questionario?.map((data) => {
            data.color = "white";
            data.message = "";
            return data;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    QuestionTableInputAPIs();
    get_all();
  }, []);

  const get_warning = async () => {
    Database?.warining({ topicid: params?.id })
      .then((res) => {
        //condition for no warning
        if (res.data.message === "no warnings") {
          message.info("No warnings generated!");
        } else {
          set_warning(res?.data);
          let resp = res?.data;
          let warnArray = [];
          let warnArrayQCode = [];
          let datas = data?.map((data, index) => {
            // warnArray=data
            if (
              resp?.warnactive === "" &&
              resp?.warnextrasol === "" &&
              resp?.warninactive === "" &&
              resp?.warnnextstep === "" &&
              resp?.warnoutofseq === "" &&
              resp?.warnrepcat === "" &&
              resp?.warnshortsol === ""
            ) {
              return (warnArray = datas);
            } else {
              if (resp?.warnnextstep !== "") {
                let code = resp?.warnnextstep?.qcode?.trim()?.split(",");
                let code_int = code.map((el) => parseInt(el));

                if (code_int?.includes(parseInt(data?.qcode))) {
                  data.color = "red";
                  data.message =
                    "Warning : No Solution has been provided and there is no Next Step";
                  warnArray?.push(data);
                  warnArrayQCode?.push(data?.qcode);
                }
              }
              if (resp?.warnoutofseq !== "") {
                let code = resp?.warnoutofseq?.qcode?.trim()?.split(",");
                if (
                  code
                    ?.map((data) => {
                      return parseInt(data);
                    })
                    ?.includes(parseInt(data?.qcode)) === true
                ) {
                  data.color = "red";
                  data.message =
                    "Warning : A Next Step question # must not reference a question # that comes after it";
                  warnArray?.push(data);
                  warnArrayQCode?.push(data?.qcode);
                }
              }

              if (resp?.warnextrasol !== "") {
                let code = resp?.warnextrasol?.qcode?.trim()?.split(",");
                if (
                  code
                    ?.map((data) => {
                      return parseInt(data);
                    })
                    ?.includes(parseInt(data?.qcode)) === true
                ) {
                  data.color = "red";
                  data.message =
                    "Warning: A Solution must not be provided if the 'Solution given' is not Yes or No?. Remove text from Solution or change the 'Solution given' answer";
                  warnArray?.push(data);
                  warnArrayQCode?.push(data?.qcode);
                }
              }

              if (resp?.warnshortsol !== "") {
                let code = resp?.warnshortsol?.qcode?.trim()?.split(",");
                if (
                  code
                    ?.map((data) => {
                      return parseInt(data);
                    })
                    ?.includes(parseInt(data?.qcode)) === true
                ) {
                  data.color = "red";
                  data.message =
                    "Warning: A Solution must be provided if the 'Solution given' is Yes or No?. Add a Solution or change the 'Solution given";
                  warnArray?.push(data);
                  warnArrayQCode?.push(data?.qcode);
                }
              }

              if (resp?.warnrepcat !== "") {
                let code = resp?.warnrepcat?.qcode?.trim()?.split(",");
                if (
                  code
                    ?.map((data) => {
                      return parseInt(data);
                    })
                    ?.includes(parseInt(data?.qcode)) === true
                ) {
                  data.color = "red";
                  data.message =
                    "Warning : This record does not have a Reporting Category?.";
                  warnArray?.push(data);
                  warnArrayQCode?.push(data?.qcode);
                }
              }
              if (resp?.warnactive !== "") {
                let code = resp?.warnactive?.qcode?.trim()?.split(",");
                if (
                  code
                    ?.map((data) => {
                      return parseInt(data);
                    })
                    ?.includes(parseInt(data?.qcode)) === true
                ) {
                  data.color = "red";
                  data.message =
                    "Warning: This record is Inactive and will not be used in the topic quiz";
                  warnArray?.push(data);
                  warnArrayQCode?.push(data?.qcode);
                }
              }

              if (resp?.warninactive !== "") {
                let code = resp?.warninactive?.qcode?.trim()?.split(",");
                if (
                  code
                    ?.map((data) => {
                      return parseInt(data);
                    })
                    ?.includes(parseInt(data?.qcode)) === true
                ) {
                  data.color = "red";
                  data.message =
                    "Warning: This inactive Question Number is being used in a 'Next step' field";
                  warnArray?.push(data);
                  warnArrayQCode?.push(data?.qcode);
                }
              }
            }
            if (warnArrayQCode?.includes(data?.qcode) === false) {
              console.log("code---", data.qcode);
              warnArrayQCode?.push(data?.qcode);
              warnArray?.push(data);
            }
          });
          setData(warnArray);
        }
      })
      .catch((err) => {
        console.log("error for warn api==>", err);
      });
  };

  async function get_all() {
    Database.priority_list().then((res) => {
      set_priotity(res.data["Priority List"]);
      console.log(res.data["Priority List"]);
    });
    Database.ReportingCategoryList({ topicid: params.id }).then((res) => {
      set_categorylist(res.data["Reporting Category List"]);
      console.log(res.data["Reporting Category List"]);
    });
    Database.guidance_list({ topicid: params.id }).then((res) => {
      set_guidance(res.data["guidance_list"]);
      console.log("guidance id data===>", res);
      console.log(res.data["guidance_list"]);
    });
  }

  return (
    <div>
      <ReactModal
        isOpen={isAddQuestion}
        className="ModalStyle4 Width90"
        onRequestClose={closeQuestionModal}
      >
        <StyledForm onSubmit={questionSubmitHandler}>
          <h4 style={{ textAlign: "center" }}>Add Question</h4>
          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="qcode"> Question Code </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput
                ref={entertedqcoderef}
                style={InputStyle}
                type="number"
                id="qcode"
                value={qcode}
                disabled

                // value={new_qcode}
              />
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="Question"> Question </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledTextarea
                ref={entertedquestionref}
                style={InputStyle}
                type="text"
                id="question"
                rows={3}
              />
              {/* <StyledInput
                ref={entertedquestionref}
                style={InputStyle}
                type="text"
                id="question"
              /> */}
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="topicid"> Question Sequence</StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput
                ref={entertedquestionseqref}
                style={InputStyle}
                type="number"
                id="topicid"
                value={qseq}
                disabled
              />
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="ifyes">
                {" "}
                Next step if this question # is answered Yes{" "}
              </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput
                ref={entertedifparentyesref}
                style={InputStyle}
                type="number"
                // placeholder="Yes or No"
                id="qdesc"
              />
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="ifyes">
                {" "}
                Next step if this question # is answered No{" "}
              </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledInput
                ref={entertedifparentnoref}
                style={InputStyle}
                type="number"
                id="qdesc"
                // placeholder="Yes or No"
              />
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="ifyes"> Priority </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              {/* <StyledInput
                ref={entertedpriorityidref}
                style={InputStyle}
                type="text"
                id="qdesc"
              /> */}
              <select style={{ width: "100%" }} ref={entertedpriorityidref}>
                {priority?.map((data) => {
                  return (
                    <option value={data.priorityid}>{data.prioritydesc}</option>
                  );
                })}
              </select>
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="ifyes"> Guidance </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              {/* <StyledInput
                
                style={InputStyle}
                type="text"
                id="qdesc"
              /> */}
              <select style={{ width: "100%" }} ref={entertedguidanceidref}>
                {guidance?.map((data, i) => {
                  return (
                    <option
                      className="select-option"
                      value={data.guidanceid}
                      title={data.cuzguidedesc}
                    >
                      {data.guidanceid}.{" "}
                      {data.cuzguidedesc.length > 71
                        ? data.cuzguidedesc.substring(0, 70) + "..."
                        : data.cuzguidedesc}
                    </option>
                  );
                })}
              </select>
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="ifyes">
                {" "}
                Solution given when answer equals this{" "}
              </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <select style={{ width: "100%" }} ref={entertedsolutionifref}>
                <option>No</option>
                <option>Yes</option>
                <option>Skip</option>
              </select>
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="ifyes"> Solution </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              <StyledTextarea
                ref={entertedsolutiondescref}
                style={InputStyle}
                type="text"
                id="qdesc"
                rows={3}
              />
              {/* <StyledInput
                ref={entertedsolutiondescref}
                style={InputStyle}
                type="text"
                id="qdesc"
              /> */}
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="ifyes"> Reporting Category </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              {/* <StyledInput
                ref={entertedreportingref}
                style={InputStyle}
                type="text"
                id="qdesc"
                /> */}
              <select style={{ width: "100%" }} ref={entertedreportingref}>
                {category?.map((data, i) => {
                  return (
                    <option value={data.repcatid}>{data.repcatdesc}</option>
                  );
                })}
              </select>
            </div>
          </StyledFormInputDiv>

          <StyledFormInputDiv>
            <div style={FormDivStyle}>
              <StyledLabel htmlFor="ifyes"> Activate Question </StyledLabel>
            </div>
            <div style={FormDivStyleTwo}>
              {/* <StyledInput
                ref={entertedactiveqref}
                style={InputStyle}
                type="text"
                id="qdesc"
              /> */}
              <select style={{ width: "100%" }} ref={entertedactiveqref}>
                <option>Yes</option>
                <option>No</option>
                {/* <option>skip</option> */}
              </select>
            </div>
          </StyledFormInputDiv>

          <div className="mt-2" align="center">
            <StyledButton
              style={{
                width: "30%",
                backgroundColor: theme,
              }}
            >
              Submit
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                backgroundColor: "red",
              }}
              onClick={() => {
                closeQuestionModal();
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </StyledForm>
      </ReactModal>
      <MainHeader />

      <div
        style={{ display: "flex", justifyContent: "space-evenly" }}
        className="mt-4 EXTQ"
      >
        <div>
          <StyledButton
            onClick={() =>
              history.push(
                `/adminfunction/custom_topic_data/${localStorage.getItem(
                  "CrID"
                )}`
              )
            }
          >
            Exit
          </StyledButton>
        </div>
        <div>
          <StyledButton
            onClick={() => {
              get_warning();
            }}
          >
            Warning
          </StyledButton>
        </div>
        <div>
          <StyledButton onClick={showQuestionModal}>Add Question</StyledButton>
        </div>
        <div>
          <StyledButton onClick={() => history.push("/guidance/" + params.id)}>
            Guidance
          </StyledButton>
        </div>
        <div>
          <StyledButton
            onClick={() => history.push("/report_category/" + params.id)}
          >
            Report Category
          </StyledButton>
        </div>
      </div>

      <QuestionTableInput datas3={data} warning={warning} />
    </div>
  );
};

export default ExtQuestion;
