import React,{useEffect} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
const theme = localStorage.getItem('brandColor');
const StyledButton1 = styled.button`
  background: ${theme};
  background: #ff8c24;
  width: 275px;
  border-radius: 30px;
  height: 50px;
  text-align: center;
  font-size: 20px !important;
  color: white;
  font-weight: 900;
  border-color: none;
  border: none;
`;

const ReNewPayment = () => {
  const history = useHistory();

  useEffect(() => {
    if(localStorage.getItem("brandColor") == null || localStorage.getItem("brandColor") == '' ){
      localStorage.setItem("brandColor","#ff8c24");  console.log("vasanth==>", );
  
    }
  }, []);

  return (
    <>
      <div className="container pb-5">
        <div className="mt-4 d-flex justify-content-around" align="center">
          <StyledButton1
            onClick={() => {
              history.push("/login");
            }}
          >
            Return to Main Menu
          </StyledButton1>
        </div>
   

        <div className="row pt-5">
          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="card">
              <div class="bg-secondary">
                <h4 className="text-white mt-3 fw-bolder text-center">
                  Small Business
                </h4>
              </div>
              <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
                <h2 className="text-secondary mt-2 fw-bold text-center">
                  $360 per year
                </h2>
              </div>

              <ul class="list-group list-group-flush">
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Full Suggestion Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Suggestion Guidance
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Topic Summary Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Compare History Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Admins - 1
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Users - 5
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Locations - 1
                </li>
              </ul>
              <div
                class="card-header text-center"
                style={{ backgroundColor: "#F2F3F4" }}
              >
                <StyledButton1
                  onClick={() => {
                    localStorage.setItem("RenewPlanID", 2);
                    localStorage.setItem(
                      "price_RP",
                       //"price_1KVxciCxPlnlMJHkB0OjX3o7"  //test price 
                      "price_1LMRv4CxPlnlMJHkHSFikJIO"  //live price
                    );
                    history.push("/payment");
                  }}
                >
                  Buy Now
                </StyledButton1>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="card">
              <div class="bg-secondary">
                <h4 className="text-white mt-3 fw-bold text-center">
                  Enterprise
                </h4>
              </div>
              <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
                <h2 className="text-secondary mt-2 fw-bold text-center">
                  $840 per year
                </h2>
              </div>

              <ul class="list-group list-group-flush">
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Full Suggestion Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Suggestion Guidance
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Topic Summary Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Compare History Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Reporting by Location
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Admins - 10
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Users - 100
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Locations - 20
                </li>
              </ul>
              <div
                class="card-header text-center"
                style={{ backgroundColor: "#F2F3F4" }}
              >
                <StyledButton1
                  onClick={() => {
                    localStorage.setItem("RenewPlanID", 3);
                    localStorage.setItem(
                      "price_RP",
                       //"price_1KVxnoCxPlnlMJHkV9pIsWvb"  //test price
                      "price_1LMRtPCxPlnlMJHkJsDuCJyh" //live price
                    );
                    history.push("/payment");
                  }}
                >
                  Buy Now
                </StyledButton1>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="card">
              <div class="bg-secondary">
                <h4 className="text-white mt-3 fw-bold text-center">
                  Enterprise + Custom
                </h4>
              </div>
              <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
                <h2 className="text-secondary mt-2 fw-bold text-center">
                  $1080 per year
                </h2>
              </div>

              <ul class="list-group list-group-flush">
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Full Suggestion Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Suggestion Guidance
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Topic Summary Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Compare History Report
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Reporting by Location
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Create Custom Topics
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Admins - 10
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Users - 100
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Locations - 20
                </li>
              </ul>
              <div
                class="card-header text-center"
                style={{ backgroundColor: "#F2F3F4" }}
              >
                <StyledButton1
                  onClick={() => {
                    localStorage.setItem("RenewPlanID", 5);
                    localStorage.setItem(
                      "price_RP",
                       //"price_1KVxhjCxPlnlMJHk9WuL1Lew" //test price
                      "price_1LMRtuCxPlnlMJHktegNRc9b" //live price 
                    );
                    history.push("/payment");
                  }}
                >
                  Buy Now
                </StyledButton1>
              </div>
            </div>
          </div>

          {/* <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
            <div class="card">
              <div class="bg-secondary">
                <h4 className="text-white mt-3 fw-bold text-center">
                  Consultants
                </h4>
              </div>
              <div class="card-header" style={{ backgroundColor: "#F2F3F4" }}>
                <h2 className="text-secondary mt-2 fw-bold text-center">
                  $1440 per year
                </h2>
              </div>

              <ul class="list-group list-group-flush">
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  All Enterprise + Custom Functionality
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Manage Client Accounts
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  Assign Topics to Clients
                </li>
                <li
                  style={{ fontSize: "15px" }}
                  class="list-group-item fw-bold text-center text-secondary"
                >
                  *Contact us for special client pricing
                </li>
              </ul>
              <div
                class="card-header text-center"
                style={{ backgroundColor: "#F2F3F4" }}
              >
                <StyledButton1
                  onClick={() => {
                    localStorage.setItem("RenewPlanID", 6);
                    localStorage.setItem(
                      "price_RP",
                      // "price_1KVxr0CxPlnlMJHkiNJtUsUH" //test price
                      "price_1MnbUvCxPlnlMJHkqDFkwOm8" //live price
                    );
                    history.push("/payment");
                  }}
                >
                  Buy Now
                </StyledButton1>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ReNewPayment;
