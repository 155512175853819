import React, { useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { reset_password_api } from "../../services/NewUI_API";
import { message } from "antd";
import styled from "styled-components";
import { BASE_URL } from "../../url/baseurl";
import { getPDF } from "../../services/AdminProgramApi";
const theme = localStorage.getItem('brandColor');

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 30px;
  height: 40px;
  text-align: center;
  font-size: 20px !important;
  color: white;
  font-weight: 900;
  border-color: none;
  border: none;
`;

function ChangePassword() {
  const history = useHistory();

  const [OldPass, setOldPass] = useState("");
  const [NewPass, setNewPass] = useState("");
  const [formErrors, setFormErrors] = useState([]);
  const [loginPDF, setLoginPDF] = useState("");

  useEffect(() => {
    getLoginPDF();
  }, []);

  const getLoginPDF = async () => {
    getPDF('Login').then((res) => {
      setLoginPDF(res.data.new_record[0].pdf_link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!OldPass) {
      formIsValid = false;
      formErrors["OldPassError"] = "Old Password is required.";
    }

    if (!NewPass) {
      formIsValid = false;
      formErrors["NewPassError"] = "New password is required";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,20}$/.test(
        NewPass
      )
    ) {
      formIsValid = false;
      formErrors["NewPassError"] =
        "Invalid Password. Please use 12 mixed characters or more. (A,a,1,@)";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const ChangePasswordVarify = () => {
    if (handleFormValidation()) {
      reset_password_api({
        userid: localStorage.getItem("UserID"),
        old_password: OldPass,
        new_password: NewPass,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.code === 200) {
            message.success(res.data.message, 8);
            setOldPass("")
            setNewPass("")
          } else {
            message.error(res.data.message);
          }
        })
        .catch((e) => {
          message.error("Something went wrong");
          console.log(e);
        });
    } else {
      message.info("Invalid credentials");
    }
  };

  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  function myFunction1() {
    var x = document.getElementById("myInput1");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const allowed = {
    uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
    lowers: "qwertyuiopasdfghjklzxcvbnm",
    numbers: "1234567890",
    symbols: "!@#$%^&*",
  };
  const getRandomCharFromString = (str) =>
    str.charAt(Math.floor(Math.random() * str.length));
  const generatePassword = (length = 12) => {
    // password will be @Param-length, default to 8, and have at least one upper, one lower, one number and one symbol
    let pwd = "";
    pwd += getRandomCharFromString(allowed.uppers); //pwd will have at least one upper
    pwd += getRandomCharFromString(allowed.lowers); //pwd will have at least one lower
    pwd += getRandomCharFromString(allowed.numbers); //pwd will have at least one number
    pwd += getRandomCharFromString(allowed.symbols); //pwd will have at least one symbolo
    for (let i = pwd.length; i < length; i++)
      pwd += getRandomCharFromString(Object.values(allowed).join("")); //fill the rest of the pwd with random characters
    setNewPass(pwd);
    return pwd;
  };

  return (
    <>
      <div className="container">
        <div className="mt-4 d-flex justify-content-around" align="center">
          <StyledButton1
            style={{ alignContent: "flex-start" }}
            onClick={() => {
              localStorage.getItem("PlanID") == 1
                ? history.push("/dashboard")
                : history.push("/Extquestion2");
            }}
          >
            Return to Main Menu
          </StyledButton1>
        </div>
        <div className="text-center mt-3">
            <a
              onClick={() => {
                window.open(
                  `${BASE_URL}${JSON.parse(
                    loginPDF
                  )}`,
                  "_blank"
                );
              }}
            >
              <i
                style={{ color: theme, fontSize: "25px" }}
                class="fas fa-info-circle"
              ></i>
            </a>
          </div>
        <div
          class="card mx-auto mt-3 Width100 shadow"
          style={{ width: "45%", borderRadius: 20 }}
        >
          <div
            class="card-header pt-3 pb-3 text-center"
            style={{
              background: `${theme}`,
              color: "white",
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            Change Your Password
          </div>

          <div class="card-body">
            <div class="form-group mt-3">
              <label style={{ fontWeight: "bold", fontSize: 16 }}>
                Old Password
              </label>
              <div class="input-group">
                <input
                  type="password"
                  id="myInput"
                  className="form-control"
                  value={OldPass}
                  onChange={(e) => setOldPass(e.target.value)}
                  placeholder=" Enter Password"
                  class="form-control"
                />
                <span
                  class="input-group-text"
                  style={{ background: "white", border: "1 px solid gray" }}
                >
                  {" "}
                  &nbsp;
                  <a onClick={() => myFunction()}>
                    <i class="fas fa-eye"></i>
                  </a>
                </span>
              </div>
            </div>
            {formErrors.OldPassError && (
              <div>
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.OldPassError}
                </p>
              </div>
            )}

            <div class="form-group mt-3">
              <label style={{ fontWeight: "bold", fontSize: 16 }}>
                New Password
              </label>
              <div class="input-group">
                <input
                  type="password"
                  id="myInput1"
                  className="form-control"
                  value={NewPass}
                  onChange={(e) => {
                    setNewPass(e.target.value);
                    console.log(e.target.value);
                  }}
                  placeholder="Enter Password"
                  required=""
                />
                <span
                  class="input-group-text"
                  style={{ background: "white", border: "1 px solid gray" }}
                >
                  {" "}
                  &nbsp;
                  <a onClick={() => generatePassword()}>
                    <i class="fa fa-plus-square"></i>
                  </a>
                </span>
                <span
                  class="input-group-text"
                  style={{ background: "white", border: "1 px solid gray" }}
                >
                  {" "}
                  &nbsp;
                  <a onClick={() => myFunction1()}>
                    <i class="fas fa-eye"></i>
                  </a>
                </span>
              </div>
            </div>
            {formErrors.NewPassError && (
              <div>
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.NewPassError}
                </p>
              </div>
            )}

            <div className="text-center mt-3">
              <button
                type="button"
                className="mobileButton"
                style={{
                  width: "40%",
                  paddingTop: 5,
                  paddingBottom: 5,
                  color: theme,
                  background: "white",
                  fontSize: 20,
                  fontWeight: "bold",
                  borderRadius: "25px",
                  border: `1px solid ${theme}`,
                }}
                onClick={() => {
                  ChangePasswordVarify();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;