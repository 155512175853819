import axios from "axios";
import  { BASE_URL} from '../url/baseurl'

export const cuz_details = async (data) => {
    let response = axios.post(`${BASE_URL}/api/cuz_cust_desc`,data);
    return response;
}

export const priority_list = async () => {
    let response = axios.get(`${BASE_URL}/api/qpriority_list`);
    return response;
}

export const ReportingCategoryList = async (data) => {
    let response = axios.post(`${BASE_URL}/api/ReportingCategoryList`,data);
    return response;
}

export const guidance_list = async (data) => {
    let response = axios.post(`${BASE_URL}/api/guidance_list`,data);
    return response;
}

export const warining = async (data) => {
    let response = axios.post(`${BASE_URL}/api/warn_out_of_seq`,data);
    return response;
}

export const get_qcode = async (data) => {
    let response = axios.post(`${BASE_URL}/api/get_qcode`,data);
    return response;
}