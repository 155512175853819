import axios from "axios";
import { BASE_URL } from "../url/baseurl";

export const register_program_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/register_program`, data);
  return response;
};

export const login_program_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/login_program`, data);
  return response;
};

export const two_factor_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/two_factor`, data);
  return response;
};

export const code_check_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/code_check`, data);
  return response;
};

export const after_successful_login_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/after_successful_login`, data);
  return response;
};

export const custfeature_api = async (data) => {
  console.log(data);
  let response = axios.put(`${BASE_URL}/api/custfeature`, data);
  return response;
};

export const user_session_data_access_api = async (data) => {
  console.log(data);
  let response = axios.get(
    `${BASE_URL}/api/user_session_data_access?userid=${data}`
  );
  return response;
};

export const topicprogram_api = async () => {
  let response = axios.get(`${BASE_URL}/api/topicprogram`);
  return response;
};

export const all_topic_grp_topic_api = async () => {
  let response = axios.get(`${BASE_URL}/api/all_topic_data`);
  return response;
};

export const user_session_data_enroll_api = async ({ userid, selpymtid }) => {
  let response = axios.get(
    `${BASE_URL}/api/user_session_data_enroll?userid=${userid}&selpymtid=${selpymtid}`
  );
  return response;
};

export const user_session_data_configfree_api = async (data) => {
  console.log(data);
  let response = axios.post(
    `${BASE_URL}/api/user_session_data_configfree`,
    data
  );
  return response;
};

export const order_creation_api = async (data) => {
  // console.log(data);
  let response = axios.post(`${BASE_URL}/api/order_creation`, data);
  return response;
};

export const free_14_days_trial_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/free_14_days_trial`, data);
  return response;
};

export const forgot_password_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/forgot_password`, data);
  return response;
};

export const forgot_password_verify_api = async (data) => {
  const token = localStorage.getItem("F_Token");
  console.log(data);
  let res = await axios.post(BASE_URL + "/api/forgot_password_verify", data, {
    headers: { Authorization: "Bearer " + token },
  });
  console.log(res);
  return res;
};

export const subscription_api = async (data) => {
  // console.log(data);
  let response = axios.post(`${BASE_URL}/api/subscription`, data);
  return response;
};

export const cancel_subscription_api = async (data) => {
  // console.log(data);
  let response = axios.post(`${BASE_URL}/api/cancel_subscription`, data);
  return response;
};

export const user_session_data_subpymt_api = async (data) => {
  // console.log(data);
  let response = axios.get(
    `${BASE_URL}/api/user_session_data_subpymt?userid=${data}`
  );
  return response;
};

export const user_session_data_subpymt_client_api = async (data) => {
  // console.log(data);
  let response = axios.get(
    `${BASE_URL}/api/user_session_data_subpymt_client?userid=${data}`
  );
  return response;
};

export const user_session_data_custsetup_sp_api = async ({
  sp_userid,
  ep_userid,
}) => {
  let response = axios.get(
    `${BASE_URL}/api/user_session_data_custsetup?sp_userid=${sp_userid}&ep_userid=${
      ep_userid + ""
    }`
  );
  return response;
};

export const user_session_data_custsetup_ep_api = async ({
  sp_userid,
  ep_userid,
}) => {
  let response = axios.get(
    `${BASE_URL}/api/user_session_data_custsetup?ep_userid=${ep_userid}&sp_userid=${
      sp_userid + ""
    }`
  );
  return response;
};

export const dashboard_api = async () => {
  let response = axios.get(`${BASE_URL}/api/dashboard`);
  return response;
};

export const dashboard_topicprogram_api = async () => {
  let response = axios.get(`${BASE_URL}/api/dashboard_topicprogram`);
  return response;
};

export const dashboard_all_topic_grp_topic_api = async (topgrpcode) => {
  console.log(topgrpcode);
  let response = axios.get(
    `${BASE_URL}/api/dashboard_all_topic_grp_topic?topgrpcode=[${topgrpcode}]`
  );
  return response;
};

export const edit_program_api = async (data) => {
  console.log(data);
  let response = axios.put(`${BASE_URL}/api/edit_program`, data);
  return response;
};

export const getupdate_details_api = async ({userid}) => {
  //console.log(data);
  let response = axios.get(`${BASE_URL}/api/getupdate_details?userid=${userid}`);
  return response;
};

export const reset_password_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/reset_password`, data);
  return response;
};

export const edit_suggestion_program_data_api = async (data) => {
  console.log(data);
  let response = axios.put(
    `${BASE_URL}/api/update_build_report_and_access_privilliges`,
    data
  );
  return response;
};

export const free_test_availability_api = async (data) => {
  console.log(data);
  let response = axios.get(
    `${BASE_URL}/api/free_test_availability?userid=${data.userid}&topiccodes=${data.topiccodes}`
  );
  console.log(response);
  return response;
};

export const free_initialstep_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/free_initialstep`, data);
  return response;
};

export const free_responsequiz_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/free_responsequiz`, data);
  return response;
};

export const free_revresponsequiz_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/free_revresponsequiz`, data);
  return response;
};

export const free_sugg_build_report_and_access_privilliges_api = async (
  data
) => {
  console.log(data);
  let response = axios.get(
    `${BASE_URL}/api/sugg_build_report_and_access_privilliges?topiccode=${data.topiccode}&userid=${data.userid}&guideselect=${data.guideselect}&useremail=${data.useremail}`
  );
  return response;
};

export const free_sugg_update_build_report_and_access_privilliges_api = async (
  data
) => {
  console.log(data);
  let response = axios.put(
    `${BASE_URL}/api/sugg_update_build_report_and_access_privilliges`,
    data
  );
  return response;
};

export const highlight_topicgrp_api = async (userid) => {
  console.log(userid);
  let response = axios.get(
    `${BASE_URL}/api/highlight_topicgrp?userid=${userid}`
  );
  console.log(response);
  return response;
};

export const free_add_custadmin_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/free_add_custadmin`, data);
  return response;
};

export const selection_proceedchange_api = async (data) => {
  console.log(data);
  let response = axios.put(`${BASE_URL}/api/selection_proceedchange`, data);
  return response;
};

export const planendcheck_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/planendcheck`, data);
  return response;
};

export const custplan_new_subscription_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/custplan_new_subscription`, data);
  return response;
};
export const guvrixadmin_check_api = async (data) => {
  console.log(data);
  let response = axios.post(`${BASE_URL}/api/guvrixadmin_check`, data);
  return response;
};