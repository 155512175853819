import React, { useState } from "react";
import styled from "styled-components";
import InfoIcon from "../assets/icons8-information-64 1.png";
import { CuzTopicUIAPI } from "../services/CuzTopicUIAPI";
import * as Database from "../services/Database"
import { useParams, useHistory } from "react-router-dom"
const theme = localStorage.getItem('brandColor');

const StyledDiv = styled.div`
  justify-content: center;
  margin: 0 auto;
  margin-top: 25px;
`;

const StyledTable = styled.table`
  width: 100vw;
  color: #757373;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  color: #ffffff;
`;

const StyledTableRow = styled.tr`
  font-weight: bold;
`;

const StyledTableHeaderData = styled.th`
  padding: 15px;
  text-align: center;
`;

const StyledTableRowData = styled.td`
  padding: 15px;
  text-align: center;
`;

const WarningTableRow = styled.tr`
  background-color: red;
  color: white;
`

function CuzTopicUIQuestionTable(route) {
  const [data, setData] = useState([]);
  const params = useParams()
  const history = useHistory()

  const getTableData = async () => {
    await CuzTopicUIAPI({ topicid: params.id })
      .then((res) => {
        setData(res.data.existing_questionario);
        console.log(res.data.existing_questionario);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getTableData();
    console.log(params)
  }, []);



  return (
    <div>
      <StyledDiv>
        <StyledTable>
          <thead>
            <StyledTableHeader>
              <StyledTableHeaderData>Question Number</StyledTableHeaderData>
              <StyledTableHeaderData>Question</StyledTableHeaderData>
              <StyledTableHeaderData>Question Sequence</StyledTableHeaderData>
              <StyledTableHeaderData>IfParentYes</StyledTableHeaderData>
              <StyledTableHeaderData>IfParentNo</StyledTableHeaderData>
              <StyledTableHeaderData>Priority</StyledTableHeaderData>
              <StyledTableHeaderData>Guidance</StyledTableHeaderData>
              <StyledTableHeaderData>SolutionIf</StyledTableHeaderData>
              <StyledTableHeaderData>Solution</StyledTableHeaderData>
              <StyledTableHeaderData>Activate Question</StyledTableHeaderData>
              <StyledTableHeaderData>Reporting Category</StyledTableHeaderData>
              <StyledTableHeaderData></StyledTableHeaderData>
            </StyledTableHeader>
          </thead>
          <tbody>
            {/* activeq: "N"
            created_at: null
            cuzguidedesc: "Guidance text2 used for this topic"
            guidanceid: 2
            ifparentno: 1
            ifparentyes: 0
            lastupdate: "0000-00-00 00:00:00"
            prioritydesc: "Low"
            priorityid: 4
            qcode: 2
            qdescr: "Need directions?"
            questionseq: 20
            repcatdesc: "Management"
            repcatid: 2
            solutiondesc: "Use Google Maps or Waze with our city address."
            solutionif: "Y"
            topicid: 1002
            updated_at: null
            userid: localStorage.getItem("UserID") */}

            {data?.map((data, i) => {

              return <StyledTableRow>
                <StyledTableRowData>{i + 1}</StyledTableRowData>
                <StyledTableRowData>{data.qdescr}</StyledTableRowData>
                <StyledTableRowData>{data.questionseq}</StyledTableRowData>
                <StyledTableRowData>{data.ifparentyes}</StyledTableRowData>
                <StyledTableRowData>{data.ifparentno}</StyledTableRowData>
                <StyledTableRowData>{data.prioritydesc}</StyledTableRowData>
                <StyledTableRowData>{data.cuzguidedesc}</StyledTableRowData>
                <StyledTableRowData>{data.solutionif}</StyledTableRowData>
                <StyledTableRowData>{data.solutiondesc}</StyledTableRowData>
                <StyledTableRowData>{data.activeq}</StyledTableRowData>
                <StyledTableRowData>{data.repcatdesc}</StyledTableRowData>
                <StyledTableRowData onClick={() => { history.push("/ExtQuestion/" + params.id) }}><img src={InfoIcon} alt="InfoImage" /></StyledTableRowData>
              </StyledTableRow>


            })}


          </tbody>
        </StyledTable>
      </StyledDiv>
    </div>
  );
}

export default CuzTopicUIQuestionTable;

