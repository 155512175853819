import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { useStripe, useElements, 
   CardNumberElement,
  CardExpiryElement,
  CardCvcElement,  
  CardElement } from "@stripe/react-stripe-js";
import PulseLoader from "react-spinners/PulseLoader";
import CardSection from "./CardSection";
import {
  order_creation_api,
  subscription_api,
  user_session_data_subpymt_api,
  user_session_data_custsetup_sp_api,
  custfeature_api,
  user_session_data_access_api,
  free_14_days_trial_api,
  user_session_data_subpymt_client_api,
  selection_proceedchange_api,
  custplan_new_subscription_api,
} from "../../../services/NewUI_API";
import "../../../App.css"
import { BASE_URL } from "../../../url/baseurl";


const theme = localStorage.getItem('brandColor');
const customer = localStorage.getItem('customerid');


// import { BASE_URL1 } from "../../../../assets/credit_card";


export default function CheckoutForm() {

console.log(localStorage.getItem('brandColor'), "brand");


  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [planid, setPlanid] = useState(localStorage.getItem("Enroll_PlanID"));
  const stripe = useStripe();
  const elements = useElements();  

  useEffect(() => {
    
    if (localStorage.getItem("Renew_Plan") === "Yes"){
 
      setPlanid(localStorage.getItem("RenewPlanID"));
    }
    console.log("Enroll_PlanID", localStorage.getItem("Enroll_PlanID"));
    console.log("RenewPlanID", localStorage.getItem("Renew_Plan"));
  }, []);
  let EnrData = JSON.parse(localStorage.getItem("EnrData"));
  console.log(EnrData);

  function stripeTokenHandler(token) {
    console.log(token.id);
    setLoader(true);

    if (localStorage.getItem("Renew_Plan") === "Yes") {
      order_creation_api({
        userid: localStorage.getItem("Renew_Plan_UserId"),
        // userid: localStorage.getItem("UserID"),
        stripe_token: token.id,
        price: localStorage.getItem("price_RP"),
      })
        .then((res) => {
          if (res.data.code == 200 && res.data.message == "Success") {
            console.log(res);

            subscription_api({
              userid: localStorage.getItem("Renew_Plan_UserId"),
              subscription: res.data.subscription,
              customer: res.data.customer,
              plan_id: localStorage.getItem("RenewPlanID"),
            })
              .then((res1) => {
                if (res1.data.code == 200) {
                  console.log(res1);

                  custplan_new_subscription_api({
                    userid: localStorage.getItem("Renew_Plan_UserId"),
                  })
                    .then((res2) => {
                      console.log(res2);
                      if (res.data.code === 200) {
                        message.success("Successful");
                        history.push("/login");
                      }
                    })
                    .catch((err2) => {
                      console.log(err2);
                    });
                }
              })
              .catch((err1) => {
                console.log(err1);
              });
          }else if(res.data.code == 500){
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
 
        });
    } else {
      selection_proceedchange_api({
        Enrolling: EnrData.Enrolling,
        Newcust: EnrData.Newcust,
        Priorfree: EnrData.Priorfree,
        customerid: EnrData.customerid,
        selpymtid: EnrData.selpymtid,
        userid: localStorage.getItem("UserID") || localStorage.getItem("E_Userid"),
      })
        .then((resp) => {
          console.log(resp);
        })
        .catch((errr) => {
          console.log(errr);
        });

      localStorage.getItem("Client_PlanID") == null
        ? (localStorage.getItem("Enroll_PlanID") == 4
            ? free_14_days_trial_api
            : order_creation_api)({
            userid:
              localStorage.getItem("E_Userid") ||
              localStorage.getItem("UserID"),
            stripe_token: token.id,
            price: localStorage.getItem("price_EP"),
          })
            .then((res) => {
              if (res.data.code == 200 && res.data.message == "Success") {
                console.log("Stripe Payment==>", res.data);
                message.success(res.data.message);

                subscription_api({
                  userid:
                    localStorage.getItem("E_Userid") ||
                    localStorage.getItem("UserID"),
                  subscription: res.data.subscription,
                  customer: res.data.customer,
                  plan_id: localStorage.getItem("Enroll_PlanID"),
                })
                  .then((res1) => {
                    if (res1.data.code == 200) {
                      console.log(res1);
                      localStorage.setItem("PlanID", res1.data.Data.plan_id)

                      user_session_data_subpymt_api(
                        localStorage.getItem("E_Userid") ||
                          localStorage.getItem("UserID")
                      )
                        .then((res2) => {
                          if (res2.data.code == 200) {
                            console.log(res2);

                            user_session_data_custsetup_sp_api({
                              sp_userid:
                                localStorage.getItem("E_Userid") ||
                                localStorage.getItem("UserID"),
                              ep_userid: null,
                            })
                              .then((res3) => {
                                if (res3.data.code == 200) {
                                  console.log(res3);

                                  let data3 = {
                                    userid:
                                      localStorage.getItem("E_Userid") ||
                                      localStorage.getItem("UserID"),
                                  };

                                  custfeature_api(data3)
                                    .then((res4) => {
                                      console.log(res4);
                                      if (res4.data.code === 200) {
                                        console.log(
                                          "cust feature res4 data==>",
                                          res4.data
                                        );
                                        // message.success("Successfully3 !");

                                        user_session_data_access_api(
                                          localStorage.getItem("E_Userid") ||
                                            localStorage.getItem("UserID")
                                        )
                                          .then((res5) => {
                                            console.log(res5);
                                            if (res5.data.code === 200) {
                                              setLoader(false);
                                              console.log(
                                                "user session res5 data==>",
                                                res5.data
                                              );
                                              res3.data.message ==
                                              "Begin_program"
                                                ? 
                                                (window.location.href =
                                                    "/extquestion2")
                                                : (window.location.href =
                                                    "/login");
                                            } else {
                                              console.log(res5.data.message);
                                            }
                                          })
                                          .catch((err5) => {
                                            setLoader(false);
                                            console.log("error usd--->>", err5);
                                          });
                                      } else {
                                        // message.error(res3.data.message);
                                        console.log(res3.data.message);
                                      }
                                    })
                                    .catch((err4) => {
                                      setLoader(false);
                                      console.log("error cf--->>", err4);
                                    });
                                } else {
                                  message.error("Something went wrong");
                                }
                              })
                              .catch((err3) => {
                                setLoader(false);
                                console.log(err3);
                              });
                          } else {
                            message.error("Something went wrong");
                          }
                        })
                        .catch((err2) => {
                          setLoader(false);
                          console.log(err2);
                        });
                    } else {
                      message.error("Something went wrong");
                    }
                  })
                  .catch((err1) => {
                    setLoader(false);
                    console.log(err1);
                  });
              }else if(res.data.code == 500){
                message.error(res.data.message);
              }
               else {
                message.error("Something went wrong");
                // history.push("/topics_program")
              }
            })
            .catch((err) => {
              setLoader(false);
              console.log("error payment===>", err);
              message.error(err.response.data.message);
            })
        : order_creation_api({
            // userid: 100000050,
            userid: localStorage.getItem("UserID"),
            stripe_token: token.id,
            price: localStorage.getItem("price_SP"),
          })
            .then((res) => {
              if (res.data.code == 200 && res.data.message == "Success") {
                console.log("Stripe Payment==>", res.data);
                message.success(res.data.message);

                subscription_api({
                  userid: localStorage.getItem("UserID"),
                  subscription: res.data.subscription,
                  customer: res.data.customer,
                  plan_id: localStorage.getItem("Client_PlanID"),
                })
                  .then((res1) => {
                    if (res1.data.code == 200) {
                      console.log(res1);

                      user_session_data_subpymt_client_api(
                        localStorage.getItem("UserID")
                      )
                        .then((res2) => {
                          if (res2.data.code == 200) {
                            console.log(res2);

                            if (res2.data.message == "custsetup") {
                              user_session_data_custsetup_sp_api({
                                sp_userid: localStorage.getItem("UserID"),
                                ep_userid: null,
                              })
                                .then((res3) => {
                                  if (res3.data.code == 200) {
                                    console.log(res3);

                                    let data3 = {
                                      userid: localStorage.getItem("UserID"),
                                    };

                                    custfeature_api(data3)
                                      .then((res4) => {
                                        console.log(res4);
                                        if (res4.data.code === 200) {
                                          console.log(
                                            "cust feature res4 data==>",
                                            res4.data
                                          );
                                          // message.success("Successfully3 !");

                                          user_session_data_access_api(
                                            localStorage.getItem("UserID")
                                          )
                                            .then((res5) => {
                                              console.log(res5);
                                              if (res5.data.code === 200) {
                                                setLoader(false);
                                                console.log(
                                                  "user session res5 data==>",
                                                  res5.data
                                                );
                                                // message.success("Successfully4 !");
                                                history.push("/extquestion2");
                                              } else {
                                                // message.error(res5.data.message);
                                                console.log(res5.data.message);
                                              }
                                            })
                                            .catch((err5) => {
                                              setLoader(false);
                                              console.log(
                                                "error usd--->>",
                                                err5
                                              );
                                            });
                                        } else {
                                          // message.error(res3.data.message);
                                          console.log(res3.data.message);
                                        }
                                      })
                                      .catch((err4) => {
                                        setLoader(false);
                                        console.log("error cf--->>", err4);
                                      });
                                  } else {
                                    message.error("Something went wrong");
                                  }
                                })
                                .catch((err3) => {
                                  setLoader(false);
                                  console.log(err3);
                                });
                            } else {
                              history.push(
                                `/clientfunction/en/enrollclient/${localStorage.getItem(
                                  "CrID"
                                )}`
                              );
                            }
                          } else {
                            message.error("Something went wrong");
                          }
                        })
                        .catch((err2) => {
                          setLoader(false);
                          console.log(err2);
                        });
                    } else {
                      message.error("Something went wrong");
                    }
                  })
                  .catch((err1) => {
                    setLoader(false);
                    console.log(err1);
                  });
              } else if(res.data.code == 500 ){
                message.error(res.data.message);
              }
              else {
                message.error("Something went wrong");
                // history.push("/topics_program")
              }
            })
            .catch((err) => {
              setLoader(false);
              console.log("error payment===>", err);
              message.error(err.response.data.message);
            });
    }
  }

  const handleSubmit = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    console.log("loader ==>",loader);
    if(loader == false){
      setLoader(true);
    console.log("userid",localStorage.getItem("Renew_Plan_UserId"));
    console.log("price",localStorage.getItem("price_EP"));

    console.log("price",localStorage.getItem("Renew_Plan"));

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement); 

    // const numbercard = elements.getElement(CardNumberElement); 
    // const expirycard = elements.getElement(CardExpiryElement); 
    // const cvvcard = elements.getElement(CardCvcElement); 
    // const CardElement = elements.getElement(CardElement); 
    
    console.log("CardElement ==>",card);
    // console.log("expirycard ==>",expirycard);
    // console.log("cvvcard ==>",cvvcard);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });

    if (error) {
      message.error(error.message);
    } else {
      // Payment method created successfully, submit the form
      // to your server for further processing
      console.log("payment ==>",paymentMethod);
    }
    const result = await stripe.createToken(card);
              
    

    // const result = await stripe.createToken(numbercard,expirycard,cvvcard);

    if (result.error) {
      // Show error to your customer.
      console.log(result.error.message);
      setLoader(false);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      stripeTokenHandler(result.token);
      console.log(result.token);
    }
  }
  };

  return (
    <>
    {customer == null || !customer ?
      <div className="container">
         
         {/* <div  className="div-left" > 
          <img  className="card-img"src={BASE_URL+'/uploads/customer/credit_card.png'} alt='image'></img>
         </div>

         <div className="div-right">  

           <h2 className="card-h2"><b> Card details </b></h2>
           <div class="card-body">
            <CardSection />
            <div style={{marginTop: "8px"}}>
              <button
                type="button"
                style={{
                  background: theme,
                  background: "#ff8c24",
                  color: "white",
                  width: "125px",
                  height: "35px",
                  borderRadius: "5px",
                  marginTop: "10px",
                  fontSize: "17px",
                  border: "none",
                }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loader == true ? (
                  <PulseLoader size={10} color={"#fff"} />
                ) : (
                  "Confirm order"
                )}
              </button>
            </div>
            <div>

         </div></div>
        </div> */}


<div class="mx-auto" style={{marginTop: "25px" , boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",width: "fit-content", padding: "25px"}}>
          <h3 style={{ textAlign: "Center" ,color: "#ff8c24"}}> Card Payment</h3>
          <hr style={{ margin:"0 0 25px 0 "}}></hr>
         
          {planid == 2 ? (
        <> 

              <h5> Plan :  Small Business</h5>
             <h5>   Price :  $360</h5>
   
        </>
           
        ) : planid == 3 ? (<>
           <h5> Plan :  Enterprise</h5>
             <h5>   Price :  $840</h5>
    

        </>
        ): planid == 4 ? (<> 
           <h5> Plan :  60 Days Trial</h5>
             <h5>   Price :  $0</h5>
   

                 
        </>):planid == 5 ? (<> 
          <h5> Plan :   Enterprise + Custom</h5>
             <h5>   Price :  $1080</h5>

   
        </>):planid == 6 ? (<> 
          <h5> Plan :   Consultants</h5>
             <h5>   Price :  $1440 </h5>


        </>):(<></>)}
      

          <CardSection />

          <div className="text-center">
              <button
                type="button"
                className="mobileButton"
                style={{

                 // background: theme,
                 background: "#ff8c24",
                 color: "white",
                 width: "125px",
                 height: "35px",
                 borderRadius: "5px",
                 marginTop: "10px",
                 fontSize: "17px",
                 border: "none",
                }}

                onClick={() => {
                  handleSubmit();
                }}
              
              >
               {loader == true ? (
                  <PulseLoader size={10} color={"#fff"} />
                ) : (
                  "Confirm order"
                )}
              </button>
            </div>

        </div>

        {/* <div
          class="card mx-auto mt-5 Width100"
          style={{ width: "39%", borderRadius: "20px" }}
        >
          <div
            class="card-header pt-3 pb-3 text-center"
            style={{
              background: "#ff8c24",
              color: "white",
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <i class="fa fa-credit-card"></i>&nbsp; Card details
          </div>
          <div class="card-body">
            <CardSection />
            <div>
              <button
                type="button"
                style={{
                  background: "#ff8c24",
                  color: "white",
                  width: "125px",
                  height: "35px",
                  borderRadius: "5px",
                  marginTop: "10px",
                  fontSize: "17px",
                  border: "none",
                }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loader == true ? (
                  <PulseLoader size={10} color={"#fff"} />
                ) : (
                  "Confirm order"
                )}
              </button>
            </div>
          </div>
        </div> */}
      </div>
      :
      <div className="container"  style={{
      display: "flex",
      alignItems: "center",
      height: "400px"
      }}>
        {/* <div  className="div-left" > 
          <img  className="card-img"src={BASE_URL+'/uploads/customer/credit_card.png'} alt='image'></img>
         </div>

         <div className="div-right"
          // class=" mt-5 Width100"
          // style={{ width: "50%", marginTop: "45px",float: "right", }}
        >  

           <h2 className="card-h2"><b> Card details </b></h2>
           <div class="card-body">
            <CardSection />
            <div style={{marginTop: "8px"}}>
              <button
                type="button"
                style={{
                  // background: theme,
                  background: "#ff8c24",
                  color: "white",
                  width: "125px",
                  height: "35px",
                  borderRadius: "5px",
                  marginTop: "10px",
                  fontSize: "17px",
                  border: "none",
                }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loader == true ? (
                  <PulseLoader size={10} color={"#fff"} />
                ) : (
                  "Confirm order"
                )}
              </button>
            </div>
            <div>

         </div></div>
        </div> */}
        
       
   

        <div class="mx-auto" style={{marginTop: "30px" , boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",width: "fit-content", padding: "25px"}}>
          <h3 style={{ textAlign: "Center" ,color: "#ff8c24"}}> Card Payment</h3>
          <hr style={{ margin:"0 0 25px 0 "}}></hr>
         
          {planid == 2 ? (
        <> 
     
     <h5> Plan :  Small Business</h5>
             <h5>   Price :  $360</h5>
   
        </>
           
        ) : planid == 3 ? (<>
          
          <h5> Plan : Enterprise</h5>
             <h5>   Price :  $840</h5>


        </>
        ): planid == 4 ? (<> 
         
         <h5> Plan :   60 Days Trial</h5>
             <h5>   Price :  $0</h5>


                 
        </>):planid == 5 ? (<> 
          <h5> Plan :   Enterprise + Custom</h5>
             <h5>   Price :  $1080</h5>

   
        </>):planid == 6 ? (<> 

          <h5> Plan :  Consultants</h5>
             <h5>   Price :  $1440</h5>


        </>):(<></>)}
      

          <CardSection />

          <div className="text-center">
              <button
                type="button"
                className="mobileButton"
                style={{

                 // background: theme,
                 background: "#ff8c24",
                 color: "white",
                 width: "125px",
                 height: "35px",
                 borderRadius: "5px",
                 marginTop: "10px",
                 fontSize: "17px",
                 border: "none",
                }}

                onClick={() => {
                  handleSubmit();
                }}
              
              >
               {loader == true ? (
                  <PulseLoader size={10} color={"#fff"} />
                ) : (
                  "Confirm order"
                )}
              </button>
            </div>

        </div>



        {/* <div
          class="card float-right mt-5 Width100"
          style={{ width: "39%", float: "right",borderRadius: "20px" }}
        >
          <div
            class="card-header pt-3 pb-3 text-center"
            style={{
              background: theme,
              color: "white",
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <i class="fa fa-credit-card"></i>&nbsp; Card details
          </div>
          <div class="card-body">
            <CardSection />
            <div>
              <button
                type="button"
                style={{
                  background: theme,
                  color: "white",
                  width: "125px",
                  height: "35px",
                  borderRadius: "5px",
                  marginTop: "10px",
                  fontSize: "17px",
                  border: "none",
                }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loader == true ? (
                  <PulseLoader size={10} color={"#fff"} />
                ) : (
                  "Confirm order"
                )}
              </button>
            </div>
          </div>
        </div> */}
      </div>
                }
    </>
  );
}
