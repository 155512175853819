import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { message, Tooltip, Collapse } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  topicprogram_api,
  all_topic_grp_topic_api,
} from "../../services/NewUI_API";

const { Panel } = Collapse;

const Topics_Program = () => {
  const history = useHistory();

  const [TopicGroupData, setTopicGroupData] = useState([]);
  const [SubGroupData, setSubGroupData] = useState([]);
  const [SubTCode, setSubTCode] = useState();
  const [showBTN, setShowBTN] = useState(false);
  const [showAllData, setShowAllData] = useState(false);

  const ReceiveTopicGroupData = async () => {
    topicprogram_api()
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          setTopicGroupData(res.data.Topicdesc);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("something went wrong");
      });
  };

  const TopicSubData = async () => {
    all_topic_grp_topic_api()
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          setSubGroupData(res.data.Topicdesc);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("something went wrong");
      });
  };

  useEffect(() => {
    TopicSubData();
    ReceiveTopicGroupData();
  }, []);

  $(document).ready(function () {
    $("#myFilter").on("keyup", function () {
      setShowAllData(true)
      var value = $(this).val().toLowerCase();
      $("a").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
      if(value == ""){
        setShowAllData(false)
      }
    });
  });

  function sortGroupData(a, b) {
    // a should come before b in the sorted order
    if (a.topgrpdesc < b.topgrpdesc) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.topgrpdesc > b.topgrpdesc) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }

  function sortData(a, b) {
    // a should come before b in the sorted order
    if (a.topicdesc < b.topicdesc) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.topicdesc > b.topicdesc) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }

  return (
    <>
      <div className="container">
        <div className="text-center mt-5">
          <span style={{ fontSize: 30, fontWeight: "bold", color: "#ff8c24" }}>
            TOPICS
          </span>
          <p style={{ fontWeight: "bold", fontSize: 16, letterSpacing: 0.5 }}>
            Filter topics and select a topic to start.
          </p>
        </div>

        <div className="row mt-5">
          <div className="col-5 Height100 Width100">
            <label style={{ fontWeight: "bold", fontSize: 16 }}>
              Filter by :
            </label>
            <div style={{ border: "1px solid #ff8c24" }} className="mt-2">
              <input
                type="text"
                id="myFilter"
                className="form-control"
                placeholder="Search for topics..."
              />
            </div>
            <div
              className="card"
              style={{
                border: "1px solid #ff8c24",
                height: "60%",
                overflow: "auto",
              }}
            >
              {showAllData == true
                ? SubGroupData?.sort(sortData)?.map((data) => {
                    return (
                      <>
                        <a
                          className="dropdown-item"
                          style={{ fontWeight: "bold", fontSize: 14 }}
                          onClick={() => {
                            setSubTCode(data.topiccode);
                            setShowBTN(true);
                          }}
                        >
                          <Tooltip title={data.topicsummary}>
                            <span>{data.topicdesc}</span>
                          </Tooltip>
                        </a>
                      </>
                    );
                  })
                : TopicGroupData?.sort(sortGroupData)?.map((data, i) => {
                    return (
                      <Collapse
                        expandIconPosition="right"
                        expandIcon={(e) => {
                          return e.isActive == false ? (
                            <PlusOutlined
                              style={{
                                color: "#ff8c24",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            />
                          ) : (
                            <MinusOutlined
                              style={{
                                color: "#ff8c24",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            />
                          );
                        }}
                      >
                        <Panel
                          key={i + 1}
                          showArrow={true}
                          header={
                            <a style={{ fontWeight: "bold", fontSize: 17 }}>
                              <Tooltip title={data.topgrpsummary}>
                                <span>{data.topgrpdesc}</span>
                              </Tooltip>
                            </a>
                          }
                        >
                          {SubGroupData?.sort(sortData)
                            ?.filter(
                              (data1) => data1.topgrpcode == data.topgrpcode
                            )
                            ?.map((data) => {
                              return (
                                <>
                                  <a
                                    className="dropdown-item"
                                    style={{ fontWeight: "bold", fontSize: 14 }}
                                    onClick={() => {
                                      setSubTCode(data.topiccode);
                                      setShowBTN(true);
                                    }}
                                  >
                                    <Tooltip title={data.topicsummary}>
                                      <span>{data.topicdesc}</span>
                                    </Tooltip>
                                  </a>
                                </>
                              );
                            })}
                        </Panel>
                      </Collapse>
                    );
                  })}
            </div>
          </div>

          <div className="col-7 Height100 Width100 mt-5">
            <p style={{ fontWeight: "bold", fontSize: 22 }}>
              {SubGroupData?.filter((item) => item.topiccode == SubTCode)?.map(
                (item) => item.topicdesc
              )}
            </p>
            <p style={{ fontWeight: "bold", fontSize: 15 }}>
              {SubGroupData?.filter((item) => item.topiccode == SubTCode)?.map(
                (item) => {
                  return (
                    <>
                      <p>{item.explained[0]}</p>
                      <p>{item.explained[1]}</p>
                      <p>{item.explained[2]}</p>
                      <p>{item.explained[3]}</p>
                      <p>{item.explained[4]}</p>
                      <p>{item.explained[5]}</p>
                      <p>{item.explained[6]}</p>
                      <p>{item.explained[7]}</p>
                      <p>{item.explained[8]}</p>
                      <p>{item.explained[9]}</p>
                      <p>{item.explained[10]}</p>
                    </>
                  );
                }
              )}
            </p>
            {showBTN == true ? (
              <button
                type="button"
                className="mobileButton"
                style={{
                  width: "20%",
                  paddingTop: 8,
                  paddingBottom: 8,
                  color: "#ff8c24",
                  background: "white",
                  fontSize: 20,
                  fontWeight: "bold",
                  borderRadius: "25px",
                  border: "1px solid #ff8c24",
                  marginBottom: 10,
                }}
                onClick={() => {
                  history.push("/login");
                }}
              >
                Launch
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Topics_Program;
