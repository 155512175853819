import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataGrid, useGridApiContext, useGridApiRef  } from "@mui/x-data-grid";
import { get_AI_Assistant_Question_Results_list_Edit } from "../../../services/AdminProgramApi";
import { update_AI_Assistant_Question_Result } from "../../../services/AdminProgramApi";
import { useParams } from "react-router-dom";
import { message } from "antd";
import InputBase from "@mui/material/InputBase";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/styles";
const theme = localStorage.getItem("brandColor");

const StyledButton = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: 500;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const useStyles = makeStyles({
  root: {
    height: "400px",
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});

const AI_Assistant_Question_Results_list_Edit = ({ match }) => {
  const apiReference = useGridApiRef();
  const Query_Code = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(100);
  const [aiQueryDetailedResults, setAiQueryDetailedResults] = useState([]);  
  const queryCode = match.params.Query_Code;
  const qrcode = match.params.QR_Code;
  const [userId] = useState(localStorage.getItem("UserID"));  

  let tempData = null;

  useEffect(() => {
    getAiQueryDetailedResultParametersForEdit(Query_Code);
  }, []);

  const getAiQueryDetailedResultParametersForEdit = async (Query_Code) => {
    try {
      const res = await get_AI_Assistant_Question_Results_list_Edit(Query_Code);
      if (res.data.code === 200) {
        let result = res.data.results;
        setAiQueryDetailedResults(result);
      } else {
        console.error(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCellEditCommit = (params) => {
    const updated_results = aiQueryDetailedResults.map((row, index) => {
      return ++index === params.id
        ? { ...row, [params.field]: params.value }
        : row;
    });
    setAiQueryDetailedResults(updated_results);
  };

  const handleRowChange = (params) => {  
    let data = {...params};        
    let val = data["1"];
    if(val){
      tempData = val
    }else{
      tempData = null
    }
    console.log(tempData)
  }

  function EditTextarea(props) {
    const { id, field, value, colDef, hasFocus } = props;
    const [valueState, setValueState] = React.useState(value);
    const [anchorEl, setAnchorEl] = React.useState();
    const [inputRef, setInputRef] = React.useState(null);    
    const apiRef = useGridApiContext();

    React.useLayoutEffect(() => {
      if (hasFocus && inputRef) {
        inputRef.focus();
      }
    }, [hasFocus, inputRef]);

    const handleRef = React.useCallback((el) => {
      setAnchorEl(el);
    }, []);

    const handleChange = React.useCallback(
      (event) => {
        const newValue = event.target.value;
        setValueState(newValue);
        apiRef.current.setEditCellValue(
          { id, field, value: newValue, debounceMs: 200 },
          event
        );
      },
      [apiRef, field, id]
    );

    return (
      <div style={{ position: "relative", alignSelf: "flex-start" }}>
        <div
          ref={handleRef}
          style={{
            height: 1,
            width: colDef.computedWidth,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        {anchorEl && (
          <Popper open anchorEl={anchorEl} placement="bottom-start">
            <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
              <InputBase
                multiline
                rows={4}
                value={valueState}
                sx={{ textarea: { resize: "both" }, width: "100%" }}
                onChange={handleChange}
                inputRef={(ref) => setInputRef(ref)}
              />
            </Paper>
          </Popper>
        )}
      </div>
    );
  }

  const columnsUsers = [
    {
      field: "QR_Question",
      headerName: "Question",
      flex: 0.3,
      align: "left",
      headerAlign: "center",
      editable: true,
      headerClassName: "super-app-theme--header",
      type: "string",
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
    {
      field: "QR_Guidance",
      headerName: "Guidance",
      flex: 0.3,
      align: "left",
      headerAlign: "center",
      editable: true,
      headerClassName: "super-app-theme--header",
      type: "string",
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
    {
      field: "QR_Solution",
      headerName: "Solution",
      flex: 0.2,
      align: "left",
      headerAlign: "center",
      editable: true,
      headerClassName: "super-app-theme--header",
      type: "string",
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
    {
      field: "QR_Accept",
      headerName: "Accept?",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      editable: true,
      headerClassName: "super-app-theme--header",
      type: "singleSelect",
      valueOptions: ["Accept", "Ignore"],
    },
  ];

  const rows = aiQueryDetailedResults.map((data, index) => ({
    ...data,
    id: index + 1,
  }));

  const handleSave = async (e) => {      
    let data = {
      ...aiQueryDetailedResults[0],
      Query_Code: queryCode,
      QR_Code: qrcode,
      UserID: userId,
    };
    if(tempData) {
      let key = Object.keys(tempData)[0];
      console.log(key)
      data[key] = tempData[key]["value"]
    }          
    try {
      const response = await update_AI_Assistant_Question_Result(data);
      if (response.status === 200) {
        history.push("/adminfunction/ai_result/" + queryCode);
        message.success("Updated successfully");
      } else {
        message.error("updated not successfully");
        console.log("update failed");
      }
    } catch {
      console.log("Api failed");
    }
  };



  const handleCancel = () => {
    history.push("/adminfunction/ai_result/" + queryCode);
  };

  return (
    <div className="container-fluid">
      <div className="mt-4 row">
        <div className=" d-flex justify-content-around" align="center">
          <StyledButton onClick={handleCancel}>Cancel</StyledButton>
          <StyledButton onClick={handleSave}>Save</StyledButton>
        </div>

        <div
          className={classes.root}
          style={{
            height: "78vh",
            width: "100%",
            marginTop: 30,
            overflow: "hidden",
          }}
        >
          <DataGrid
            apiRef={apiReference}
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff8c24",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            rows={rows}
            columns={columnsUsers}
            pageSize={pageSize}
            getRowHeight={() => "auto"}
            onEditRowsModelChange={handleRowChange}
            onCellEditCommit={handleCellEditCommit}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[100]}
            pagination
            hideFooterPagination={rows.length <= pageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default AI_Assistant_Question_Results_list_Edit;
