import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { get_available_custom_topics, create_custom_topic} from "../../../services/AdminProgramApi";
import { makeStyles } from "@material-ui/styles";
import { message } from "antd";

const theme = localStorage.getItem("brandColor");

const StyledButton = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: 500;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;  
`;


const StyledButtonST = styled.button`
  background: ${theme};
  width: 80px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  font-size: 15px !important;
  color: white;
  font-weight: 400;
  margin: 10px 0;
  border-color: none;
  border: none;
`;

const TextLeft = styled.div`  
  text-align:left;
`;

const useStyles = makeStyles({
  root: {
    height: "400px",
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});


const Available_Custom_Topics = ({ match }) => {
  const params = match.params;
  const query_code = params.Query_Code;
  const history = useHistory();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(100);  
  const [availableCustomTopics, setAvailableCustomTopics] = useState([]);
  const [userId] = useState(localStorage.getItem('UserID'));
  const [customerId] = useState(localStorage.getItem('customerid'));
  const [selectDisabled, setSelectDisabled] = useState(false)
  
  useEffect(() => {
    getAvailableCustomTopics(customerId);
  }, []);


  const getAvailableCustomTopics = async (customerId) => {
    try {
        const res = await get_available_custom_topics(customerId);
        if(res.data.code == 200){
            setAvailableCustomTopics(res.data.results)
        }
    } catch (err){
        console.log(err)
    }
  } 

  const SelectButton = ({ data  }) => {
    const handleStartClick = async () => {  
      setSelectDisabled(true)        
      const { topicid }  = data;    
      try {        
        const res = await create_custom_topic({
          topic_id : topicid,
          query_code : query_code,
          user_id : userId
        });
        if(res.data.code == 200){
          message.success(res.data.message)
          history.push("/ExtQuestion/"+ topicid);
        }else{
          message.error(res.data.message)
          setSelectDisabled(false)
        }        
      } catch (err){
        message.error("Something went wrong. Please try again");
        setSelectDisabled(false)
        console.log(err)        
      }
      
    };

    return (
      <StyledButtonST disabled={selectDisabled} onClick={handleStartClick} style={{ width: 100, opacity: (selectDisabled ? 0.5 : 1) }}>
        Select
      </StyledButtonST>
    );
};

  const columnsUsers = [       
    {
      field: "cuzdesc",
      headerName: "Custom Topic Title",
      type: "text",
      flex: 0.2,      
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <TextLeft>{params.value}</TextLeft>,
    },
    {
      field: "cuzsummary",
      headerName: "Custom Topic Summary",
      flex: 0.3,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <TextLeft>{params.value}</TextLeft>      
    },
    {
      field: "select",
      headerName: "Select",
      type: "text",
      flex: 0.1,     
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <SelectButton data={params.row} />,
    },
  ];

  const rows = availableCustomTopics.map((data, index) => ({
    ...data,
    id: index + 1,    
  }));


  return (
    <div className="container-fluid">      
      <div className="mt-4 row" align="center">
        <div className=" d-flex justify-content-around" align="center">
          <StyledButton
            onClick={() => {
              history.push(`/adminfunction/ai_result_list/${customerId}`);
            }}
          >
            Exit
          </StyledButton>                  
        </div>

        <div
          className={classes.root}
          style={{
            height: "78vh",
            width: "100%",
            marginTop: 30,
            overflow: "hidden",
          }}
        >
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff8c24",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            rows={rows}
            columns={columnsUsers}
            pageSize={pageSize}
            getRowHeight={() => "auto"}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[100]}
            pagination
            hideFooterPagination={rows.length <= pageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default Available_Custom_Topics;
