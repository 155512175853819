import React, { useEffect, useRef, useState } from "react";
import MainHeader from "../components/MainHeader";
import { Link, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import "../App.css";
import ReactModal from "react-modal";
import axios from "axios";
import { BASE_URL } from "../url/baseurl";
import { guidance_table_data_api } from "../services/GuidanceApi";
import * as Database from "../services/Database";
import { message } from "antd";
import GuidanceData from "./GuidanceData";

const theme = localStorage.getItem('brandColor');

const StyledButton = styled.button`
  background: ${theme};
  width: 150px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  padding: 0px 20px;
  font-weight: 500;
  border-color: none;
  border: none;
  margin: 5px;
`;

const StyledDivButtons = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
`;

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
//   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`

const StyledFormInputDiv = styled.div`
  display: flex;

  margin-top: 4px;

  width: 100%;
`;

const Guidance = () => {
    const history = useHistory();
    const [isAddQuestion, setIsAddQuestion] = useState(false);
    const entercuzguidedescref = useRef();

    const [data, setData] = useState([]);
    const [warning, set_warning] = useState({});

    const params = useParams();

    const showQuestionModal = async () => {
        setIsAddQuestion(true);
    };

    const closeQuestionModal = () => {
        setIsAddQuestion(false);
    };

    const questionSubmitHandler = async (event) => {
        event.preventDefault();
        const questionObject = {
            topicid: params.id,
            cuzguidedesc: entercuzguidedescref.current.value,
        };
        console.log(questionObject);
        sendQuestionDataToAPIHandler(questionObject);
    };

    const sendQuestionDataToAPIHandler = async (data) => {
        const response = await axios
            .post(`${BASE_URL}/api/create_cuzguidance`, data)
            .then((res) => {
                console.log("====Guidance add>", res);
                if (res.data.code === 200) {
                    message.success("Guidance add successfully")
                    QuestionTableInputAPIs();
                    setIsAddQuestion(false);
                } else {
                    message.error("Something went wrong,please try again")
                }
            })
            .catch((err) => {
                console.log("guidance add error===>", err)
                message.error("Something went wrong,please try again")
            }

            );
    };
    const QuestionTableInputAPIs = async () => {
        await guidance_table_data_api({ topicid: params.id })
            .then((res) => {
                // alert(JSON.stringify(res))
                console.log("table  response guidence====>", res.data.guidance_list);
                // setData(
                //     res.data.existing_questionario?.map((data) => {
                //         data.color = "white";
                //         data.message = "";
                //         return data;
                //     })
                // );
                // console.log(res.data.existing_questionario);
                setData(res.data.guidance_list)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        QuestionTableInputAPIs();
    }, []);

    const get_warning = () => {
        Database.warining({ topicid: params.id }).then((res) => {
            console.log(res);
            set_warning(res.data);
            let resp = res.data;
            let datas = data?.map((data, index) => {
                if (
                    resp.warnactive === "" &&
                    resp.warnextrasol === "" &&
                    resp.warninactive === "" &&
                    resp.warnnextstep === "" &&
                    resp.warnoutofseq === "" &&
                    resp.warnrepcat === "" &&
                    resp.warnshortsol === ""
                ) {
                    return datas;
                } else {
                    if (resp.warnnextstep !== "") {
                        let code = resp.warnnextstep.qcode.trim().split(",");
                        console.log(code)
                        if (code?.map((data) => { return parseInt(data) }).includes(data.qcode) === true) {
                            data.color = "red";
                            data.message =
                                "'Warning only: No Solution has been provided and there is no Next Step";
                            return data;
                        } else {
                            return data;
                        }
                    }

                    if (resp.warnoutofseq !== "") {
                        let code = resp.warnnextstep.qcode.split(",");
                        if (code.includes(data.qcode) === true) {
                            data.color = "red";
                            data.message =
                                "Error: A Next Step question # must not reference a question # that comes after it.";
                            return data;
                        } else {
                            return data;
                        }
                    }

                    if (resp.warnextrasol !== "") {
                        let code = resp.warnnextstep.qcode.split(",");
                        if (code.includes(data.qcode) === true) {
                            data.color = "red";
                            data.message =
                                "Error: A Solution must not be provided if the 'Solution given' is not Yes or No. Remove text from Solution or change the 'Solution given' answer.";
                            return data;
                        } else {
                            return data;
                        }
                    }

                    if (resp.warnshortsol !== "") {
                        let code = resp.warnnextstep.qcode.split(",");
                        if (code.includes(data.qcode) === true) {
                            data.color = "red";
                            data.message =
                                "Error: A Solution must be provided if the 'Solution given' is Yes or No. Add a Solution or change the 'Solution given";
                            return data;
                        } else {
                            return data;
                        }
                    }

                    if (resp.warnrepcat !== "") {
                        let code = resp.warnnextstep.qcode.split(",");
                        if (code.includes(data.qcode) === true) {
                            data.color = "red";
                            data.message =
                                "Warning only: This record does not have a Reporting Category.";
                            return data;
                        } else {
                            return data;
                        }
                    }
                    if (resp.warnactive !== "") {
                        let code = resp.warnnextstep.qcode.split(",");
                        if (code.includes(data.qcode) === true) {
                            data.color = "red";
                            data.message =
                                "Warning only: This record is Inactive and will not be used in the topic quiz.";
                            return data;
                        } else {
                            return data;
                        }
                    }
                    if (resp.warninactive !== "") {
                        let code = resp.warnnextstep.qcode.split(",");
                        if (code.includes(data.qcode) === true) {
                            data.color = "red";
                            data.message =
                                "Error: This inactive Question Number is being used in a 'Next step' field.";
                            return data;
                        } else {
                            return data;
                        }
                    } else {
                        alert("No warnings generated!");
                    }
                }
            });
            setData(datas)

        });

    };




    return (
        <div>
            <ReactModal
                isOpen={isAddQuestion}
                className="ModalStyle2 Width90"
                onRequestClose={closeQuestionModal}
            >
                <StyledForm onSubmit={questionSubmitHandler}>
                    <h4 style={{textAlign: "center"}}>Add Guidance</h4>
                    <StyledFormInputDiv>

                        <StyledLabel htmlFor="cuzguidence"> Guidance Description</StyledLabel>

                        <textarea rows="7"
                            ref={entercuzguidedescref}
                            id="cuzguidence"
                            placeholder="Enter Guidance Description"
                            style={{ width: 550 }}
                        />

                    </StyledFormInputDiv>
                    <div className="mt-2" align="center">
                        <StyledButton
                        style={{
                            width: '30%',
                            backgroundColor: theme,
                        }}
                        >
                            Submit
                        </StyledButton>
                        <StyledButton
                        style={{
                            width: '30%',
                            backgroundColor: "red",
                        }}
                        onClick={() => {
                            closeQuestionModal();
                        }}
                        >
                        Cancel
                        </StyledButton>
                    </div>
                </StyledForm>
            </ReactModal>
            <MainHeader />

        <div style={{display: "flex", justifyContent: "space-evenly"}} className="mt-4 EXTQ">
            <div>
                <StyledButton
                    onClick={() =>
                    history.push(`/ExtQuestion/${params.id}`)}
                >
                    Go Back
                </StyledButton>
            </div>
            <div>
                <StyledButton onClick={showQuestionModal}>Add Guidance</StyledButton>
            </div>
        </div>

        <GuidanceData datas={data} warning={warning} />
        </div>
    );
};

export default Guidance;
