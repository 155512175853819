import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { BASE_URL } from "../../url/baseurl";
import print from "print-js";
import { Tooltip } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import {
  user_session_data,
  report_data,
  comparerpt_run_report_api,
  runreporta_api,
  comparerpt_drilldown_api,
  report_group_location_api, custgroup_location_api
} from "../../services/ReportCategoryApi";

import axios from "axios";
import {
  PrinterOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { message } from "antd";
const theme = localStorage.getItem('brandColor');
const StyledButton = styled.button`
  background: ${theme};
  width: 230px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;

const StyledTableRow = styled.tr`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
  border-bottom: 1px solid #9d9d9d;
`;


const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ModalStyle = {
  content: {
    width: "500px",
    height: "300px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",

    justifyContent: "center",
  },
};


const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});



function Topic_History_Cmp_Report() {
  const [table_name, setTable_name] = useState("run_report");
  const classes = useStyles();

  // ----------------------- Hooks ------------------------
  const [location_radio, setLocation_radio] = useState("");
  const [topic_radio, setTopic_radio] = useState("");
  const [period_radio, setPeriod_radio] = useState("");

  const [userid, setUserid] = useState(localStorage.getItem("UserID"));
  // const [userid, setUserid] = useState(100000014);

  const [userData, setUserData] = useState("");
  const [list, setList] = useState([]);
  const [cust_id, setCust_id] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [locationList, setLocationList] = useState("");
  const [periodList, setPeriodList] = useState([]);
  const [topicList, setTopicList] = useState("");
  const [hide, setHide] = useState(false);

  const [previousPeriod, setPreviousPeriod] = useState("");
  const [latestPeriod, setLatestPeriod] = useState("");

  // --------------------- Sorting Hooks ----------------------

  const [sortBySno, setSortBySno] = useState(true);
  const [sortByQuesRisk, setSortByQuesRisk] = useState(false);
  const [sortByCategory, setSortByCategory] = useState(false);
  const [sortByEarlierResponse, setSortByEarlierResponse] = useState(false);
  const [sortByEarlierAssignedUser, setSortByEarlierAssignedUser] =
    useState(false);
  const [sortByEarlierSuggCompleted, setSortByEarlierSuggCompleted] =
    useState(false);
  const [sortByResponse, setSortByResponse] = useState(false);
  const [sortByAssignedUser, setSortByAssignedUser] = useState(false);
  const [sortBySuggCompleted, setSortBySuggCompleted] = useState(false);
  const [sortByRiskLevel, setSortByRiskLevel] = useState(false);
  const [sortRev, setSortRev] = useState(false);
  const [descGroup, setDescGroup] = useState([]);
  const [groupid, setGroupid] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [field, setField] = useState([]);
  const [reportgroup, setReportgroup] = useState("");
  const [pageSize, setPageSize] = useState(100);

  const history = useHistory();

  const getUserSessionData = async () => {
    user_session_data(userid)
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data);

          setUserData(res.data.userdata);
          setList(res.data.list);
          setCust_id(res.data.userdata[0].customerid);

          // console.log("User Data ->", userData);
          // console.log("List ->", list);
          // console.log("Customer ID ->", cust_id);

          // if (!cust_id) setCust_id((userData?.customerid))
        }
      })
      .catch((e) => {
        console.log(e);

        message.error("something went wrong");
      });
  };

  const getReportData = async () => {

    report_data({
      userid: userid,
      customerid: cust_id,
    })
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data);

          setCountryList(res.data.Countrylist);
          //setLocationList(res.data.Locationlist);
          setTopicList(res.data.Topiclist);
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("something went wrong");
      });
  };

  const getReportlocation = async () => {
    report_group_location_api({
      customerid: cust_id,

    })

      .then((res) => {
        if (res.data.code == 200) {
          console.log("res group data===>>", res.data);
          setDescGroup(res.data.result);
          console.log("res group data===>>", res.data.groupid);

          setGroupid(res.data.groupid.custgroupid);

          console.log("gid", reportgroup);
        } else {
          console.log("error");
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error===>>", err);
      });
  };

  const getgrouplocation = async () => {
    console.log("id", reportgroup)
    custgroup_location_api({
      customerid: cust_id,
      Custgroupid: reportgroup,
    })

      .then((res) => {
        if (res.data.code == 200) {
          console.log("res group data===>>", res.data);
          setLocationList(res.data.result);
          // console.log("res group data===>>", res.data.result[0].custgroupid);
          // setGroupid(res.data.result[0].custgroupid);

        } else {
          console.log("error");
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error===>>", err);
      });
  };


  useEffect(() => {
    getUserSessionData();
  }, []);

  useEffect(() => {
    getReportData();
    getReportlocation();
    getgrouplocation();
  }, [cust_id]);

  //hooks for conditional table rendering report button click
  const [run, setRun] = useState("");

  // -------------------comparerpt_run_report_api fun call----------------

  const [run_report_msg, setRun_report_msg] = useState("");
  const [latest_period, setLatest_period] = useState();

  const comparerpt_run_report_api_func = async () => {
    let data = {
      seltopicid: parseInt(topic_radio),
      sellocationid: parseInt(location_radio),
      customerid: cust_id,
      userid: userid, //dummy userid
      Locationlist: locationList,
      Topiclist: topicList,
    };
    comparerpt_run_report_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          console.log("comparerpt_run_report_api res===>", res.data);
          setRun("run_report");
          //setHide("true");
          setLatest_period(res.data.Latestperiod);
          if (res.data.message === "multiprior") {
            setRun_report_msg(res.data.message);
            setPeriodList(res.data.Perioodlist);
            //setHide("false");
          } else if (res.data.message === "oneprior") {
            setRun_report_msg(res.data.message);
            setHide("false");
            setMetriclist(res.data.Metriclist);
            setTable_name("dry_report");

            setPeriod_radio(res.data.Selperiodcode);
            setSelcountrycode(res.data.Selcountrycode);
            setSellocationdesc(res.data.Sellocationdesc);
            setSeltopicdesc(res.data.Seltopicdesc);
            setPreviousPeriod(res.data.Selperioddesc);
            setLatestPeriod(res.data.Sellatestdesc);
          } else {
            message.error("Something went wrong!");
          }
        } else if (res.data.code == 400 && res.data.message === "noprior") {
          message.error(
            "There is no prior period activity to compare. Select a new Location and Topic."
          );
        } else if (
          res.data.code == 400 &&
          res.data.message === "no data found"
        ) {
          message.error(
            "There is no prior period activity to compare. Select a new Location and Topic."
          );
        } else {
          message.error("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log("comparerpt_run_report_api error==>", err);
      });
  };

  // --------------------------------------runreporta_api func call---------------------------------

  const [Metriclist, setMetriclist] = useState([]);
  const [Sellocationdesc, setSellocationdesc] = useState("");
  const [Seltopicdesc, setSeltopicdesc] = useState("");
  const [Selcountrycode, setSelcountrycode] = useState("");

  const runreporta_api_func = async () => {
    let data = {
      topicid: parseInt(topic_radio),
      locationid: parseInt(location_radio),
      periodcode: parseInt(period_radio),
      Topiclist: topicList,
      Locationlist: locationList,
      latestperiod: latest_period,
      periodlist: periodList,
      customerid: cust_id,
    };
    runreporta_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          setRun("run_report");
          console.log("runreporta_api res===>", res.data);
          setTable_name("dry_report");
          setMetriclist(res.data.Metriclist);
          setSelcountrycode(res.data.Selcountrycode);
          setSellocationdesc(res.data.Sellocationdesc);
          setSeltopicdesc(res.data.Seltopicdesc);
          setCsv_table_type("run_report");
          setPreviousPeriod(res.data.Selperioddesc);
          setLatestPeriod(res.data.Sellatestdesc);
        }
      })
      .catch((err) => {
        console.log("runreporta_api error==>", err);
      });
  };

  // --------------------comparerpt_drilldown_api func --------------------

  const [Detaillate, setDetaillate] = useState([]);

  const comparerpt_drilldown_api_func = async () => {
    let data = {
      topicid: topic_radio,
      locationid: location_radio,
      customerid: cust_id,
      latestperiod: latest_period,
      periodcode: period_radio,
    };
    comparerpt_drilldown_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          setRun("drill_report");
          console.log("comparerpt_drilldown_api res==>", res.data);
          setTable_name("drill_down");
          setCsv_table_type("dry_report");
          setDetaillate(res.data.Detaillate);
        } else {
          // message.error("Something went wrong !")
          console.log("error==>", res);
        }
      })
      .catch((err) => {
        console.log("comparerpt_drilldown_api error===>", err);
      });
  };

  // Sorting of Drill Down Table
  useEffect(() => {
    let data = {
      topicid: topic_radio,
      locationid: location_radio,
      customerid: cust_id,
      latestperiod: latest_period,
      periodcode: period_radio,
    };
    comparerpt_drilldown_api(data)
      .then((res) => {
        console.log(" Data==>", res);
        if (res.data.code === 200) {
          if (sortBySno) {
            setDetaillate(
              sortRev ? res.data.Detaillate?.reverse() : res.data.Detaillate
            );
          } else if (sortByQuesRisk) {
            setDetaillate(
              sortRev
                ? res.data.Detaillate?.sort((a, b) => {
                  const x = a.prioritydesc;
                  const y = b.prioritydesc;
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
                : res.data.Detaillate?.sort((a, b) => {
                  const x = a.prioritydesc;
                  const y = b.prioritydesc;
                  return x === y ? 0 : x > y ? 1 : -1;
                })
            );
          } else if (sortByCategory) {
            setDetaillate(
              sortRev
                ? res.data.Detaillate?.sort((a, b) => {
                  const x = a.repcatdesc;
                  const y = b.repcatdesc;
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
                : res.data.Detaillate?.sort((a, b) => {
                  const x = a.repcatdesc;
                  const y = b.repcatdesc;
                  return x === y ? 0 : x > y ? 1 : -1;
                })
            );
          } else if (sortByEarlierResponse) {
            setDetaillate(
              sortRev
                ? res.data.Detaillate?.sort((a, b) => {
                  const x = a.eresponse;
                  const y = b.eresponse;
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
                : res.data.Detaillate?.sort((a, b) => {
                  const x = a.eresponse;
                  const y = b.eresponse;
                  return x === y ? 0 : x > y ? 1 : -1;
                })
            );
          } else if (sortByEarlierAssignedUser) {
            setDetaillate(
              sortRev
                ? res.data.Detaillate?.sort((a, b) => {
                  const x = a.eownerid;
                  const y = b.eownerid;
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
                : res.data.Detaillate?.sort((a, b) => {
                  const x = a.eownerid;
                  const y = b.eownerid;
                  return x === y ? 0 : x > y ? 1 : -1;
                })
            );
          } else if (sortByEarlierSuggCompleted) {
            setDetaillate(
              sortRev
                ? res.data.Detaillate?.sort((a, b) => {
                  const x = a.ecompleted;
                  const y = b.ecompleted;
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
                : res.data.Detaillate?.sort((a, b) => {
                  const x = a.ecompleted;
                  const y = b.ecompleted;
                  return x === y ? 0 : x > y ? 1 : -1;
                })
            );
          } else if (sortByResponse) {
            setDetaillate(
              sortRev
                ? res.data.Detaillate?.sort((a, b) => {
                  const x = a.response;
                  const y = b.response;
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
                : res.data.Detaillate?.sort((a, b) => {
                  const x = a.response;
                  const y = b.response;
                  return x === y ? 0 : x > y ? 1 : -1;
                })
            );
          } else if (sortByAssignedUser) {
            setDetaillate(
              sortRev
                ? res.data.Detaillate?.sort((a, b) => {
                  const x = a.qownerid;
                  const y = b.qownerid;
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
                : res.data.Detaillate?.sort((a, b) => {
                  const x = a.qownerid;
                  const y = b.qownerid;
                  return x === y ? 0 : x > y ? 1 : -1;
                })
            );
          } else if (sortBySuggCompleted) {
            setDetaillate(
              sortRev
                ? res.data.Detaillate?.sort((a, b) => {
                  const x = a.completed;
                  const y = b.completed;
                  return x === y ? 0 : x > y ? 1 : -1;
                })?.reverse()
                : res.data.Detaillate?.sort((a, b) => {
                  const x = a.completed;
                  const y = b.completed;
                  return x === y ? 0 : x > y ? 1 : -1;
                })
            );
          } else if (sortByRiskLevel) {
            setDetaillate(
              sortRev
                ? res.data.Detaillate?.sort(
                  (a, b) => a.leveldisplay - b.leveldisplay
                )?.reverse()
                : res.data.Detaillate?.sort(
                  (a, b) => a.leveldisplay - b.leveldisplay
                )
            );
          } else {
            setDetaillate(res.data.Detaillate);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(Detaillate, "useeffect");
    // setDraftData(data.reverse());
  }, [
    sortBySno,
    sortRev,
    sortByQuesRisk,
    sortByCategory,
    sortByEarlierResponse,
    sortByEarlierAssignedUser,
    sortByEarlierSuggCompleted,
    sortByResponse,
    sortByAssignedUser,
    sortBySuggCompleted,
    sortByRiskLevel,
  ]);

  // csv download

  const [csv_table_type, setCsv_table_type] = useState("run_report");

  function download_csv_run_report() {
    if (csv_table_type === "run_report") {
      var table_header = {
        metric: "Metric",
        earlier: "Earlier (period)",
        latest: "Latest (period)  ",
        increase: "Increase (Decrease)  ",
        riskLevel: "Risk Level",
        possibleReason: "Possible Reason ",
      };
    }
    if (csv_table_type === "dry_report") {
      var table_header = {
        Question: "Question  ",
        QuesRisk: "Ques Risk",
        Solution: "Solution  ",
        Category: "Category  ",
        EalierPeriod: "Earlier Period",
        EarlierResponse: "Earlier Response",
        EarlierAssignedUser: "Earlier Assigned User",
        ESuggCompleted: "Earlier Sugg Completed",
        LastPeriod: "Last Period",
        Response: "Response",
        AssignedUser: "Assigned User ",
        SuggCompleted: "Sugg Completed",
        RiskLevel: "Risk Level",
        ReasonforVariance: "Reason for Variance ",
      };
    }

    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

    if (csv_table_type === "run_report") {
      var csv = Metriclist?.map(function (d) {
        if (csv_table_type === "run_report") {
          let temp = {
            metric: d.metricdesc,
            earlier: d.early,
            latest: d.late,
            increase: d.variance,
            riskLevel: d.leveldisplay,
            possibleReason: d.reason,
          };
          return JSON.stringify(Object.values(temp));
        }

        return JSON.stringify(Object.values(d));
      })
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
    }
    if (csv_table_type === "dry_report") {
      var csv = Detaillate?.map(function (d) {
        if (csv_table_type === "dry_report") {
          let temp = {
            Question: d.qdescr,
            QuesRisk: d.prioritydesc,
            Solution: d.solutiondesc,
            Category: d.repcatdesc,
            EalierPeriod: d.eperioddesc,
            EarlierResponse: d.eresponse,
            EarlierAssignedUser: d.eownerid,
            ESuggCompleted: d.ecompleted,
            LastPeriod: d.perioddesc,
            Response: d.response,
            AssignedUser: d.qownerid,
            SuggCompleted: d.completed,
            RiskLevel: d.leveldisplay,
            ReasonforVariance: d.reason,
          };
          return JSON.stringify(Object.values(temp));
        }

        return JSON.stringify(Object.values(d));
      })
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
    }

    let temporary = vaarrsfs + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "report.csv";
    hiddenElement.click();
  }

  //  print data

  const print_runReport = () => {
    if (csv_table_type === "run_report") {
      print({
        printable: Metriclist,
        properties: [
          { field: "metricdesc", displayName: "Metric" },
          { field: "early", displayName: "Earlier (20-Jun)" },
          { field: "late", displayName: "Latest (21-Oct)" },
          { field: "variance", displayName: "Increase (Decrease)" },
          { field: "leveldisplay", displayName: "Risk Level" },
          { field: "reason", displayName: "Possible Reason" },
        ],
        type: "json",
        gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
        gridStyle: "border: 2px solid #3971A5;",
      });
    }

    if (csv_table_type === "dry_report") {
      print({
        printable: Detaillate,
        properties: [
          { field: "qdescr", displayName: "Question" },
          { field: "prioritydesc", displayName: "Ques Risk" },
          { field: "solutiondesc", displayName: "Solution" },
          { field: "repcatdesc", displayName: "Category" },
          { field: "eperioddesc", displayName: "Earlier Period" },
          { field: "eresponse", displayName: "Earlier Response" },
          { field: "eownerid", displayName: "Earlier Assigned User" },
          { field: "ecompleted", displayName: "Earlier Sugg Completed" },
          { field: "perioddesc", displayName: "Last Period" },
          { field: "response", displayName: "Response" },
          { field: "qownerid", displayName: "Assigned User" },
          { field: "completed", displayName: "Sugg Completed" },
          { field: "leveldisplay", displayName: "Risk Level" },
          { field: "reason", displayName: "Reason for Variance" },
        ],
        type: "json",
        gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
        gridStyle: "border: 2px solid #3971A5;",
      });
    }
  };




  let SNo = 0;
  const rows1 = Detaillate.map((Detaillate) => {
    return {
      ...Detaillate,
      SNo: SNo + 1,
      id: SNo++,
    };
  });

  const rows = rows1;

  const columns: GridColumns = [
    {
      field: "SNo",
      headerName: "S.No",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "qdescr",
      headerName: "Question",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value?.substring(0, 10)}...</span>
        </Tooltip>
      ),
    },
    {
      field: "prioritydesc",
      headerName: "Ques Risk",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value?.substring(0, 4)}...</span>
        </Tooltip>
      ),
    },
    {
      field: "solutiondesc",
      headerName: "Solution",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value?.substring(0, 10)}...</span>
        </Tooltip>
      ),
    },
    {
      field: "repcatdesc",
      headerName: "Category",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip
        
        title={params.value}
      >
        {/* {params.value?.slice(0, 12)}... */}
        {params.value?.length > 5
            ? `${params.value.substring(0, 5)}...`
            : params.value}
      </Tooltip>
      ),
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       borderRight: '1px solid #9d9d9d',


      //     }}
      //   >
      //     {params.value}
      //   </div>
      // ),
    },
    {
      field: "eperioddesc",
      headerName: "Earlier Period",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip
        
        title={params.value}
      >
        {/* {params.value?.slice(0, 12)}... */}
        {params.value?.length > 3
            ? `${params.value.substring(0, 3)}...`
            : params.value}
      </Tooltip>
      ),
    },
    {
      field: "eresponse",
      headerName: "Earlier Response",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip
        
        title={params.value}
      >
       
        {params.value?.length > 3
            ? `${params.value.substring(0, 3)}...`
            : params.value}
      </Tooltip>
      ),
    },
    {
      field: "eownerid",
      headerName: "Earlier Assigned User",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value?.substring(0, 10)}...</span>
        </Tooltip>
      ),
    },
    {
      field: "ecompleted",
      headerName: "Earlier Sugg Completed",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip
        
        title={params.value}
      >
       
        {params.value?.length > 5
            ? `${params.value.substring(0, 5)}...`
            : params.value}
      </Tooltip>
      ),
    },
    {
      field: "perioddesc",
      headerName: "Last Period",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip
        
        title={params.value}
      >
       
        {params.value?.length > 4
            ? `${params.value.substring(0, 4)}...`
            : params.value}
      </Tooltip>
      ),
    },
    {
      field: "response",
      headerName: "Response",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip
        
        title={params.value}
      >
       
        {params.value}
            
      </Tooltip>
      ),
    },
    {
      field: "qownerid",
      headerName: "Assigned User",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value?.substring(0, 10)}...</span>
        </Tooltip>
      ),
    },
    {
      field: "completed",
      headerName: "Sugg Completed",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip
        
        title={params.value}
      >
       
        {params.value}
            
      </Tooltip>
      ),
    },
    {
      field: "leveldisplay",
      headerName: "Risk Level",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <td
          style={{ backgroundColor: `${params.row.colorid}` }}
        >
          {params.value}
        </td>
      ),
    },
    {
      field: "reason",
      headerName: "Reason for Variance",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value?.substring(0, 10)}...</span>
        </Tooltip>
      ),
    },
  ];






  let SNo1 = 0;
  const rows2 = Metriclist.map((Metriclist) => {
    return {
      ...Metriclist,
      SNo1: SNo1 + 1,
      id: SNo1++,
    };
  });

  const rows3 = rows2;

  const columns2: GridColumns = [
    {
      field: "SNo1",
      headerName: "S.No",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "metricdesc",
      headerName: "Metric",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 35
                ? `${params.value.substring(0, 35)}...`
                : params.value}
          </Tooltip>
        );
      },

    },
    {
      field: "earlycat",
      headerName:  previousPeriod ,
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const earlyValue = parseFloat(params.row.early);
        const earlyCat = params.row.earlycat;
    
        if (isNaN(earlyValue)) {
          return null; // or return a default value/error message
        }
    
        const roundedValue = Math.round(earlyValue * 100) / 100;
    
        return (
         <>
            {roundedValue} {earlyCat}


           



            </>






        );


        // return (
        //   <Tooltip
           
        //     title={params.value}
        //   >
        //     {/* {params.value?.slice(0, 12)}... */}
        //     {params.value?.length > 12
        //         ? `${params.value.substring(0, 12)}...`
        //         : params.value}
        //   </Tooltip>
        // );


      },

    },
    {
      field: "latecat",
      headerName: latestPeriod ,
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const lateValue = parseFloat(params.row.late);
        const lateCat = params.row.latecat;
    
        if (isNaN(lateValue)) {
          return null; // or return a default value/error message
        }
    
        const roundedValue = Math.round(lateValue * 100) / 100;
    
        return (
         <>
            {roundedValue} {lateCat}




            </>
        );
      },

    },
    {
      field: "variance",
      headerName: "Increase (Decrease)",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",


      renderCell: (params) => {
        const varianceValue = parseFloat(params.row.variance);
        //const varianceCat = params.row.latecat;
    
        if (isNaN(varianceValue)) {
          return null; // or return a default value/error message
        }
    
        const roundedValue = Math.round(varianceValue * 100) / 100;
    
        return (
         <>
            {roundedValue} 


           


            </>
        );
      },




    },
    {
      field: "leveldisplay",
      headerName: "Risk Level",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <td
          style={{ backgroundColor: `${params.row.colorid}` }}
        >
          {params.value}
        </td>




      ),
    },
    {
      field: "reason",
      headerName: " Possible Reason",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 40
                ? `${params.value.substring(0, 40)}...`
                : params.value}
          </Tooltip>
        );
      },
    },

  ];




  return (
    <>
      <div className="container">
        <div align="center">
          <h4 style={{ paddingTop: "2%" }}>Topic History Comparison Report</h4>
        </div>

        <div className="row1 row " align="center">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
            <StyledButton className="row1-btn"
              onClick={() => {
                history.push("/Extquestion2");
                // if (run == "run_report") setRun("");
                // if (run == "drill_report") setRun("run_report");
                // if (run == "") history.push("/Extquestion2");
              }}
            >
              Exit
            </StyledButton>
          </div>
          {/* <div hidden={hide} className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5"> */}
          {table_name == "run_report" ? (
            <>
              {run_report_msg == "multiprior" ? (
                ""
              ) : (
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                  <div class="btn-group drop " role="group">
                    <select
                      style={{ borderColor: "#ff8c24" }}
                      type="text"
                      defaultValue={groupid}
                      withAll={true}
                      multi={true}
                      value={reportgroup}
                      onClick={() => {
                        getReportlocation();
                        console.log("location");
                        console.log("group", descGroup);
                        console.log(reportgroup, "Reportgroup")
                        console.log("value11", reportgroup)
                      }}
                      // onClick={(e) => {
                      //   getReportlocation();

                      //  }}
                      onChange={(e) => {

                        setSelectedOptions("true");
                        setReportgroup(e.target.value);
                        console.log("value", e.target.value);
                      }}
                    >
                      <option value={groupid}>All</option>
                      {descGroup?.map((data, i) => {

                        //   console.log(country)
                        return (
                          <option value={data.custgroupid}>
                            {data.custgroupdesc?.substring(0, 25)}...
                          </option>
                        );
                      })}
                    </select>
                    <button class="btn btn-default ant-btn-default1" type="button" tabindex="-1"
                      onClick={() => {
                        getgrouplocation();
                        console.log("Add Locations");
                      }}
                    >Run</button>

                  </div>
                </div>

              )}
            </>
          ) : (
            " "
          )}
          {/* </div>   */}
          <>
            {(table_name === "run_report" || table_name == "dry_report") && (
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                {table_name == "run_report" ? (
                  <>
                    {run_report_msg == "multiprior" ? (

                      <StyledButton
                        onClick={() => {
                          setRun_report_msg("oneprior");

                        }}
                      >
                        New Selection
                      </StyledButton>
                    ) : (
                      " "
                    )}
                  </>
                ) : (
                  <>
                    {table_name == "dry_report" ? (

                      <StyledButton className="row1-btn"
                        onClick={() => {
                          setTable_name("run_report");

                          // if (run == "run_report") setRun("");
                          // if (run == "drill_report") setRun("run_report");
                          // if (run == "") history.push("/Extquestion2");
                        }}
                      >
                        New Selection
                      </StyledButton>

                    ) : (
                      ""

                    )}
                  </>
                )}
              </div>
            )}
          </>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
            {table_name == "run_report" ? (
              <>
                {run_report_msg == "oneprior" ? (

                  <StyledButton
                    onClick={() => {
                      comparerpt_run_report_api_func();
                    }}
                  >
                    Run Report
                  </StyledButton>
                ) : (
                  <>
                    {run_report_msg == "multiprior" ? (
                      <StyledButton
                        onClick={() => {
                          runreporta_api_func();
                        }}
                      >
                        Run Report
                      </StyledButton>
                    ) : (
                      <StyledButton
                        onClick={() => {
                          comparerpt_run_report_api_func();
                        }}
                      >
                        Run Report
                      </StyledButton>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {table_name == "dry_report" ? (
                  <StyledButton className="row1-btn"
                    onClick={() => {
                      comparerpt_drilldown_api_func();
                    }}
                  >
                    Drill Down
                  </StyledButton>
                ) : table_name == "drill_down" ? (
                  <StyledButton
                    onClick={() =>
                      run_report_msg == "multiprior"
                        ? runreporta_api_func()
                        : comparerpt_run_report_api_func()
                    }
                  >
                    Summary Report
                  </StyledButton>
                ) : (
                  ""
                )}
              </>
            )}
          </div>

          {table_name == "run_report" ? (
            ""
          ) : (
            <>
              {list &&
                list?.map((data, index) => (
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                    <StyledButton className="row1-btn"
                      onClick={() => {
                        data.listvalue == "Print Reports"
                          ? print_runReport()
                          : download_csv_run_report();
                      }}
                    >
                      {data.listvalue} &nbsp;
                      {data.listvalue == "Print Reports" ? (
                        <PrinterOutlined style={{ fontSize: 20 }} />
                      ) : (
                        <DownloadOutlined style={{ fontSize: 20 }} />
                      )}
                    </StyledButton>
                  </div>
                ))}
            </>
          )}
        </div>

        {table_name == "run_report" ? (
          <>
            {run_report_msg == "multiprior" ? (
              <div className="container">
                <div className="row inline-flex">
                  <div className="col-12">
                    <div style={{ marginTop: 50 }}>
                      <h4>Earlier Periods</h4>
                      <StyledTable>
                        <thead>
                          <StyledTableHeader>
                            {" "}
                            <StyledTableHeaderData>
                              S.No &nbsp;
                            </StyledTableHeaderData>
                            <StyledTableHeaderData align="center">
                              Period &nbsp;
                            </StyledTableHeaderData>
                            <StyledTableHeaderData></StyledTableHeaderData>
                          </StyledTableHeader>
                        </thead>

                        <tbody>
                          {periodList &&
                            periodList?.map((data, index) => {
                              return (
                                <>
                                  <tr key={data.periodcode}>
                                    <td>{index + 1}</td>
                                    <td id={data.periodcode}>
                                      {data.perioddesc}
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        // checked={selectAllLocationList}
                                        value={data.periodcode}
                                        checked={
                                          period_radio ===
                                          String(data.periodcode)
                                        }
                                        onChange={(e) => {
                                          setPeriod_radio(e.target.value);
                                          // console.log("radio==>",e.target.value,location_radio)
                                        }}
                                      // id={data.locationid}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </StyledTable>
                    </div>{" "}
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row inline-flex">
                  <div className="col-md-6 col-sm-12">
                    <div style={{ marginTop: 40 }}>

                      <StyledTable>
                        <thead>
                          <StyledTableHeader>
                            {" "}
                            <StyledTableHeaderData>
                              S.No &nbsp;
                            </StyledTableHeaderData>
                            <StyledTableHeaderData align="center">
                              Location &nbsp;
                            </StyledTableHeaderData>
                            <StyledTableHeaderData></StyledTableHeaderData>
                          </StyledTableHeader>
                        </thead>

                        <tbody>
                          {locationList &&
                            locationList?.map((data, index) => {
                              return (
                                <>
                                  <tr key={data.locationid}>
                                    <td>{index + 1}</td>
                                    <td id={data.locationid}>
                                      {data.locationdesc}
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        // checked={selectAllLocationList}
                                        value={data.locationid}
                                        checked={
                                          location_radio ===
                                          String(data.locationid)
                                        }
                                        onChange={(e) => {
                                          setLocation_radio(e.target.value);
                                          // console.log("radio==>",e.target.value,location_radio)
                                        }}
                                      // id={data.locationid}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </StyledTable>
                    </div>{" "}
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div style={{ marginTop: 40 }}>

                      <StyledTable>
                        <thead>
                          <StyledTableHeader>
                            {" "}
                            <StyledTableHeaderData>
                              S.No &nbsp;
                            </StyledTableHeaderData>
                            <StyledTableHeaderData>
                              Topic &nbsp;
                            </StyledTableHeaderData>
                            <StyledTableHeaderData></StyledTableHeaderData>
                          </StyledTableHeader>
                        </thead>

                        <tbody>
                          {topicList &&
                            topicList?.map((data, index) => {
                              return (
                                <>
                                  <tr key={data.topicid}>
                                    <td>{index + 1}</td>
                                    <td id={data.topicid}>{data.cuzdesc}</td>
                                    <td>
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        //  checked={selectAllTopicList}

                                        value={data.topicid}
                                        checked={
                                          topic_radio === String(data.topicid)
                                        }
                                        onChange={(e) => {
                                          setTopic_radio(e.target.value);
                                          // console.log("radio==>",e.target.value,location_radio)
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </StyledTable>
                    </div>{" "}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {table_name == "drill_down" ? (
              <div className="col-12">
                <div className="mt-4 table-responsive">
                  {/* <h4>
                    Line Item Report Selection:&nbsp;{Seltopicdesc},&nbsp;
                    {Sellocationdesc},&nbsp;{Selcountrycode}
                  </h4> */}
                  <h4>
                    {Seltopicdesc},&nbsp;
                    {Sellocationdesc},&nbsp;{Selcountrycode}
                  </h4>
                  {/* <div className="tableFixHeadLarge">
                    <StyledTable>
                      <thead>
                        <ScrollTableHeader>
                          <StyledTableHeaderData>
                            S.No&nbsp;
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(false);
                                setSortByCategory(false);
                                setSortByEarlierResponse(false);
                                setSortByEarlierAssignedUser(false);
                                setSortByEarlierSuggCompleted(false);
                                setSortByResponse(false);
                                setSortByAssignedUser(false);
                                setSortBySuggCompleted(false);
                                setSortByRiskLevel(false);
                                setSortBySno(true);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>Question</StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Ques Risk &nbsp;
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(true);
                                setSortByCategory(false);
                                setSortByEarlierResponse(false);
                                setSortByEarlierAssignedUser(false);
                                setSortByEarlierSuggCompleted(false);
                                setSortByResponse(false);
                                setSortByAssignedUser(false);
                                setSortBySuggCompleted(false);
                                setSortByRiskLevel(false);
                                setSortBySno(false);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>Solution</StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Category
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(false);
                                setSortByCategory(true);
                                setSortByEarlierResponse(false);
                                setSortByEarlierAssignedUser(false);
                                setSortByEarlierSuggCompleted(false);
                                setSortByResponse(false);
                                setSortByAssignedUser(false);
                                setSortBySuggCompleted(false);
                                setSortByRiskLevel(false);
                                setSortBySno(false);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData >
                            Earlier Period
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Earlier Response
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(false);
                                setSortByCategory(false);
                                setSortByEarlierResponse(true);
                                setSortByEarlierAssignedUser(false);
                                setSortByEarlierSuggCompleted(false);
                                setSortByResponse(false);
                                setSortByAssignedUser(false);
                                setSortBySuggCompleted(false);
                                setSortByRiskLevel(false);
                                setSortBySno(false);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Earlier Assigned User
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(false);
                                setSortByCategory(false);
                                setSortByEarlierResponse(false);
                                setSortByEarlierAssignedUser(true);
                                setSortByEarlierSuggCompleted(false);
                                setSortByResponse(false);
                                setSortByAssignedUser(false);
                                setSortBySuggCompleted(false);
                                setSortByRiskLevel(false);
                                setSortBySno(false);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Earlier Sugg Completed
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(false);
                                setSortByCategory(false);
                                setSortByEarlierResponse(false);
                                setSortByEarlierAssignedUser(false);
                                setSortByEarlierSuggCompleted(true);
                                setSortByResponse(false);
                                setSortByAssignedUser(false);
                                setSortBySuggCompleted(false);
                                setSortByRiskLevel(false);
                                setSortBySno(false);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Last Period
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Response
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(false);
                                setSortByCategory(false);
                                setSortByEarlierResponse(false);
                                setSortByEarlierAssignedUser(false);
                                setSortByEarlierSuggCompleted(false);
                                setSortByResponse(true);
                                setSortByAssignedUser(false);
                                setSortBySuggCompleted(false);
                                setSortByRiskLevel(false);
                                setSortBySno(false);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Assigned User
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(false);
                                setSortByCategory(false);
                                setSortByEarlierResponse(false);
                                setSortByEarlierAssignedUser(false);
                                setSortByEarlierSuggCompleted(false);
                                setSortByResponse(false);
                                setSortByAssignedUser(true);
                                setSortBySuggCompleted(false);
                                setSortByRiskLevel(false);
                                setSortBySno(false);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Sugg Completed
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(false);
                                setSortByCategory(false);
                                setSortByEarlierResponse(false);
                                setSortByEarlierAssignedUser(false);
                                setSortByEarlierSuggCompleted(false);
                                setSortByResponse(false);
                                setSortByAssignedUser(false);
                                setSortBySuggCompleted(true);
                                setSortByRiskLevel(false);
                                setSortBySno(false);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Risk Level
                            <a
                              onClick={() => {
                                setSortRev(!sortRev);
                                setSortByQuesRisk(false);
                                setSortByCategory(false);
                                setSortByEarlierResponse(false);
                                setSortByEarlierAssignedUser(false);
                                setSortByEarlierSuggCompleted(false);
                                setSortByResponse(false);
                                setSortByAssignedUser(false);
                                setSortBySuggCompleted(false);
                                setSortByRiskLevel(true);
                                setSortBySno(false);
                              }}
                            >
                              <i class="fas fa-sort" />
                            </a>
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Reason for Variance
                          </StyledTableHeaderData>
                        </ScrollTableHeader>
                      </thead>

                      <tbody>
                        {Detaillate &&
                          Detaillate?.map((data, index) => {
                            return (
                              <>
                                
                                <StyledTableRow key={data.topicid}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <Tooltip title={data.qdescr}>
                                      <span>
                                        {data.qdescr?.substring(0, 40)}...
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <td>{data.prioritydesc}</td>
                                  <td>
                                    <Tooltip title={data.solutiondesc}>
                                      <span>
                                        {data.solutiondesc?.substring(0, 40)}...
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <td>{data.repcatdesc}</td>

                                  <td style={{ borderLeft: `1px solid #9d9d9d` }}>
                                    {data.eperioddesc}
                                  </td>
                                  <td>
                                    {data.eresponse}
                                  </td>
                                  <td>
                                    <Tooltip title={data.eownerid}>
                                      <span>
                                        {data.eownerid?.substring(0, 10)}...
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <td>
                                    {data.ecompleted}
                                  </td>
                                  <td style={{ borderLeft: `1px solid #9d9d9d` }}>
                                    {data.perioddesc}
                                  </td>
                                  <td>
                                    {data.response}
                                  </td>
                                  <td>
                                    <Tooltip title={data.qownerid}>
                                      <span>
                                        {data.qownerid?.substring(0, 10)}...
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <td>
                                    {data.completed}
                                  </td>
                                  <td
                                    style={{ backgroundColor: `${data.colorid}` }}
                                  >
                                    {data.leveldisplay}
                                  </td>
                                  <td>
                                    <Tooltip title={data.reason}>
                                      <span>
                                        {data.reason?.substring(0, 40)}...
                                      </span>
                                    </Tooltip>
                                  </td>
                                 
                                </StyledTableRow>
                              </>
                            );
                          })}
                      </tbody>
                    </StyledTable>
                  </div> */}


                  <div
                    style={{ height: '65vh', width: "100%" }}
                    className={classes.root}
                  >
                    <DataGrid
                      sx={{
                        ".MuiDataGrid-columnHeaders": {
                          backgroundColor: "#ff8c24",
                        },
                        "&.MuiDataGrid-root": {
                          border: "none",
                        },
                      }}
                      rows={rows}
                      getRowId={(r) => r.id}
                      id="_id"
                      columns={columns}
                      experimentalFeatures={{ newEditingApi: true }}
                      rowsPerPageOptions={[ 100]}
                      pagination
                      hideFooterPagination={rows.length <= pageSize}
                    />
                  </div>


                </div>{" "}
              </div>
            ) : (
              <div className="col-12">
                <div className="mt-4 table-responsive">
                  <h4>
                    {/* Summary Report Selection:&nbsp;{Seltopicdesc},&nbsp;
                    {Sellocationdesc},&nbsp;{Selcountrycode} */}
                    &nbsp;{Seltopicdesc},&nbsp;
                    {Sellocationdesc},&nbsp;{Selcountrycode}
                  </h4>
                  {/* <div className="tableFixHeadLarge">
                    <StyledTable>
                      <thead>
                        <ScrollTableHeader>
                          <StyledTableHeaderData>S.No</StyledTableHeaderData>

                          <StyledTableHeaderData>Metric</StyledTableHeaderData>

                          <StyledTableHeaderData>
                            {previousPeriod}
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            {latestPeriod}
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Increase (Decrease)
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Risk Level
                          </StyledTableHeaderData>

                          <StyledTableHeaderData>
                            Possible Reason
                          </StyledTableHeaderData>
                        </ScrollTableHeader>
                      </thead>

                      <tbody>
                        {Metriclist &&
                          Metriclist?.map((data, index) => {
                            return (
                              <>
                              
                                <StyledTableRow key={data.topicid}>
                                  <td>{index + 1}</td>
                                  <td>{data.metricdesc}</td>
                                  <td>
                                    {Math.round(data.early * 100) / 100}{" "}
                                    {data.earlycat}
                                  </td>
                                  <td>
                                    {Math.round(data.late * 100) / 100}{" "}
                                    {data.latecat}
                                  </td>
                                  <td>{Math.round(data.variance * 100) / 100}</td>
                                  <td
                                    style={{ backgroundColor: `${data.colorid}` }}
                                  >
                                    {data.leveldisplay}
                                  </td>
                                  <td>{data.reason}</td>
                                </StyledTableRow>
                              </>
                            );
                          })}
                      </tbody>
                    </StyledTable>
                  </div> */}



                  <div
                    style={{ height: '65vh', width: "100%" }}
                    className={classes.root}
                  >
                    <DataGrid
                      sx={{
                        ".MuiDataGrid-columnHeaders": {
                          backgroundColor: "#ff8c24",
                        },
                        "&.MuiDataGrid-root": {
                          border: "none",
                        },
                      }}
                      rows={rows3}
                      getRowId={(r) => r.id}
                      id="_id"
                      columns={columns2}
                      experimentalFeatures={{ newEditingApi: true }}
                      rowsPerPageOptions={[ 100]}
                      pagination
                      hideFooterPagination={rows3.length <= pageSize}
                    />
                  </div>



                </div>{" "}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Topic_History_Cmp_Report;
