import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import {
  assigned_cat,
  edittopcat,
  newcat,
  editcat,
  changecat,
  addcat,
} from "../../services/ClientProgramApi";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const ClientTopicCategories = ({ match }) => {
  const history = useHistory();

  const customerid = match.params.customerid;

  ///hooks for table data
  const [assigned_cate, setAssigned_cate] = useState([]);

  // hooks for fetch Drop-down data
  const [categoryList, setCategoryList] = useState([]);

  // hooks for edit modal
  const [edit_topic_cat_modal, setEdit_topic_cat_modal] = useState(false);
  const [cuzdesc, setCuzdesc] = useState("");
  const [cate_desc, setCate_desc] = useState("");
  const [topicId, setTopicId] = useState();
  const [categoryId, setCategoryId] = useState("");

  // hooks for add modal
  const [add_topic_cat_modal, setAdd_topic_cat_modal] = useState(false);
  const [category, setCategory] = useState("");

  const [edit_category_modal, setEdit_category_modal] = useState(false);

  // ------------------------ Validation --------------------------
  const [formErrors, setFormErrors] = useState([]);

  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!category) {
      formIsValid = false;
      formErrors["category_error"] = "Category is required.";
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  //...........Reset Data..........................
  const resetField = () => {
    setCategory("");
    setFormErrors([]);
  };

  const Assinged_categories_api = async () => {
    assigned_cat(customerid)
      .then((res) => {
        if (res.data.code == 200) {
          console.log("res data=>>", res.data);
          setAssigned_cate(res.data.Topiccatlist);
        }
      })
      .catch((err) => {
        console.log("error==>", err);
      });
  };

  const fetchDropDown = (customerid) => {
    edittopcat(customerid)
      .then((res) => {
        console.log("fetchData", res);
        setCategoryList(res.data?.Categorylist);
        console.log(categoryList);
        Assinged_categories_api();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const add_Category = () => {
    addcat({
      customerid: customerid,
      categdesc: category,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setAdd_topic_cat_modal(false);
          resetField();
          message.success("Category added successfully");
          Assinged_categories_api(customerid);
          fetchDropDown(customerid);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const editTopicCat = () => {
    let data = {
      topicid: topicId,
      categoryid: categoryId,
    };
    console.log(data);
    newcat(data)
      .then((res) => {
        if (res.data.code === 200) {
          setEdit_topic_cat_modal(false);
          Assinged_categories_api(customerid);
          message.success("Succesfully updated !");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const editCat = () => {
    changecat({
      customerid: customerid,
      categoryid: categoryId,
      categdesc: category,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setEdit_category_modal(false);
          Assinged_categories_api(customerid);
          fetchDropDown(customerid);
          message.success("Succesfully updated !");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    Assinged_categories_api();
    fetchDropDown(customerid);
  }, [customerid]);

  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center">
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/Extquestion2/true");
            }}
          >
            Return to Client Menu
          </StyledButton1>
        </div>
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              setAdd_topic_cat_modal(true);
            }}
          >
            Add Category
          </StyledButton1>
        </div>
      </div>

      <div className="m-4 table-responsive tableFixHeadXXLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData>S.no</StyledTableHeaderData>
              <StyledTableHeaderData>Client Topic</StyledTableHeaderData>
              <StyledTableHeaderData>Topic Category</StyledTableHeaderData>
              <StyledTableHeaderData>Assign Topic</StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {assigned_cate &&
              assigned_cate?.map((data, index) => (
                <StyledTableRow>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td style={{ color: "black" }}>{data.cuzdesc}</td>
                  <td>
                    {data.categdesc}{" "}
                    <a
                      onClick={() => {
                        setCategory(data.categdesc);
                        setCategoryId(data.categoryid);

                        setEdit_category_modal(true);
                      }}
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </a>
                  </td>

                  <td>
                    <a
                      onClick={() => {
                        setCuzdesc(data.cuzdesc);
                        setCate_desc(data.categdesc);
                        setTopicId(data.topicid);
                        setCategoryId(data.categoryid);
                        setEdit_topic_cat_modal(true);
                      }}
                    >
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                  </td>
                </StyledTableRow>
              ))}
          </tbody>
        </StyledTable>
      </div>

      {/* Edit modal Client Topic Categories */}

      <ReactModal className="ModalStyle1 Width90" isOpen={edit_topic_cat_modal}>
        <div>
          {" "}
          <StyledForm>
            {" "}
            <div align="center" className="mb-3">
              <h4>Assign Category to Topic</h4>
            </div>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Client Topic</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  style={{
                    width: "100%",
                    alignItems: "center",
                    border: "1px solid gray",
                  }}
                  value={cuzdesc}
                  disabled={true}
                />
              </div>
            </StyledFormInputDiv>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Topic Category</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  style={{
                    width: "100%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={categoryId ? categoryId : ""}
                  onChange={(e) => {
                    setCategoryId(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Category name
                  </option>
                  {categoryList &&
                    categoryList?.map((data, index) => {
                      return (
                        <option value={data.categoryid}>
                          {data.categdesc}
                        </option>
                      );
                    })}
                </select>
              </div>
            </StyledFormInputDiv>
          </StyledForm>
        </div>
        <div align="center">
          <StyledButton
            style={{
              width: "40%",
              height: 30,
              marginTop: 50,
            }}
            onClick={() => {
              editTopicCat();
            }}
          >
            Save Assignment
          </StyledButton>
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              backgroundColor: "red",
              marginLeft: 10,
            }}
            onClick={() => {
              setEdit_topic_cat_modal(false);
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </ReactModal>

      {/* Add modal Client Topic Categories */}

      <ReactModal className="ModalStyle1 Width90" isOpen={add_topic_cat_modal}>
        <div>
          {" "}
          <StyledForm>
            {" "}
            <div align="center" className="mb-3">
              <h4>Add Category</h4>
            </div>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel
                  htmlFor="qdesc"
                  style={{ marginLeft: "20%", fontSize: "20px" }}
                >
                  Category
                </StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    // marginLeft: "10%",
                    alignItems: "center",
                  }}
                  placeholder="Enter Category"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    console.log(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.category_error && (
              <div className="offset-6">
                {" "}
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.category_error}
                </p>
              </div>
            )}
          </StyledForm>
        </div>
        <div align="center">
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              marginTop: 50,
            }}
            onClick={() => {
              if (handleValidation()) {
                add_Category();
              } else {
                message.error("Please complete all data");
              }
            }}
          >
            Add
          </StyledButton>
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              backgroundColor: "red",
              marginLeft: 10,
            }}
            onClick={() => {
              setAdd_topic_cat_modal(false);
              resetField();
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </ReactModal>

      {/* Edit modal Category */}

      <ReactModal className="ModalStyle1 Width90" isOpen={edit_category_modal}>
        <div>
          {" "}
          <StyledForm>
            {" "}
            <div align="center" className="mb-3">
              <h4>Edit Category Description</h4>
            </div>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel
                  htmlFor="qdesc"
                  style={{ marginLeft: "20%", fontSize: "20px" }}
                >
                  Category
                </StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    // marginLeft: "30%",
                    alignItems: "center",
                  }}
                  placeholder="Enter Category"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    console.log(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
          </StyledForm>
        </div>
        <div align="center">
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              marginTop: 50,
            }}
            onClick={() => {
              editCat();
            }}
          >
            Edit
          </StyledButton>
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              backgroundColor: "red",
              marginLeft: 10,
            }}
            onClick={() => {
              setEdit_category_modal(false);
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </ReactModal>
    </div>
  );
};

export default ClientTopicCategories;
