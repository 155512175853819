import React, { useState, useEffect } from "react";
import { BASE_URL } from "../url/baseurl";
import styled from "styled-components";
import { message } from "antd";
import { guvrixadmin_check_api } from "../services/NewUI_API";
const GuvrixAdmin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState([]);
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      formErrors["email_error"] = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      formErrors["email_error"] = "Invalid Email id.";
    }
    if (!password) {
      formIsValid = false;
      formErrors["password_error"] = "Password is required.";
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const Login_Function = async () => {
    let data = {
      email: email,
      password: password,
    };
    guvrixadmin_check_api(data).then((response) => {
      console.log(response.data.code);
      if (response.data.code == 200) {
        message.success(response.data.message);
        localStorage.setItem("Name", "vasanth");
        localStorage.setItem("token", "token");
        // console.log(localStorage.getItem('Name'));
        window.location.href = "/Support_menu";
      } else {
        message.error(response.data.message);
        console.log(response.data.message);
      }
    });
  };
  useEffect(() => {
    localStorage.removeItem("Name");
    localStorage.setItem("brandColor", "#ff8c24");
    localStorage.setItem("brandHeader", "#212529");
    localStorage.setItem("brandIcon", "customer/guvrix_logo.png");
    

    console.log("Name ==>", localStorage.getItem("Name"));
  }, []);
  function myFunction() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <>
      <div className="container">
        <div
          className="mt-5"
          style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}
        >
          {/* <p>
            Never logged in? &nbsp;
            <a
              
            >
              Sign up
            </a>
          </p> */}
        </div>
        <div className="text-center">
          <a onClick={() => {}}>
            <i
              style={{ color: "#ff8c24", fontSize: "25px" }}
              class="fas fa-info-circle"
            ></i>
          </a>
        </div>

        <div
          class="card mx-auto mt-3 Width100 shadow"
          style={{ width: "40%", borderRadius: 20 }}
        >
          <div
            class="card-header pt-3 pb-3 text-center"
            style={{
              background: "#ff8c24",
              color: "white",
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <i class="fas fa-sign-in-alt"></i>&nbsp; Sign in
          </div>

          <div class="card-body">
            <div
              class="input-group mb-3"
              style={{ border: "1px solid #ff8c24" }}
            >
              <span
                class="input-group-text"
                style={{ background: "white", border: "none" }}
              >
                <i style={{ color: "#ff8c24" }} class="far fa-envelope"></i>
              </span>
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>

            <div
              class="input-group mb-3"
              style={{ border: "1px solid #ff8c24" }}
            >
              <span
                class="input-group-text"
                style={{ background: "white", border: "none" }}
              >
                <i style={{ color: "#ff8c24" }} class="fas fa-key"></i>
              </span>
              <input
                type="password"
                id="myInput"
                class="form-control"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
              <span
                class="input-group-text"
                style={{ background: "white", border: "none" }}
              >
                {"|"}&nbsp;
                <a onClick={() => myFunction()}>
                  <i class="fas fa-eye"></i>
                </a>
              </span>
            </div>

            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                style={{ color: "black" }}
              />
              <label class="form-check-label">Remember me</label>
            </div>

            <div className="text-center">
              <button
                type="button"
                className="mobileButton"
                style={{
                  width: "40%",
                  paddingTop: 5,
                  paddingBottom: 5,
                  color: "#ff8c24",
                  background: "white",
                  fontSize: 20,
                  fontWeight: "bold",
                  borderRadius: "25px",
                  border: "1px solid #ff8c24",
                }}
                onClick={() => {
                  if (handleValidation()) {
                    Login_Function();
                    console.log("sucess");
                  } else {
                    message.info("Please complete all data");
                  }
                }}
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GuvrixAdmin;
