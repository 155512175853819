import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import {
  new_client,
  enroll_client,
  edit_client,
  change_client,
  delete_client,
  client_enroll,
} from "../../services/ClientProgramApi";
import styled from "styled-components";
import { message } from "antd";
import moment from "moment";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;
const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const StyledButtonEC = styled.button`
  background: ${theme};
  width: 110px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  font-size: 15px !important;
  color: white;
  font-weight: 400;
  border-color: none;
  border: none;
`;

const ClientFunction = ({ match }) => {
  const history = useHistory();
  console.log("propdata", match);

  const [add_client_modal, setAdd_client_modal] = useState(false);
  const [edit_client_modal, setEdit_client_modal] = useState(false);
  const [delete_client_modal, setDelete_client_modal] = useState(false);
  const [client_enroll_modal, setClient_enroll_modal] = useState(false);

  const [userid, setUserid] = useState(localStorage.getItem("UserID"));

  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [startDate, setStartDate] = useState("");
  const [countryList, setCountryList] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [planList, setPlanList] = useState("");
  const [enrollData, setEnrollData] = useState("");

  const [formErrors, setFormErrors] = useState([]);

  // const selection = match.params.selection;
  const customerid = match.params.customerid;

  //hooks for edit modal data
  const [ownerEmail, setOwnerEmail] = useState("");
  const [cust_name, setCust_name] = useState("");
  const [select_country, setSelect_country] = useState("");
  const [enrollPlan, setEnrollPlan] = useState("");
  const [enrollPlan1, setEnrollPlan1] = useState("");
  const [contractorid, setContractorid] = useState("");
  const [enrollplan_id, setEnrollplan_id] = useState("");

  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!ownerEmail) {
      formIsValid = false;
      formErrors["owner_email_error"] = "Owner Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(ownerEmail)) {
      formIsValid = false;
      formErrors["owner_email_error"] = "Invalid Email id.";
    }
    if (!companyName) {
      formIsValid = false;
      formErrors["company_name_error"] = "Company name is required.";
    }
    if (!country) {
      formIsValid = false;
      formErrors["country_error"] = "Country is required.";
    }

    if (!enrollPlan) {
      formIsValid = false;
      formErrors["enroll_plan_error"] = "Enroll plan is required.";
    }
    if (!startDate) {
      formIsValid = false;
      formErrors["start_date_error"] = "Start date is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const addhandleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!ownerEmail) {
      formIsValid = false;
      formErrors["owner_email_error"] = "Owner Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(ownerEmail)) {
      formIsValid = false;
      formErrors["owner_email_error"] = "Invalid Email id.";
    }
    if (!companyName) {
      formIsValid = false;
      formErrors["company_name_error"] = "Company name is required.";
    }
    if (!country) {
      formIsValid = false;
      formErrors["country_error"] = "Country is required.";
    }
    if (!enrollPlan) {
      formIsValid = false;
      formErrors["enroll_plan_error"] = "Enroll plan is required.";
    }
    if (!startDate) {
      formIsValid = false;
      formErrors["start_date_error"] = "Start date is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  //...........Reset Data..........................
  const resetField = () => {
    setOwnerEmail("");
    setCompanyName("");
    setCountry("");
    setEnrollPlan("");
    setStartDate("");
    setFormErrors([]);
  };

  const addClientData = () => {
    new_client({
      contractorid: customerid,
      owneremail: ownerEmail,
      compname: companyName,
      clientcountry: country,
      enrollplanid: enrollPlan,
      startdate: startDate,
    })
      .then((res) => {
        if (res.data.code === 200) {
          message.success("Client added successfully");
          resetField();
          enrollClient(customerid);
          setAdd_client_modal(false);
        } else {
          if (res.data.message === "clientexists") {
            message.error(
              "This Account Owner email is already in your pending client list."
            );
          } else if (res.data.message === "activecust") {
            message.error(
              "This Account Owner email is already an active member with another customer. You cannot use this email to enroll this client."
            );
          } else if (res.data.message === "activeother") {
            message.error(
              "This Account Owner email is already an active member with another customer. A user cannot use the same email for two different customers."
            );
          } else {
            message.error(res.data.message);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const editClientData = () => {
    change_client({
      contractorid: contractorid,
      owneremail: ownerEmail,
      compname: companyName,
      clientcountry: country,
      enrollplanid: enrollPlan,
      startdate: startDate,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setEdit_client_modal(false);
          resetField();
          enrollClient(customerid);
          message.success("Client updated successfully");
        } else {
          if (res.data.message === "clientexists") {
            message.error(
              "This Account Owner email is already in your pending client list."
            );
          } else if (res.data.message === "activecust") {
            message.error(
              "This Account Owner email is already an active member with another customer. You cannot use this email to enroll this client."
            );
          } else if (res.data.message === "activeother") {
            message.error(
              "This Account Owner email is already an active member with another customer. A user cannot use the same email for two different customers."
            );
          } else {
            message.error(res.data.message);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const client_enroll_function = () => {
    client_enroll({
      owneremail: ownerEmail,
      userid: userid,
    })
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res);
          enrollClient(customerid);
          setClient_enroll_modal(false);
          history.push("/subpymt_program");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteClientData = () => {
    delete_client({
      contractorid: contractorid,
      owneremail: ownerEmail,
    })
      .then((res) => {
        if (res.data.code === 200) {
          message.success("Client Deleted successfully");
          enrollClient(customerid);
          setDelete_client_modal(false);
        } else {
          if (res.data.message === "clientexists") {
            message.error(
              "This Account Owner email is already in your pending client list."
            );
          } else if (res.data.message === "activecust") {
            message.error(
              "This Account Owner email is already an active member with another customer. You cannot use this email to enroll this client."
            );
          } else if (res.data.message === "activeother") {
            message.error(
              "This Account Owner email is already an active member with another customer. A user cannot use the same email for two different customers."
            );
          } else {
            message.error(res.data.message);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchClientData = () => {
    edit_client()
      .then((res) => {
        console.log("fetchClientData", res);
        setCountryList(res.data?.Countrylist);
        setCustomerList(res.data?.Customerlist);
        setPlanList(res.data?.Planlist);

        setTimeout(() => {
          console.log(countryList);
          console.log(customerList);
          console.log(planList);
        }, 700);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const enrollClient = async (customerid) => {
    enroll_client(customerid)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          setEnrollData(res.data.Pendinglist);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    enrollClient(customerid);
    fetchClientData();
    setTimeout(() => {
      console.log("message", enrollData);
    }, 700);
  }, []);

  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center">
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/Extquestion2/true");
            }}
          >
            Return to Client Menu
          </StyledButton1>
        </div>
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              setAdd_client_modal(true);
            }}
          >
            Add New Client
          </StyledButton1>
        </div>
      </div>

      <ReactModal className="ModalStyle1 Width90" isOpen={add_client_modal}>
        <div>
          {" "}
          <StyledForm>
            {" "}
            <div align="center" className="mb-3">
              <h4>Add New Client</h4>
            </div>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Account Owner Email</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  type="email"
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  placeholder="Enter Owner Email"
                  value={ownerEmail}
                  onChange={(e) => {
                    setOwnerEmail(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.owner_email_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.owner_email_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Client's Name </StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  type="text"
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  placeholder="Enter Client's Name"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.company_name_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.company_name_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Home Country</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select country
                  </option>

                  {countryList &&
                    countryList?.map((data, index) => {
                      return (
                        <option value={data.countrycode} key={data.countrycode}>
                          {data.countryfulldesc +
                            "  [" +
                            data.countrycode +
                            "]"}
                        </option>
                      );
                    })}
                </select>
              </div>
            </StyledFormInputDiv>
            {formErrors.country_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.country_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Enrolled Plan</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={enrollPlan}
                  onChange={(e) => {
                    setEnrollPlan(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select plan
                  </option>

                  {planList &&
                    planList?.map((data, index) => {
                      return (
                        <option value={data.pymtid} id={data.pymtid}>
                          {data.pymtdesc}
                        </option>
                      );
                    })}
                </select>
              </div>
            </StyledFormInputDiv>
            {formErrors.enroll_plan_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.enroll_plan_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel
                  onClick={() => console.log(startDate)}
                  htmlFor="qdesc"
                >
                  Plan Start Date
                </StyledLabel>
              </div>
              <div style={FormDivStyle}>
                <input
                  type="date"
                  value={startDate}
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.start_date_error && (
              <div className="offset-8">
                {" "}
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.start_date_error}
                </p>
              </div>
            )}
          </StyledForm>
        </div>
        <div align="center">
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              marginTop: 50,
            }}
            onClick={() => {
              if (addhandleValidation()) {
                addClientData();
              } else {
                message.error("Please complete all data");
              }
            }}
          >
            Save New Client
          </StyledButton>
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              backgroundColor: "red",
              marginLeft: 10,
            }}
            onClick={() => {
              setAdd_client_modal(false);
              resetField();
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </ReactModal>

      {/* --------------- Edit Details Modal ------------ */}
      <ReactModal className="ModalStyle1 Width90" isOpen={edit_client_modal}>
        <div>
          {" "}
          <StyledForm>
            {" "}
            <div align="center" className="mb-3">
              <h4>Edit Client Details</h4>
            </div>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Account Owner Email</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  type="email"
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  disabled={true}
                  value={ownerEmail}
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.owner_email_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.owner_email_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Client's Name </StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  type="text"
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.company_name_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.company_name_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Home Country</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                >
                  <option value={country} selected disabled hidden>
                    {country}
                  </option>

                  {countryList &&
                    countryList?.map((data, index) => {
                      return (
                        <option value={data.countrycode} key={data.countrycode}>
                          {data.countryfulldesc +
                            "  [" +
                            data.countrycode +
                            "]"}
                        </option>
                      );
                    })}
                </select>
              </div>
            </StyledFormInputDiv>
            {formErrors.country_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.country_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Enrolled Plan</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={enrollPlan}
                  placeholder="hjdfvcghghjdfjhdv"
                  onChange={(e) => {
                    setEnrollPlan(e.target.value);
                  }}
                >
                  <option value={enrollplan_id} selected disabled hidden>
                    {enrollplan_id}
                  </option>

                  {planList &&
                    planList?.map((data, index) => {
                      console.log(
                        "====>>",
                        enrollPlan,
                        enrollplan_id,
                        data.pymtid
                      );
                      return (
                        <option value={data.pymtid} id={data.pymtid}>
                          {data.pymtdesc}
                        </option>
                      );
                    })}
                </select>
              </div>
            </StyledFormInputDiv>
            {formErrors.enroll_plan_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.enroll_plan_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel
                  onClick={() =>
                    console.log(moment(startDate).format("dd-MM-yyyy"))
                  }
                  htmlFor="qdesc"
                >
                  Plan Start Date
                </StyledLabel>
              </div>
              <div style={FormDivStyle}>
                <input
                  type="date"
                  defaultValue={moment(startDate).format("yyyy-MM-DD")}
                  // placeholder={startDate}
                  format="yyyy-MM-DD"
                  style={{
                    width: "90%",
                    marginLeft: "10%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
            {formErrors.start_date_error && (
              <div className="offset-8">
                {" "}
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.start_date_error}
                </p>
              </div>
            )}
          </StyledForm>
        </div>
        <div align="center">
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              marginTop: 50,
            }}
            onClick={() => {
              if (handleValidation()) {
                editClientData();
              } else {
                message.error("Please complete all data");
              }
            }}
          >
            Edit Client
          </StyledButton>
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              backgroundColor: "red",
              marginLeft: 10,
            }}
            onClick={() => {
              setEdit_client_modal(false);
              resetField();
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </ReactModal>

      {/* --------------- Delete Details Modal ------------ */}
      <ReactModal className="ModalStyle2 Width90" isOpen={delete_client_modal}>
        <div>
          <div align="center" className="mb-3">
            <h4>
              Are you sure you want to Delete this client?
            </h4>
            <StyledButton
              style={{ width: "30%", height: 30, marginTop: 50 }}
              onClick={() => {
                deleteClientData();
              }}
            >
              Delete Client
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setDelete_client_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </ReactModal>

      {/* --------------- Enroll Client Button Modal ------------ */}
      <ReactModal className="ModalStyle2 Width90" isOpen={client_enroll_modal}>
        <div>
          <div align="center" className="mb-3">
            <h4>
              Are you sure you want to Enroll this client?
            </h4>
            <StyledButton
              style={{ width: "30%", height: 30, marginTop: 50 }}
              onClick={() => {
                client_enroll_function();
              }}
            >
              Enroll Client
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setClient_enroll_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </div>
      </ReactModal>

      <div className="m-4 table-responsive tableFixHeadXXLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData>S.No</StyledTableHeaderData>
              <StyledTableHeaderData>Account Owner Email</StyledTableHeaderData>
              <StyledTableHeaderData>Client's Name</StyledTableHeaderData>
              <StyledTableHeaderData>Home Country</StyledTableHeaderData>
              <StyledTableHeaderData>Enrolled Plan</StyledTableHeaderData>
              <StyledTableHeaderData>Plan Start Date</StyledTableHeaderData>
              <StyledTableHeaderData>Note</StyledTableHeaderData>
              <StyledTableHeaderData>Action</StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {enrollData &&
              enrollData?.map((data, index) => (
                <StyledTableRow>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td style={{ color: "black" }}>{data.owneremail}</td>
                  <td style={{ color: "black" }}>{data.compname}</td>
                  <td style={{ color: "black" }}>{data.clientcountry}</td>
                  <td style={{ color: "black" }}>{data.pymtdesc}</td>
                  <td style={{ color: "black" }}>{data.startdate}</td>
                  <td style={{ color: "black" }}>
                    {data.clientid ? "Enroll pending" : ""}
                  </td>
                  <td>
                    <div hidden={data.clientid != null ? true : false} className="d-flex">
                      <a
                        onClick={() => {
                          for (let i = 0; i < customerList.length; i++) {
                            if (
                              customerList[i] &&
                              customerList[i].customerid == data.contractorid
                            ) {
                              setCust_name(customerList[i].custdesc);
                            }
                          }
                          for (let i = 0; i < planList.length; i++) {
                            if (
                              planList[i] &&
                              planList[i].pymtid == data.enrollplanid
                            ) {
                              setEnrollPlan(planList[i].pymtid);
                              setEnrollplan_id(planList[i].pymtdesc);
                              console.log(
                                "===>",
                                enrollPlan,
                                planList[i].pymtid
                              );
                            }
                          }

                          //  setContractorid(data.contractorid)

                          setOwnerEmail(data.owneremail);
                          setCompanyName(data.compname);
                          setCountry(data.clientcountry);
                          setStartDate(data.startdate);
                          setContractorid(data.contractorid);

                          setEdit_client_modal(true);
                        }}
                      >
                        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                      </a>
                      &nbsp; &nbsp;
                      <a
                        onClick={() => {
                          setOwnerEmail(data.owneremail);
                          setContractorid(data.contractorid);
                          setDelete_client_modal(true);
                          console.log("deleted");
                        }}
                      >
                        <i
                          class="fas fa-trash"
                          // hidden={data.clientid}
                          style={{ color: "red" }}
                          aria-hidden="true"
                        ></i>
                      </a>
                      &nbsp; &nbsp;
                      <StyledButtonEC
                        style={{ alignContent: "flex-start" }}
                        onClick={() => {
                          setClient_enroll_modal(true);
                          setOwnerEmail(data.owneremail);
                        }}
                      >
                        Enroll Client
                      </StyledButtonEC>
                    </div>
                  </td>
                </StyledTableRow>
              ))}
          </tbody>
        </StyledTable>
      </div>
    </div>
  );
};

export default ClientFunction;
