import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import styled from "styled-components";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: darkgrey;
  font-weight: 500;
  text-decoration: none;
  font-size: larger;

  &:active {
    text-decoration: none;
    color: darkgrey;
  }
  &:hover {
    text-decoration: none;
    color: grey;
    cursor: pointer;
  }
`;

const WelcomeHeader = () => {
  return (
    <AppBar
      style={{
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        {/* <Link to="/welcome">
          {" "}
          <img
            src={logo}
            alt="logo"
            style={{
              width: "118px",
              height: "138px",
            }}
          />{" "}
        </Link> */}
        <StyledLink to="/welcome"> Home </StyledLink>
      </Toolbar>
    </AppBar>
  );
};

export default WelcomeHeader;
