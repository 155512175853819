import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { edit_program_api , getupdate_details_api} from "../../services/NewUI_API";
import { needlist_loccountry_locsectors_api } from "../../services/AdminProgramApi";
import styled from "styled-components";
import Form from 'react-bootstrap/Form';
const theme = localStorage.getItem('brandColor');
const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 30px;
  height: 40px;
  text-align: center;
  font-size: 20px !important;
  color: white;
  font-weight: 900;
  border-color: none;
  border: none;
`;

const Edit_Profile = () => {
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [location, setLocation] = useState("");
  const [s_location, setS_location] = useState("");
  const [twoFactor, setTwoFactor] = useState("N");
  const [twoFa, setTwoFa] = useState(false);
  const [updated, setUpdated] = useState("");
  const [switchState, setSwitchState] = useState();
  // const [userId, setUserId] = useState()

  const [countryList, setCountryList] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  //let update = "";
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!firstName) {
      formIsValid = false;
      formErrors["firstName_error"] = "First Name is required.";
    }
    if (!location) {
      formIsValid = false;
      formErrors["location_error"] = "Home Country is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const Country_list = async () => {
    let data = "loccountry";
    needlist_loccountry_locsectors_api(data)
      .then((res) => {
        if ((res.data.code = 200)) {
          console.log("country list res==>", res.data);
          setCountryList(res.data.country_list_for_locations);
        }
      })
      .catch((err) => {
        console.log("error===>", err);
      });
  };

  useEffect(() => {
    Country_list();
  }, []);


  const resetFields = () => {
    setFirstName("")
          setLocation("")
          setS_location("")
          //setSwitchState(false);
          //update = "";
          // if(update == ""){
          //   setSwitchState(false);
          // }
  }

  const EditProfile_F = () => {
    edit_program_api({
      userid: localStorage.getItem("UserID"),
      fname: firstName,
      home_country: location,
      home_state: s_location,
      twofa: twoFactor,
    })
      .then((res) => {
        if (res.data.code === 200) {
         
          
          console.log(res);
          message.success("Edited Successfully");
         // resetFields();
          // setFirstName("")
          // setLocation("")
          // setS_location("")
          // setSwitchState(false);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getupdate_details =  async () => {
    getupdate_details_api({

      userid: localStorage.getItem("UserID"),
      
      
    })
      .then((res) => {
        if (res.data.code === 200) {
          let update = res.data.userupdate;
          setFirstName(res.data.editdetails[0]['firstname'])
          setLocation(res.data.editdetails[0]['homecountry']);
          setS_location(res.data.editdetails[0]['homestate']);
          //setTwoFactor(res.data.editdetails[0]['twofa'])
          setUpdated(res.data.editdetails[0]['twofa']);
          //let update = updated;
          console.log(update);
          console.log("toooo",updated);
          
          //if(update == 'Y'){
            if(update[0]['twofa'] == 'Y' ){
              //setTwoFactor('Y');
            setSwitchState(true);
          }else{
            //setTwoFactor("N");
            setSwitchState(false);
          }
          console.log("2fa",update)
          console.log("2fa1",switchState)
          //setUpdated(res.data.userupdate[0]['twofa']);
          console.log("update", res.data.userupdate[0]['twofa'])
          console.log(res);

        } else {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getupdate_details();
   
  }, []);

  // const brand = (value) => {
  //   setTwoFactor({twoFa:value?"Yes":"No"});
  //   console.log(twoFactor,"twoFactor");
  // }

  const twofact = () => {
    if(twoFactor == "N"){
      setTwoFactor("Y");
      console.log(twoFactor,"two")
      console.log("update",updated);
    }
    else{
      setTwoFactor("N");
      console.log(twoFactor,"two")
      console.log("update",updated);
    }
    console.log(twoFactor,"two")
  }
  return (
    <>
      <div className="container">
        <div className="mt-4 d-flex justify-content-around" align="center">
          <StyledButton1
            style={{ alignContent: "flex-start" }}
            onClick={() => {
              localStorage.getItem("PlanID") == 1
                ? history.push("/dashboard")
                : history.push("/Extquestion2");
            }}
          >
            Return to Main Menu
          </StyledButton1>
        </div>

        <div className="text-center mt-3">
          <span style={{ fontSize: 30, fontWeight: "bold", color: theme }}>
            EDIT PROFILE
          </span>
          <p style={{ fontWeight: "bold", fontSize: 16, letterSpacing: 0.5 }}>
            Feel free to edit your username or country.
          </p>
        </div>

        <div className="mt-5" style={{ marginLeft: "15%", marginRight: "15%" }}>
          <form>
            <div class="form-group">
              <label style={{ fontWeight: "bold", fontSize: 16 }}>
                First Name
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
            {formErrors.firstName_error && (
              <div>
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.firstName_error}
                </p>
              </div>
            )}
            <div class="form-group mt-3">
              <label style={{ fontWeight: "bold", fontSize: 16 }}>
                Home Country
              </label>
              <select
                class="form-control form-select"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                value={location}
              >
                <option value="" selected disabled hidden>
                  Select Country
                </option>
                {countryList?.map((data) => {
                  return (
                    <option value={data.countrycode}>
                      {data.countryfulldesc}
                    </option>
                  );
                })}
              </select>
            </div>
            {formErrors.location_error && (
              <div>
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.location_error}
                </p>
              </div>
            )}
            <div class="form-group mt-3">
              <label style={{ fontWeight: "bold", fontSize: 16 }}>
                Home State
              </label>
              <select
                class="form-control form-select"
                onChange={(e) => {
                  setS_location(e.target.value);
                }}
                disabled={location == "USA" ? false : true}
                value={s_location}
              >
                <option value="" selected disabled hidden>
                  Select State
                </option>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="District of Columbia">
                  District of Columbia
                </option>
                <option value="Micronesia">Micronesia</option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Guam">Guam</option>
                <option value="Hawall">Hawall</option>
                <option value="Idaho">Idaho</option>
                <option value="Illinois">Illinois</option>
                <option value="Indianal">Indianal</option>
                <option value="lowa">lowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Apps">Apps</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </option>
                <option value="Chio">Chio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Palau">Palau</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virgin Islands">Virgin Islands</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
                <option value="Armed Forces Americas">
                  Armed Forces Americas
                </option>
                <option value="Armed Forces Europe, Canada, Africa and Middle East">
                  Armed Forces Europe, Canada, Africa and Middle East
                </option>
                <option value="Armed Forces Pacific">
                  Armed Forces Pacific
                </option>
              </select>
            </div>

            <div class="form-group mt-3">
              <label style={{ fontWeight: "bold", fontSize: 16 }}>
              Provide Security Code via Email (Two Factor Authentication)
              </label>
              <div style={{ width: "100%", alignSelf: "center" }}>
              
              <Form.Check
                 style={{ width: "100%" }}
                    type="switch"
                    id="custom-switch"
                    value={twoFactor}
                    defaultChecked={switchState}
                    onChange={(e) => {
                      twofact();
                      // if(twoFactor == "Y"){
                      //   setTwoFactor("N");
                      // }else{
                      //   setTwoFactor("Y");
                      // }
                       console.log(e.target.value,"e.target.value");
                       console.log(twoFactor,"twoFactor");
                    }}

                    // onValueChange={(value) => {
                    //   brand(value);
                    //   console.log(twoFactor,"twoFactor");
                    // }}
                    //  value={defaultBrand}
                    //  defaultChecked={switchState}
                    //  //default={switchState}
                    
                    // onChange={() => {
                    //   setDisabled(!disabled)
                    //   console.log(disabled);
                      
                    //   Default();
                     
                    // }}
                  >
                    
                  </Form.Check>
                    </div>
              </div>


          </form>

          <button
            className="mt-3 mb-5 mobileButton"
            type="button"
            style={{
              width: "30%",
              paddingTop: 8,
              paddingBottom: 8,
              color: theme,
              background: "white",
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: "25px",
              border: `1px solid ${theme}`,
            }}
            onClick={() => {
              if (handleValidation()) {
                EditProfile_F();
              } else {
                message.info("Please complete all data");
              }
            }}
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default Edit_Profile;
