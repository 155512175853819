import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import {
  assign_topic,
  new_assign,
  add_assign,
} from "../../services/ClientProgramApi";
import axios from "axios";
import { BASE_URL } from "../../url/baseurl";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const TopicAssignment = ({ match }) => {
  const history = useHistory();
  console.log("propdata", match);

  const [edit_client_modal, setEdit_client_modal] = useState(false);
  const [add_assign_modal, setAdd_assign_modal] = useState(false);
  const [clientName, setClientName] = useState("");
  const [assignedTopic, setAssignedTopic] = useState("");
  const [activeAssignment, setActiveAssignment] = useState("");
  const [client_id, setClient_id] = useState("");
  const [topic_id, setTopic_id] = useState("");
  const [activeChange, setActiveChange] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [asnTopicList, setAsnTopicList] = useState([]);
  const [assignedTopicId, setAssignedTopicId] = useState("");
  const [clientNameId, setClientNameId] = useState("");
  const [formErrors, setFormErrors] = useState([]);

  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!clientNameId) {
      formIsValid = false;
      formErrors["clientNameId_error"] = "Client Name is required.";
    }
    if (!assignedTopicId) {
      formIsValid = false;
      formErrors["assignedTopicId_error"] = "Assigned Topic is required.";
    }
    if (!activeAssignment) {
      formIsValid = false;
      formErrors["activeAssignment_error"] = "Active Assignment is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  //...........Reset Data..........................
  const resetField = () => {
    setClientNameId("");
    setAssignedTopicId("");
    setActiveAssignment("");
    setFormErrors([]);
  };

  const customerid = match.params.customerid;

  const editTopicAssignment = async () => {
    let data = {
      clientid: client_id,
      topicid: topic_id,
      activetc: activeAssignment,
    };

    let res = await axios.get(
      `${BASE_URL}/api/active_change?customerid=${customerid}&
      clientid=${client_id}&
      topicid=${topic_id}&
      activetc=${activeAssignment}
      `,
      data
    );
    console.log("res--->>", res);

    if (res.data.code == 200) {
      setEdit_client_modal(false);
      resetField();
      AsnTopic(customerid);
      message.success("Successfully Updated");
    } else {
      message.error(res.data.message);
    }
  };

  const AsnTopic = async (customerid) => {
    assign_topic(customerid)
      .then((res) => {
        console.log(res);

        if (res.data.code === 200) {
          setActiveChange(res.data.Assignlist);
        } else {
          message.error(res.data.message);
        }
        console.log("enrolldata", res.data.Assignlist);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addSelectData = () => {
    add_assign({
      clientid: clientNameId,
      topicid: assignedTopicId,
      activetc: activeAssignment,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setAdd_assign_modal(false);
          resetField();
          message.success("Client added successfully");
          AsnTopic(customerid);
        } else if (res.data.code == 400 && res.data.message == "assignexists") {
          message.error("This topic has already been assigned to this client.");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchData = (customerid) => {
    new_assign(customerid)
      .then((res) => {
        if(res.data.code == 200) {
          console.log("fetchData", res);
          setClientsList(res.data?.Activeclient);
          setAsnTopicList(res.data?.Activetopic);

          setTimeout(() => {
            console.log(clientsList);
            console.log(asnTopicList);
          }, 700);
        } else {
          message.error("No client has been enrolled");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    AsnTopic(customerid);
    fetchData(customerid);
    setTimeout(() => {
      console.log("message", activeChange);
      console.log(customerid);
    }, 700);
  }, []);

  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center">
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/Extquestion2/true");
            }}
          >
            Return to Client Menu
          </StyledButton1>
        </div>
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              setAdd_assign_modal(true);
            }}
          >
            Add Topic to Client
          </StyledButton1>
        </div>
      </div>

      {/* --------------- Add Topic to Client Modal ------------ */}
      <ReactModal className="ModalStyle1 Width90" isOpen={add_assign_modal}>
        <div>
          {" "}
          <StyledForm>
            {" "}
            <div align="center" className="mb-3">
              <h4>Add Topic to Client</h4>
            </div>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Client Name</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  style={{
                    width: "100%",
                    marginLeft: "0%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={clientNameId}
                  onChange={(e) => {
                    setClientNameId(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Client Name
                  </option>
                  {clientsList &&
                    clientsList?.map((data, index) => {
                      return (
                        <option value={data.clientid} label={data.custdesc}>
                          {data.custdesc}
                        </option>
                      );
                    })}
                </select>
              </div>
            </StyledFormInputDiv>
            {formErrors.clientNameId_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.clientNameId_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Assigned Topic</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  style={{
                    width: "100%",
                    marginLeft: "0%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={assignedTopicId}
                  onChange={(e) => {
                    setAssignedTopicId(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Assigned Topic
                  </option>
                  {asnTopicList &&
                    asnTopicList?.map((data, index) => {
                      // console.log("---->>>",data[index].customerid)
                      return (
                        <option value={data.topicid}>{data.cuzdesc}</option>
                      );
                    })}
                </select>
              </div>
            </StyledFormInputDiv>
            {formErrors.assignedTopicId_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.assignedTopicId_error}
                </p>
              </div>
            )}
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Active Assignment</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  style={{
                    width: "100%",
                    marginLeft: "0%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={activeAssignment}
                  onChange={(e) => {
                    setActiveAssignment(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Active Assignment
                  </option>
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
              </div>
            </StyledFormInputDiv>
            {formErrors.activeAssignment_error && (
              <div className="offset-8">
                <p
                  style={{
                    color: "red",
                    fontSize: ".8rem",
                    marginTop: "0px",
                  }}
                >
                  {formErrors.activeAssignment_error}
                </p>
              </div>
            )}
          </StyledForm>
        </div>
        <div align="center">
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              marginTop: 50,
            }}
            onClick={() => {
              if (handleValidation()) {
                addSelectData();
              } else {
                message.error("Please complete all data");
              }
            }}
          >
            Save New Assignment
          </StyledButton>
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              backgroundColor: "red",
              marginLeft: 10,
            }}
            onClick={() => {
              setAdd_assign_modal(false);
              resetField();
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </ReactModal>

      {/* --------------- Edit Details Modal ------------ */}
      <ReactModal className="ModalStyle1 Width90" isOpen={edit_client_modal}>
        <div>
          {" "}
          <StyledForm>
            {" "}
            <div align="center" className="mb-3">
              <h4>Edit Assigned Topic</h4>
            </div>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Client Name</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  style={{
                    width: "85%",
                    marginLeft: "15%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={clientName}
                  disabled={true}
                  onChange={(e) => {
                    setClientName(e.target.value);
                    console.log(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Assigned Topic</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <input
                  style={{
                    width: "85%",
                    marginLeft: "15%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={assignedTopic}
                  disabled={true}
                  onChange={(e) => {
                    setAssignedTopic(e.target.value);
                    console.log(e.target.value);
                  }}
                />
              </div>
            </StyledFormInputDiv>
            <StyledFormInputDiv>
              <div style={FormDivStyle}>
                <StyledLabel htmlFor="qdesc">Active Assignment</StyledLabel>
              </div>
              <div style={{ width: "100%", alignSelf: "center" }}>
                <select
                  style={{
                    width: "85%",
                    marginLeft: "15%",
                    alignItems: "center",
                    border: "1px solid black",
                  }}
                  value={activeAssignment}
                  onChange={(e) => {
                    setActiveAssignment(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Active Assignment
                  </option>
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
              </div>
            </StyledFormInputDiv>
          </StyledForm>
        </div>
        <div align="center">
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              marginTop: 50,
            }}
            onClick={() => {
              editTopicAssignment();
            }}
          >
            Save Changes
          </StyledButton>
          <StyledButton
            style={{
              width: "30%",
              height: 30,
              backgroundColor: "red",
              marginLeft: 10,
            }}
            onClick={() => {
              setEdit_client_modal(false);
              resetField();
            }}
          >
            Cancel
          </StyledButton>
        </div>
      </ReactModal>

      <div className="m-4 table-responsive tableFixHeadXXLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData>S.No</StyledTableHeaderData>
              <StyledTableHeaderData>Client's Name</StyledTableHeaderData>
              <StyledTableHeaderData>Assigned Topic</StyledTableHeaderData>
              <StyledTableHeaderData>Active Assignment</StyledTableHeaderData>
              <StyledTableHeaderData>Action</StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>
          <tbody>
            {activeChange &&
              activeChange?.map((data, index) => (
                <StyledTableRow>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td style={{ color: "black" }}>{data.custdesc}</td>
                  <td style={{ color: "black" }}>{data.cuzdesc}</td>
                  <td style={{ color: "black" }}>{data.activetc}</td>
                  <td>
                    <a
                      onClick={() => {
                        setClientName(data.custdesc);
                        setAssignedTopic(data.cuzdesc);
                        setActiveAssignment(data.activetc);
                        setClient_id(data.clientid);
                        setTopic_id(data.topicid);
                        setEdit_client_modal(true);
                      }}
                    >
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                  </td>
                </StyledTableRow>
              ))}
          </tbody>
        </StyledTable>
      </div>
    </div>
  );
};

export default TopicAssignment;
